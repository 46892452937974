import { actionTypes as types } from '../constants';

const download = {
  fetching: false,
  fetched: false,
  result: {},
  requestBody: false,
  requestStatus: false,
  error: '',
};
const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestBody: false,
  requestStatus: false,
  error: '',
  MTdownloadStatus: false,
  manageTransactionEmailStatus: false,
  manageTransactionDownload: download,
};
export const manageTransactionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.MANAGE_TRANSACTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {}, requestBody: action.requestBody,
    };
  case types.MANAGE_TRANSACTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.MANAGE_TRANSACTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const refundLinesTransactionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.REFUND_LINES_TRANSACTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {}, requestBody: action.requestBody,
    };
  case types.REFUND_LINES_TRANSACTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.REFUND_LINES_TRANSACTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const viewTransactionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.VIEW_TRANSACTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.VIEW_TRANSACTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.VIEW_TRANSACTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const addTransactionNoteResult = (state = initialState, action) => {
  switch (action.type) {
  case types.ADD_TRANSACTION_NOTE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.ADD_TRANSACTION_NOTE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.ADD_TRANSACTION_NOTE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const captureOrRefundTransactionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CAPTURE_REFUND_TRANSACTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CAPTURE_REFUND_TRANSACTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CAPTURE_REFUND_TRANSACTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const updateStatusReviewResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_STATUS_REVIEW_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_STATUS_REVIEW_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_STATUS_REVIEW_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const getMTdownloadStatus = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
    case types.MT_DOWNLOAD_STATUS:
      return {
        ...state, MTdownloadStatus: action.data,
      };
    default:
      return { ...state, MTdownloadStatus: false };
    }
  } else {
    return state;
  }
};

export const emailManageTransactionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.EMAIL_TRANSACTION_REPORT_REQUEST:
    return { ...state, manageTransactionEmailStatus: false };
  case types.EMAIL_TRANSACTION_REPORT_SUCCESS:
    return { ...state, manageTransactionEmailStatus: action };
  case types.EMAIL_TRANSACTION_REPORT_FAILURE:
    return { ...state, manageTransactionEmailStatus: false };
  default:
    return state;
  }
};

export const updateAdditionalFiltersResult = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_UPDATE_ADDITIONAL_FILTERS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.POST_UPDATE_ADDITIONAL_FILTERS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.POST_UPDATE_ADDITIONAL_FILTERS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const manageTransactionDownloadResult = (state = initialState.manageTransactionDownload, action) => {
  switch (action.type) {
    case types.MANAGE_TRANSACTION_DOWNLOAD_CLEAR:
      return {
        ...state, fetching: true, error: false, result: {}, requestBody: false,
      }; 
  case types.MANAGE_TRANSACTION_DOWNLOAD_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {}, requestBody: action.requestBody,
    };
  case types.MANAGE_TRANSACTION_DOWNLOAD_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.MANAGE_TRANSACTION_DOWNLOAD_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const additionalFiltersResult = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_ADDITIONAL_FILTERS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.POST_ADDITIONAL_FILTERS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.POST_ADDITIONAL_FILTERS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
