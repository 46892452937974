import React, { useState, useEffect } from 'react';
import Multiselect from '@khanacademy/react-multi-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Col, Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import { Accordion } from 'react-bootstrap';
import globals from '../../../../../redux/constants/globals';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import {
  getClientConfigurationRequest, getSystemMetaDataRequest, getPaymentMetaDataRequest, updateClientConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import arrowdown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import { authModeListArray } from '../Constants';
import { activeKeyMerchantConfiguration, validationRequiredInputMessage } from '../Constants';
import { alertMessageSetTimeOut } from '../Helper';

function MerchantConfiguration(props) {
  const [showLoader, setLoader] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [inputField, setInpuField] = useState({
    clientId: '',
    clientName: '',
    country: '',
    currency: '',
    language: '',
    transactionTimeToLive: '',
    paymentMethod: '',
    storeFront: '',
    timezone: '',
    mode: '',
    authenticationMode: '',
    smsNotification: '',
    emailNotification: '',
    maxAmount: '',
    salt: '',
    merchantType: '',
    masterMerchant: '',
    username: '',
    password: '',
  });

  const [validationError, setValidationError] = useState({
    countryValue: false,
    authenticationModeValue: false,
    maxAmountValue: false,
    languageValue: false,
    timezoneValue: false,
    paymentMethodValue: false,
  });

  // Getting the data from local storage
  // const getData = () => {
  //   const localStorageConfig = localStorage.getItem('clientConfiguration');
  //   const configObject = localStorageConfig && JSON.parse(localStorageConfig);
  //   const currentClientId = globals.clientId;
  //   return configObject[currentClientId];
  // };
  // Fetching TimeZone Data from local storage using the getData function.
  const selectedTimeZoneData = [];
  const timeZoneDataList = [];

  const getSystemMetaData = () => {
    let data = localStorage.getItem('systemMetaDataResult');
    data = JSON.parse(data);
    return data.systemMetaData;
  };

  const timeZoneData = getSystemMetaData() && getSystemMetaData().timezones;
  if (timeZoneData) {
    timeZoneData.sort((a, b) => a.offset.localeCompare(b.offset));
  }
  const timekey = 'offset';
  const newTimeZoneData = [...new Map(timeZoneData && timeZoneData.map(item => [item[timekey], item])).values()];
  if (newTimeZoneData) {
    newTimeZoneData.forEach(key => {
      const selectOption = {
        timezone_id: key.id,
        timeZoneCode: key.offset,
      };
      timeZoneDataList.push(selectOption);
      if (timeZoneData === key.offset) {
        selectedTimeZoneData.push(selectOption);
      }
    });
  }

  // Fetching data for languages
  const selectedLanguageList = [];
  const languageList = [];
  const languageData = getSystemMetaData() && getSystemMetaData().languages;
  if (languageData) {
    languageData.sort((a, b) => a.localeCode.localeCompare(b.localeCode));
  }
  const key = 'localeCode';
  const newLanguageData = [...new Map(languageData && languageData.map(item => [item[key], item])).values()];
  newLanguageData.forEach(key => {
    const selectOption = {
      language_id: key.id,
      code: key.localeCode,
    };
    languageList.push(selectOption);
    if (newLanguageData === key.code) {
      selectedLanguageList.push(selectOption);
    }
  });

  const viewMode = props.getFormMode.result === 'View';
  useEffect(() => {
    props.getSystemMetaDataRequest();
    if (!props.getSystemMetaDataResult.requestStatus && props.getSystemMetaDataResult.fetched) {
      notify('error', toastMessages.merchantServices.error.backendError);
    }
  }, []);
  useEffect(() => {
    props.getPaymentMetaDataRequest();
    if (props.getPaymentMetaDataResult.requestStatus === false && props.getPaymentMetaDataResult.fetched) {
      notify('error', toastMessages.merchantServices.error.backendError);
    }
  }, []);

  const response = props.getClientConfigurationResult && props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration;
  if (response && !apiCall) {
    setApiCall(true);
    _setData(response);
  }

  function _setData(clientConfiguration) {
    inputField.id = clientConfiguration.id;
    inputField.clientName = clientConfiguration.name;
    inputField.country = clientConfiguration.country_id && clientConfiguration.country_id.toString();
    inputField.language = clientConfiguration.language;
    inputField.timezone = clientConfiguration.timezone;
    inputField.maxAmount = clientConfiguration.max_amount;
    inputField.salt = clientConfiguration.salt;
    inputField.authenticationMode = clientConfiguration.authentication_mode && clientConfiguration.authentication_mode.toString();
    inputField.smsNotification = clientConfiguration.sms_notification;
    inputField.emailNotification = clientConfiguration.email_notification;
    const pmSelectedListArray = [];
    const pmConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.pm_configurations && props.getClientConfigurationResult.result.client_configuration.pm_configurations.pm_configuration;

    if (Array.isArray(pmConfigurations)) {
      pmConfigurations.forEach(key => {
        pmSelectedListArray.push(key.pm_id);
        inputField.paymentMethod = pmSelectedListArray;
      });
    } else {
      pmSelectedListArray.push(pmConfigurations.pm_id);
      inputField.paymentMethod = pmSelectedListArray;
    }
    setLoader(false);
  }

  const _toggleOnChange = field => {
    setInpuField({ ...inputField, [field]: !inputField[field] });
  };

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'paymentMethod') {
      const eventUpdated = event.filter(element => element !== undefined);
      value = eventUpdated;
    } else if (field === 'transactionTime' || field === 'salt' || field === 'country' || field === 'language' || field === 'timezone' || field === 'authenticationMode' || field === 'maxAmount') {
      value = event.target.value;
    } else {
      value = event;
    }
    setInpuField({ ...inputField, [field]: value });
  };

  const authenticationModeSelectedListArray = [];
  const authenticationModeListArray = [];

  const authenticationModeConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.authentication_mode;
  authModeListArray.forEach(key => {
    const selectOption = {
      id: key.id,
      authentication_mode: key.name,
    };
    authenticationModeListArray.push(selectOption);
    if (authenticationModeConfigurations === key.name) {
      authenticationModeSelectedListArray.push(selectOption);
    }
  });

  const countrySelectedListArray = [];
  const countryAPICall = countryList => {
    const countryConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.country_id;
    countryList.forEach(key => {
      const selectOption = {
        country_id: key.id,
        name: key.name,
      };
      countryListArray.push(selectOption);
      if (countryConfigurations === key.id) countrySelectedListArray.push(selectOption);
    });
  };
  // Populating Country List
  const countryListArray = [];
  let countryDetails = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.country_details && props.getSystemMetaDataResult.result.system_metadata.country_details.country_detail;
  if (countryDetails) {
    countryDetails.sort((a, b) => a.name.localeCompare(b.name));
    if (!Array.isArray(countryDetails)) {
      countryDetails = [{
        ...countryDetails,
      }];
    }
    countryAPICall(countryDetails);
  }

  const currencySelectedListArray = [];
  const currencyAPICall = currencyList => {
    const currencyConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.currency_id;
    currencyList.forEach(key => {
      const selectOption = {
        currency_id: key.id,
        name: key.name,
      };
      currencyListArray.push(selectOption);
      if (currencyConfigurations === key.id) currencySelectedListArray.push(selectOption);
    });
  };
  // Populating currency List
  const currencyListArray = [];
  let currencyDetails = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.currency_details && props.getSystemMetaDataResult.result.system_metadata.currency_details.currency_detail;
  if (currencyDetails) {
    if (!Array.isArray(currencyDetails)) {
      currencyDetails = [{
        ...currencyDetails,
      }];
    }
    currencyAPICall(currencyDetails);
  }

  // populating payment Method List
  const paymentMethodListArray = [];
  let paymentMethods = props.getPaymentMetaDataResult.result && props.getPaymentMetaDataResult.result.payment_metadata && props.getPaymentMetaDataResult.result.payment_metadata.pms && props.getPaymentMetaDataResult.result.payment_metadata.pms.pm;
  if (paymentMethods) {
    if (!Array.isArray(paymentMethods)) {
      paymentMethods = [{
        ...paymentMethods,
      }];
    }

    paymentMethods.forEach(key => {
      paymentMethodListArray.push({
        value: key.id,
        label: key.name,
      });
    });
  }

  // storeFront Listing
  const storeFrontListArray = [];
  let storeFronts = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.account_configurations && props.getClientConfigurationResult.result.client_configuration.account_configurations.account_config;
  if (storeFronts) {
    if (!Array.isArray(storeFronts)) {
      storeFronts = [{
        ...storeFronts,
      }];
    }
    storeFronts.forEach(key => {
      storeFrontListArray.push({
        value: key.id,
        label: key.markup,
      });
    });
  }

  function clientConfigurationValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (inputField.country === '' || inputField.country === 'Please Select' || inputField.country === undefined) {
      errorValue.countryValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.language === '' || inputField.language === 'Please Select' || inputField.language === undefined) {
      errorValue.languageValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.timezone === '' || inputField.timezone === 'Please Select' || inputField.timezone === undefined) {
      errorValue.timezoneValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.authenticationMode === '' || inputField.authenticationMode === 'Please Select' || inputField.authenticationMode === undefined) {
      errorValue.authenticationModeValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.maxAmount === '') {
      errorValue.maxAmountValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.salt === '') {
      errorValue.salt = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (inputField.paymentMethod.length === 0) {
      errorValue.paymentMethodValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else {
      errorValue.countryValue = false;
      errorValue.authenticationModeValue = false;
      errorValue.salt = false;
      errorValue.maxAmountValue = false;
      errorValue.languageValue = false;
      errorValue.timezoneValue = false;
      errorValue.paymentMethodValue = false;
    }
    setValidationError(errorValue);
    return validate;
  }

  const saveClientConfigurationResults = () => {
    if (clientConfigurationValidation()) {
      const pmSelectedListArray = [];
      inputField.paymentMethod.forEach(key => {
        const selectPaymentMethod = {
          pm_id: key,
          enabled: true,
        };
        pmSelectedListArray.push(selectPaymentMethod);
      });

      const requestBody = {
        id: globals.clientId,
        name: inputField.clientName,
        country_id: inputField.country,
        language: inputField.language,
        timezone: inputField.timezone,
        max_amount: inputField.maxAmount,
        salt: inputField.salt,
        authentication_mode: inputField.authenticationMode,
        payment_method: inputField.paymentMethod,
        sms_notification: inputField.smsNotification,
        email_notification: inputField.emailNotification,
        pm_configurations: pmSelectedListArray,
      };
      props.updateClientConfigurationRequest(requestBody);
    }
  };

  // Wait for Sucess result of updateClientConfigurationRequest then call getClientConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyMerchantConfiguration && props.updateClientConfigurationResult.fetched && !props.updateClientConfigurationResult.fetching && props.updateClientConfigurationResult.requestStatus) {
      if (props.updateClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.updated);
      } else {
        notify('error', toastMessages.merchantServices.error.updated);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.updateClientConfigurationResult]);

  // Show Loader still get getClientConfigurationResult
  useEffect(() => {
    if (props.getClientConfigurationResult.fetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  });

  const clearState = () => {
    if (props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration) {
      _setData(props.getClientConfigurationResult.result.client_configuration);
    }

    // Removing the existing validation message in case of cancel button click
    const errorValue = { ...validationError };
    errorValue.countryValue = false;
    errorValue.authenticationModeValue = false;
    errorValue.maxAmountValue = false;
    errorValue.languageValue = false;
    errorValue.timezoneValue = false;
    errorValue.paymentMethodValue = false;
    errorValue.salt = false;
    setValidationError(errorValue);
    notify('success', toastMessages.merchantServices.success.reset);
  };

  return (
    <div className="card">
      <div className="card-header" id="headingTwo">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyMerchantConfiguration ? '' : activeKeyMerchantConfiguration)} eventKey={activeKeyMerchantConfiguration}>
          <h6>
            Merchant Configuration
            <img src={arrowdown} className="float-right" alt="down arrow" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyMerchantConfiguration}>
        <div className="card-body">
          <div className="p-4">
            <Form className="row">
              <div className="p-0 col-sm-12">
                <div>
                  <div className="row">
                    {(!showLoader) ? <Col className="form-group" md={4} style={{ marginBottom: '0px' }}>
                      <Form.Label>Storefronts:&nbsp;</Form.Label>
                      {storeFrontListArray.map((key, index) => <Form.Label style={{ color: 'black' }}>{(key.label && storeFrontListArray.length - 1 > index) ? `${key.label}  |`.concat(' ') : `${key.label}`}</Form.Label>)}
                    </Col>
                      : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                  </div>
                  <div className="col-14"><hr /></div>
                  <div className="row">
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Country</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'country')}
                        value={inputField.country}
                        disabled={viewMode}
                      >
                        <option disabled selected value="">Please Select</option>
                        {countryListArray.map(key => <option value={key.country_id}>{key.name}</option>)}
                      </Form.Control>
                    </Col>
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Language</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'language')}
                        value={inputField.language}
                        disabled={viewMode}
                      >
                        <option disabled selected value="">Please Select</option>
                        {languageList.map(key => <option value={key.language_id}>{key.code}</option>)}
                      </Form.Control>
                    </Col>
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Timezone</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'timezone')}
                        value={inputField.timezone}
                        disabled={viewMode}
                      >
                        <option disabled selected value="">Please Select</option>
                        {timeZoneDataList.map(key => <option value={key.timezone_id}>{key.timeZoneCode}</option>)}
                      </Form.Control>
                    </Col>
                  </div>
                  <div className="row">
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Max Amount</Form.Label>
                      <Form.Control
                        type="number"
                        onChange={e => inputOnChange(e, 'maxAmount')}
                        value={inputField.maxAmount}
                        disabled={viewMode}
                      />
                    </Col>
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Salt</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={e => inputOnChange(e, 'salt')}
                        value={inputField.salt}
                        disabled={viewMode}
                      />
                    </Col>
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Authentication Mode</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'authenticationMode')}
                        value={inputField.authenticationMode}
                        disabled={viewMode}
                      >
                        <option disabled selected value="">Please Select</option>
                        {authModeListArray.map(key => <option value={key.name}>{key.name}</option>)}
                      </Form.Control>
                    </Col>
                  </div>
                  <div className="row">
                    <Col className="form-group" md={4}>
                      <Form.Label className="mandatory">Payment Method</Form.Label>
                      <Multiselect
                        options={paymentMethodListArray}
                        selected={inputField.paymentMethod}
                        value={inputField.paymentMethod}
                        disabled={viewMode}
                        onSelectedChanged={e => inputOnChange(e, 'paymentMethod')}
                        overrideStrings={{
                          selectSomeItems: 'Select Payment Method',
                          allItemsAreSelected: 'All Payment Methods are selected',
                          selectAll: 'Select All Payment Methods',
                        }}
                      />
                    </Col>
                    <Col className="form-group" md={4}>
                      <Form.Label>Notification</Form.Label>
                      <Form.Row md={4}>
                        <Form.Check
                          type="checkbox"
                          checked={inputField.smsNotification}
                          onClick={() => _toggleOnChange('smsNotification')}
                          disabled={viewMode}
                          label="SMS"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Check
                          type="checkbox"
                          checked={inputField.emailNotification}
                          onClick={() => _toggleOnChange('emailNotification')}
                          disabled={viewMode}
                          label="Email"
                        />
                      </Form.Row>
                    </Col>
                  </div>
                  <div className="row">
                    <Col className="form-group" md={8}>
                      {(validationError.countryValue || validationError.languageValue || validationError.timezoneValue || validationError.maxAmountValue || validationError.salt || validationError.authenticationModeValue) && <div className="alert alert-danger">
                        {validationRequiredInputMessage}
                      </div>}
                      {(validationError.paymentMethodValue) && <div className="alert alert-danger">
                        Payment methods may be configured for rules, please edit.
                      </div>}
                    </Col>
                    <Col className="pt-2 pb-2 mt-3" md={4}>
                      <button
                        type="button"
                        disabled={viewMode}
                        className="btn btn-primary float-right btn-sm  mr-3"
                        onClick={() => saveClientConfigurationResults()}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        disabled={viewMode}
                        className="btn btn-outline-primary float-right btn-sm  mr-3"
                        onClick={() => clearState()}
                      >
                        Cancel
                      </button>
                    </Col>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>

  );
}

MerchantConfiguration.propTypes = {

  getSystemMetaDataRequest: PropTypes.object,
  getPaymentMetaDataRequest: PropTypes.object,
  getClientConfigurationRequest: PropTypes.object,
  getClientConfigurationResult: PropTypes.object,
  getSystemMetaDataResult: PropTypes.object,
  getPaymentMetaDataResult: PropTypes.object,
  updateClientConfigurationRequest: PropTypes.func,
  updateClientConfigurationResult: PropTypes.object,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
  getFormMode: PropTypes.any,
};
const mapStateToProps = state => ({
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  getPaymentMetaDataResult: state.getPaymentMetaDataResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  createClientConfigurationResult: state.createClientConfigurationResult,
  updateClientConfigurationResult: state.updateClientConfigurationResult,
  getFormMode: state.getFormMode,
});
export default connect(
  mapStateToProps,
  {
    getSystemMetaDataRequest, getPaymentMetaDataRequest, getClientConfigurationRequest, updateClientConfigurationRequest,
  }
)(MerchantConfiguration);
