export function configurationList(listType, addonConfigurationList) {
  const fx = [];
  if (listType === 'DCC') {
    addonConfigurationList.forEach(addonConfig => {
      const count = fx.length + 1;
      fx.push({
        key: count,
        country: addonConfig.country_name,
        currency: addonConfig.currency_code,
        paymentMethod: addonConfig.pm_name,
        country_id: addonConfig.country_id,
        currency_id: addonConfig.currency_id,
        pm_id: addonConfig.pm_id,
        action: '',
        deleteKey: false,
        existFlag: true,
        dcc: true,
      });
    });
  } else if (listType === 'PCC') {
    addonConfigurationList.forEach(pccAddonConfig => {
      const count = fx.length + 1;
      fx.push({
        key: count,
        saleCurrency: pccAddonConfig.currency_code,
        exchangeCurrency: pccAddonConfig.settlement_currency_code,
        paymentMethod: pccAddonConfig.pm_name,
        currency_id: pccAddonConfig.currency_id,
        settlement_currency_id: pccAddonConfig.settlement_currency_id,
        pm_id: pccAddonConfig.pm_id,
        action: '',
        deleteKey: false,
        existFlag: true,
        pcc: true,
      });
    });
  }
  return fx;
}
