/* eslint-disable */
import {sessionExpiryRedirection} from '../../app/shared/utils/Redirect';

function checkHTTPStatus(status) {
  if (status === 401) {
    localStorage.setItem('sessionExpired', true);
    sessionExpiryRedirection();
  }
}

/**
 * Generates a random traceId and spanId and assign them to the headers.
 * For good, you need to use library `npm install @opentelemetry/api`
 */
function setTracingHeaders(headers) {
  headers['X-B3-TraceId'] = generateRandomHex(16);
  headers['X-B3-SpanId'] = generateRandomHex(8);
}

function generateRandomHex(size) {
  const bytes = new Uint8Array(size);
  crypto.getRandomValues(bytes);
  return Array.from(bytes).map(b => b.toString(16).padStart(2, '0')).join('');
}

export const post = async (
  {
    url, success, failure, dispatch, body, setAuthToken,
  }) => {
  let data;
  const authToken = localStorage.getItem('authToken');
  try {
    const customHeaders = {
      'Content-Type': 'application/json',
    };
    if (setAuthToken && authToken !== null) {
      customHeaders['x-cpm-auth-token'] = authToken;
    }

    setTracingHeaders(customHeaders);

    const res = await fetch(url, {
      method: 'POST',
      headers: customHeaders,
      body: JSON.stringify(body),
    });

    const xAuthToken = res.headers.get('x-cpm-auth-token');
    if (xAuthToken) {
      localStorage.setItem('authToken', xAuthToken);
    }
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const postSync = async ({
  url, bannerNumber, success, failure, dispatch, body, setAuthToken,
}) => {
  let data;
  const authToken = localStorage.getItem('authToken');
  try {
    const customHeaders = {
      'Content-Type': 'application/json',
    };
    if (setAuthToken && authToken !== null) {
      customHeaders['x-cpm-auth-token'] = authToken;
    }

    setTracingHeaders(customHeaders);

    const res = await fetch(url, {
      method: 'POST',
      headers: customHeaders,
      body: JSON.stringify(body),
    });

    const xAuthToken = res.headers.get('x-cpm-auth-token');
    if (xAuthToken) {
      localStorage.setItem('authToken', xAuthToken);
    }
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data, bannerNumber });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data, bannerNumber });
    }
  }
};


export const get = async ({
  url, success, failure, dispatch, setAuthToken, setContentTypeHeder,
}) => {
  let data;
  const authToken = localStorage.getItem('authToken');
  try {
    const customHeaders = {};
    if (setContentTypeHeder) {
      customHeaders['Content-Type'] = 'application/json';
    }
    if (setAuthToken && authToken !== null) {
      customHeaders['x-cpm-auth-token'] = authToken;
    }

    setTracingHeaders(customHeaders);

    const res = await fetch(url, {
      method: 'get',
      headers: customHeaders,
    });
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};

export const put = async ({
  url, success, failure, dispatch, body, setAuthToken,
}) => {
  let data;
  const authToken = localStorage.getItem('authToken');
  try {
    const customHeaders = {
      'Content-Type': 'application/json',
    };
    if (setAuthToken && authToken !== null) {
      customHeaders['x-cpm-auth-token'] = authToken;
    }

    setTracingHeaders(customHeaders);

    const res = await fetch(url, {
      method: 'PUT',
      headers: customHeaders,
      body: JSON.stringify(body),
    });

    const xAuthToken = res.headers.get('x-cpm-auth-token');
    if (xAuthToken) {
      localStorage.setItem('authToken', xAuthToken);
    }
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};


export const del = async ({
  url, success, failure, dispatch, body, setAuthToken,
}) => {
  let data;
  const authToken = localStorage.getItem('authToken');
  try {
    const customHeaders = {
      'Content-Type': 'application/json',
    };
    if (setAuthToken && authToken !== null) {
      customHeaders['x-cpm-auth-token'] = authToken;
    }

    setTracingHeaders(customHeaders);

    const res = await fetch(url, {
      method: 'DELETE',
      headers: customHeaders,
      body: JSON.stringify(body),
    });

    const xAuthToken = res.headers.get('x-cpm-auth-token');
    if (xAuthToken) {
      localStorage.setItem('authToken', xAuthToken);
    }
    data = await res.json();
    checkHTTPStatus(res.status);
    dispatch({ type: success, data });
  } catch (e) {
    if (failure) {
      dispatch({ type: failure, data });
    }
  }
};
