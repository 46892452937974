import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Accordion, Col, Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useEffect, useState } from 'react';
import globals from '../../../../../redux/constants/globals';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import {
  tableHeadingColor, alertMessageSetTimeOut, _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable,
} from '../Helper';

import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { splitPaymentConfigList } from './SplitPaymentUtility';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import AlertPopup from '../../../../shared/Popups/AlertPopup';
import {
  postAddonConfigurationRequest, getAddonConfigurationRequest, deleteAddonConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeySplitPayment, validationRequiredInputMessage } from '../Constants';
import {
  on, off, toggleRollback, toggleReoffer, toggleOnValue,
} from '../Constants';

function VasSetupSplitPayment(props) {
  const reset = {
    serviceType: {
      nameProp: 'Select',
      idProp: '',
    },
    combinationName: '',
    numberOfSplits: '',
    paymentType1: {
      nameProp: '',
      idProp: '',
    },
    paymentType2: {
      nameProp: '',
      idProp: '',
    },
    paymentType3: {
      nameProp: '',
      idProp: '',
    },
    paymentType4: {
      nameProp: '',
      idProp: '',
    },
    toggleRollback: off,
    toggleReoffer: off,
  };
  const [inputField, setInpuField] = useState(reset);
  const [tempNumberOfSplits, setTempNumberOfSplits] = useState('0');
  const [disFlag, setDisFlag] = useState(false);
  const [api, setApi] = useState(true);
  const [paymentTypesDetail, setPaymentTypesDetail] = useState([]);
  const [prevSystemMetaDataResult, setPrevSystemMetaDataResult] = useState(props.getSystemMetaDataResult.result);
  const [conventionalData, setConventionalData] = useState([]);
  const [cashlessData, setCashlessData] = useState([]);
  const [hybridData, setHybridData] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [alertMessageForServiceTypes, setAlertMessageForServiceTypes] = useState(false);
  const [preValue, setPreValue] = useState('');
  const [preId, setPreId] = useState('');

  useEffect(() => {
    if (prevSystemMetaDataResult !== props.getSystemMetaDataResult.result) {
      setPrevSystemMetaDataResult(props.getSystemMetaDataResult);
    }
    if (prevSystemMetaDataResult && prevSystemMetaDataResult.result && prevSystemMetaDataResult.result.system_metadata) {
      setData(prevSystemMetaDataResult.result.system_metadata);
    }
  }, [props, prevSystemMetaDataResult]);
  function setData(systemMetadata) {
    setPaymentTypesDetail(systemMetadata && systemMetadata.pm_types && systemMetadata.pm_types.pm_type);
  }
  const serviceTypeDetail = [];
  let splitPaymentConfigDetail = props.getAddonConfigurationResult.result.addon_configuration_response && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs.split_payment_config;
  if (splitPaymentConfigDetail) {
    if (!Array.isArray(splitPaymentConfigDetail)) {
      splitPaymentConfigDetail = [{
        ...splitPaymentConfigDetail,
      }];
    }
    splitPaymentConfigDetail.forEach(key => {
      serviceTypeDetail.push({
        sub_type: key.sub_type,
        name: key.name,
      });
    });
  }
  const serviceTypeDropdown = [];
  let addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;
  if (addonTypes) {
    if (!Array.isArray(addonTypes)) {
      addonTypes = [{
        ...addonTypes,
      }];
    }
    addonTypes.forEach(key => {
      if (key.name === 'Split Payment') {
        key.addon_subtypes.addon_subtype.forEach(subType => {
          serviceTypeDropdown.push({
            id: subType.id,
            type: subType.name.toLowerCase(),
          });
        });
      }
    });
  }
  serviceTypeDetail.sort((a, b) => a.sub_type > b.sub_type ? 1 : -1);
  const serviceTypeList = [];
  serviceTypeDetail.forEach(key => {
    const found = serviceTypeList.some(el => el.sub_type === key.sub_type);
    if (!found) {
      serviceTypeList.push({
        sub_type: key.sub_type,
        name: key.name,
      });
    }
  });
  serviceTypeDropdown.forEach(key => {
    const found = serviceTypeList.some(el => el.sub_type === key.type);
    if (!found) {
      serviceTypeList.push({
        sub_type: key.type,
      });
    }
  });

  // Format payment Type column based on selected of numberOfSplits or api flag
  const rowFormatter = (cell, row) => (
    <div className="childPaymentType">
      {row.paymentType1Name && <div>{row.paymentType1Name}</div>}
      {row.paymentType2Name && <div>{row.paymentType2Name}</div>}
      {(row.paymentType3Name && (!api || tempNumberOfSplits === '3' || tempNumberOfSplits === '4')) && <div>{row.paymentType3Name}</div>}
      {(row.paymentType4Name && (!api || tempNumberOfSplits === '4')) && <div>{row.paymentType4Name}</div>}
    </div>
  );
  const preConfigForSplitPayment = () => {
    setApi(false);
    let splitPaymentConfig = props.getAddonConfigurationResult.result.addon_configuration_response && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs.split_payment_config;
    if (splitPaymentConfig) {
      if (!Array.isArray(splitPaymentConfig)) {
        splitPaymentConfig = [{
          ...splitPaymentConfig,
        }];
      }
    }
    splitPaymentConfig.sort((a, b) => a.sub_type > b.sub_type ? 1 : -1);
    const serviceRequestObject = [];
    splitPaymentConfig.forEach(key => {
      const found = serviceRequestObject.some(el => el.subTypeName === key.sub_type);
      const subTypesObject = [];
      if (!found) {
        subTypesObject.push({
          sub_type: key.sub_type,
          addon_configurations: key.addon_configurations,
          name: key.name,
          is_rollback: key.is_rollback,
          is_reoffer: key.is_reoffer,
        });
        serviceRequestObject.push({
          subTypeName: key.sub_type,
          subTypes: subTypesObject,
        });
      } else {
        const serviceRequestObjectLength = serviceRequestObject.length - 1;
        serviceRequestObject[serviceRequestObjectLength].subTypes.push({
          sub_type: key.sub_type,
          addon_configurations: key.addon_configurations,
          name: key.name,
          is_rollback: key.is_rollback,
          is_reoffer: key.is_reoffer,
        });
      }
    });
    if (serviceRequestObject !== undefined) {
      const findConventional = serviceRequestObject.find(element => (element.subTypeName === 'conventional'));
      if (findConventional && findConventional.subTypeName === 'conventional') {
        setConventionalData(splitPaymentConfigList('conventional', findConventional.subTypes, paymentTypesDetail));
      }
      const findCashless = serviceRequestObject.find(element => (element.subTypeName === 'cashless'));
      if (findCashless && findCashless.subTypeName === 'cashless') {
        setCashlessData(splitPaymentConfigList('cashless', findCashless.subTypes, paymentTypesDetail));
      }
      const findHybrid = serviceRequestObject.find(element => (element.subTypeName === 'hybrid'));
      if (findHybrid && findHybrid.subTypeName === 'hybrid') {
        setHybridData(splitPaymentConfigList('hybrid', findHybrid.subTypes, paymentTypesDetail));
      }
    }
  };
  const updatedConfigForSplitPayment = () => {
    let splitPaymentConfig = props.getAddonConfigurationResult.result.addon_configuration_response && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs && props.getAddonConfigurationResult.result.addon_configuration_response.split_payment_configs.split_payment_config;
    if (splitPaymentConfig) {
      if (!Array.isArray(splitPaymentConfig)) {
        splitPaymentConfig = [{
          ...splitPaymentConfig,
        }];
      }
      splitPaymentConfig.sort((a, b) => a.sub_type > b.sub_type ? 1 : -1);
      const serviceRequestObject = [];
      splitPaymentConfig.forEach(key => {
        const found = serviceRequestObject.some(el => el.subTypeName === key.sub_type);
        const subTypesObject = [];
        if (!found) {
          subTypesObject.push({
            sub_type: key.sub_type,
            addon_configurations: key.addon_configurations,
            name: key.name,
            is_rollback: key.is_rollback,
            is_reoffer: key.is_reoffer,
          });
          serviceRequestObject.push({
            subTypeName: key.sub_type,
            subTypes: subTypesObject,
          });
        } else {
          const serviceRequestObjectLength = serviceRequestObject.length - 1;
          serviceRequestObject[serviceRequestObjectLength].subTypes.push({
            sub_type: key.sub_type,
            addon_configurations: key.addon_configurations,
            name: key.name,
            is_rollback: key.is_rollback,
            is_reoffer: key.is_reoffer,
          });
        }
      });
      if (serviceRequestObject !== undefined) {
        const findConventional = serviceRequestObject.find(element => (element.subTypeName === 'conventional'));
        if (findConventional && findConventional.subTypeName === 'conventional') {
          setConventionalData(splitPaymentConfigList('conventional', findConventional.subTypes, paymentTypesDetail));
        }
        const findCashless = serviceRequestObject.find(element => (element.subTypeName === 'cashless'));
        if (findCashless && findCashless.subTypeName === 'cashless') {
          setCashlessData(splitPaymentConfigList('cashless', findCashless.subTypes, paymentTypesDetail));
        }
        const findHybrid = serviceRequestObject.find(element => (element.subTypeName === 'hybrid'));
        if (findHybrid && findHybrid.subTypeName === 'hybrid') {
          setHybridData(splitPaymentConfigList('hybrid', findHybrid.subTypes, paymentTypesDetail));
        }
      }
    }
  };
  const inputOnChange = (event, field) => {
    const id = event.target.value;
    if (field === 'serviceType') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setPreValue(value);
      setPreId(id);
      if (value === 'cashless') {
        const findUpdatedData = cashlessData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setAlertMessageForServiceTypes(true);
        } else {
          const cashlessPaymentData = prevSystemMetaDataResult.result.system_metadata && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes.cashless;
          setPaymentTypesDetail(cashlessPaymentData);
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        }
      } else if (value === 'conventional') {
        const findUpdatedData = conventionalData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setAlertMessageForServiceTypes(true);
        } else {
          const conventionalPaymentData = prevSystemMetaDataResult.result.system_metadata && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes.conventional;
          setPaymentTypesDetail(conventionalPaymentData);
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        }
      } else if (value === 'hybrid') {
        const findUpdatedData = hybridData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setAlertMessageForServiceTypes(true);
        } else {
          const hybridPaymentData = prevSystemMetaDataResult.result.system_metadata && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes && prevSystemMetaDataResult.result.system_metadata.splitPaymentTypes.hybrid;
          setPaymentTypesDetail(hybridPaymentData);
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        }
      }
      if (inputField.serviceType.nameProp === 'Select') {
        setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
      }
    } else if (field === toggleRollback) {
      if (inputField.toggleRollback === on) {
        setInpuField({ ...inputField, [field]: off });
      } else if (inputField.toggleRollback === off) {
        setInpuField({ ...inputField, [field]: on });
      }
    } else if (field === toggleReoffer) {
      if (inputField.toggleReoffer === on) {
        setInpuField({ ...inputField, [field]: off });
      } else if (inputField.toggleReoffer === off) {
        setInpuField({ ...inputField, [field]: on });
      }
    } else if (field === 'paymentType1' || field === 'paymentType2' || field === 'paymentType3' || field === 'paymentType4') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else if (field === 'numberOfSplits') {
      setInpuField({ ...inputField, [field]: id });
      setTempNumberOfSplits(id);
    } else {
      setInpuField({ ...inputField, [field]: id });
    }
    if (api) {
      preConfigForSplitPayment();
    }
  };
  const _onClickDelete = row => {
    deleteRow(row);
  };

  const deleteRow = row => {
    let filter = '';
    if (row.conventional) {
      filter = getUnique(conventionalData, 'key');
      if (filter.length !== conventionalData.length) {
        setConventionalData(filter);
      } else {
        filter = conventionalData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setConventionalData(filter);
      }
    } else if (row.cashless) {
      filter = getUnique(cashlessData, 'key');
      if (filter.length !== cashlessData.length) {
        setCashlessData(filter);
      } else {
        filter = cashlessData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setCashlessData(filter);
      }
    } else if (row.hybrid) {
      filter = getUnique(hybridData, 'key');
      if (filter.length !== hybridData.length) {
        setHybridData(filter);
      } else {
        filter = hybridData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setHybridData(filter);
      }
    }

    if (filter.filter(item => item.deleteKey === true).length > 0) {
      // Make save button enabled
      setSaveButtonDisabledFlag(0);
      setHasDeleteRecordExist(1);
    } else {
      // Make save button disabled
      setSaveButtonDisabledFlag(1);
      setHasDeleteRecordExist(0);
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }
  const action = (cell, row) => (
    <div>
      {(row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={() => _onClickDelete(row)} title="Delete" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={() => _onClickDelete(row)} title="Add" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );

  const _onClickAddButton = () => {
    setDisFlag(false);
    let toggleRollbackFlag = false;
    let toggleReofferFlag = false;
    if (inputField.serviceType.idProp === undefined || inputField.combinationName === undefined || inputField.paymentType1.nameProp === undefined || inputField.numberOfSplits === undefined
      || inputField.serviceType.nameProp === 'Select' || inputField.combinationName === 'Select' || inputField.numberOfSplits === 'Select' || inputField.paymentType1.nameProp === 'Select'
      || inputField.serviceType.nameProp === '' || inputField.combinationName === '' || inputField.numberOfSplits === '' || inputField.paymentType1.nameProp === '') {
      setDisFlag(true);
      alertMessageSetTimeOut();
      return false;
    }
    if (inputField.serviceType.nameProp === 'conventional') {
      if (inputField.toggleRollback === toggleOnValue) {
        toggleRollbackFlag = true;
      } else {
        toggleRollbackFlag = false;
      }
      if (inputField.toggleReoffer === toggleOnValue) {
        toggleReofferFlag = true;
      } else {
        toggleReofferFlag = false;
      }
      const count = conventionalData.length + 1;
      conventionalData.push({
        key: count,
        serviceTypeName: inputField.serviceType.nameProp,
        toggleRollback: toggleRollbackFlag,
        toggleReoffer: toggleReofferFlag,
        combinationName: inputField.combinationName,
        paymentType1Name: inputField.paymentType1.nameProp,
        paymentType2Name: inputField.paymentType2.nameProp,
        paymentType3Name: inputField.paymentType3.nameProp,
        paymentType4Name: inputField.paymentType4.nameProp,
        paymentType1Id: inputField.paymentType1.idProp,
        paymentType2Id: inputField.paymentType2.idProp,
        paymentType3Id: inputField.paymentType3.idProp,
        paymentType4Id: inputField.paymentType4.idProp,
        action: '',
        deleteKey: false,
        existFlag: false,
        conventional: true,
      });
    }
    if (inputField.serviceType.nameProp === 'cashless') {
      if (inputField.toggleRollback === toggleOnValue) {
        toggleRollbackFlag = true;
      } else {
        toggleRollbackFlag = false;
      }
      if (inputField.toggleReoffer === toggleOnValue) {
        toggleReofferFlag = true;
      } else {
        toggleReofferFlag = false;
      }
      const count = cashlessData.length + 1;
      cashlessData.push({
        key: count,
        serviceTypeName: inputField.serviceType.nameProp,
        toggleRollback: toggleRollbackFlag,
        toggleReoffer: toggleReofferFlag,
        combinationName: inputField.combinationName,
        paymentType1Name: inputField.paymentType1.nameProp,
        paymentType2Name: inputField.paymentType2.nameProp,
        paymentType3Name: inputField.paymentType3.nameProp,
        paymentType4Name: inputField.paymentType4.nameProp,
        paymentType1Id: inputField.paymentType1.idProp,
        paymentType2Id: inputField.paymentType2.idProp,
        paymentType3Id: inputField.paymentType3.idProp,
        paymentType4Id: inputField.paymentType4.idProp,
        action: '',
        deleteKey: false,
        existFlag: false,
        cashless: true,
      });
    }
    if (inputField.serviceType.nameProp === 'hybrid') {
      if (inputField.toggleRollback === toggleOnValue) {
        toggleRollbackFlag = true;
      } else {
        toggleRollbackFlag = false;
      }
      if (inputField.toggleReoffer === toggleOnValue) {
        toggleReofferFlag = true;
      } else {
        toggleReofferFlag = false;
      }
      const count = hybridData.length + 1;
      hybridData.push({
        key: count,
        serviceTypeName: inputField.serviceType.nameProp,
        toggleRollback: toggleRollbackFlag,
        toggleReoffer: toggleReofferFlag,
        combinationName: inputField.combinationName,
        paymentType1Name: inputField.paymentType1.nameProp,
        paymentType2Name: inputField.paymentType2.nameProp,
        paymentType3Name: inputField.paymentType3.nameProp,
        paymentType4Name: inputField.paymentType4.nameProp,
        paymentType1Id: inputField.paymentType1.idProp,
        paymentType2Id: inputField.paymentType2.idProp,
        paymentType3Id: inputField.paymentType3.idProp,
        paymentType4Id: inputField.paymentType4.idProp,
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });
    }
    const data = { ...inputField };
    data.key = 0;
    data.toggleRollback = off;
    data.toggleReoffer = off;
    data.combinationName = '';
    data.numberOfSplits = 'Select';
    data.paymentType1.nameProp = '';
    data.paymentType2.nameProp = '';
    data.paymentType3.nameProp = '';
    data.paymentType4.nameProp = '';
    data.paymentType1.idProp = '';
    data.paymentType2.idProp = '';
    data.paymentType3.idProp = '';
    data.paymentType4.idProp = '';
    setInpuField(data);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  // Table data
  const splitPaymentTypeData = () => {
    let split = [];
    if (inputField.serviceType.nameProp === 'conventional') {
      split = conventionalData;
    }
    if (inputField.serviceType.nameProp === 'cashless') {
      split = cashlessData;
    }
    if (inputField.serviceType.nameProp === 'hybrid') {
      split = hybridData;
    }
    return split;
  };

  const saveSplitPaymentResults = () => {
    setDisFlag(false);
    let findConventional = '';
    let findCashless = '';
    let findHybrid = '';
    let requestBodyForDeleteApi;
    findConventional = conventionalData.some(element => (element.deleteKey === false));
    findCashless = cashlessData.some(element => (element.deleteKey === false));
    findHybrid = hybridData.some(element => (element.deleteKey === false));
    if (inputField.serviceType.nameProp === 'conventional' && conventionalData.length > 0 && !findConventional) {
      requestBodyForDeleteApi = {
        client_id: globals.clientId,
        conventional: -1,
      };
      props.deleteAddonConfigurationRequest(requestBodyForDeleteApi);
      setConventionalData([]);
    } else if (inputField.serviceType.nameProp === 'cashless' && cashlessData.length > 0 && !findCashless) {
      requestBodyForDeleteApi = {
        client_id: globals.clientId,
        cashless: -1,
      };
      props.deleteAddonConfigurationRequest(requestBodyForDeleteApi);
      setCashlessData([]);
    } else if (inputField.serviceType.nameProp === 'hybrid' && hybridData.length > 0 && !findHybrid) {
      requestBodyForDeleteApi = {
        client_id: globals.clientId,
        hybrid: -1,
      };
      props.deleteAddonConfigurationRequest(requestBodyForDeleteApi);
      setHybridData([]);
    } else {
      const splitConfigsList = [];
      if (inputField.serviceType.nameProp === 'conventional') {
        conventionalData.forEach(obj => {
          const splitPaymentListArray = [];
          const splitConfigList = [];
          if (!obj.deleteKey) {
            if (obj.paymentType1Id) {
              splitPaymentListArray.push({
                sequence_no: 1,
                payment_type_id: parseInt(obj.paymentType1Id, 10),
              });
            }
            if (obj.paymentType2Id) {
              splitPaymentListArray.push({
                sequence_no: 2,
                payment_type_id: parseInt(obj.paymentType2Id, 10),
              });
            }
            if (obj.paymentType3Id) {
              splitPaymentListArray.push({
                sequence_no: 3,
                payment_type_id: parseInt(obj.paymentType3Id, 10),
              });
            }
            if (obj.paymentType4Id) {
              splitPaymentListArray.push({
                sequence_no: 4,
                payment_type_id: parseInt(obj.paymentType4Id, 10),
              });
            }
            const isRollbackValue = (obj.toggleRollback === undefined) ? false : obj.toggleRollback;
            const isReorderValue = (obj.toggleReoffer === undefined) ? false : obj.toggleReoffer;
            splitConfigList.push({
              sub_type: obj.serviceTypeName,
              name: obj.combinationName,
              is_rollback: isRollbackValue,
              is_reoffer: isReorderValue,
              addon_configurations: {
                addon_configuration: splitPaymentListArray,
              },
            });
            splitConfigsList.push({
              splitConfigList,
            });
          }
        });
      } else if (inputField.serviceType.nameProp === 'cashless') {
        cashlessData.forEach(obj => {
          const splitPaymentListArray = [];
          const splitConfigList = [];
          if (!obj.deleteKey) {
            if (obj.paymentType1Id) {
              splitPaymentListArray.push({
                sequence_no: 1,
                payment_type_id: obj.paymentType1Id,
              });
            }
            if (obj.paymentType2Id) {
              splitPaymentListArray.push({
                sequence_no: 2,
                payment_type_id: parseInt(obj.paymentType2Id, 10),
              });
            }
            if (obj.paymentType3Id) {
              splitPaymentListArray.push({
                sequence_no: 3,
                payment_type_id: obj.paymentType3Id,
              });
            }
            if (obj.paymentType4Id) {
              splitPaymentListArray.push({
                sequence_no: 4,
                payment_type_id: parseInt(obj.paymentType4Id, 10),
              });
            }
            const isRollbackValue = (obj.toggleRollback === undefined) ? false : obj.toggleRollback;
            const isReorderValue = (obj.toggleReoffer === undefined) ? false : obj.toggleReoffer;
            splitConfigList.push({
              sub_type: obj.serviceTypeName,
              name: obj.combinationName,
              is_rollback: isRollbackValue,
              is_reoffer: isReorderValue,
              addon_configurations: {
                addon_configuration: splitPaymentListArray,
              },
            });
            splitConfigsList.push({
              splitConfigList,
            });
          }
        });
      } else if (inputField.serviceType.nameProp === 'hybrid') {
        hybridData.forEach(obj => {
          const splitPaymentListArray = [];
          const splitConfigList = [];
          if (!obj.deleteKey) {
            if (obj.paymentType1Id) {
              splitPaymentListArray.push({
                sequence_no: 1,
                payment_type_id: obj.paymentType1Id,
              });
            }
            if (obj.paymentType2Id) {
              splitPaymentListArray.push({
                sequence_no: 2,
                payment_type_id: parseInt(obj.paymentType2Id, 10),
              });
            }
            if (obj.paymentType3Id) {
              splitPaymentListArray.push({
                sequence_no: 3,
                payment_type_id: parseInt(obj.paymentType3Id, 10),
              });
            }
            if (obj.paymentType4Id) {
              splitPaymentListArray.push({
                sequence_no: 4,
                payment_type_id: parseInt(obj.paymentType4Id, 10),
              });
            }
            const isRollbackValue = (obj.toggleRollback === undefined) ? false : obj.toggleRollback;
            const isReorderValue = (obj.toggleReoffer === undefined) ? false : obj.toggleReoffer;
            splitConfigList.push({
              sub_type: obj.serviceTypeName,
              name: obj.combinationName,
              is_rollback: isRollbackValue,
              is_reoffer: isReorderValue,
              addon_configurations: {
                addon_configuration: splitPaymentListArray,
              },
            });
            splitConfigsList.push({
              splitConfigList,
            });
          }
        });
      }
      const splitPaymentConfig = [];
      if (splitConfigsList) {
        splitConfigsList.forEach(key => {
          key.splitConfigList.forEach(obj => {
            splitPaymentConfig.push({
              sub_type: obj.sub_type,
              name: obj.name,
              is_rollback: obj.is_rollback,
              is_reoffer: obj.is_reoffer,
              addon_configurations: obj.addon_configurations,
            });
          });
        });
      }
      const requestBody = {
        client_id: globals.clientId,
        split_payment_configs: {
          split_payment_config: splitPaymentConfig,
        },
      };
      // Post API call with table data
      props.postAddonConfigurationRequest(requestBody);
    }

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
  };
  // Wait for Success result of postAddonConfigurationResult then call getAddonConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeySplitPayment && props.postAddonConfigurationResult.fetched && !props.postAddonConfigurationResult.fetching && props.postAddonConfigurationResult.requestStatus) {
      if (props.postAddonConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.added);
      } else {
        notify('error', toastMessages.merchantServices.error.added);
      }
      props.getAddonConfigurationRequest();
    }
  }, [props.postAddonConfigurationResult]);

  useEffect(() => {
    if (props.activeKey === activeKeySplitPayment && props.deleteAddonConfigurationResult.fetched && !props.deleteAddonConfigurationResult.fetching && props.deleteAddonConfigurationResult.requestStatus) {
      if (props.deleteAddonConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getAddonConfigurationRequest();
    }
  }, [props.deleteAddonConfigurationResult]);

  // Show Loader still get getAddonConfigurationResult
  useEffect(() => {
    if (props.getAddonConfigurationResult.fetching) {
      setIsLoader(true);
    } else {
      setIsLoader(false);
    }
  });
  // If get Updated getProviderConfigurationResult then call updatedConfigForSplitPayment()
  useEffect(() => {
    updatedConfigForSplitPayment();
  }, [props.getAddonConfigurationResult.result]);

  // set default data for conventionalData, cashlessData and conventionalData
  const clearState = () => {
    setDisFlag(false);
    const preConfigConventionalData = conventionalData.filter(conventional => conventional.existFlag === true);
    if (inputField.serviceType.nameProp === 'conventional') {
      setConventionalData(preConfigConventionalData);
    }
    const preConfigCashlessData = cashlessData.filter(cashless => cashless.existFlag === true);
    if (inputField.serviceType.nameProp === 'cashless') {
      setCashlessData(preConfigCashlessData);
    }
    const preConfigHybridDataData = hybridData.filter(hybrid => hybrid.existFlag === true);
    if (inputField.serviceType.nameProp === 'hybrid') {
      setHybridData(preConfigHybridDataData);
    }

    if (inputField.serviceType.nameProp === 'conventional') {
      const preUpdateConfigConventionalData = preConfigConventionalData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setConventionalData(preUpdateConfigConventionalData);
    } else if (inputField.serviceType.nameProp === 'cashless') {
      const preUpdateConfigCashlessData = preConfigCashlessData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setCashlessData(preUpdateConfigCashlessData);
    } else if (inputField.serviceType.nameProp === 'hybrid') {
      const preUpdateConfigHybridData = preConfigHybridDataData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setHybridData(preUpdateConfigHybridData);
    }
    const data = { ...inputField };
    data.key = 0;
    data.toggleRollback = off;
    data.toggleReoffer = off;
    data.combinationName = '';
    data.numberOfSplits = 'Select';
    data.paymentType1.nameProp = '';
    data.paymentType2.nameProp = '';
    data.paymentType3.nameProp = '';
    data.paymentType4.nameProp = '';
    data.paymentType1.idProp = '';
    data.paymentType2.idProp = '';
    data.paymentType3.idProp = '';
    data.paymentType4.idProp = '';
    setInpuField(data);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // Toast Messages
    notify('success', toastMessages.merchantServices.success.reset);
  };
  const confirmYesData = () => {
    const field = 'serviceType';
    setDisFlag(false);
    const preConfigConventionalData = conventionalData.filter(conventional => conventional.existFlag === true);
    if (inputField.serviceType.nameProp === 'conventional') {
      setConventionalData(preConfigConventionalData);
    }
    const preConfigCashlessData = cashlessData.filter(cashless => cashless.existFlag === true);
    if (inputField.serviceType.nameProp === 'cashless') {
      setCashlessData(preConfigCashlessData);
    }
    const preConfigHybridDataData = hybridData.filter(hybrid => hybrid.existFlag === true);
    if (inputField.serviceType.nameProp === 'hybrid') {
      setHybridData(preConfigHybridDataData);
    }

    if (inputField.serviceType.nameProp === 'conventional') {
      const preUpdateConfigConventionalData = preConfigConventionalData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setConventionalData(preUpdateConfigConventionalData);
    } else if (inputField.serviceType.nameProp === 'cashless') {
      const preUpdateConfigCashlessData = preConfigCashlessData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setCashlessData(preUpdateConfigCashlessData);
    } else if (inputField.serviceType.nameProp === 'hybrid') {
      const preUpdateConfigHybridData = preConfigHybridDataData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setHybridData(preUpdateConfigHybridData);
    }
    setInpuField({ ...inputField, [field]: { nameProp: preValue, idProp: preId } });
    setAlertMessageForServiceTypes(false);
  };
  const confirmNoData = () => {
    setAlertMessageForServiceTypes(false);
  };
  return (
    <div className="card">
      {(alertMessageForServiceTypes) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      {props.activeKey === activeKeySplitPayment && hasDeleteRecordExist === 1 && <DeletePopup
        /*
        declineDeleteClick={() => {
          const filterConventionalData = conventionalData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setConventionalData(filterConventionalData);

          const filterCashlessData = cashlessData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setCashlessData(filterCashlessData);

          const filterHybridData = hybridData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setHybridData(filterHybridData);
        }}
        */
        declineDeleteClick={() => {
          const filterConventionalData = conventionalData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setConventionalData(filterConventionalData);

          const filterCashlessData = cashlessData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setCashlessData(filterCashlessData);

          const filterHybridData = hybridData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setHybridData(filterHybridData);

          setHasDeleteRecordExist(0);
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveSplitPaymentResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeySplitPayment ? '' : activeKeySplitPayment)} eventKey={activeKeySplitPayment}>
          <h6>
            Split Payment
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeySplitPayment}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Service Type
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'serviceType')}
                    value={inputField.serviceType.idProp}
                    data-toggle="modal"
                    data-target="#alertModal"
                  >
                    <option disabled selected value="">Please Select</option>
                    {serviceTypeList.map(key => <option value={key.sub_type}>{key.sub_type}</option>)}
                  </Form.Control>
                </Col>
              </div>
              {(inputField.serviceType.nameProp !== 'Select') && <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label>Rollback</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={inputField.toggleRollback === on}
                      onClick={e => inputOnChange(e, toggleRollback)} />
                    <div className="state p-success">
                      <label htmlFor="Rollback" />
                    </div>
                  </div>
                </Col>
                <Col className="form-group" md={3}>
                  <Form.Label>Re-Offer</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={inputField.toggleReoffer === on}
                      onClick={e => inputOnChange(e, toggleReoffer)} />
                    <div className="state p-success">
                      <label htmlFor="Re-Offer" />
                    </div>
                  </div>
                </Col>
              </div>}
              {(inputField.serviceType.nameProp !== 'Select') && <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Combination name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="text"
                    onChange={e => inputOnChange(e, 'combinationName')}
                    value={inputField.combinationName}
                  />
                </Col>
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Number of Splits
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'numberOfSplits')}
                    value={inputField.numberOfSplits}
                  >
                    <option selected>Select</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </Form.Control>
                </Col>
              </div>}
              <div className="row">
                {(inputField.numberOfSplits === '2' || inputField.numberOfSplits === '3' || inputField.numberOfSplits === '4') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Payment Type 1
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'paymentType1')}
                    value={inputField.paymentType1.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    {paymentTypesDetail && paymentTypesDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.numberOfSplits === '2' || inputField.numberOfSplits === '3' || inputField.numberOfSplits === '4') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Payment Type 2
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'paymentType2')}
                    value={inputField.paymentType2.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    {paymentTypesDetail && paymentTypesDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.numberOfSplits === '3' || inputField.numberOfSplits === '4') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Payment Type 3
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'paymentType3')}
                    value={inputField.paymentType3.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    {paymentTypesDetail && paymentTypesDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.numberOfSplits === '4') && <Col className="form-group" md={3}>
                  <Form.Label>
                    Payment Type 4
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'paymentType4')}
                    value={inputField.paymentType4.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    {paymentTypesDetail && paymentTypesDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.numberOfSplits === '2' || inputField.numberOfSplits === '3') && <Col className="form-group" md={1}>
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </Col>}
              </div>
              <div className="row">
                {(inputField.numberOfSplits === '4') && <Col className="form-group" md={12}>
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mt-1"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                  {disFlag && <div className="alert alert-danger">
                    {disFlag ? validationRequiredInputMessage : ''}
                  </div>}
                </Col>}
              </div>
              {(inputField.numberOfSplits === '2' || inputField.numberOfSplits === '3') && disFlag && <div className="alert alert-danger">
                {disFlag ? validationRequiredInputMessage : ''}
              </div>}
              {(inputField.serviceType.nameProp !== 'Select') && <div className="row">
                {(!isLoader) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  <BootstrapTable data={splitPaymentTypeData()}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    <TableHeaderColumn dataField="serviceTypeName" dataSort dataAlign="left" className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Service Types</TableHeaderColumn>
                    <TableHeaderColumn dataField="combinationName" dataSort dataAlign="left" className={tableHeadingColor} columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Combination Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="paymentType" dataSort dataFormat={rowFormatter} className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Payment Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <Col className="pt-2 pb-2 mt-3" md={12}>
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveSplitPaymentResults()}
                  >
                    Save
                  </button>}
                  <Button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mr-3"
                    onClick={() => clearState()}
                  >
                    Cancel
                  </Button>
                </Col>
              </div>}
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}
VasSetupSplitPayment.propTypes = {
  getAddonConfigurationResult: PropTypes.object,
  postAddonConfigurationResult: PropTypes.object,
  deleteAddonConfigurationResult: PropTypes.object,
  getSystemMetaDataResult: PropTypes.object,
  postAddonConfigurationRequest: PropTypes.func,
  getAddonConfigurationRequest: PropTypes.func,
  deleteAddonConfigurationRequest: PropTypes.func,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getAddonConfigurationResult: state.getAddonConfigurationResult,
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  postAddonConfigurationResult: state.postAddonConfigurationResult,
  deleteAddonConfigurationResult: state.deleteAddonConfigurationResult,
});
export default connect(
  mapStateToProps, {
    postAddonConfigurationRequest, getAddonConfigurationRequest, deleteAddonConfigurationRequest,
  }
)(VasSetupSplitPayment);
