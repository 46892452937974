import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import validator from 'validator';
import { Col } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import { Accordion, Button } from 'react-bootstrap';
import globals from '../../../../../redux/constants/globals';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import {
  getClientConfigurationRequest, updateClientConfigurationRequest, deleteClientConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';

import arrowdown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import { tableHeadingColor } from '../Helper';
import { _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut } from '../Helper';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyMerchantUrl, validationRequiredInputMessage } from '../Constants';

function MerchantUrl(props) {
  const reset = {
    urlTypeId: '',
    urlCategory: '',
    urlType: '',
    urlName: '',
  };
  const [merchantInputField, setMerchantInputField] = useState(reset);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [urlType, setUrlType] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [urlCategoryListArray, seturlCategoryListArray] = useState([]);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [validationError, setValidationError] = useState({
    urlCategoryDropdownValue: false,
    urlTypeDropdownValue: false,
    urlNameTextValue: false,
  });


  function clearState() {
    const existingMerchantUrlData = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.client_urls.client_url;
    const initialTableData = [];
    let count = 1;
    if (Array.isArray(existingMerchantUrlData)) {
      existingMerchantUrlData.forEach(keyExisting => {
        initialTableData.push({
          key: count,
          urlTypeId: keyExisting.type_id,
          urlCategory: keyExisting.url_category,
          urlType: keyExisting.name,
          urlName: keyExisting.value,
          action: '',
          deleteKey: false,
          existFlag: false,
          hybrid: true,
        });
        count += 1;
      });
    } else {
      initialTableData.push({
        key: count,
        urlTypeId: existingMerchantUrlData.type_id,
        urlCategory: existingMerchantUrlData.url_category,
        urlType: existingMerchantUrlData.name,
        urlName: existingMerchantUrlData.value,
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });
      count += 1;
    }

    const data = { ...merchantInputField };
    data.urlTypeId = '';
    data.urlCategory = '';
    data.urlType = '';
    data.urlName = '';
    setMerchantInputField(data);
    setTableData(initialTableData);

    // Removing the existing validation message in case of cancel button click
    const errorValue = { ...validationError };
    errorValue.urlCategoryDropdownValue = false;
    errorValue.urlTypeDropdownValue = false;
    errorValue.urlNameTextValue = false;
    setValidationError(errorValue);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // Toast message
    notify('success', toastMessages.merchantServices.success.reset);
  }

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'urlName') {
      value = event.target.value;
      setMerchantInputField({ ...merchantInputField, [field]: value });
    } else if (field === 'urlCategory') {
      // fetch the data from the system metadata for the dropdown listing
      let requestData = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.client_urls
      && props.getSystemMetaDataResult.result.system_metadata.client_urls.client_url;

      if (!Array.isArray(requestData)) {
        requestData = [{
          ...requestData,
        }];
      }
      const serviceRequestObject = getUrlCategoryListData(requestData);
      const filteredUrlTypeArray = serviceRequestObject.filter(service => service.urlCategory === event.target.value)[0].urlTypes;

      let filteredTableData = [];
      if (tableData.length > 0) {
        filteredTableData = tableData.filter(category => category.urlCategory === event.target.value);
      } else {
        filteredTableData = requestData.filter(category => category.urlCategory === event.target.value);
      }
      const filteredTableDataResult = filteredTableData.map(a => a.urlTypeId);
      const filteredExistingMerchantUrlData = filteredUrlTypeArray.filter(category => filteredTableDataResult.includes(category.urlTypeId) === false);

      setUrlType(filteredExistingMerchantUrlData);
      value = event.target.value;
      setMerchantInputField({ ...merchantInputField, [field]: value });
    } else if (field === 'urlType') {
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      const urlTypeIdKey = 'urlTypeId';
      setMerchantInputField({ ...merchantInputField, [field]: value, [urlTypeIdKey]: parseInt(event.target.value, 10) });
    }
  };

  const initTableData = (urlTypeIdParam, urlCategoryParam, urlTypeParam, urlNameParam) => {
    const count = tableData.length + 1;
    tableData.push({
      key: count,
      urlTypeId: urlTypeIdParam,
      urlCategory: urlCategoryParam,
      urlType: urlTypeParam,
      urlName: urlNameParam,
      action: '',
      deleteKey: false,
      existFlag: false,
      hybrid: true,
    });
  };

  const getUrlCategoryListData = requestData => {
    // Code to show the url types which are associated with selected url category
    const serviceRequestObject = [];
    requestData.sort((a, b) => a.url_category > b.url_category ? 1 : -1);
    requestData.forEach(key => {
      const urlTypesObject = [];

      const found = serviceRequestObject.some(el => el.urlCategory === key.url_category);
      if (!found) {
        urlTypesObject.push({
          urlTypeId: key.type_id,
          urlTypeName: key.name,
        });

        serviceRequestObject.push({
          urlCategory: key.url_category,
          urlTypes: urlTypesObject,
        });
      } else {
        const serviceRequestObjectLength = serviceRequestObject.length - 1;
        serviceRequestObject[serviceRequestObjectLength].urlTypes.push({
          urlTypeId: key.type_id,
          urlTypeName: key.name,
        });
      }
      setLoader(false);
    });
    return serviceRequestObject;
  };

  const getInitialStateData = () => {
    // Set the initial table data which is stored in the client configuration
    const existingMerchantUrlData = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.client_urls.client_url;

    // fetch the data from the system metadata for the dropdown listing
    let requestData = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.client_urls
    && props.getSystemMetaDataResult.result.system_metadata.client_urls.client_url;

    if (requestData && !apiCall) {
      setApiCall(true);
      if (!Array.isArray(requestData)) {
        requestData = [{
          ...requestData,
        }];
      }

      // Show the existing data
      if (existingMerchantUrlData) {
        if (Array.isArray(existingMerchantUrlData)) {
          existingMerchantUrlData.forEach(keyExisting => {
            initTableData(keyExisting.type_id, keyExisting.url_category, keyExisting.name, keyExisting.value);
          });
        } else {
          initTableData(existingMerchantUrlData.type_id, existingMerchantUrlData.url_category, existingMerchantUrlData.name, existingMerchantUrlData.value);
        }
      }

      setLoader(false);
      const serviceRequestObject = getUrlCategoryListData(requestData);
      seturlCategoryListArray(serviceRequestObject);
    }
  };

  // common function for initial data
  getInitialStateData();

  const onClickAddButton = () => {
    if (merchantUrlValidation()) {
      const count = tableData.length + 1;
      tableData.push({
        key: count,
        urlTypeId: merchantInputField.urlTypeId,
        urlCategory: merchantInputField.urlCategory,
        urlType: merchantInputField.urlType,
        urlName: merchantInputField.urlName,
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });
      setTableData(tableData);
      const data = { ...merchantInputField };
      data.urlTypeId = '';
      data.urlCategory = '';
      data.urlType = '';
      data.urlName = '';
      setUrlType([]);
      setMerchantInputField(data);

      // Make save button enabled
      setSaveButtonDisabledFlag(0);
    }
  };

  function merchantUrlValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (merchantInputField.urlCategory === '' || merchantInputField.urlCategory === 'Please Select') {
      errorValue.urlCategoryDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (merchantInputField.urlType === '' || merchantInputField.urlType === 'Please Select') {
      errorValue.urlCategoryDropdownValue = false;
      errorValue.urlTypeDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (merchantInputField.urlName === '' || !validator.isURL(merchantInputField.urlName, { protocols: ['http', 'https'], require_protocol: false })) {
      errorValue.urlCategoryDropdownValue = false;
      errorValue.urlTypeDropdownValue = false;
      errorValue.urlNameTextValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else {
      errorValue.urlCategoryDropdownValue = false;
      errorValue.urlTypeDropdownValue = false;
      errorValue.urlNameTextValue = false;
    }
    setValidationError(errorValue);
    return validate;
  }

  const deleteRow = row => {
    let filter = '';
    if (row.urlTypeId) {
      filter = getUnique(tableData, 'key');
      if (filter.length !== tableData.length) {
        setTableData(filter);
      } else {
        filter = tableData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setTableData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };
  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const saveMerchantUrlResults = () => {
    const categorySelectedListArray = [];

    const updatedTableData = [];
    let count = 1;
    if (tableData.length !== 0) {
      const tableDataDeleteKeyArray = tableData.filter(deleteKeyIndex => deleteKeyIndex.deleteKey === false);
      if (tableDataDeleteKeyArray.length === 0) {
        const selectConfigData = {
          url: -1,
          client_id: globals.clientId,
        };
        props.deleteClientConfigurationRequest(selectConfigData);
        setTableData([]);
      } else {
        tableData.forEach(key => {
          if (key.deleteKey === false) {
            const selectUrlCategoryData = {
              name: key.urlType,
              type_id: key.urlTypeId,
              value: key.urlName,
            };
            categorySelectedListArray.push(selectUrlCategoryData);

            updatedTableData.push({
              key: count,
              urlCategory: key.urlCategory,
              urlName: key.urlName,
              urlType: key.urlType,
              urlTypeId: key.urlTypeId,
              action: '',
              deleteKey: false,
              existFlag: false,
              hybrid: true,
            });
            count += 1;
          }
        });

        const requestBody = {
          id: globals.clientId,
          client_urls: categorySelectedListArray,
        };
        props.updateClientConfigurationRequest(requestBody);
        setTableData(updatedTableData);
      }

      // Make save button disabled
      setSaveButtonDisabledFlag(1);
    }
  };

  // Wait for Sucess result of updateClientConfigurationRequest then call getClientConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyMerchantUrl && props.updateClientConfigurationResult.fetched && !props.updateClientConfigurationResult.fetching && props.updateClientConfigurationResult.requestStatus) {
      if (props.updateClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.updated);
      } else {
        notify('error', toastMessages.merchantServices.error.updated);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.updateClientConfigurationResult]);

  useEffect(() => {
    if (props.activeKey === activeKeyMerchantUrl && props.deleteClientConfigurationResult.fetched && !props.deleteClientConfigurationResult.fetching && props.deleteClientConfigurationResult.requestStatus) {
      if (props.deleteClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.deleteClientConfigurationResult]);

  // Show Loader still get getClientConfigurationResult
  useEffect(() => {
    if (props.getClientConfigurationResult.fetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  });

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const action = (cell, row) => (
    <div>
      { (row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );

  // Need all four params in order to highlight delete functionality and its change color

  const viewMode = props.getFormMode.result === 'View';
  return (
    <div className="card">
      {props.activeKey === activeKeyMerchantUrl && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = tableData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setTableData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveMerchantUrlResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingTwo">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyMerchantUrl ? '' : activeKeyMerchantUrl)} eventKey={activeKeyMerchantUrl}>
          <h6>
            Merchant URL
            <img src={arrowdown} className="float-right" alt="" />
          </h6>

        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyMerchantUrl}>
        <div className="card-body">
          <div className="mt-4">
            <Form className="row">
              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Category</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'urlCategory')}
                  value={merchantInputField.urlCategory}
                  disabled={viewMode}
                >
                  <option disabled selected value="">Please Select</option>
                  {urlCategoryListArray && urlCategoryListArray.map(key => <option value={key.urlCategory}>{key.urlCategory}</option>)}
                </Form.Control>
                {(validationError.urlCategoryDropdownValue || validationError.urlTypeDropdownValue) && <div className="alert alert-danger">
                  {validationRequiredInputMessage}
                </div>}
              </Col>

              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Url Type</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'urlType')}
                  value={merchantInputField.urlTypeId}
                  disabled={viewMode}
                >
                  <option disabled selected value="">Please Select</option>
                  {urlType.map(key => <option value={key.urlTypeId}>{key.urlTypeName}</option>)}
                </Form.Control>
              </Col>

              <Col className="form-group" md={4}>
                <Form.Label className="mandatory">URL</Form.Label>
                <Form.Control
                  type="text"
                  name="urlName"
                  onChange={e => inputOnChange(e, 'urlName')}
                  value={merchantInputField.urlName}
                  disabled={viewMode}

                />
                {(validationError.urlNameTextValue) && <div className="alert alert-danger">
                  Please enter valid URL.
                </div>}
              </Col>
              <div className="col">
                <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                <button
                  type="button"
                  className="btn btn-sm float-left btn-outline-primary mt-1"
                  onClick={() => onClickAddButton()}
                >
                  <i className="fas fa-plus" />
                  &nbsp;&nbsp;Add
                </button>
              </div>
              <div className="row">
                {(!showLoader) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  <BootstrapTable
                    data={tableData}
                  >
                    <TableHeaderColumn dataField="key" hidden isKey>No.</TableHeaderColumn>
                    <TableHeaderColumn dataField="urlCategory" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Url Category</TableHeaderColumn>
                    <TableHeaderColumn dataField="urlType" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Url Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="urlName" dataAlign="left" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Url</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <Col className="pt-2 pb-2 mt-3" md={12}>
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveMerchantUrlResults()}
                  >
                    Save
                  </button>}
                  <button
                    type="button"
                    className="btn btn-outline-primary float-right btn-sm  mr-3"
                    disabled={viewMode}
                    onClick={() => clearState()}
                  >
                    Cancel
                  </button>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}

MerchantUrl.propTypes = {
  getClientConfigurationRequest: PropTypes.object,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
  getClientConfigurationResult: PropTypes.object,
  getSystemMetaDataResult: PropTypes.object,
  updateClientConfigurationRequest: PropTypes.func,
  updateClientConfigurationResult: PropTypes.object,
  getFormMode: PropTypes.any,
  deleteClientConfigurationRequest: PropTypes.func,
  deleteClientConfigurationResult: PropTypes.object,
};
const mapStateToProps = state => ({
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  updateClientConfigurationResult: state.updateClientConfigurationResult,
  getFormMode: state.getFormMode,
  deleteClientConfigurationResult: state.deleteClientConfigurationResult,
});

export default connect(
  mapStateToProps,
  { getClientConfigurationRequest, updateClientConfigurationRequest, deleteClientConfigurationRequest }
)(MerchantUrl);
