import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { features } from '../../header/routing';
import LogoWhite from '../../../../static/images/logoWhite.svg';
import { sessionRedirection } from '../../../shared/utils/Redirect';
import CPDLogo from '../../../../static/icons/Logo/CPD.png';
import LoginIcon from '../../../../static/icons/usr-w-icon.svg';
import { ProfileScreen } from '../../../shared/utils/ClientData';


function HomePage(props) {
  const logout = () => {
    // logout and redirect
    sessionRedirection();
  };
  const permissions = props.getPermissionObject.data.menu;

  const analyticsList = features(permissions).find(v => v.menuGroup === 'Analytics');

  const voyageList = features(permissions).find(v => v.menuGroup === 'Voyage');

  const velocityList = features(permissions).find(v => v.menuGroup === 'Velocity');

  const consoleList = features(permissions).find(v => v.menuGroup === 'Console');
  const username = localStorage.getItem('username') || '';

  const pageData = (<div className="container-fluid loginscreen landingscreen login-screen">
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        <img className="dashboardBox-logo" src={LogoWhite} alt="vision-logo" />
        <div className="cpd-log-box">
          <div className="loginUser">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                <img src={LoginIcon} alt="cpd-logo" />
                {username}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {ProfileScreen() && <Link to="Profile" className="dropdown-item text-primary"> Profile</Link>}
                <button type="button" className="dropdown-item text-danger" onClick={() => logout()}>
                  <small>Logout</small>
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <img src={CPDLogo} alt="cpd-logo" className="login-screen-cpd-logo" />
        </div>
      </div>


      <div className="col-12 col-sm-12 col-md-12">
        <div className="dashboardBox">
          {analyticsList.menus.length > 0 && <div className="analyticsBox">
            <h2> analytics</h2>
            <ul className="loginDashboarLinls">
              {analyticsList.menus.map(key => key.permission && <li>

                <Link to={key.menuURL} className="nav-link">
                  <img src={key.icon} alt="sesktop" />
                  {key.menuItem}
                </Link>

              </li>)}
            </ul>
          </div>
          }


          {velocityList.menus.length > 0 && <div className="velocityBox">
            <h2> velocity</h2>
            <ul className="loginDashboarLinls">
              {velocityList.menus.map(key => key.permission && <li>

                <Link to={key.menuURL} className="nav-link">
                  <img src={key.icon} alt="sesktop" />
                  {key.menuItem}
                </Link>

              </li>)}
            </ul>
          </div>
          }

          {voyageList.menus.length > 0 && <div className="voyageBox">
            <h2> Voyage</h2>
            <ul className="loginDashboarLinls">
              {voyageList.menus.map(key => key.permission && <li>

                <Link to={key.menuURL} className="nav-link">
                  <img src={key.icon} alt="sesktop" />
                  {key.menuItem}
                </Link>

              </li>)}
            </ul>
          </div>
          }

          {consoleList.menus.length > 0 && <div className="consoleBox">
            <h2> Console</h2>
            <ul className="loginDashboarLinls">
              {consoleList.menus.map(key => key.permission && <li>

                <Link to={key.menuURL} className="nav-link">
                  <img src={key.icon} alt="sesktop" />
                  {key.menuItem}
                </Link>

              </li>)}
            </ul>
          </div>
          }
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12" />
    </div>
  </div>);

  return pageData;
}


HomePage.propTypes = {
  getPermissionObject: PropTypes.object,
};
const mapStateToProps = state => ({
  getPermissionObject: state.getPermissionObject,
});

export default connect(mapStateToProps, {})(HomePage);
