import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Col, Button, Accordion } from 'react-bootstrap';
import MultiSelect from '@khanacademy/react-multi-select';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useState, useEffect } from 'react';
import globals from '../../../../../redux/constants/globals';
import PageLoader from '../../../../shared/Loaders/TransparentLoader';
import {
  postAddonConfigurationRequest, getAllConfigurationForFxRequest, deleteAddonConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { tableHeadingColor, alertMessageSetTimeOut, _onClickDeleteRowStyleDisable } from '../Helper';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import { configurationList } from './VasSetupUtility';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyFx, validationRequiredInputMessage } from '../Constants';
import AlertPopup from '../../../../shared/Popups/AlertPopup';

function VasSetupFx(props) {
  const [showLoader] = useState(false);
  const reset = {
    serviceTypes: {
      nameProp: '',
      idProp: '',
    },
    country: {
      nameProp: '',
      idProp: '',
    },
    currency: {
      nameProp: '',
      idProp: '',
    },
    saleCurrency: {
      nameProp: '',
      idProp: '',
    },
    exchangeCurrency: {
      nameProp: '',
      idProp: '',
    },
    paymentMethod: [],
  };

  const [inputField, setInpuField] = useState(reset);
  const [fxData, setFxData] = useState([]);
  const [pccFxData, setPccFxData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [msg, setMsg] = useState('');
  const [disFlag, setDisFlag] = useState(false);
  const [countryDetail, setCountryDetail] = useState([]);
  const [currencyDetail, setCurrencyDetail] = useState([]);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [prevAddonConfigurationResult, setPrevAddonConfigurationResult] = useState(props.getAddonConfigurationResult.result);
  const [prevSystemMetaDataResult, setPrevSystemMetaDataResult] = useState(props.getSystemMetaDataResult.result);
  const [prevPaymentMetaDataResult, setPrevPaymentMetaDataResult] = useState(props.getPaymentMetaDataResult.result);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [alertMessageForServiceTypes, setAlertMessageForServiceTypes] = useState(false);
  const [preValue, setPreValue] = useState('');
  const [preId, setPreId] = useState('');

  useEffect(() => {
    if (prevAddonConfigurationResult !== props.getAddonConfigurationResult.result) {
      setPrevAddonConfigurationResult(props.getAddonConfigurationResult);
    }
  }, [props, prevAddonConfigurationResult]);

  useEffect(() => {
    if (prevSystemMetaDataResult !== props.getSystemMetaDataResult.result) {
      setPrevSystemMetaDataResult(props.getSystemMetaDataResult);
    }
    if (prevSystemMetaDataResult && prevSystemMetaDataResult.result && prevSystemMetaDataResult.result.system_metadata) {
      setData(prevSystemMetaDataResult.result.system_metadata);
    }
  }, [props, prevSystemMetaDataResult]);
  function setData(systemMetadata) {
    const countryDetailsList = systemMetadata && systemMetadata.country_details && systemMetadata.country_details.country_detail;
    if (countryDetailsList) {
      countryDetailsList.sort((a, b) => a.name.localeCompare(b.name));
      setCountryDetail(countryDetailsList);
    }
    const currencyDetailsList = systemMetadata && systemMetadata.currency_details && systemMetadata.currency_details.currency_detail;
    if (currencyDetailsList) {
      currencyDetailsList.sort((a, b) => a.code.localeCompare(b.code));
      setCurrencyDetail(currencyDetailsList);
    }
  }

  useEffect(() => {
    if (prevPaymentMetaDataResult !== props.getPaymentMetaDataResult.result) {
      setPrevPaymentMetaDataResult(props.getPaymentMetaDataResult);
    }
  }, [props, prevPaymentMetaDataResult]);

  const inputOnChange = (event, field) => {
    const index = event.nativeEvent.target.selectedIndex;
    const value = event.nativeEvent.target[index].text;
    const id = event.target.value;
    setPreValue(value);
    setPreId(id);
    if (field === 'country' || field === 'currency' || field === 'saleCurrency' || field === 'exchangeCurrency') {
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else if (field === 'serviceTypes') {
      if (value === 'DCC') {
        const findUpdatedData = pccFxData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setAlertMessageForServiceTypes(true);
        } else {
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
          setAlertMessageForServiceTypes(false);
        }
      } else if (value === 'PCC') {
        const findUpdatedData = fxData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setAlertMessageForServiceTypes(true);
        } else {
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
          setAlertMessageForServiceTypes(false);
        }
      }
    } else {
      setInpuField({ ...inputField, [field]: value });
    }
  };
  const inputOnChangePm = (event, field) => {
    setInpuField({ ...inputField, [field]: event });
  };

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const deleteRow = row => {
    let filter = '';
    if (row.dcc) {
      filter = getUnique(fxData, 'key');
      if (filter.length !== fxData.length) {
        setFxData(filter);
      } else {
        filter = fxData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setFxData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    } else {
      filter = getUnique(pccFxData, 'key');
      if (filter.length !== pccFxData.length) {
        setPccFxData(filter);
      } else {
        filter = pccFxData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setPccFxData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }
      }
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }
  const serviceTypeDropdown = [];
  let addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;
  if (addonTypes) {
    if (!Array.isArray(addonTypes)) {
      addonTypes = [{
        ...addonTypes,
      }];
    }
    addonTypes.forEach(key => {
      if (key.name === 'FX') {
        key.addon_subtypes.addon_subtype.forEach(subType => {
          serviceTypeDropdown.push({
            id: subType.id,
            type: subType.name,
          });
        });
      }
    });
  }

  let payConfigList = [];
  const pmConfigurationsAPICall = paymentList => {
    let pmConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.pm_configurations && props.getClientConfigurationResult.result.client_configuration.pm_configurations.pm_configuration;
    if (pmConfigurations) {
      if (!Array.isArray(pmConfigurations)) {
        pmConfigurations = [{
          ...pmConfigurations,
        }];
      }
      payConfigList = pmConfigurations.filter(obj => obj.enabled === true).map(obj => obj.pm_id);
    }
    paymentList.forEach(key => {
      const multiSelectOption = {
        value: key.id,
        label: key.name,
      };
      paymentMethodListArray.push(multiSelectOption);
      if (payConfigList.includes(key.id)) paymentMethodSelectedListArray.push(multiSelectOption);
    });
  };
  const paymentMethodListArray = [];
  const paymentMethodSelectedListArray = [];
  let paymentMethod = props.getPaymentMetaDataResult.result && props.getPaymentMetaDataResult.result.payment_metadata && props.getPaymentMetaDataResult.result.payment_metadata.pms && props.getPaymentMetaDataResult.result.payment_metadata.pms.pm;
  if (paymentMethod) {
    if (!Array.isArray(paymentMethod)) {
      paymentMethod = [{
        ...paymentMethod,
      }];
    }
    pmConfigurationsAPICall(paymentMethod);
  }

  const _onClickAddButton = () => {
    setMsg('');
    setDisFlag(false);
    if (inputField.country === undefined || inputField.currency === undefined || inputField.paymentMethod === undefined
    || inputField.country === 'Select' || inputField.currency === 'Select' || inputField.paymentMethod === 'Select Payment Method' || inputField.country.idProp === '' || inputField.currency.idProp === '' || inputField.paymentMethod.idProp === '' || inputField.paymentMethod.length === 0) {
      setDisFlag(true);
      alertMessageSetTimeOut();
      return false;
    }
    let flag = false;
    if (inputField.paymentMethod) {
      inputField.paymentMethod.forEach(key => {
        flag = false;
        fxData.forEach(data => {
          if ((data.country_id === parseInt(inputField.country.idProp, 10) || data.country_id === 0 || parseInt(inputField.country.idProp, 10) === 0) && (data.currency_id === parseInt(inputField.currency.idProp, 10) || data.currency_id === 0 || parseInt(inputField.currency.idProp, 10) === 0) && data.pm_id === key) {
            flag = true;
            if (flag) {
              setErrorMsg(true);
              const errmsg = 'Duplicate Entry Not Allow';
              setMsg(errmsg);
              alertMessageSetTimeOut();
            }
          }
        });
      });
      inputField.paymentMethod.forEach(key => {
        flag = false;
        fxData.forEach(data => {
          if ((data.country_id === parseInt(inputField.country.idProp, 10) || data.country_id === 0 || parseInt(inputField.country.idProp, 10) === 0) && (data.currency_id === parseInt(inputField.currency.idProp, 10) || data.currency_id === 0 || parseInt(inputField.currency.idProp, 10) === 0) && data.pm_id === key) {
            flag = true;
          }
        });
        if (!flag) {
          const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
          const count = fxData.length + 1;
          fxData.push({
            key: count,
            country: inputField.country.nameProp,
            country_id: parseInt(inputField.country.idProp, 10),
            currency: inputField.currency.nameProp,
            currency_id: parseInt(inputField.currency.idProp, 10),
            paymentMethod: selectedPaymentMethod[0].label,
            pm_id: key,
            saleCurrency: inputField.saleCurrency,
            action: '',
            deleteKey: false,
            existFlag: false,
            dcc: true,
          });
        }
      });
    }
    setFxData(fxData);
    const data = { ...inputField };
    data.key = 0;
    data.country.idProp = '';
    data.currency.idProp = '';
    data.paymentMethod = '';
    setInpuField(data);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };

  const _onClickAddButtonForPcc = () => {
    setMsg('');
    setDisFlag(false);
    if (inputField.saleCurrency === undefined || inputField.exchangeCurrency === undefined || inputField.paymentMethod === undefined
    || inputField.saleCurrency === 'Select' || inputField.exchangeCurrency === 'Select' || inputField.paymentMethod === 'Select Payment Method' || inputField.saleCurrency.idProp === '' || inputField.exchangeCurrency.idProp === '' || inputField.paymentMethod.length === 0) {
      setDisFlag(true);
      alertMessageSetTimeOut();
      return false;
    }
    let flag = false;
    if (inputField.paymentMethod) {
      inputField.paymentMethod.forEach(key => {
        flag = false;
        pccFxData.forEach(data => {
          if (data.currency_id === parseInt(inputField.saleCurrency.idProp, 10) && data.settlement_currency_id === parseInt(inputField.exchangeCurrency.idProp, 10) && data.pm_id === key) {
            flag = true;
            if (flag) {
              setErrorMsg(true);
              const errmsg = 'Duplicate Entry Not Allow';
              setMsg(errmsg);
              alertMessageSetTimeOut();
            }
          }
        });
      });
      inputField.paymentMethod.forEach(key => {
        flag = false;
        pccFxData.forEach(data => {
          if (data.currency_id === parseInt(inputField.saleCurrency.idProp, 10) && data.settlement_currency_id === parseInt(inputField.exchangeCurrency.idProp, 10) && data.pm_id === key) {
            flag = true;
          }
        });
        if (!flag) {
          const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
          const count = pccFxData.length + 1;
          pccFxData.push({
            key: count,
            saleCurrency: inputField.saleCurrency.nameProp,
            currency_id: parseInt(inputField.saleCurrency.idProp, 10),
            exchangeCurrency: inputField.exchangeCurrency.nameProp,
            settlement_currency_id: parseInt(inputField.exchangeCurrency.idProp, 10),
            paymentMethod: selectedPaymentMethod[0].label,
            pm_id: key,
            action: '',
            deleteKey: false,
            existFlag: false,
            pcc: true,
          });
        }
      });
    }
    setPccFxData(pccFxData);
    const data = { ...inputField };
    data.key = 0;
    data.saleCurrency.idProp = '';
    data.exchangeCurrency.idProp = '';
    data.paymentMethod = '';
    setInpuField(data);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };

  const action = (cell, row) => (
    <div>
      { (row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Delete" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Add" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );
  const saveFxResults = () => {
    setMsg('');
    setIsLoaderOn(true);
    setDisFlag(false);
    let findPcc = '';
    let findDcc = '';
    let requestBodyForDeleteApi;
    findDcc = fxData.some(element => (element.deleteKey === false));
    findPcc = pccFxData.some(element => (element.deleteKey === false));
    if (inputField.serviceTypes.nameProp === 'DCC' && fxData.length > 0 && !findDcc) {
      requestBodyForDeleteApi = {
        client_id: globals.clientId,
        dcc: -1,
      };
      props.deleteAddonConfigurationRequest(requestBodyForDeleteApi);
      setFxData([]);
    } else if (inputField.serviceTypes.nameProp === 'PCC' && pccFxData.length > 0 && !findPcc) {
      requestBodyForDeleteApi = {
        client_id: globals.clientId,
        pcc: -1,
      };
      props.deleteAddonConfigurationRequest(requestBodyForDeleteApi);
      setPccFxData([]);
    } else {
      const dccListArray = [];
      const pccListArray = [];
      let requestBody;
      if (inputField.serviceTypes.nameProp === 'DCC') {
        fxData.forEach(obj => {
          if (!obj.deleteKey) {
            dccListArray.push({
              country_id: obj.country_id,
              currency_id: obj.currency_id,
              pm_id: obj.pm_id,
            });
          }
        });
        requestBody = {
          client_id: globals.clientId,
          dcc_config: {
            addon_configurations: {
              addon_configuration: dccListArray,
            },
          },
        };
      } else if (inputField.serviceTypes.nameProp === 'PCC') {
        pccFxData.forEach(obj => {
          if (!obj.deleteKey) {
            pccListArray.push({
              currency_id: obj.currency_id,
              pm_id: obj.pm_id,
              settlement_currency_id: obj.settlement_currency_id,
              is_presentment: true,
            });
          }
        });
        requestBody = {
          client_id: globals.clientId,
          pcc_config: {
            addon_configurations: {
              addon_configuration: pccListArray,
            },
          },
        };
      }
      // Put API call with table data
      props.postAddonConfigurationRequest(requestBody);
    }

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
  };
  // Wait for Sucess result of postAddonConfigurationResult then call getAllConfigurationForFxRequest
  useEffect(() => {
    if (props.activeKey === activeKeyFx && props.postAddonConfigurationResult.fetched && !props.postAddonConfigurationResult.fetching && props.postAddonConfigurationResult.requestStatus) {
      if (props.postAddonConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.added);
      } else {
        notify('error', toastMessages.merchantServices.error.added);
      }
      props.getAllConfigurationForFxRequest();
    }
  }, [props.postAddonConfigurationResult]);

  // Wait for Sucess result of postAddonConfigurationResult then call getAllConfigurationForFxRequest
  useEffect(() => {
    if (props.activeKey === activeKeyFx && props.deleteAddonConfigurationResult.fetched && !props.deleteAddonConfigurationResult.fetching && props.deleteAddonConfigurationResult.requestStatus) {
      if (props.deleteAddonConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getAllConfigurationForFxRequest();
    }
  }, [props.deleteAddonConfigurationResult]);

  // Show Loader still get getAllConfigurationForFxResult
  useEffect(() => {
    if (props.getAllConfigurationForFxResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });
  useEffect(() => {
    if (props.getAllConfigurationForFxResult.fetched && !props.getAllConfigurationForFxResult.fetching && props.getAllConfigurationForFxResult.requestStatus && (props.activeKey === activeKeyFx || props.activeKey === '0')) {
      updatedDccAndPccData();
    }
  }, [props.getAllConfigurationForFxResult.result]);

  const updatedDccAndPccData = () => {
    let addonConfigurationForFxData = props.getAllConfigurationForFxResult && props.getAllConfigurationForFxResult.result && props.getAllConfigurationForFxResult.result.addon_configuration;
    if (addonConfigurationForFxData) {
      if (!Array.isArray(addonConfigurationForFxData)) {
        addonConfigurationForFxData = [{
          ...addonConfigurationForFxData,
        }];
      }
      addonConfigurationForFxData = addonConfigurationForFxData.map(obj => {
        if (obj.country_id === 0 || obj.country_id === null) {
          return { ...obj, country_id: 0, country_name: '-- All --' };
        }
        return obj;
      });
      addonConfigurationForFxData = addonConfigurationForFxData.map(obj => {
        if (obj.currency_id === 0 || obj.currency_id === null) {
          return { ...obj, currency_id: 0, currency_code: '-- All --' };
        }
        return obj;
      });
      setFxData(configurationList('DCC', addonConfigurationForFxData));
    }

    let pccConfig = props.getAllConfigurationForFxResult.result && props.getAllConfigurationForFxResult.result.pcc_config;
    if (pccConfig) {
      if (!Array.isArray(pccConfig)) {
        pccConfig = [{
          ...pccConfig,
        }];
      }
      pccConfig = pccConfig.map(obj => {
        if (obj.country_id === 0 || obj.country_id === null) {
          return { ...obj, country_id: 0, country_name: '-- All --' };
        }
        return obj;
      });
      pccConfig = pccConfig.map(obj => {
        if (obj.currency_id === 0 || obj.currency_id === null) {
          return { ...obj, currency_id: 0, currency_code: '-- All --' };
        }
        return obj;
      });
      setPccFxData(configurationList('PCC', pccConfig));
    }
  };

  // set default data for pccFxData and fxData
  const clearState = () => {
    setMsg('');
    setDisFlag(false);
    const preConfigDccFxData = fxData.filter(fx => fx.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'DCC') {
      setFxData(preConfigDccFxData);
    }
    const preConfigPccFxData = pccFxData.filter(pccFx => pccFx.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'PCC') {
      setPccFxData(preConfigPccFxData);
    }

    if (inputField.serviceTypes.nameProp === 'DCC') {
      const preUpdatedDccConfigData = preConfigDccFxData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setFxData(preUpdatedDccConfigData);
    } else if (inputField.serviceTypes.nameProp === 'PCC') {
      const preUpdatePccConfigData = preConfigPccFxData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPccFxData(preUpdatePccConfigData);
    }
    const data = { ...inputField };
    data.key = 0;
    data.country.idProp = '';
    data.currency.idProp = '';
    data.paymentMethod = '';
    data.saleCurrency.idProp = '';
    data.exchangeCurrency.idProp = '';
    setInpuField(data);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // toast messages
    notify('success', toastMessages.merchantServices.success.reset);
  };

  const confirmYesData = () => {
    const field = 'serviceTypes';
    const preConfigDccData = fxData.filter(dcc => dcc.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'DCC') {
      setFxData(preConfigDccData);
    }
    const preConfigPccData = pccFxData.filter(pcc => pcc.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'PCC') {
      setPccFxData(preConfigPccData);
    }

    if (inputField.serviceTypes.nameProp === 'DCC') {
      const preUpdateConfigDccData = preConfigDccData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setFxData(preUpdateConfigDccData);
    } else if (inputField.serviceTypes.nameProp === 'PCC') {
      const preUpdateConfigPccData = preConfigPccData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPccFxData(preUpdateConfigPccData);
    }
    setInpuField({ ...inputField, [field]: { nameProp: preValue, idProp: preId } });
    setAlertMessageForServiceTypes(false);
  };
  const confirmNoData = () => {
    setAlertMessageForServiceTypes(false);
  };
  return (
    <div className="card">
      {(alertMessageForServiceTypes) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      {props.activeKey === activeKeyFx && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterFxData = fxData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setFxData(filterFxData);

          const filterPccFxData = pccFxData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPccFxData(filterPccFxData);

          setHasDeleteRecordExist(0);
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveFxResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      {showLoader && <PageLoader />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyFx ? '' : activeKeyFx)} eventKey={activeKeyFx}>
          <h6>
            FX
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyFx}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Service Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'serviceTypes')}
                      value={inputField.serviceTypes.idProp}
                      data-toggle="modal"
                      data-target="#alertModal"
                    >
                      <option disabled selected value="">Please Select</option>
                      {serviceTypeDropdown.map(key => <option value={key.id}>{key.type}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
              { (inputField.serviceTypes.nameProp === 'DCC') && <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Country
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'country')}
                      value={inputField.country.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {countryDetail.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Sale Currency
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'currency')}
                      value={inputField.currency.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {currencyDetail.map(key => <option value={key.id}>{key.code}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <Col className="form-group" md={2}>
                  <Form.Label className="mandatory">Payment Method</Form.Label>
                  <MultiSelect
                    options={paymentMethodSelectedListArray}
                    selected={inputField.paymentMethod}
                    onSelectedChanged={e => inputOnChangePm(e, 'paymentMethod')}
                    overrideStrings={{
                      selectSomeItems: 'Select Payment Method',
                      allItemsAreSelected: 'All Payment Method are selected...',
                      selectAll: 'Select All Payment Method',
                    }}
                  />
                </Col>
                <div className="col-md-1">
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </div>
              </div>}
              { (inputField.serviceTypes.nameProp === 'PCC') && <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Sale Currency
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'saleCurrency')}
                      value={inputField.saleCurrency.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {currencyDetail.map(key => <option value={key.id}>{key.code}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Exchange Currency
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'exchangeCurrency')}
                      value={inputField.exchangeCurrency.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {currencyDetail.map(key => <option value={key.id}>{key.code}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <Col className="form-group" md={2}>
                  <Form.Label className="mandatory">Payment Method</Form.Label>
                  <MultiSelect
                    options={paymentMethodSelectedListArray}
                    selected={inputField.paymentMethod}
                    onSelectedChanged={e => inputOnChangePm(e, 'paymentMethod')}
                    overrideStrings={{
                      selectSomeItems: 'Select Payment Method',
                      allItemsAreSelected: 'All Payment Method are selected...',
                      selectAll: 'Select All Payment Method',
                    }}
                  />
                </Col>
                <div className="col-md-1">
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => _onClickAddButtonForPcc()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </div>
              </div>}
              { (inputField.serviceTypes.nameProp === 'DCC' || inputField.serviceTypes.nameProp === 'PCC') && errorMsg && <div className="alert alert-danger">
                {errorMsg ? msg : ''}
              </div>}
              { disFlag && <div className="alert alert-danger">
                {disFlag ? validationRequiredInputMessage : ''}
              </div>}
              {(inputField.serviceTypes.nameProp === 'DCC' || inputField.serviceTypes.nameProp === 'PCC') && <div className="row">
                {(!isLoaderOn) ? <div className="col-md-12  pt-2 pb-2 mt-3">
                  <BootstrapTable data={inputField.serviceTypes.nameProp === 'DCC' ? fxData : pccFxData}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    { (inputField.serviceTypes.nameProp === 'DCC') && <TableHeaderColumn dataField="country" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Country</TableHeaderColumn>}
                    { (inputField.serviceTypes.nameProp === 'DCC') && <TableHeaderColumn dataField="currency" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Sale Currency</TableHeaderColumn>}
                    { (inputField.serviceTypes.nameProp === 'PCC') && <TableHeaderColumn dataField="saleCurrency" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Sale Currency</TableHeaderColumn>}
                    { (inputField.serviceTypes.nameProp === 'PCC') && <TableHeaderColumn dataField="exchangeCurrency" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Exchange Currency</TableHeaderColumn>}
                    { (inputField.serviceTypes.nameProp === 'DCC' || inputField.serviceTypes.nameProp === 'PCC') && <TableHeaderColumn dataField="paymentMethod" dataSort className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Payment Method</TableHeaderColumn>}
                    { (inputField.serviceTypes.nameProp === 'DCC' || inputField.serviceTypes.nameProp === 'PCC') && <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>}
                  </BootstrapTable>
                </div>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <div className="col-md-12  pt-2 pb-2 mt-3">
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveFxResults()}
                  >
                    Save
                  </button>}
                  <Button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mr-3"
                    onClick={() => clearState()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>}
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}
VasSetupFx.propTypes = {
  postAddonConfigurationRequest: PropTypes.func,
  getAllConfigurationForFxRequest: PropTypes.object,
  getSystemMetaDataResult: PropTypes.object,
  postAddonConfigurationResult: PropTypes.object,
  deleteAddonConfigurationResult: PropTypes.object,
  getClientConfigurationResult: PropTypes.object,
  getPaymentMetaDataResult: PropTypes.object,
  getAddonConfigurationResult: PropTypes.object,
  getAllConfigurationForFxResult: PropTypes.object,
  deleteAddonConfigurationRequest: PropTypes.func,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  getPaymentMetaDataResult: state.getPaymentMetaDataResult,
  getAddonConfigurationResult: state.getAddonConfigurationResult,
  getAllConfigurationForFxResult: state.getAllConfigurationForFxResult,
  postAddonConfigurationResult: state.postAddonConfigurationResult,
  deleteAddonConfigurationResult: state.deleteAddonConfigurationResult,
});

export default connect(
  mapStateToProps, {
    postAddonConfigurationRequest, getAllConfigurationForFxRequest, deleteAddonConfigurationRequest,
  }
)(VasSetupFx);
