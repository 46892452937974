import React from 'react';
import PropTypes from 'prop-types';

const DeletePopup = props => (
  <div className="modal fade show active" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Alert!</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {props.params === 'enable_payment_gateway' ? 'Removal of Payment Gateway/Acquirer will remove all its associated routes, Are you sure you want to delete this Payment Gateway/Acquirer?' : 'Are you sure you want to delete?'}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={props.declineDeleteClick}>No</button>
          <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={props.acceptDeleteClick}>
            <i className="far" />
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
);
DeletePopup.propTypes = {
  declineDeleteClick: PropTypes.func,
  acceptDeleteClick: PropTypes.func,
  params: PropTypes.string,
};

export default DeletePopup;
