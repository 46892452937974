import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
};

export const createNotificationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CREATE_NOTIFICATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CREATE_NOTIFICATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CREATE_NOTIFICATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const userSegmentationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_NOTIFICATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_NOTIFICATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_NOTIFICATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const scheduleNotificationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.SCHEDULE_NOTIFICATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.SCHEDULE_NOTIFICATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.SCHEDULE_NOTIFICATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


const notificationState = {
  name: '',
  id: '',
  notificationId: '',
  eventId: '',
  eventName: '',
  promoId: '',
  promoName: '',
  navigationFlow: '',
  mode: '',
};
export const getNotificationEngineData = (state = notificationState, action) => {
  switch (action.type) {
  case types.SET_NOTIFICATION_DATA:
    return { ...state, ...action.data };
  case types.CLEAR_NOTIFICATION_DATA:
    return {
      notificationId: '',
      promoId: '',
      promoName: '',
      navigationFlow: '',
      mode: '',
    };
  default:
    return state;
  }
};

export const getNotificationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_NOTIFICATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_NOTIFICATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_NOTIFICATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const notificationMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_NOTIFICATION_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_NOTIFICATION_METADATA_SUCCESS:
    return {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      error: false,
    };
  case types.GET_NOTIFICATION_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

const initialAwsObjectState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
  requestNumber: false,
};

export const getSynchronisedAWSObjectResult = (state = initialAwsObjectState, action) => {
  switch (action.type) {
  case types.GET_SYNCHRONIZED_AWSOBJECT_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {}, requestNumber: false,
    };
  case types.GET_SYNCHRONIZED_AWSOBJECT_SUCCESS:
    return {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      error: false,
      requestNumber: action.bannerNumber,
    };
  case types.GET_SYNCHRONIZED_AWSOBJECT_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      result: false,
      error: true,
      requestNumber: false,
    };
  default:
    return state;
  }
};


export const getAllNotificationsResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALLNOTIFICATIONS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALLNOTIFICATIONS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALLNOTIFICATIONS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

const storeFrontState = {
  action: {},
  storeFront: false,
};

export const cloneStoreFrontData = (state = storeFrontState, action) => {
  switch (action.type) {
  case types.SET_CLONE_STOREFRONT:
    // eslint-disable-next-line no-case-declarations
    const actionData = action.data.action || state.action;
    // eslint-disable-next-line no-case-declarations
    const stateData = { ...state, action: actionData, storeFront: action.data.storeFront || state.storeFront };


    return stateData;
  case types.CLEAR_CLONE_STOREFRONT:
    return {
      ...state, action: false, storeFront: false,
    };
  default:
    return state;
  }
};
