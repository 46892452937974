

/*
**  Dynamic Banners Constant
*/

export const CHANGE_DYNAMICBANNER_NAME = 'CHANGE_DYNAMICBANNER_NAME';
export const CHANGE_DYNAMICBANNER_FILE = 'CHANGE_DYNAMICBANNER_FILE';
export const ON_DYNAMICBANNER_FILE_DELETE = 'ON_DYNAMICBANNER_FILE_DELETE';
export const CHANGE_DYNAMICBANNER_STARTDATE = 'CHANGE_DYNAMICBANNER_STARTDATE';
export const CHANGE_DYNAMICBANNER_ENDDATE = 'CHANGE_DYNAMICBANNER_ENDDATE';
export const ADD_DYNAMICBANNER_ENDDATE = 'ADD_DYNAMICBANNER_ENDDATE';
export const REMOVE_DYNAMICBANNER_ENDDATE = 'REMOVE_DYNAMICBANNER_ENDDATE';
export const ADD_DYNAMICBANNER_NEWBANNER = 'ADD_DYNAMICBANNER_NEWBANNER';
// Dynamic Banner Create Request
export const DYNAMICBANNER_CREATE_REQUEST = 'DYNAMICBANNER_CREATE_REQUEST';
export const DYNAMICBANNER_CREATE_SUCCESS = 'DYNAMICBANNER_CREATE_SUCCESS';
export const DYNAMICBANNER_CREATE_FAILURE = 'DYNAMICBANNER_CREATE_FAILURE';
// Dynamic Banner Create Request
export const DYNAMICBANNER_UPDATE_REQUEST = 'DYNAMICBANNER_UPDATE_REQUEST';
export const DYNAMICBANNER_UPDATE_SUCCESS = 'DYNAMICBANNER_UPDATE_SUCCESS';
export const DYNAMICBANNER_UPDATE_FAILURE = 'DYNAMICBANNER_UPDATE_FAILURE';
// Dynamic Banner View Request
export const DYNAMICBANNER_VIEW_REQUEST = 'DYNAMICBANNER_VIEW_REQUEST';
export const DYNAMICBANNER_VIEW_SUCCESS = 'DYNAMICBANNER_VIEW_SUCCESS';
export const DYNAMICBANNER_VIEW_FAILURE = 'DYNAMICBANNER_VIEW_FAILURE';
// Dynamic Banner Edit Request
export const DYNAMICBANNER_EDIT_REQUEST = 'DYNAMICBANNER_EDIT_REQUEST';
export const DYNAMICBANNER_EDIT_FAILURE = 'DYNAMICBANNER_EDIT_FAILURE';
export const DYNAMICBANNER_EDIT_SUCCESS = 'DYNAMICBANNER_EDIT_SUCCESS';
// Dynamic Banner Aws Object Request
export const GET_DYNAMICBANNER_AWSOBJECT_REQUEST = 'GET_DYNAMICBANNER_AWSOBJECT_REQUEST';
export const GET_DYNAMICBANNER_AWSOBJECT_SUCCESS = 'GET_DYNAMICBANNER_AWSOBJECT_SUCCESS';
export const GET_DYNAMICBANNER_AWSOBJECT_FAILURE = 'GET_DYNAMICBANNER_AWSOBJECT_FAILURE';
// Dynamic Banner Delete Request
export const DYNAMICBANNER_DELETE_REQUEST = 'DYNAMICBANNER_DELETE_REQUEST';
export const DYNAMICBANNER_DELETE_SUCCESS = 'DYNAMICBANNER_DELETE_SUCCESS';
export const DYNAMICBANNER_DELETE_FAILURE = 'DYNAMICBANNER_DELETE_FAILURE';
// Dynamic Banner Reset edit mode
export const RESET_DYNAMICBANNER_EDIT_FORM = 'RESET_DYNAMICBANNER_EDIT_FORM';
// Set Dynamic Banner data
export const SET_VIEW_DYNAMICBANNER = 'SET_VIEW_DYNAMICBANNER';
export const CLEAR_VIEW_DYNAMICBANNER = 'CLEAR_VIEW_DYNAMICBANNER';
