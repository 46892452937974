/* eslint-disable */
import globals from '../../../redux/constants/globals';
import { getUniqueFromArray } from './index';

const data = JSON.parse(localStorage.getItem('ClientData'));
const userListForAccountData = JSON.parse(localStorage.getItem('userListForAccount'));

export const clientData = {
  defaultMpointCurrencyId: data && data.defaultMpointCurrencyId,
  defaultCurrencyId: data && data.defaultCurrencyId,
  defaultTimeZone: data && data.defaultTimeZone,
  defaultUserTimeZone: userListForAccountData && userListForAccountData.userUTCTimezone ? userListForAccountData.userUTCTimezone : false,
  defaultCountryName: data && data.defaultCountryName,
};

export const getClientLogo = () => {
  const sessionData = JSON.parse(localStorage.getItem('ClientData'));
  return sessionData && sessionData.logo;
};

export const getClientConfigurations = () => {
  const localStorageConfig = localStorage.getItem('clientConfiguration');
  const configObject = localStorageConfig && JSON.parse(localStorageConfig);
  return configObject;
};

export const getSystemMetaDataConfiguration = () => {
  const data = localStorage.getItem('systemMetaDataResult');
  const configObject = data && JSON.parse(data);
  return configObject;
};

export const currencyData = currentSelectedClientId => getClientConfigurations()[currentSelectedClientId].currencies;

export const storeFrontsData = currentSelectedClientId => getClientConfigurations()[currentSelectedClientId].storeFronts;

export const transactionStatus = currentSelectedClientId => getSystemMetaDataConfiguration().transactionStates;

export const getCurrencyList = currentSelectedClientIds => {
  const currencyList = [];
  let currencies = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const currenciesPerClient = currencyData(key) || [];
      currencies = [...new Set([...currencies, ...currenciesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const currenciesPerClient = currencyData(key) || [];
      currencies = [...new Set([...currencies, ...currenciesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  currencies.forEach(key => {
    currencyList.push({ id: key.id, value: key.name, mpointCurrencyId: key.mpointCurrencyId });
  });
  return getUniqueFromArray(currencyList, 'id');
};

// Currency with mpoint currency id
export const getMPointCurrencyList = currentSelectedClientIds => {
  const currencyList = [];
  let currency = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const currencyPerClient = currencyData(key) || [];
      currency = [...new Set([...currency, ...currencyPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const currencyPerClient = currencyData(key) || [];
      currency = [...new Set([...currency, ...currencyPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  currency.forEach(key => {
    currencyList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(currencyList, 'id');
};

export const getCurrencyNameById = id => {
  const currencyFilter = getCurrencyList().filter(key => key.id.toString() === id.toString());
  let currencyName = '';
  if (currencyFilter && currencyFilter.length > 0) {
    currencyName = currencyFilter[0].value;
  }
  return currencyName;
};


export const pspNameData = currentSelectedClientIds => getClientConfigurations()[currentSelectedClientIds].psp;

export const walletData = () => getSystemMetaDataConfiguration().walletList;

export const pspNameList = currentSelectedClientIds => {
  const pspNameList = [];
  let pspNames = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const pspNamesPerClient = pspNameData(key) || [];
      pspNames = [...new Set([...pspNames, ...pspNamesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const pspNamesPerClient = pspNameData(key) || [];
      pspNames = [...new Set([...pspNames, ...pspNamesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  pspNames.forEach(key => {
    pspNameList.push({ id: key.id, value: key.name, paymentType: key.paymentTypeId });
  });
  return pspNameList;
};


export const purchasePathList = currentSelectedClientIds => {
  const purPathList = [];
  let purPath = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const purPathPerClient = purchasePath(key) || [];
      purPath = [...new Set([...purPath, ...purPathPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const purPathPerClient = purchasePath(key) || [];
      purPath = [...new Set([...purPath, ...purPathPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }


  purPath.forEach(key => {
    purPathList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(purPathList, 'id');
};

export const routingRuleList = currentSelectedClientIds => {
  const routingList = [];
  let rules = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const rulesPerClient = routingRule(key) || [];
      rules = [...new Set([...rulesPerClient, ...rules])].filter((v, i, a) => a.findIndex(v2 => (v2.ruleId === v.ruleId)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const rulesPerClient = routingRule(key) || [];
      rules = [...new Set([...rulesPerClient, ...rules])].filter((v, i, a) => a.findIndex(v2 => (v2.ruleId === v.ruleId)) === i);
    });
  }

  rules.forEach(key => {
    routingList.push({ id: key.ruleId, value: key.ruleName });
  });
  return getUniqueFromArray(routingList, 'id');
};

export const fraudCheckList = currentSelectedClientIds => {
  const fraudCheckList = [];
  let fraudCheck = [];

  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const fraudCheckPerClient = fraudCheckStates(key) || [];
      fraudCheck = [...new Set([...fraudCheck, ...fraudCheckPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const fraudCheckPerClient = fraudCheckStates(key) || [];
      fraudCheck = [...new Set([...fraudCheck, ...fraudCheckPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  fraudCheck.forEach(key => {
    fraudCheckList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(fraudCheckList, 'id');
};
export const preAuthFraudStatusList = currentSelectedClientIds => {
  const preAuthFraudStatusList = [];
  let preAuthFraudStatus = [];

  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const preAuthFraudStatusPerClient = preAuthFraudStates(key) || [];
      preAuthFraudStatus = [...new Set([...preAuthFraudStatus, ...preAuthFraudStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const preAuthFraudStatusPerClient = preAuthFraudStates(key) || [];
      preAuthFraudStatus = [...new Set([...preAuthFraudStatus, ...preAuthFraudStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  preAuthFraudStatus.forEach(key => {
    preAuthFraudStatusList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(preAuthFraudStatusList, 'id');
};
export const postAuthFraudStatusList = currentSelectedClientIds => {
  const postAuthFraudStatusList = [];
  let postAuthFraudStatus = [];

  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const postAuthFraudStatusPerClient = postAuthFraudStates(key) || [];
      postAuthFraudStatus = [...new Set([...postAuthFraudStatus, ...postAuthFraudStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const postAuthFraudStatusPerClient = postAuthFraudStates(key) || [];
      postAuthFraudStatus = [...new Set([...postAuthFraudStatus, ...postAuthFraudStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  postAuthFraudStatus.forEach(key => {
    postAuthFraudStatusList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(postAuthFraudStatusList, 'id');
};

export const postAuthFraudStates = () => getSystemMetaDataConfiguration().postAuthFraudStates;

export const preAuthFraudStates = () => getSystemMetaDataConfiguration().preAuthFraudStates;

export const preAuthFraudProvider = () => getSystemMetaDataConfiguration().preAuthFraudProvider;

export const postAuthFraudProvider = () => getSystemMetaDataConfiguration().postAuthFraudProvider;

export const fraudCheckStates = () => getSystemMetaDataConfiguration().fraudCheckStates;

export const paymentMethods = currentSelectedClientId => getClientConfigurations()[currentSelectedClientId].paymentMethods;

export const purchasePath = () => getSystemMetaDataConfiguration().purchasePath;

export const routingRule = currentSelectedClientId => getClientConfigurations()[currentSelectedClientId].routingRule;

export const countryConfigsData = currentSelectedClientId => getClientConfigurations()[currentSelectedClientId].countries;

export const countryConfigsList = currentSelectedClientIds => {
  const countryConfigsList = [];
  let countryConfigs = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const countryConfigsPerClient = countryConfigsData(key) || [];
      countryConfigs = [...new Set([...countryConfigs, ...countryConfigsPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const countryConfigsPerClient = countryConfigsData(key) || [];
      countryConfigs = [...new Set([...countryConfigs, ...countryConfigsPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  countryConfigs.forEach(key => {
    countryConfigsList.push({ id: key.id, value: key.name, mpointCountryId: key.mpointCountryId });
  });
  return countryConfigsList;
};


export const pointOfSaleList = () => {
  const pointOfSaleList = countryConfigsList();
  return getUniqueFromArray(pointOfSaleList, 'id');
};

export const pointOfSaleFilterList = () => {
  const pointOfSaleList = [];
  const countryConfigs = countryConfigsList() || [];
  countryConfigs.forEach(key => {
    pointOfSaleList.push({ id: key.id, value: key.value });
  });
  return getUniqueFromArray(pointOfSaleList, 'id');
};

export const paymentMethodList = currentSelectedClientIds => {
  const paymentMethodList = [];
  let paymentMethod = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const paymentMethodPerClient = paymentMethods(key) || [];
      paymentMethod = [...new Set([...paymentMethod, ...paymentMethodPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const paymentMethodPerClient = paymentMethods(key) || [];
      paymentMethod = [...new Set([...paymentMethod, ...paymentMethodPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  paymentMethod.forEach(key => {
    paymentMethodList.push({ id: key.id, value: key.name, paymentType: key.paymentTypeId });
  });
  return getUniqueFromArray(paymentMethodList, 'id');
};


export const storeFrontsList = currentSelectedClientIds => {
  const storeFrontsList = [];
  let storeFronts = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const storeFrontsPerClient = storeFrontsData(key) || [];
      storeFronts = [...new Set([...storeFronts, ...storeFrontsPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const storeFrontsPerClient = storeFrontsData(key) || [];
      storeFronts = [...new Set([...storeFronts, ...storeFrontsPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  storeFronts.forEach(key => {
    storeFrontsList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(storeFrontsList, 'id');
};

export const transactionStatusList = (currentSelectedClientIds, flag = false) => {
  const transactionStatussList = [];
  let gettransactionStatus = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const gettransactionStatusPerClient = transactionStatus(key) || [];
      gettransactionStatus = [...new Set([...gettransactionStatus, ...gettransactionStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const gettransactionStatusPerClient = transactionStatus(key) || [];
      gettransactionStatus = [...new Set([...gettransactionStatus, ...gettransactionStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  gettransactionStatus.forEach(key => {
    if (flag) {
      if (key.id === 2003 || key.id === 2013 || key.id === 2203 || key.id === 20032) {
        transactionStatussList.push({ id: key.id, value: key.name });
      }
    } else {
      transactionStatussList.push({ id: key.id, value: key.name });
    }
  });
  return getUniqueFromArray(transactionStatussList, 'id');
};

export const clientAdditonalProperties = selectedClentIds => {
  let additionalProperties = [];
  if (selectedClentIds && selectedClentIds.length === 1) {
    const clientId = selectedClentIds[0];
    additionalProperties = getClientConfigurations()[clientId].additionalProperties;
  } else if (selectedClentIds && selectedClentIds.length > 1) {
    selectedClentIds.forEach(client => {
      additionalProperties = { ...additionalProperties, ...getClientConfigurations()[client].additionalProperties };
    });
  }
  return additionalProperties;
};

export const checkClientAdditonalProperties = selectedClentIds => {
  let additionalProperties = [];
  if (selectedClentIds && selectedClentIds.length === 1) {
    const clientId = selectedClentIds[0];
    additionalProperties = getClientConfigurations()[clientId].additionalProperties;
  } else if (selectedClentIds && selectedClentIds.length > 1) {
    const clientId = globals.clientId;
    additionalProperties = getClientConfigurations()[clientId].additionalProperties;
  }
  return additionalProperties;
};


export const ProfileScreen = () => {
  const clientConfiguration = JSON.parse(localStorage.getItem('clientConfiguration'));
  const clientID = globals.clientId;
  return clientConfiguration && clientConfiguration[clientID] && clientConfiguration[clientID].additionalProperties && clientConfiguration[clientID].additionalProperties.ProfileScreen === 'true';
};

// Payment Types List
export const paymentTypesData = currentSelectedClientId => currentSelectedClientId && getClientConfigurations()[currentSelectedClientId].paymentTypes;

export const paymentTypesList = currentSelectedClientIds => {
  const paymentTypesList = [];
  let paymentTypes = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const paymentTypesPerClient = paymentTypesData(key) || [];
      paymentTypes = [...new Set([...paymentTypes, ...paymentTypesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const paymentTypesPerClient = paymentTypesData(key) || [];
      paymentTypes = [...new Set([...paymentTypes, ...paymentTypesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }

  if (paymentTypes) {
    paymentTypes.forEach(key => {
      if (key.name.split(',').length > 1) {
        key.name.split(',').forEach(splitedKey => {
          paymentTypesList.push({ id: splitedKey, typeId: key.id, value: splitedKey });
        });
      } else { paymentTypesList.push({ id: key.name, typeId: key.id, value: key.name }); }
    });
  }
  return paymentTypesList;
};


// Fraud Check States Types List
// export const fraudStatesData = currentSelectedClientIds =>  getClientConfigurations()[currentSelectedClientIds].fraudCheckStates;

// export const fraudStatesList = currentSelectedClientIds => {
//   const fraudStatesList = [];
//   let fraudStates = [];
//   if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
//     currentSelectedClientIds.forEach(key => {
//       const fraudStatesPerClient = fraudStatesData(key) || [];
//       fraudStates = [...new Set([...fraudStates, ...fraudStatesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
//     });
//   } else {
//     const currentAllSelectedClientIds = [];
//     JSON.parse(globals.clientIdNameList).forEach(key => {
//       currentAllSelectedClientIds.push(key.accountId);
//     });

//     currentAllSelectedClientIds.forEach(key => {
//       const fraudStatesPerClient = fraudStatesData(key) || [];
//       fraudStates = [...new Set([...fraudStates, ...fraudStatesPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
//     });
//   }

//   fraudStates.forEach(key => {
//     fraudStatesList.push({ id: key.id, value: key.name });
//   });
//   fraudStatesList.push({ id: -2, value: 'N.A.' });
//   return getUniqueFromArray(fraudStatesList, 'id');
// };


export const fxServicesData = () => getSystemMetaDataConfiguration().fxServices;
export const splitPaymentData = () => getSystemMetaDataConfiguration().splitPayment;
export const rejectionTypeData = () => getSystemMetaDataConfiguration().rejectionType;


export const fxServicesList = currentSelectedClientIds => {
  const fxServicesList = [];
  let fxService = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const fxServicePerClient = fxServicesData(key) || [];
      fxService = [...new Set([...fxService, ...fxServicePerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const fxServicePerClient = fxServicesData(key) || [];
      fxService = [...new Set([...fxService, ...fxServicePerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
    });
  }
  fxService.forEach(key => {
    fxServicesList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(fxServicesList, 'id');
};

export const splitPaymentList = currentSelectedClientIds => {
  const splitPaymentList = [];
  let splitPayment = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const splitPaymentPerClient = splitPaymentData(key) || [];
      splitPayment = [...new Set([...splitPayment, ...splitPaymentPerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const splitPaymentPerClient = splitPaymentData(key) || [];
      splitPayment = [...new Set([...splitPayment, ...splitPaymentPerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  }

  splitPayment.forEach(key => {
    splitPaymentList.push({ id: key.name, value: key.name });
  });
  return getUniqueFromArray(splitPaymentList, 'id');
};

export const walletDeatilList = currentSelectedClientIds => {
  const walletDeatilList = [];
  let walletType = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const rejectionTypePerClient = walletData(key) || [];
      walletType = [...new Set([...walletType, ...rejectionTypePerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const walletTypePerClient = walletData(key) || [];
      walletType = [...new Set([...walletType, ...walletTypePerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  }

  walletType.forEach(key => {
    walletDeatilList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(walletDeatilList, 'id');
};

export const rejectionTypeList = currentSelectedClientIds => {
  const rejectionTypeList = [];
  let rejectionType = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const rejectionTypePerClient = rejectionTypeData(key) || [];
      rejectionType = [...new Set([...rejectionType, ...rejectionTypePerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const rejectionTypePerClient = rejectionTypeData(key) || [];
      rejectionType = [...new Set([...rejectionType, ...rejectionTypePerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  }

  rejectionType.forEach(key => {
    rejectionTypeList.push({ id: key.id, value: key.name });
  });
  return getUniqueFromArray(rejectionTypeList, 'id');
};

export const systemMetaDatafilter = (filterNmae, currentSelectedClientIds,) => {
  const ListData = () => getSystemMetaDataConfiguration()[filterNmae];

  const filterDataList = [];
  let storeFilterData = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const filterDataListPerClient = ListData(key) || [];
      if (filterNmae === 'threeDSecureECIList') {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i);
      } else {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      }
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const filterDataListPerClient = ListData(key) || [];
      if (filterNmae === 'threeDSecureECIList') {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i);
      } else {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      }
    });
  }
  if (filterNmae === 'threeDSecureECIList') {
    storeFilterData.forEach(key => {
      filterDataList.push({ id: key.value, value: key.value });
    });
  } else {
    storeFilterData.forEach(key => {
      filterDataList.push({ id: key.id, value: key.name });
    });
  }

  return getUniqueFromArray(filterDataList, 'id');
};

export const filterDataList = (currentSelectedClientIds, filterNmae) => {
  const ListData = currentSelectedClientIds => getClientConfigurations()[currentSelectedClientIds][filterNmae];

  const filterDataList = [];
  let storeFilterData = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const filterDataListPerClient = ListData(key) || [];
      if (filterNmae === 'threeDSecureECIList') {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i);
      } else {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      }
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const filterDataListPerClient = ListData(key) || [];
      if (filterNmae === 'threeDSecureECIList') {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i);
      } else {
        storeFilterData = [...new Set([...storeFilterData, ...filterDataListPerClient])].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
      }
    });
  }
  if (filterNmae === 'threeDSecureECIList') {
    storeFilterData.forEach(key => {
      filterDataList.push({ id: key.value, value: key.value });
    });
  } else {
    storeFilterData.forEach(key => {
      filterDataList.push({ id: key.id, value: key.name });
    });
  }

  return getUniqueFromArray(filterDataList, 'id');
};

// 3ds Status List
export const threeDSStatusData = currentSelectedClientIds => getClientConfigurations()[currentSelectedClientIds].threeDSStatus;

export const threeDSStatusList = currentSelectedClientIds => {
  const threeDSStatusList = [];
  let threeDSStatus = [];
  if (currentSelectedClientIds && currentSelectedClientIds.length !== 0) {
    currentSelectedClientIds.forEach(key => {
      const threeDSStatusPerClient = threeDSStatusData(key) || [];
      threeDSStatus = [...new Set([...threeDSStatus, ...threeDSStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  } else {
    const currentAllSelectedClientIds = [];
    JSON.parse(globals.clientIdNameList).forEach(key => {
      currentAllSelectedClientIds.push(key.accountId);
    });

    currentAllSelectedClientIds.forEach(key => {
      const threeDSStatusPerClient = threeDSStatusData(key) || [];
      threeDSStatus = [...new Set([...threeDSStatus, ...threeDSStatusPerClient])].filter((v, i, a) => a.findIndex(v2 => ['name'].every(k => v2[k] === v[k])) === i);
    });
  }

  threeDSStatus.forEach(key => {
    threeDSStatusList.push({ id: key.id, value: key.name });
  });

  return getUniqueFromArray(threeDSStatusList, 'id');
};

export const PAN_AVAILABLE = selectedClientIds => checkClientAdditonalProperties(selectedClientIds) && checkClientAdditonalProperties(selectedClientIds).MTPANDetails === 'true';
export const searchList = selectedClientIds => {
  const list = [
    {
      value: 'SELECT',
      name: 'Select',
      active: true,
      disabled: false,
    },
    {
      value: 'ORDER_NO',
      name: 'Order No',
      active: true,
      disabled: false,
    },
    {
      value: 'PAN',
      name: 'PAN',
      active: PAN_AVAILABLE(selectedClientIds),
      disabled: false,
    },
    {
      value: 'TRANSACTION_ID',
      name: 'Transaction ID',
      disabled: false,
    },
    {
      value: 'SESSION_ID',
      name: 'Session ID',
      active: true,
      disabled: false,
    },
    {
      value: 'PAYMENT_AMOUNT',
      name: 'Payment Amount',
      active: true,
      disabled: false,
    },
    {
      value: 'FAILED_DESCRIPTION',
      name: 'Rejected description',
      active: true,
      disabled: false,
    },
    {
      value: 'AUTH_CODE',
      name: 'Auth code',
      active: true,
      disabled: false,
    },
    {
      value: 'CARDHOLDER_NAME',
      name: 'Cardholder name',
      active: true,
      disabled: false,
    },
    {
      value: 'CUSTOMER_IP_ADDRESS',
      name: 'Customer IP Address',
      active: true,
      disabled: false,
    },
    {
      value: 'REFUND_AMOUNT',
      name: 'Refund Amount',
      active: true,
      disabled: false,
    },
    {
      value: 'REFUND_TRANSACTION_DATE',
      name: 'Refund Transaction Date',
      active: true,
      disabled: false,
    },
  ];

  return list;
};

const getConfigProperty = (property, defaultValue, selectedClientId) => parseInt(((checkClientAdditonalProperties(selectedClientId) && checkClientAdditonalProperties(selectedClientId)[property]) || defaultValue), 10);

export const defaultWildCardMinLength = 4;
export const defaultWildCardMaxLength = 4;


export const configurations = selectedClientId => ({
  orderNoMinLength: getConfigProperty('orderNoMinLength', defaultWildCardMinLength, selectedClientId),
  orderNoMaxLength: getConfigProperty('orderNoMaxLength', defaultWildCardMaxLength, selectedClientId),
  ticketNoMinLength: getConfigProperty('ticketNoMinLength', defaultWildCardMinLength, selectedClientId),
});


export function convertToArray(data) {
  let value = data;
  if (!Array.isArray(value)) {
    value = [{
      ...value,
    }];
  }
  return value;
}

export const listFromMetadata = (getBinRangeMetaDataResult, filterNmae) => {
  const list = [];
  const data = getBinRangeMetaDataResult && getBinRangeMetaDataResult.result;
  let dropDownData = '';
  if (filterNmae === 'issuerCountry') {
    dropDownData = data && data.bin_range_meta_data && data.bin_range_meta_data.countries.country;
  } else if (filterNmae === 'issuerbanks') {
    dropDownData = data && data.bin_range_meta_data && data.bin_range_meta_data.issuer_banks && data.bin_range_meta_data.issuer_banks.issuer_bank;
  } else if (filterNmae === 'cardType') {
    dropDownData = data && data.bin_range_meta_data && data.bin_range_meta_data.card_categories && data.bin_range_meta_data.card_categories.card_category;
  }
  if (dropDownData) {
    // const paymentCountryArray = convertToArray(dropDownData);
    dropDownData.forEach(key => {
      if (key) {
        list.push({
          value: key.toString(),
          label: key.toString(),
        });
      }
    });   
  }
  // return list;
  return list;

};


export function addDecimal(currentSelectedClientId, paymentAmount, country) {
  const currenciesList = getClientConfigurations()[currentSelectedClientId].currencies;
  const getCurrencies = currenciesList.find(obj => obj.mpointCurrencyId === Number(country));
  const paymentAmountArray = paymentAmount.split('.');
  const dece = paymentAmountArray[1];
  let amount = paymentAmount;
  if (!dece) {
    amount = parseFloat(paymentAmount).toFixed(getCurrencies.decimal);
  }
  return amount;
}


export function getdDecimalErrors(currentSelectedClientId, paymentAmount, country, type, errors) {
  const errorFields = errors;
  const currenciesList = getClientConfigurations()[currentSelectedClientId].currencies;
  const getCurrencies = currenciesList.find(obj => obj.mpointCurrencyId === Number(country));
  const paymentAmountArray = paymentAmount.split('.');
  const dece = paymentAmountArray[1];
  if (dece && dece.length) {
    errorFields[type] = dece.length === getCurrencies.decimal;
  } else {
    errorFields[type] = false;
  }
  return errorFields;
}
