/* eslint-disable */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Accordion, Button, Col } from 'react-bootstrap';
import MultiSelect from '@khanacademy/react-multi-select';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useState, useEffect } from 'react';
import globals from '../../../../../redux/constants/globals';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import {
  postAddonConfigurationRequest, getAllConfigurationForFxRequest, postProviderConfigurationRequest, getProviderConfigurationRequest, deleteAddonConfigurationRequest, deleteProviderConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import {
  alertMessageSetTimeOut, tableHeadingColor, _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable,
} from '../Helper';
import { fraudConfigurationList } from './FraudManagementUtility';
import { fraudConfigList } from './FraudMgmtUtility';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import AlertPopup from '../../../../shared/Popups/AlertPopup';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyFraudManagement, validationRequiredInputMessage } from '../Constants';
import { on, off } from '../Constants';
import { getPreAuthRulesRequest, getPostAuthRulesRequest } from '../../../../../redux/actions/DynamicRouting';

function VasSetupFraudManagement(props) {
  const reset = {
    serviceTypes: {
      nameProp: 'Select',
      idProp: '',
    },
    country: {
      nameProp: '',
      idProp: '',
    },
    currency: {
      nameProp: '',
      idProp: '',
    },
    provider: {
      nameProp: '',
      idProp: '',
    },
    serviceProvider: {
      nameProp: '',
      idProp: '',
    },
    version: {
      nameProp: '',
      idProp: '',
    },
    storeFronts: [],
    paymentMethod: [],
    mid: '',
    username: '',
    password: '',
    toggleStatus: off,
  };
  const [inputField, setInpuField] = useState(reset);
  const [providerid, setProviderid] = useState(false);
  const [preAuthData, setPreAuthData] = useState([]);
  const [postAuthData, setPostAuthData] = useState([]);
  const [threeDSData, setThreeDSData] = useState([]);

  const [preAData, setPreAData] = useState([]);
  const [postAData, setPostAData] = useState([]);
  const [tdsData, setTdsData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [errorMsgDelete, setErrorMsgDelete] = useState(false);
  const [disFlag, setDisFlag] = useState(false);
  const [displayFlag, setDisplayFlag] = useState(false);
  const [countryDetail, setCountryDetail] = useState([]);
  const [versionDetail, setVersionDetail] = useState([]);
  const [currencyDetail, setCurrencyDetail] = useState([]);
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [preAFlag, setPreAFlag] = useState('');
  const [postAFlag, setPostAFlag] = useState(false);
  const [tdsFlag, setTdsFlag] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [prevSystemMetaDataResult, setPrevSystemMetaDataResult] = useState(props.getSystemMetaDataResult.result);
  const [prevPaymentMetaDataResult, setPrevPaymentMetaDataResult] = useState(props.getPaymentMetaDataResult.result);
  const [alertMessageForServiceTypes, setAlertMessageForServiceTypes] = useState(false);
  const [preValue, setPreValue] = useState('');
  const [preId, setPreId] = useState('');
  const [rulesPriList, makePriRulesList] = useState('');
  const [rulesPostList, makePostRulesList] = useState('');


  useEffect(() => {
    const { getPreAuthRulesResult } = props;
    if (getPreAuthRulesResult.result) {
      makePriRulesList(getPreAuthRulesResult);
    }
  }, [props.getPreAuthRulesResult]);

  useEffect(() => {
    const { getPostAuthRulesResult } = props;
    if (getPostAuthRulesResult.result) {
      makePostRulesList(getPostAuthRulesResult);
    }
  }, [props.getPostAuthRulesResult]);

  useEffect(() => {
    if (prevSystemMetaDataResult !== props.getSystemMetaDataResult.result) {
      setPrevSystemMetaDataResult(props.getSystemMetaDataResult);
    }
    if (prevSystemMetaDataResult && prevSystemMetaDataResult.result && prevSystemMetaDataResult.result.system_metadata) {
      setData(prevSystemMetaDataResult.result.system_metadata);
    }
  }, [props, prevSystemMetaDataResult]);

  function setData(systemMetadata) {
    setCountryDetail(systemMetadata && systemMetadata.country_details && systemMetadata.country_details.country_detail);
    setCurrencyDetail(systemMetadata && systemMetadata.currency_details && systemMetadata.currency_details.currency_detail);
  }
  useEffect(() => {
    if (prevPaymentMetaDataResult !== props.getPaymentMetaDataResult.result) {
      setPrevPaymentMetaDataResult(props.getPaymentMetaDataResult);
    }
    if (prevPaymentMetaDataResult && prevPaymentMetaDataResult.result && prevPaymentMetaDataResult.result.payment_metadata) {
      setPaymentMetaData(prevPaymentMetaDataResult.result.payment_metadata);
    }
  }, [props, prevPaymentMetaDataResult]);


  function setPaymentMetaData(paymentMetadata) {
    setVersionDetail(paymentMetadata && paymentMetadata.versions && paymentMetadata.versions.version);
  }
  let payConfigList = [];
  const pmConfigurationsAPICall = paymentList => {
    let pmConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.pm_configurations && props.getClientConfigurationResult.result.client_configuration.pm_configurations.pm_configuration;
    if (pmConfigurations) {
      if (!Array.isArray(pmConfigurations)) {
        pmConfigurations = [{
          ...pmConfigurations,
        }];
      }
      payConfigList = pmConfigurations.filter(obj => obj.enabled === true).map(obj => obj.pm_id);
    }
    paymentList.forEach(key => {
      const multiSelectOption = {
        value: key.id,
        label: key.name,
      };
      paymentMethodListArray.push(multiSelectOption);
      if (payConfigList.includes(key.id)) paymentMethodSelectedListArray.push(multiSelectOption);
    });
  };
  const paymentMethodListArray = [];
  const paymentMethodSelectedListArray = [];
  let paymentMethod = props.getPaymentMetaDataResult.result && props.getPaymentMetaDataResult.result.payment_metadata && props.getPaymentMetaDataResult.result.payment_metadata.pms && props.getPaymentMetaDataResult.result.payment_metadata.pms.pm;
  if (paymentMethod) {
    if (!Array.isArray(paymentMethod)) {
      paymentMethod = [{
        ...paymentMethod,
      }];
    }
    pmConfigurationsAPICall(paymentMethod);
  }

  let storeFrontsConfigList = [];
  const storeFrontsConfigurationsAPICall = storeFrontsList => {
    let storeFrontsConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.account_configurations && props.getClientConfigurationResult.result.client_configuration.account_configurations.account_config;
    if (storeFrontsConfigurations) {
      if (!Array.isArray(storeFrontsConfigurations)) {
        storeFrontsConfigurations = [{
          ...storeFrontsConfigurations,
        }];
      }
      storeFrontsConfigList = storeFrontsConfigurations.map(obj => obj.id);
    }
    storeFrontsList.forEach(key => {
      const multiSelectOption = {
        value: key.id,
        label: key.name,
      };
      storeFrontsListArray.push(multiSelectOption);
      if (storeFrontsConfigList.includes(key.id)) storeFrontsSelectedListArray.push(multiSelectOption);
    });
  };
  const storeFrontsListArray = [];
  const storeFrontsSelectedListArray = [];
  let storeFronts = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.account_configurations && props.getClientConfigurationResult.result.client_configuration.account_configurations.account_config;
  if (storeFronts) {
    if (!Array.isArray(storeFronts)) {
      storeFronts = [{
        ...storeFronts,
      }];
    }
    storeFrontsConfigurationsAPICall(storeFronts);
  }

  const serviceTypeDropdown = [];
  let addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;
  if (addonTypes) {
    if (!Array.isArray(addonTypes)) {
      addonTypes = [{
        ...addonTypes,
      }];
    }
    addonTypes.forEach(key => {
      if (key.name === 'Fraud Management') {
        key.addon_subtypes.addon_subtype.forEach(subType => {
          serviceTypeDropdown.push({
            id: subType.id,
            type: subType.name,
          });
        });
      }
    });
  }
  const inputOnChange = (event, field) => {
    const id = event.target.value;

    if (field === 'serviceTypes') {
      if (id === '5' || id === '4') {
        props.getPreAuthRulesRequest({ id: 200 });
        props.getPostAuthRulesRequest({ id: 201 });
      }

      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setPreValue(value);
      setPreId(id);
      if (id === '4') {
        const findUpdatedDataPreAuthorization = preAData && preAData.some(element => (element.deleteKey === true || element.existFlag === false));
        const findUpdatedDataPreAuth = preAuthData && preAuthData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedDataPreAuthorization || findUpdatedDataPreAuth) {
          setAlertMessageForServiceTypes(true);
        } else {
          const formValues = { ...inputField };
          formValues.serviceTypes.idProp = id;
          formValues.serviceTypes.nameProp = value;
          formValues.provider.idProp = '';
          formValues.provider.nameProp = '';
          setInpuField(formValues);
        }
      } else if (id === '5') {
        const findUpdatedDataPostAuthorization = postAData && postAData.some(element => (element.deleteKey === true || element.existFlag === false));
        const findUpdatedDataPostAuth = postAuthData && postAuthData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedDataPostAuthorization || findUpdatedDataPostAuth) {
          setAlertMessageForServiceTypes(true);
        } else {
          const formValues = { ...inputField };
          formValues.serviceTypes.idProp = id;
          formValues.serviceTypes.nameProp = value;
          formValues.provider.idProp = '';
          formValues.provider.nameProp = '';
          setInpuField(formValues);
        }
      } else if (id === '6') {
        const findUpdatedDataTds = tdsData && tdsData.some(element => (element.deleteKey === true || element.existFlag === false));
        const findUpdatedDataThreeDs = threeDSData && threeDSData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedDataTds || findUpdatedDataThreeDs) {
          setAlertMessageForServiceTypes(true);
        } else {
          const formValues = { ...inputField };
          formValues.serviceTypes.idProp = id;
          formValues.serviceTypes.nameProp = value;
          formValues.provider.idProp = '';
          formValues.provider.nameProp = '';
          setInpuField(formValues);
        }
      }
      if (inputField.serviceTypes.nameProp === 'Select') {
        const formValues = { ...inputField };
        formValues.serviceTypes.idProp = id;
        formValues.serviceTypes.nameProp = value;
        formValues.provider.idProp = '';
        formValues.provider.nameProp = '';
        setInpuField(formValues);
      }
    } else if (field === 'country' || field === 'currency' || field === 'version') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else if (field === 'toggleStatus') {
      if (inputField.toggleStatus === on) {
        setInpuField({ ...inputField, [field]: off });
      } else if (inputField.toggleStatus === off) {
        setInpuField({ ...inputField, [field]: on });
      }
    } else {
      setInpuField({ ...inputField, [field]: id });
    }
  };
  const selectOnChangeProviderSetup = event => {
    const index = event.nativeEvent.target.selectedIndex;
    const value = event.nativeEvent.target[index].text;
    const formValues = { ...inputField };
    formValues.provider.idProp = event.target.value;
    formValues.provider.nameProp = value;
    formValues.mid = '';
    formValues.username = '';
    formValues.password = '';
    setProviderid(event.target.value);
    setInpuField(formValues);
  };
  const selectOnChangeServiceProviderSetup = event => {
    const index = event.nativeEvent.target.selectedIndex;
    const value = event.nativeEvent.target[index].text;
    const formValues = { ...inputField };
    formValues.serviceProvider.idProp = event.target.value;
    formValues.serviceProvider.nameProp = value;
    formValues.version.idProp = '';
    formValues.country.nameProp = '';
    formValues.country.idProp = '';
    formValues.currency.nameProp = '';
    formValues.currency.idProp = '';
    formValues.version.nameProp = '';
    formValues.paymentMethod = '';
    formValues.storeFronts = '';
    setInpuField(formValues);
  };
  const inputOnChangePm = (event, field) => {
    setInpuField({ ...inputField, [field]: event });
  };
  const providerDropdown = [];
  const providerDropdownForPrePost = [];
  const providerDropdownForTds = [];
  let psps = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.psps && props.getSystemMetaDataResult.result.system_metadata.psps.psp;
  if (psps) {
    if (!Array.isArray(psps)) {
      psps = [{
        ...psps,
      }];
    }
    // For Pre Auth & Post Auth type_id  is 9 AND for 3DS type_id is 6
    psps.forEach(key => {
      if (inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth') { // pre and post
        if (key.type_id === 9) {
          providerDropdown.push({
            id: key.id,
            name: key.name,
            type_id: key.type_id,
          });
          providerDropdownForPrePost.push({
            id: key.id,
            name: key.name,
            type_id: key.type_id,
          });
        }
      }
      // if (inputField.serviceTypes.nameProp === 'Post Auth') { // pre and post
      //   if (key.type_id === 10) {
      //     providerDropdown.push({
      //       id: key.id,
      //       name: key.name,
      //       type_id: key.type_id,
      //     });
      //     providerDropdownForPrePost.push({
      //       id: key.id,
      //       name: key.name,
      //       type_id: key.type_id,
      //     });
      //   }
      // } 
      else if (inputField.serviceTypes.nameProp === '3DS') { // 3ds
        if (key.type_id === 6) {
          providerDropdown.push({
            id: key.id,
            name: key.name,
            type_id: key.type_id,
          });
          providerDropdownForTds.push({
            id: key.id,
            name: key.name,
            type_id: key.type_id,
          });
        }
      }
    });
  }
  const providerConfig = [];
  let clientProviderConfiguration = null;
  if(props.getProviderConfigurationResult.result && props.getProviderConfigurationResult.result.client_provider_configurations && props.getProviderConfigurationResult.result.client_provider_configurations.client_provider_configuration) {
    clientProviderConfiguration = props.getProviderConfigurationResult.result && props.getProviderConfigurationResult.result.client_provider_configurations && props.getProviderConfigurationResult.result.client_provider_configurations.client_provider_configuration;
  } else if(props.getProviderConfigurationResult.result && props.getProviderConfigurationResult.result.client_provider_configuration) {
    clientProviderConfiguration = props.getProviderConfigurationResult.result && props.getProviderConfigurationResult.result.client_provider_configuration;
  }

  if (clientProviderConfiguration) {
    if (!Array.isArray(clientProviderConfiguration)) {
      clientProviderConfiguration = [{
        ...clientProviderConfiguration,
      }];
    }
    clientProviderConfiguration.forEach(fraudConfig => {
      if (inputField.serviceTypes.nameProp === '3DS') {
        providerDropdownForTds.forEach(key => {
          if (key.id === fraudConfig.id) { // provider_id with name
            providerConfig.push({
              provider_id: key.id,
              provider_name: key.name,
              mid: fraudConfig.name,
              username: fraudConfig.username,
              password: fraudConfig.password,
            });
          }
        });
      } else {
        providerDropdownForPrePost.forEach(key => {
          if (key.id === fraudConfig.id) { // provider_id with name
            providerConfig.push({
              provider_id: key.id,
              provider_name: key.name,
              mid: fraudConfig.name,
              username: fraudConfig.username,
              password: fraudConfig.password,
            });
          }
        });
      }
    });

    if (inputField.serviceTypes.nameProp === 'Pre Auth' && !preAFlag) {
      setPreAData(fraudConfigList('preAuth', providerConfig));
      setPreAFlag(true);
    } else if (inputField.serviceTypes.nameProp === 'Post Auth' && !postAFlag) {
      setPostAData(fraudConfigList('postAuth', providerConfig));
      setPostAFlag(true);
    } else if (inputField.serviceTypes.nameProp === '3DS' && tdsData.length === 0 && !tdsFlag) {
      setTdsData(fraudConfigList('3DS', providerConfig));
      setTdsFlag(true);
    }
  }
  const updatedPrePostTdsData = () => {
    const providerConfigs = [];
    let clientProviderConfiguration = props.getProviderConfigurationResult.result && props.getProviderConfigurationResult.result.client_provider_configurations && props.getProviderConfigurationResult.result.client_provider_configurations.client_provider_configuration;
    if (clientProviderConfiguration) {
      if (!Array.isArray(clientProviderConfiguration)) {
        clientProviderConfiguration = [{
          ...clientProviderConfiguration,
        }];
      }
      clientProviderConfiguration.forEach(fraudConfig => {
        if (inputField.serviceTypes.nameProp === '3DS') {
          providerDropdownForTds.forEach(key => {
            if (key.id === fraudConfig.id) { // provider_id with name
              providerConfigs.push({
                provider_id: key.id,
                provider_name: key.name,
                mid: fraudConfig.name,
                username: fraudConfig.username,
                password: fraudConfig.password,
              });
            }
          });
        } else {
          providerDropdownForPrePost.forEach(key => {
            if (key.id === fraudConfig.id) { // provider_id with name
              providerConfigs.push({
                provider_id: key.id,
                provider_name: key.name,
                mid: fraudConfig.name,
                username: fraudConfig.username,
                password: fraudConfig.password,
              });
            }
          });
        }
      });
      if (inputField.serviceTypes.nameProp === 'Pre Auth') {
        setPreAData(fraudConfigList('preAuth', providerConfigs));
      } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
        setPostAData(fraudConfigList('postAuth', providerConfigs));
      } else if (inputField.serviceTypes.nameProp === '3DS') {
        setTdsData(fraudConfigList('3DS', providerConfigs));
      }
    }
  };
  const updatedPrePostData = () => {
    const defaultAddonConfiguration = [
      {
        id: null,
        enabled: null,
        country_id: null,
        currency_id: null,
        pm_id: null,
        country_name: null,
        currency_name: null,
        currency_code: null,
        settlement_currency_id: null,
        settlement_currency_name: null,
        settlement_currency_code: null,
        pm_name: null,
        provider_id: Number(providerid),
        provider_name: null,
      }
    ]

    const fraudConfigForPreAuth = props.getAllConfigurationForFxResult.result && props.getAllConfigurationForFxResult.result.fraud_config;
    if (fraudConfigForPreAuth) {
      const findPreAuth = fraudConfigForPreAuth.find(element => (element.sub_type === 'pre_auth'));
      if (findPreAuth.sub_type === 'pre_auth') {

        let addonConfiguration = findPreAuth.addon_configuration.length ? findPreAuth.addon_configuration : defaultAddonConfiguration

        addonConfiguration = addonConfiguration.map(obj => {
          if (obj.country_id === 0 || obj.country_id === null) {
            return { ...obj, country_id: 0, country_name: '-- All --' };
          }
          return obj;
        });
        addonConfiguration = addonConfiguration.map(obj => {
          if (obj.currency_id === 0 || obj.currency_id === null) {
            return { ...obj, currency_id: 0, currency_code: '-- All --' };
          }
          return obj;
        });
        setPreAuthData(fraudConfigurationList('preAuth', addonConfiguration));
      }
      const findPostAuth = fraudConfigForPreAuth.find(element => (element.sub_type === 'post_auth'));
      if (findPostAuth.sub_type === 'post_auth') {


        let addonConfiguration = findPostAuth.addon_configuration.length ? findPostAuth.addon_configuration : defaultAddonConfiguration

        addonConfiguration = addonConfiguration.map(obj => {
          if (obj.country_id === 0 || obj.country_id === null) {
            return { ...obj, country_id: 0, country_name: '-- All --' };
          }
          return obj;
        });
        addonConfiguration = addonConfiguration.map(obj => {
          if (obj.currency_id === 0 || obj.currency_id === null) {
            return { ...obj, currency_id: 0, currency_code: '-- All --' };
          }
          return obj;
        });
        setPostAuthData(fraudConfigurationList('postAuth', addonConfiguration));
      }
    }

    let fraudConfigForThreeDs = props.getAllConfigurationForFxResult.result && props.getAllConfigurationForFxResult.result.mpi_config;
    if (fraudConfigForThreeDs) {
      if (!Array.isArray(fraudConfigForThreeDs)) {
        fraudConfigForThreeDs = [{
          ...fraudConfigForThreeDs,
        }];
      }
      fraudConfigForThreeDs = fraudConfigForThreeDs.map(obj => {
        if (obj.country_id === 0 || obj.country_id === null) {
          return { ...obj, country_id: 0, country_name: '-- All --' };
        }
        return obj;
      });
      fraudConfigForThreeDs = fraudConfigForThreeDs.map(obj => {
        if (obj.currency_id === 0 || obj.currency_id === null) {
          return { ...obj, currency_id: 0, currency_code: '-- All --' };
        }
        return obj;
      });
      fraudConfigForThreeDs = fraudConfigForThreeDs.map(obj => {
        if (obj.account_id === 0 || obj.account_id === null) {
          return { ...obj, account_id: 0, account_name: 'All Storefronts' };
        }
        return obj;
      });

      setThreeDSData(fraudConfigurationList('3DS', fraudConfigForThreeDs));
    }
  };
  // Table data
  const fraudDataFunction = () => {
    let fraud = [];
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      fraud = preAData;
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      fraud = postAData;
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      fraud = tdsData;
    }
    return fraud;
  };

  // Table data
  const fraudManagementData = () => {
    let fraud = [];
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      fraud = preAuthData;
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      fraud = postAuthData;
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      fraud = threeDSData;
    }
    return fraud;
  };

  const _onClickDelete = row => {
    deleteRow(row);
  };

  const checkFraudRoutingId = providerId => {
    let data = { pri: false, post: false }
    if (rulesPriList && (!data.post && !data.pri)) {
      if (rulesPriList.result && rulesPriList.result.code && (Number(rulesPriList.result.code) === 109)) {
        data.pri = false;
      } else {
        if (Array.isArray(rulesPriList.result.rule)) {
          rulesPriList.result.rule.forEach(getActions => {
            if (getActions && getActions.actions && (!data.post && !data.pri)) {
              if (Array.isArray(getActions.actions)) {
                data.pri = additionalData.additional_data.find(element => (Number(element.value) === providerId));
              } else {
                data.pri = Number(getActions.actions.value) === providerId;
              }
            }
          })
        } else {
          const getActions = rulesPriList.result.rule;
          if (getActions && getActions.actions && (!data.post && !data.pri)) {
            if (Array.isArray(getActions.actions)) {
              data.pri = additionalData.additional_data.find(element => (Number(element.value) === providerId));
            } else {
              data.pri = Number(getActions.actions.value) === providerId;
            }
          }
        }
      }
    }
    if (rulesPostList && (!data.post && !data.pri)) {
      if (rulesPostList.result && rulesPostList.result.code && (Number(rulesPostList.result.code) === 109)) {
        data.post = false;
      } else {

        if (Array.isArray(rulesPostList.result.rule)) {
          rulesPostList.result.rule.forEach(getActions => {
            if (getActions && getActions.actions && (!data.post && !data.pri)) {
              if (Array.isArray(getActions.actions)) {
                data.post = additionalData.additional_data.find(element => (Number(element.value) === providerId));
              } else {
                data.post = Number(getActions.actions.value) === providerId;
              }
            }
          })
        } else {
          const getActions = rulesPostList.result.rule;
          if (getActions && getActions.actions && (!data.post && !data.pri)) {
            if (Array.isArray(getActions.actions)) {
              data.post = additionalData.additional_data.find(element => (Number(element.value) === providerId));
            } else {
              data.post = Number(getActions.actions.value) === providerId;
            }
          }
        }


      }
    }


    return data.post || data.pri;
  };

  const deleteRow = row => {
    let filter = '';
    if (row.preA) {
      if (checkFraudRoutingId(row.providerId)) {
        notify('warn', toastMessages.FraudManagementRemove.warn.deleted);
      } else {
        filter = getUnique(preAData, 'key');
        if (filter.length !== preAData.length) {
          setPreAData(filter);
        } else {
          filter = preAData.filter((
            item => {
              if (item.key === row.key) {
                item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPreAData(filter);
        }
      }

    } else if (row.postA) {
      if (checkFraudRoutingId(row.providerId)) {
        notify('warn', toastMessages.FraudManagementRemove.warn.deleted);
      } else {
        filter = getUnique(postAData, 'key');
        if (filter.length !== postAData.length) {
          setPostAData(filter);
        } else {
          filter = postAData.filter((
            item => {
              if (item.key === row.key) {
                item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPostAData(filter);
        }
      }

    } else if (row.tDS) {
      filter = getUnique(tdsData, 'key');
      if (filter.length !== tdsData.length) {
        setTdsData(filter);
      } else {
        filter = tdsData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setTdsData(filter);
      }
    } else if (row.preAuth) {
      filter = getUnique(preAuthData, 'key');
      if (filter.length !== preAuthData.length) {
        setPreAuthData(filter);
      } else {
        filter = preAuthData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setPreAuthData(filter);
      }
    } else if (row.postAuth) {
      filter = getUnique(postAuthData, 'key');
      if (filter.length !== postAuthData.length) {
        setPostAuthData(filter);
      } else {
        filter = postAuthData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setPostAuthData(filter);
      }
    } else if (row.threeDS) {
      filter = getUnique(threeDSData, 'key');
      if (filter.length !== threeDSData.length) {
        setThreeDSData(filter);
      } else {
        filter = threeDSData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setThreeDSData(filter);
      }
    }

    if (filter && filter.filter(item => item.deleteKey === true).length > 0) {
      // Make save button enabled
      setSaveButtonDisabledFlag(0);
      setHasDeleteRecordExist(1);
    } else {
      // Make save button disabled
      setSaveButtonDisabledFlag(1);
      setHasDeleteRecordExist(0);
    }
  };

  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const onClickAddButtonForProviderSetup = () => {
    let fraud = [];
    let flag = false;
    setMsg('');
    setDisplayFlag(false);
    setDisFlag(false);
    if (inputField.mid === undefined || inputField.username === undefined || inputField.password === undefined || inputField.provider === undefined
      || inputField.mid === '' || inputField.username === '' || inputField.password === '' || inputField.provider.idProp === '' || inputField.provider.nameProp === '') {
      setDisFlag(true);
      alertMessageSetTimeOut();
      return false;
    }
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      if (preAData) {
        preAData.forEach(data => {
          if (data.providerName === inputField.provider.nameProp) {
            flag = true;
            setErrorMsg(true);
            const errmsg = 'Provider Already Present';
            setMsg(errmsg);
            alertMessageSetTimeOut();
          }
        });
      }
      if (!flag) {
        fraud = preAData;
        const count = fraud.length + 1;
        fraud.push({
          key: count,
          providerId: inputField.provider.idProp,
          providerName: inputField.provider.nameProp,
          mid: inputField.mid,
          username: inputField.username,
          password: inputField.password,
          deleteKey: false,
          existFlag: false,
          preA: true,
        });
        setPreAData(fraud);
      }
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      if (postAData) {
        postAData.forEach(data => {
          if (data.providerName === inputField.provider.nameProp) {
            flag = true;
            setErrorMsg(true);
            const errmsg = 'Provider Already Present';
            setMsg(errmsg);
            alertMessageSetTimeOut();
          }
        });
      }
      if (!flag) {
        fraud = postAData;
        const count = fraud.length + 1;
        fraud.push({
          key: count,
          providerId: inputField.provider.idProp,
          providerName: inputField.provider.nameProp,
          mid: inputField.mid,
          username: inputField.username,
          password: inputField.password,
          deleteKey: false,
          existFlag: false,
          postA: true,
        });
        setPostAData(fraud);
      }
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      if (tdsData) {
        tdsData.forEach(data => {
          if (data.providerName === inputField.provider.nameProp) {
            flag = true;
            setErrorMsg(true);
            const errmsg = 'Provider Already Present';
            setMsg(errmsg);
            alertMessageSetTimeOut();
          }
        });
      }
      if (!flag) {
        fraud = tdsData;
        const count = fraud.length + 1;
        fraud.push({
          key: count,
          providerId: inputField.provider.idProp,
          providerName: inputField.provider.nameProp,
          mid: inputField.mid,
          username: inputField.username,
          password: inputField.password,
          deleteKey: false,
          existFlag: false,
          tDS: true,
        });
        setTdsData(fraud);
      }
    }
    const formValues = { ...inputField };
    formValues.provider.idProp = '';
    formValues.provider.nameProp = '';
    formValues.username = '';
    formValues.password = '';
    formValues.mid = '';
    setInpuField(formValues);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  const onClickAddButtonForServiceSetup = () => {
    let flag = false;
    setMsg('');
    setDisplayFlag(false);
    setDisFlag(false);
    if (inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth') {
      if (inputField.serviceProvider === undefined || inputField.country === undefined || inputField.currency === undefined || inputField.paymentMethod === undefined
        || inputField.serviceProvider.nameProp === '' || inputField.country.nameProp === '' || inputField.currency.nameProp === '' || inputField.paymentMethod.nameProp === ''
        || inputField.serviceProvider.nameProp === 'Please Select' || inputField.country.nameProp === 'Please Select' || inputField.currency.nameProp === 'Please Select' || inputField.paymentMethod === 'Select Payment Method') {
        setDisplayFlag(true);
        alertMessageSetTimeOut();
        return false;
      }
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      if (inputField.serviceProvider === undefined || inputField.version === undefined || inputField.paymentMethod === undefined || inputField.country === undefined || inputField.currency === undefined || inputField.storeFronts === undefined
        || inputField.serviceProvider.nameProp === '' || inputField.version.nameProp === '' || inputField.paymentMethod.nameProp === '' || inputField.country.nameProp === '' || inputField.currency.nameProp === '' || inputField.storeFronts.nameProp === ''
        || inputField.serviceProvider.nameProp === 'Please Select' || inputField.version.nameProp === 'Please Select' || inputField.paymentMethod === 'Select Payment Method' || inputField.country.nameProp === 'Please Select' || inputField.currency.nameProp === 'Please Select' || inputField.storeFronts === 'Select Storefronts') {
        setDisplayFlag(true);
        alertMessageSetTimeOut();
        return false;
      }
    }

    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      if (inputField.paymentMethod) {
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (preAuthData) {
            preAuthData.forEach(data => {
              if (data.provider_id === parseInt(inputField.serviceProvider.idProp, 10) && data.country_id === parseInt(inputField.country.idProp, 10) && data.currency_id === parseInt(inputField.currency.idProp, 10) && data.pm_id === key) {
                flag = true;
              }
            });
          }
        });
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (preAuthData) {
            preAuthData.forEach(data => {
              if (data.currency_id === parseInt(inputField.currency.idProp, 10) && data.country_id === parseInt(inputField.country.idProp, 10) && data.pm_id === key) {
                flag = true;
                setErrorMsg(true);
                const errmsg = 'Two different Fraud Gateways cannot be configured for Same country, currency and payment method combination';
                setMsg(errmsg);
                alertMessageSetTimeOut();
              }
            });
          }
          if (!flag) {
            const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
            const count = preAuthData.length + 1;
            preAuthData.push({
              key: count,
              paymentMethod: selectedPaymentMethod[0].label,
              pm_id: key,
              providerName: inputField.serviceProvider.nameProp,
              provider_id: parseInt(inputField.serviceProvider.idProp, 10),
              country: inputField.country.nameProp,
              country_id: parseInt(inputField.country.idProp, 10),
              currency: inputField.currency.nameProp,
              currency_id: parseInt(inputField.currency.idProp, 10),
              action: '',
              deleteKey: false,
              existFlag: false,
              preAuth: true,
            });
          }
        });
      }
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      if (inputField.paymentMethod) {
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (postAuthData) {
            postAuthData.forEach(data => {
              if (data.provider_id === parseInt(inputField.serviceProvider.idProp, 10) && data.country_id === parseInt(inputField.country.idProp, 10) && data.currency_id === parseInt(inputField.currency.idProp, 10) && data.pm_id === key) {
                flag = true;
              }
            });
          }
        });
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (postAuthData) {
            postAuthData.forEach(data => {
              if (data.currency_id === parseInt(inputField.currency.idProp, 10) && data.country_id === parseInt(inputField.country.idProp, 10) && data.pm_id === key) {
                flag = true;
                setErrorMsg(true);
                const errmsg = 'Two different Fraud Gateways cannot be configured for Same country, currency and payment method combination';
                setMsg(errmsg);
                alertMessageSetTimeOut();
              }
            });
          }
          if (!flag) {
            const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
            const count = postAuthData.length + 1;
            postAuthData.push({
              key: count,
              paymentMethod: selectedPaymentMethod[0].label,
              pm_id: key,
              providerName: inputField.serviceProvider.nameProp,
              provider_id: parseInt(inputField.serviceProvider.idProp, 10),
              country: inputField.country.nameProp,
              country_id: parseInt(inputField.country.idProp, 10),
              currency: inputField.currency.nameProp,
              currency_id: parseInt(inputField.currency.idProp, 10),
              action: '',
              deleteKey: false,
              existFlag: false,
              postAuth: true,
            });
          }
        });
      }
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      if (inputField.paymentMethod || inputField.storeFronts) {
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (threeDSData) {
            threeDSData.forEach(data => {
              if (data.provider_id === parseInt(inputField.serviceProvider.idProp, 10) && data.pm_id === key && (data.currency_id === parseInt(inputField.currency.idProp, 10) || data.currency_id === 0 || parseInt(inputField.currency.idProp, 10) === 0) && (data.country_id === parseInt(inputField.country.idProp, 10) || data.country_id === 0 || parseInt(inputField.country.idProp, 10) === 0) && (data.account_id === parseInt(inputField.storeFronts) || data.account_id === 0 || parseInt(inputField.storeFronts, 10) === 0)) {
                flag = true;
                setErrorMsg(true);
                const errmsg = 'Duplicate Entry Not Allowed';
                setMsg(errmsg);
                alertMessageSetTimeOut();
              }
            });
          }
        });
        inputField.paymentMethod.forEach(key => {
          flag = false;
          if (threeDSData) {
            threeDSData.forEach(data => {
              if (data.provider_id === parseInt(inputField.serviceProvider.idProp, 10) && data.pm_id === key && (data.currency_id === parseInt(inputField.currency.idProp, 10) || data.currency_id === 0 || parseInt(inputField.currency.idProp, 10) === 0) && (data.country_id === parseInt(inputField.country.idProp, 10) || data.country_id === 0 || parseInt(inputField.country.idProp, 10) === 0) && (data.account_id === parseInt(inputField.storeFronts) || data.account_id === 0 || parseInt(inputField.storeFronts, 10) === 0)) {
                flag = true;
              }
            });
          }
          if (!flag) {
            const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
            const count = threeDSData.length + 1;
            // threeDSData.push({
            //   key: count,
            //   paymentMethod: selectedPaymentMethod[0].label,
            //   pm_id: key,
            //   providerName: inputField.serviceProvider.nameProp,
            //   provider_id: parseInt(inputField.serviceProvider.idProp, 10),
            //   version: inputField.version.nameProp,
            //   version_id: parseInt(inputField.version.idProp, 10),
            //   action: '',
            //   deleteKey: false,
            //   existFlag: false,
            //   threeDS: true,
            // });
            if (inputField.storeFronts.length === storeFrontsSelectedListArray.length) {
              threeDSData.push({
                key: count,
                paymentMethod: selectedPaymentMethod[0].label,
                pm_id: key,
                providerName: inputField.serviceProvider.nameProp,
                provider_id: parseInt(inputField.serviceProvider.idProp, 10),
                version: inputField.version.nameProp,
                version_id: parseInt(inputField.version.idProp, 10),
                country: inputField.country.nameProp,
                country_id: parseInt(inputField.country.idProp, 10),
                currency: inputField.currency.nameProp,
                currency_id: parseInt(inputField.currency.idProp, 10),
                account_id: 0,
                storeFronts: 'All Storefronts',
                action: '',
                deleteKey: false,
                existFlag: false,
                threeDS: true,
              });
            } else {
              inputField.storeFronts.forEach(stf => {
                const selectedStoreFronts = storeFrontsListArray.filter(obj => obj.value === stf);
                threeDSData.push({
                  key: count,
                  paymentMethod: selectedPaymentMethod[0].label,
                  pm_id: key,
                  providerName: inputField.serviceProvider.nameProp,
                  provider_id: parseInt(inputField.serviceProvider.idProp, 10),
                  version: inputField.version.nameProp,
                  version_id: parseInt(inputField.version.idProp, 10),
                  country: inputField.country.nameProp,
                  country_id: parseInt(inputField.country.idProp, 10),
                  currency: inputField.currency.nameProp,
                  currency_id: parseInt(inputField.currency.idProp, 10),
                  account_id: stf,
                  storeFronts: selectedStoreFronts[0].label,
                  action: '',
                  deleteKey: false,
                  existFlag: false,
                  threeDS: true,
                });
              })
            }
          }
        });
      }
    }
    const formValues = { ...inputField };
    formValues.serviceProvider.idProp = '';
    formValues.country.idProp = '';
    formValues.currency.idProp = '';
    formValues.serviceProvider.nameProp = 'Please Select';
    formValues.country.nameProp = '';
    formValues.currency.nameProp = '';
    formValues.paymentMethod = '';
    formValues.storeFronts = '';
    setInpuField(formValues);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  const action = (cell, row) => (
    <div>
      {(row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={() => _onClickDelete(row)} title="Delete" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={() => _onClickDelete(row)} title="Add" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );

  function providerList() {
    let fraud = [];
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      const tempArr = [];
      if (preAData) {
        preAData.forEach(key => {
          if (!key.deleteKey) {
            tempArr.push({
              id: key.providerId,
              name: key.providerName,
            });
          }
        });
      }
      fraud = tempArr;
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      const tempArr = [];
      if (postAData) {
        fraud = postAData.forEach(key => {
          if (!key.deleteKey) {
            tempArr.push({
              id: key.providerId,
              name: key.providerName,
            });
          }
        });
      }
      fraud = tempArr;
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      const tempArr = [];
      if (tdsData) {
        tdsData.forEach(key => {
          if (!key.deleteKey) {
            tempArr.push({
              id: key.providerId,
              name: key.providerName,
            });
          }
        });
      }
      fraud = tempArr;
    }
    return fraud;
  }
  const providerArrayList = [];
  if (inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') {
    providerList().forEach(key => {
      providerArrayList.push({
        id: key.id,
        name: key.name,
      });
    });
  }
  function validateProviderDataDeletion() {
    // First Table
    let provider = [];
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      provider = preAData;
    } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
      provider = postAData;
    } else if (inputField.serviceTypes.nameProp === '3DS') {
      provider = tdsData;
    }

    // Second Table
    let service = [];
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      service = preAuthData;
    }
    if (inputField.serviceTypes.nameProp === 'Post Auth') {
      service = postAuthData;
    }
    if (inputField.serviceTypes.nameProp === '3DS') {
      service = threeDSData;
    }
    let allowDeletingParentRecord = false;
    if (provider.length > 0) {
      provider.forEach(providerElement => {
        if (providerElement.deleteKey === true) {
          if (service.length > 0) {
            service.forEach(serviceElement => {
              if (providerElement.providerId === serviceElement.provider_id && serviceElement.deleteKey === false) {
                allowDeletingParentRecord = true;
              }
            });
          }
        }
      });
    }
    return allowDeletingParentRecord;
  }

  const saveFraudManagementResults = () => {
    if (validateProviderDataDeletion()) {
      setErrorMsgDelete(true);
      alertMessageSetTimeOut();
    } else {
      setMsg('');
      setDisFlag(false);
      setDisplayFlag(false);
      const serviceSetupListArray = [];
      const providerSetupListArray = [];
      const fraudConfigList = [];
      const fraudConfigListForMpi = [];
      let flag;
      let findPreAuth = '';
      let findPostAuth = '';
      let findThreeDSData = '';
      let findPreA = '';
      let findPostA = '';
      let findTds = '';
      let providerRequestBodyForDeleteApi;
      let addonRequestBodyForDeleteApi;
      if (preAuthData.length > 0) {
        findPreAuth = preAuthData.some(element => (element.deleteKey === false));
      } else {
        findPreAuth = true;
      }
      if (postAuthData.length > 0) {
        findPostAuth = postAuthData.some(element => (element.deleteKey === false));
      } else {
        findPostAuth = true;
      }
      if (threeDSData.length > 0) {
        findThreeDSData = threeDSData.some(element => (element.deleteKey === false));
      } else {
        findThreeDSData = true;
      }
      if (preAData.length > 0) {
        findPreA = preAData.some(element => (element.deleteKey === false));
      } else {
        findPreA = true;
        setPostAData(false);
      }
      if (postAData.length > 0) {
        findPostA = postAData.some(element => (element.deleteKey === false));
      } else {
        findPostA = true;
        setPreAFlag(false);
      }
      if (tdsData.length > 0) {
        findTds = tdsData.some(element => (element.deleteKey === false));
      } else {
        findTds = true;
      }

      if ((!findPreAuth && preAuthData.length > 0) || (!findPostAuth && postAuthData.length > 0) || (!findThreeDSData && threeDSData.length > 0) || (!findPreA && preAData.length > 0) || (!findPostA && postAData.length > 0) || (!findTds && tdsData.length > 0)) {
        if (inputField.serviceTypes.nameProp === 'Pre Auth' && (preAuthData.length > 0) && (!findPreAuth)) {
          if (!findPreAuth && preAuthData.length > 0) {
            addonRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              preAuth: -1,
            };
            props.deleteAddonConfigurationRequest(addonRequestBodyForDeleteApi);
            setPreAuthData([]);
          }
        }
        if (inputField.serviceTypes.nameProp === 'Pre Auth' && (preAData.length > 0) && (!findPreA)) {
          if (!findPreA && preAData.length > 0) {
            providerRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              provider_type: 9,
            };
            props.deleteProviderConfigurationRequest(providerRequestBodyForDeleteApi);
            setPreAData([]);
          }
        }
        if (inputField.serviceTypes.nameProp === 'Post Auth' && (postAuthData.length > 0) && (!findPostAuth)) {
          if (!findPostAuth && postAuthData.length > 0) {
            addonRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              postAuth: -1,
            };
            props.deleteAddonConfigurationRequest(addonRequestBodyForDeleteApi);
            setPostAuthData([]);
          }
        }
        if (inputField.serviceTypes.nameProp === 'Post Auth' && (postAData.length > 0) && (!findPostA)) {
          if (!findPostA && postAData.length > 0) {
            providerRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              provider_type: 9,
            };
            props.deleteProviderConfigurationRequest(providerRequestBodyForDeleteApi);
            setPostAData([]);
          }
        }
        if (inputField.serviceTypes.nameProp === '3DS' && (threeDSData.length > 0) && (!findThreeDSData)) {
          if (!findThreeDSData && threeDSData.length > 0) {
            addonRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              mpi: -1,
            };
            props.deleteAddonConfigurationRequest(addonRequestBodyForDeleteApi);
            setThreeDSData([]);
          }
        }
        if (inputField.serviceTypes.nameProp === '3DS' && (tdsData.length > 0) && (!findTds)) {
          if (!findTds && tdsData.length > 0) {
            providerRequestBodyForDeleteApi = {
              client_id: globals.clientId,
              provider_type: 6,
            };
            props.deleteProviderConfigurationRequest(providerRequestBodyForDeleteApi);
            setTdsData([]);
          }
        }
      } else {
        if (inputField.serviceTypes.nameProp === 'Pre Auth') {
          if (inputField.toggleStatus === on) {
            flag = true;
          } else {
            flag = false;
          }
          if (preAuthData) {
            preAuthData.forEach(obj => {
              if (!obj.deleteKey) {
                serviceSetupListArray.push({
                  provider_id: obj.provider_id === null ? 0 : obj.provider_id,
                  pm_id: obj.pm_id,
                  country_id: obj.country_id,
                  currency_id: obj.currency_id,
                });
              }
            });
          }
          fraudConfigList.push({
            sub_type: 'pre_auth',
            is_rollback: flag,
            addon_configurations: {
              addon_configuration: serviceSetupListArray,
            },
          });
        } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
          if (postAuthData) {
            postAuthData.forEach(obj => {
              if (!obj.deleteKey) {
                serviceSetupListArray.push({
                  provider_id: obj.provider_id,
                  pm_id: obj.pm_id,
                  country_id: obj.country_id,
                  currency_id: obj.currency_id,
                });
              }
            });
          }
          fraudConfigList.push({
            sub_type: 'post_auth',
            is_rollback: 'true',
            addon_configurations: {
              addon_configuration: serviceSetupListArray,
            },
          });
        } else if (inputField.serviceTypes.nameProp === '3DS') {
          if (threeDSData) {
            threeDSData.forEach(obj => {
              if (!obj.deleteKey) {
                serviceSetupListArray.push({
                  provider_id: obj.provider_id,
                  pm_id: obj.pm_id,
                  country_id: obj.country_id,
                  currency_id: obj.currency_id,
                  account_id: obj.account_id,
                  provider_id: obj.provider_id,
                });
              }
            });
          }
          fraudConfigListForMpi.push({
            addon_configuration: serviceSetupListArray,
          });
        }
        if (inputField.serviceTypes.nameProp === 'Pre Auth') {
          setPostAFlag(false);
          if (preAData) {
            preAData.forEach(obj => {
              if (!obj.deleteKey) {
                providerSetupListArray.push({
                  client_id: globals.clientId,
                  id: obj.providerId,
                  name: obj.mid,
                  username: obj.username,
                  password: obj.password,
                });
              }
            });
          }
        } else if (inputField.serviceTypes.nameProp === 'Post Auth') {
          setPreAFlag(false);
          if (postAData) {
            postAData.forEach(obj => {
              if (!obj.deleteKey) {
                providerSetupListArray.push({
                  client_id: globals.clientId,
                  id: obj.providerId,
                  name: obj.mid,
                  username: obj.username,
                  password: obj.password,
                });
              }
            });
          }
        } else if (inputField.serviceTypes.nameProp === '3DS') {
          if (tdsData) {
            tdsData.forEach(obj => {
              if (!obj.deleteKey) {
                providerSetupListArray.push({
                  client_id: globals.clientId,
                  id: obj.providerId,
                  name: obj.mid,
                  username: obj.username,
                  password: obj.password,
                });
              }
            });
          }
        }
        const requestBodyForProviderSetup = {
          client_provider_configuration: providerSetupListArray,
        };
        const requestBodyForServiceSetup = {
          client_id: globals.clientId,
          fraud_configs: {
            fraud_config: fraudConfigList,
          },
        };
        const requestBodyForServiceSetupMpi = {
          client_id: globals.clientId,
          mpi_config: {
            version: inputField.version.nameProp,
            addon_configurations: {
              addon_configuration: serviceSetupListArray,
            },
          },
        };

        // Post API call with table data
        if (requestBodyForProviderSetup && requestBodyForProviderSetup.client_provider_configuration) {
          props.postProviderConfigurationRequest(requestBodyForProviderSetup);
        }

        if (inputField.serviceTypes.nameProp === '3DS' && serviceSetupListArray.length > 0) {
          props.postAddonConfigurationRequest(requestBodyForServiceSetupMpi);
        } else {
          props.postAddonConfigurationRequest(requestBodyForServiceSetup);
        }
      }

      // Make save button disabled
      setSaveButtonDisabledFlag(1);
    }
  };

  useEffect(() => {
    if (props.activeKey === activeKeyFraudManagement && props.deleteAddonConfigurationResult.fetched && !props.deleteAddonConfigurationResult.fetching && props.deleteAddonConfigurationResult.requestStatus) {
      if (props.deleteAddonConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getAllConfigurationForFxRequest();
    }
  }, [props.deleteAddonConfigurationResult]);

  useEffect(() => {
    if (props.activeKey === activeKeyFraudManagement && props.deleteProviderConfigurationResult.fetched && !props.deleteProviderConfigurationResult.fetching && props.deleteProviderConfigurationResult.requestStatus) {
      if (props.deleteProviderConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getProviderConfigurationRequest();
    }
  }, [props.deleteProviderConfigurationResult]);


  // Wait for Sucess result of postProviderConfigurationResult then call getProviderConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyFraudManagement && props.postProviderConfigurationResult.fetched && !props.postProviderConfigurationResult.fetching && props.postProviderConfigurationResult.requestStatus) {
      props.getProviderConfigurationRequest();
    }
  }, [props.postProviderConfigurationResult]);

  // Wait for Sucess result of postAddonConfigurationResult then call getAllConfigurationForFxRequest
  useEffect(() => {
    if (props.activeKey === activeKeyFraudManagement && props.postAddonConfigurationResult.fetched && !props.postAddonConfigurationResult.fetching && props.postAddonConfigurationResult.requestStatus) {
      if (props.postAddonConfigurationResult.requestStatus && !props.postAddonConfigurationResult.error && !props.postAddonConfigurationResult.result.status) {
        notify('success', toastMessages.merchantServices.success.added);
      } else {
        notify('error', toastMessages.merchantServices.error.added);
      }
      props.getAllConfigurationForFxRequest();
    }
  }, [props.postAddonConfigurationResult]);

  // Show Loader still get getAllConfigurationForFxResult
  useEffect(() => {
    if (props.getAllConfigurationForFxResult.fetching || props.postAddonConfigurationResult.fetching || props.deleteAddonConfigurationResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });

  // Show Loader still get getProviderConfigurationResult
  useEffect(() => {
    if (props.getProviderConfigurationResult.fetching || props.postProviderConfigurationResult.fetching || props.deleteProviderConfigurationResult.fetching) {
      setIsLoader(true);
    } else {
      setIsLoader(false);
    }
  });

  // If get Updated getAllConfigurationForFxResult then call updatedPrePostData()
  useEffect(() => {
    updatedPrePostData();
  }, [props.getAllConfigurationForFxResult.result]);

  // If get Updated getProviderConfigurationResult then call updatedPrePostData()
  useEffect(() => {
    updatedPrePostTdsData();
  }, [props.getProviderConfigurationResult.result]);

  // set default data for table
  const clearState = () => {
    setDisFlag(false);
    setDisplayFlag(false);
    setMsg('');

    const preConfigPreAuthData = preAuthData.filter(pre => pre.existFlag === true);
    const preConfigPreAData = preAData.filter(pre => pre.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'Pre Auth') {
      const preUpdatedConfigPreAuthData = preConfigPreAuthData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPreAuthData(preUpdatedConfigPreAuthData);

      const preUpdatedConfigPreAData = preConfigPreAData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPreAData(preUpdatedConfigPreAData);
    }

    const preConfigPostAuthData = postAuthData.filter(post => post.existFlag === true);
    const preConfigPostAData = postAData.filter(post => post.existFlag === true);
    if (inputField.serviceTypes.nameProp === 'Post Auth') {
      const preUpdatedConfigPostAuthData = preConfigPostAuthData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPostAuthData(preUpdatedConfigPostAuthData);

      const preUpdatedConfigPostAData = preConfigPostAData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setPostAData(preUpdatedConfigPostAData);
    }

    const preConfigThreeDsData = threeDSData.filter(tds => tds.existFlag === true);
    const preConfigTdsData = tdsData.filter(tds => tds.existFlag === true);
    if (inputField.serviceTypes.nameProp === '3DS') {
      const preUpdatedConfigThreeDsData = preConfigThreeDsData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setThreeDSData(preUpdatedConfigThreeDsData);

      const preUpdatedConfigTdsData = preConfigTdsData.map(p => p.deleteKey === true ? { ...p, deleteKey: false } : p);
      setTdsData(preUpdatedConfigTdsData);
    }

    const formValues = { ...inputField };
    formValues.is_rollback = on;
    formValues.provider.idProp = '';
    formValues.provider.nameProp = '';
    formValues.username = '';
    formValues.password = '';
    formValues.mid = '';
    formValues.serviceProvider.idProp = '';
    formValues.country.idProp = '';
    formValues.currency.idProp = '';
    formValues.serviceProvider.nameProp = 'Please Select';
    formValues.country.nameProp = '';
    formValues.currency.nameProp = '';
    formValues.paymentMethod = '';
    formValues.toggleStatus = off;
    formValues.version.idProp = '';
    formValues.version.nameProp = '';
    formValues.storeFronts = '';
    setInpuField(formValues);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // Toast Message
    notify('success', toastMessages.merchantServices.success.reset);
  };
  const confirmYesData = () => {
    clearState();
    setAlertMessageForServiceTypes(false);
    const formValues = { ...inputField };
    formValues.serviceTypes.idProp = preId;
    formValues.serviceTypes.nameProp = preValue;
    formValues.provider.idProp = '';
    formValues.provider.nameProp = '';
    setInpuField(formValues);
  };
  const confirmNoData = () => {
    setAlertMessageForServiceTypes(false);
  };
  return (
    <div className="card">
      {(alertMessageForServiceTypes) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      {props.activeKey === activeKeyFraudManagement && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterPreAData = preAData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPreAData(filterPreAData);

          const filterPostAData = postAData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPostAData(filterPostAData);

          const filterTdsData = tdsData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setTdsData(filterTdsData);

          const filterPreAuthData = preAuthData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPreAuthData(filterPreAuthData);

          const filterPostAuthData = postAuthData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setPostAuthData(filterPostAuthData);

          const filterThreeDSData = threeDSData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setThreeDSData(filterThreeDSData);

          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveFraudManagementResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyFraudManagement ? '' : activeKeyFraudManagement)} eventKey={activeKeyFraudManagement}>
          <h6>
            Fraud Management
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyFraudManagement}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Service Type
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'serviceTypes')}
                    value={inputField.serviceTypes.idProp}
                    data-toggle="modal"
                    data-target="#alertModal"
                  >
                    <option disabled selected value="">Please Select</option>
                    {serviceTypeDropdown.map(key => <option value={key.id}>{key.type}</option>)}
                  </Form.Control>
                </Col>
              </div>
              {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <div>
                <div className="cards_heading mr-1 ml-1 mt-3">Provider Setup</div>
              </div>}
              {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Provider</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => selectOnChangeProviderSetup(e)}
                    value={inputField.provider.idProp}
                  >
                    <option selected>Please Select</option>
                    {providerDropdown && providerDropdown.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>
                {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth') && <Col className="form-group" md={2}>
                  <Form.Label>Rollback</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={inputField.toggleStatus === on}
                      onClick={e => inputOnChange(e, 'toggleStatus')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </Col>}

              </div>}

              {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <div className="row">
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">MID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="MID"
                    name="text"
                    onChange={e => inputOnChange(e, 'mid')}
                    value={inputField.mid}
                    maxLength={55}
                  />
                </Col>
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Username"
                    name="text"
                    onChange={e => inputOnChange(e, 'username')}
                    value={inputField.username}
                    maxLength={55}
                  />
                </Col>
                <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Password</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Password"
                    name="text"
                    onChange={e => inputOnChange(e, 'password')}
                    value={inputField.password}
                  />
                </Col>
                <Col className="form-group" md={1}>
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => onClickAddButtonForProviderSetup()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </Col>
              </div>}
              {errorMsg && <div className="alert alert-danger">
                {msg === 'Provider Already Present' ? msg : ''}
              </div>}
              {disFlag && <div className="alert alert-danger">
                {validationRequiredInputMessage}
              </div>}
              {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <div className="row">
                {(!isLoader) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  <BootstrapTable data={fraudDataFunction()}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Provider</TableHeaderColumn>
                    <TableHeaderColumn dataField="mid" dataAlign="center" dataSort className={tableHeadingColor} columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>MID</TableHeaderColumn>
                    <TableHeaderColumn dataField="username" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Username</TableHeaderColumn>
                    <TableHeaderColumn dataField="password" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Password</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
              </div>}
              {(inputField.serviceTypes.idProp === '6') && <div>
                <div className="cards_heading mr-1 ml-1 mt-3">Service Setup</div>
              </div>}
              {(inputField.serviceTypes.idProp === '6') && <div className="row">
                {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Provider</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => selectOnChangeServiceProviderSetup(e)}
                    value={inputField.serviceProvider.idProp}
                  >
                    <option selected>Please Select</option>
                    {providerArrayList && providerArrayList.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}

              </div>}
              {(inputField.serviceTypes.idProp === '6') && <div className="row">
                {(inputField.serviceTypes.nameProp === '3DS') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Provider</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => selectOnChangeServiceProviderSetup(e)}
                    value={inputField.serviceProvider.idProp}
                  >
                    <option selected>Please Select</option>
                    {providerList().map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.serviceTypes.nameProp === '3DS') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Version
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'version')}
                    value={inputField.version.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    {versionDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.serviceTypes.idProp === '6') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Payment Method</Form.Label>
                  <MultiSelect
                    options={paymentMethodSelectedListArray}
                    selected={inputField.paymentMethod}
                    onSelectedChanged={e => inputOnChangePm(e, 'paymentMethod')}
                    overrideStrings={{
                      selectSomeItems: 'Select Payment Method',
                      allItemsAreSelected: 'All Payment Method are selected...',
                      selectAll: 'Select All Payment Method',
                    }}
                  />
                </Col>}
                {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth' || inputField.serviceTypes.nameProp === '3DS') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Country
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'country')}
                    value={inputField.country.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    <option value={0}>-- All --</option>
                    {countryDetail.map(key => <option value={key.id}>{key.name}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth' || inputField.serviceTypes.nameProp === '3DS') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">
                    Currency
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={e => inputOnChange(e, 'currency')}
                    value={inputField.currency.idProp}
                  >
                    <option disabled selected value="">Please Select</option>
                    <option value={0}>-- All --</option>
                    {currencyDetail.map(key => <option value={key.id}>{key.code}</option>)}
                  </Form.Control>
                </Col>}
                {(inputField.serviceTypes.nameProp === '3DS') && <Col className="form-group" md={3}>
                  <Form.Label className="mandatory">Storefront</Form.Label>
                  <MultiSelect
                    options={storeFrontsSelectedListArray}
                    selected={inputField.storeFronts}
                    onSelectedChanged={e => inputOnChangePm(e, 'storeFronts')}
                    overrideStrings={{
                      selectSomeItems: 'Select Storefronts',
                      allItemsAreSelected: 'All Storefronts are selected...',
                      selectAll: 'Select All Storefronts',
                    }}
                  />
                </Col>}
                <Col className="form-group" md={1}>
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => onClickAddButtonForServiceSetup()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </Col>
              </div>}
              {errorMsg && <div className="alert alert-danger">
                {msg !== 'Provider Already Present' ? msg : ''}
              </div>}
              {displayFlag && <div className="alert alert-danger">
                {validationRequiredInputMessage}
              </div>}
              {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <div className="row">
                {(!isLoaderOn) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  {inputField.serviceTypes.idProp !== '4' && inputField.serviceTypes.idProp !== '5' &&
                    <BootstrapTable data={fraudManagementData()}>
                      <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                      <TableHeaderColumn dataField="providerName" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Provider</TableHeaderColumn>
                      {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5' || inputField.serviceTypes.idProp === '6') && <TableHeaderColumn dataField="paymentMethod" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Payment Method</TableHeaderColumn>}
                      {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth' || inputField.serviceTypes.nameProp === '3DS') && <TableHeaderColumn dataField="country" dataSort className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Country</TableHeaderColumn>}
                      {(inputField.serviceTypes.nameProp === 'Pre Auth' || inputField.serviceTypes.nameProp === 'Post Auth' || inputField.serviceTypes.nameProp === '3DS') && <TableHeaderColumn dataField="currency" dataSort className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Currency</TableHeaderColumn>}
                      {(inputField.serviceTypes.idProp === '6') && <TableHeaderColumn dataField="storeFronts" dataSort className={tableHeadingColor} columnClassName={_onClickDeleteRowStyleDisable}>Storefront</TableHeaderColumn>}
                      <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                    </BootstrapTable>
                  }
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <div className="col-md-12  pt-2 pb-2 mt-3">
                  {(inputField.serviceTypes.idProp === '4' || inputField.serviceTypes.idProp === '5') && <Form.Text className="text-warning float-left mr-3">
                    NOTE:- Deleting/Updating PreAuth configuration will also delete/update PostAuth configuration and vice versa..
                  </Form.Text>}
                  <div className="col-md-12  pt-2 pb-2 mt-3 pl-0">
                    {errorMsgDelete && <div className="alert alert-danger">
                      As there is dependent provider entries exists in service setup, You can not delete entry from provider setup.
                    </div>}
                  </div>
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveFraudManagementResults()}
                  >
                    Save
                  </button>}
                  <Button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mr-3"
                    onClick={() => clearState()}
                  >
                    Cancel
                  </Button>
                </div>
              </div>}
            </Form>
          </div>
        </div>
      </Accordion.Collapse>

    </div>
  );
}
VasSetupFraudManagement.propTypes = {

  getSystemMetaDataResult: PropTypes.object,
  getPaymentMetaDataResult: PropTypes.object,
  getClientConfigurationResult: PropTypes.object,

  getProviderConfigurationResult: PropTypes.object,
  getAllConfigurationForFxResult: PropTypes.object,
  getAllConfigurationForFxRequest: PropTypes.object,

  postAddonConfigurationResult: PropTypes.object,
  postProviderConfigurationResult: PropTypes.object,

  getProviderConfigurationRequest: PropTypes.func,
  postProviderConfigurationRequest: PropTypes.func,
  deleteAddonConfigurationResult: PropTypes.object,
  deleteProviderConfigurationResult: PropTypes.object,
  postAddonConfigurationRequest: PropTypes.func,
  deleteAddonConfigurationRequest: PropTypes.func,
  deleteProviderConfigurationRequest: PropTypes.func,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
  getPreAuthRulesRequest: PropTypes.func,
  getPostAuthRulesRequest: PropTypes.func,
  getPreAuthRulesResult: PropTypes.object,
  getPostAuthRulesResult: PropTypes.object,

};
const mapStateToProps = state => ({
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  getPaymentMetaDataResult: state.getPaymentMetaDataResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  getProviderConfigurationResult: state.getProviderConfigurationResult,
  getAllConfigurationForFxResult: state.getAllConfigurationForFxResult,
  postAddonConfigurationResult: state.postAddonConfigurationResult,
  postProviderConfigurationResult: state.postProviderConfigurationResult,
  deleteAddonConfigurationResult: state.deleteAddonConfigurationResult,
  deleteProviderConfigurationResult: state.deleteProviderConfigurationResult,
  getPreAuthRulesRequest: state.getPreAuthRulesRequest,
  getPostAuthRulesRequest: state.getPostAuthRulesRequest,
  getPreAuthRulesResult: state.getPreAuthRulesResult,
  getPostAuthRulesResult: state.getPostAuthRulesResult,
});

export default connect(
  mapStateToProps, {
    getPreAuthRulesRequest, getPostAuthRulesRequest,
  getAllConfigurationForFxRequest, getProviderConfigurationRequest,
  postAddonConfigurationRequest, postProviderConfigurationRequest,
  deleteAddonConfigurationRequest, deleteProviderConfigurationRequest,
}
)(VasSetupFraudManagement);