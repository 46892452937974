import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../static/images/loader-vision.gif';

const Loader = props => (
  <div className={props.classname}><img src={loader} alt="loader" /></div>

);


Loader.defaultProps = {
  classname: 'loader--white',
};

Loader.propTypes = {
  classname: PropTypes.string,
};


export default Loader;
