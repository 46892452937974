import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: '',
};

export const loginResult = (state = initialState, action) => {
  switch (action.type) {
  case types.LOGIN_REQUEST:
    return { ...state, fetching: true };
  case types.LOGIN_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.LOGIN_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};

export const forgotPasswordResult = (state = initialState, action) => {
  switch (action.type) {
  case types.FORGOT_PASSWORD_REQUEST:
    return { ...state, fetching: true };
  case types.FORGOT_PASSWORD_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.FORGOT_PASSWORD_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};

export const resetPasswordResult = (state = initialState, action) => {
  switch (action.type) {
  case types.RESET_PASSWORD_REQUEST:
    return { ...state, fetching: true };
  case types.RESET_PASSWORD_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.FORGOT_PASSWORD_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};

export const resetPasswordTokenResult = (state = initialState, action) => {
  switch (action.type) {
  case types.RESET_PASSWORD_TOKEN_REQUEST:
    return { ...state, fetching: true };
  case types.RESET_PASSWORD_TOKEN_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.RESET_PASSWORD_TOKEN_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};

const tokenLoginInitialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: '',
};

export const tokenBasedLoginResult = (state = tokenLoginInitialState, action) => {
  switch (action.type) {
  case types.TOKEN_LOGIN_REQUEST:
    return { ...state, fetching: true };
  case types.TOKEN_LOGIN_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.TOKEN_LOGIN_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};
