/* eslint-disable */
import { combineReducers } from 'redux';

import { dashboardResult, KPIDashboardResult, FilterResult } from './Dashboard';
import { clientConfigResult } from './clientConfig';
import { SystemMetaDataResult } from './SystemMetaData';
import {
  loginResult, tokenBasedLoginResult, forgotPasswordResult, resetPasswordResult, resetPasswordTokenResult,
} from './Login';
import { getProfileResult, updateProfileResult } from './Profile';
import { permissionsResult, getPermissionObject } from './Permissions';
import { locationResult } from './Location';
import { listlocationResult } from './ListLocation';
import { ibeConfigResult } from './IbeConfigResult';
import { ibeConfigUpdateResult } from './IbeConfigResult';
import { AddLocationResult } from './AddLocation';
import { promotionListResult } from './PromotionList';
import { triggerListResult } from './TriggerList';
import { manualTriggerListResult } from './ManualTriggerList';
import { orderSummaryResult } from './OrderSummary';
import { configureMessageResult } from './ConfigureMessage';
import { previewPublishResult } from './PreviewPublish';
import { scheduleResult } from './Schedule';
import { deleteResult } from './Delete';
import { triggerEditResult } from './TriggerEdit';
import { autoTriggerListResult } from './AutoTriggerList';
import { configureAutoTriggerResult } from './ConfigureAutoTrigger';
import { deleteAutoTriggerResult } from './DeleteAutoTrigger';
import { retrieveAutoTriggerResult } from './RetrieveAutoTrigger';
import {
  searchReportsResult, viewReportResult, downloadReportResult, getMySubscriptionsResult, subscribeReportResult, getSubscribeResult,
  updateSubscriptionResult, unSubscribeResult, saveReportFilterResult, getReportFilterResult,
  updateReportFilterResult,
  deleteReportFilterResult, emailReportResult,
} from './ReportsResult';
import { promotionCreateResult } from './PromotionCreate';
import { promotionUpdateResult } from './PromotionUpdate';
import { promotionDeleteResult } from './PromotionDelete';
import { promotionViewResult } from './PromotionView';
import { eventResult } from './Event';

import { dynamicBannerResult } from '../../app/components/pages/DynamicBanner/Redux/reducer';
import { promotionTopicListResult } from './PromotionTopicList';
import {
  broadcastResult, broadcastCreateResult, editBroadcastResult, deleteBroadcastResult,
} from './Broadcast';
import { getViewPromotionData, getAwsObjectResult } from './Promotion';
import { getSessionExpired } from './SessionExpired';
import {
  createNotificationResult, userSegmentationResult, getNotificationEngineData, scheduleNotificationResult, getNotificationResult, notificationMetaDataResult, getSynchronisedAWSObjectResult,
  cloneStoreFrontData,
} from './NotificationEngine';
import {
  emailManageTransactionResult, getMTdownloadStatus,
  manageTransactionResult, viewTransactionResult,
  addTransactionNoteResult, captureOrRefundTransactionResult,
  updateStatusReviewResult, updateAdditionalFiltersResult,
  manageTransactionDownloadResult, additionalFiltersResult, refundLinesTransactionResult,
} from './ManageTransaction';
import {
  createGeoFenceResult, deleteGeoFenceResult, searchGeoFenceResult, viewGeoFenceResult, getGeoFenceData,
} from './GeoFence';
import { getScreensResult, deleteScreenResult, createScreenResult } from './ScreenConfigurations';
import { getPreAuthRulesResult, getPostAuthRulesResult, getFraudAuthRulesResult, 
  getfraudMetaDataResult, getRulesResult, updateRouteResult, staticRouteRidirect, deleteRouteResult, getRulesMetaDataResult, getDRRulesMetaDataResult, createRuleResult, getRuleListResult, getFormMode, updateRuleResult, deleteRuleResult, getClientConfigDataResult, getCustomerTypeResult, getSubscriptionResult, updateManageSubscriptionResult, configureRouteResult, getRouteResult, getRouteMetaDataResult, getBinRangeMetaDataResult, getBinRangeSearchResult, getIataCodeResult, getRouteDetailsResult, getInstallmentRulesMetaDataResult, getActiveRuleResult, deleteActiveRuleResult,
} from './DynamicRouting';
import {
  getProvidersResult, addFXConfigResult, getFXConfigResult, updateFXConfigResult,
} from './FX';
import {
  getFilterConfigurationsResult, getAllKeysResult, getAllQueuesResult, getAllFiltersResult, createFilterConfigurationResult,
  deleteFilterConfigurationResult, getFilterConfigResult, updateFilterConfigurationResult,
} from './EventNotification';
import {
  getPaymentLinkMetaDataResult, createPaymentLinkResult, getPaymentLinksResult, deletePaymentLinkResult, getPaymentLinkFileResult, getPaymentLinkResult,
} from './PaymentLink';
import {
  getClientConfigurationResult, deleteClientConfigurationResult, postClientConfigurationResult, getSystemMetaDataResult, getPaymentMetaDataResult, updateClientConfigurationResult, getAddonConfigurationResult, getRouteConfigurationResult, getRoutePSPConfigurationResult, updateRoutePSPConfigurationResult, getAllConfigurationForFxResult, postAddonConfigurationResult, getProviderConfigurationResult, postProviderConfigurationResult, deleteAddonConfigurationResult, deleteProviderConfigurationResult, getPSPConfigurationResult, updatePSPConfigurationResult, deletePSPConfigurationResult, getPSPConfigurationByIdResult, deleteRoutePSPConfigurationResult, getRoutePSPConfigurationByIdResult, updateRoutePSPConfigurationByRouteIdResult, deleteRoutePSPConfigurationByRouteIdResult,
} from './MerchantOnboarding';

const rootReducer = combineReducers({
  dashboardResult,
  clientConfigResult,
  SystemMetaDataResult,
  loginResult,
  forgotPasswordResult,
  resetPasswordResult,
  resetPasswordTokenResult,
  permissionsResult,
  locationResult,
  listlocationResult,
  ibeConfigResult,
  ibeConfigUpdateResult,
  AddLocationResult,
  promotionListResult,
  triggerListResult,
  manualTriggerListResult,
  orderSummaryResult,
  configureMessageResult,
  previewPublishResult,
  scheduleResult,
  deleteResult,
  triggerEditResult,
  autoTriggerListResult,
  configureAutoTriggerResult,
  deleteAutoTriggerResult,
  retrieveAutoTriggerResult,
  searchReportsResult,
  viewReportResult,
  downloadReportResult,
  emailReportResult,
  promotionCreateResult,
  promotionUpdateResult,
  promotionDeleteResult,
  promotionViewResult,
  eventResult,
  dynamicBannerResult,
  tokenBasedLoginResult,
  getProfileResult,
  updateProfileResult,
  promotionTopicListResult,
  broadcastResult,
  deleteBroadcastResult,
  broadcastCreateResult,
  editBroadcastResult,
  getViewPromotionData,
  getAwsObjectResult,
  getPermissionObject,
  getMySubscriptionsResult,
  subscribeReportResult,
  getSubscribeResult,
  updateSubscriptionResult,
  unSubscribeResult,
  saveReportFilterResult,
  getReportFilterResult,
  updateReportFilterResult,
  deleteReportFilterResult,
  getSessionExpired,
  emailManageTransactionResult,
  additionalFiltersResult,
  manageTransactionDownloadResult,
  getMTdownloadStatus,
  manageTransactionResult,
  viewTransactionResult,
  addTransactionNoteResult,
  captureOrRefundTransactionResult,
  createGeoFenceResult,
  deleteGeoFenceResult,
  searchGeoFenceResult,
  viewGeoFenceResult,
  getCustomerTypeResult,
  getGeoFenceData,
  createNotificationResult,
  userSegmentationResult,
  getNotificationEngineData,
  scheduleNotificationResult,
  getNotificationResult,
  notificationMetaDataResult,
  getSynchronisedAWSObjectResult,
  getScreensResult,
  deleteScreenResult,
  createScreenResult,
  cloneStoreFrontData,
  getPreAuthRulesResult, 
  getPostAuthRulesResult,
  getFraudAuthRulesResult,
  getRulesResult,
  getProvidersResult,
  addFXConfigResult,
  updateFXConfigResult,
  getFXConfigResult,
  getRulesMetaDataResult,
  getfraudMetaDataResult,
  getDRRulesMetaDataResult,
  createRuleResult,
  getRuleListResult,
  getFormMode,
  updateRuleResult,
  deleteRuleResult,
  getClientConfigDataResult,
  KPIDashboardResult,
  getSubscriptionResult,
  updateManageSubscriptionResult,
  configureRouteResult,
  getRouteResult,
  getRouteMetaDataResult,
  getBinRangeMetaDataResult,
  getBinRangeSearchResult,
  updateStatusReviewResult,
  FilterResult,
  getIataCodeResult,
  getInstallmentRulesMetaDataResult,
  getRouteDetailsResult,
  updateRouteResult,
  deleteRouteResult,
  getActiveRuleResult,
  deleteActiveRuleResult,
  staticRouteRidirect,
  getFilterConfigurationsResult,
  getAllKeysResult,
  getAllQueuesResult,
  getAllFiltersResult,
  createFilterConfigurationResult,
  deleteFilterConfigurationResult,
  getFilterConfigResult,
  updateFilterConfigurationResult,
  getPaymentLinkMetaDataResult,
  createPaymentLinkResult,
  getPaymentLinksResult,
  deletePaymentLinkResult,
  getPaymentLinkFileResult,
  getPaymentLinkResult,

  getClientConfigurationResult,
  postClientConfigurationResult,
  deleteClientConfigurationResult,
  getSystemMetaDataResult,
  getPaymentMetaDataResult,
  updateClientConfigurationResult,
  getAddonConfigurationResult,
  getRouteConfigurationResult,
  getRoutePSPConfigurationResult,
  updateRoutePSPConfigurationResult,
  getAllConfigurationForFxResult,
  postAddonConfigurationResult,
  getProviderConfigurationResult,
  postProviderConfigurationResult,
  deleteAddonConfigurationResult,
  deleteProviderConfigurationResult,
  getPSPConfigurationResult,
  updatePSPConfigurationResult,
  deletePSPConfigurationResult,
  getPSPConfigurationByIdResult,
  deleteRoutePSPConfigurationResult,
  getRoutePSPConfigurationByIdResult,
  updateRoutePSPConfigurationByRouteIdResult,
  deleteRoutePSPConfigurationByRouteIdResult,
  updateAdditionalFiltersResult,
  refundLinesTransactionResult,
});
export default rootReducer;
