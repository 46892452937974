import { actionTypes as types, urls } from '../constants';
import { get } from '../helpers';
import globals from '../constants/globals';

export const permissionsRequest = () => dispatch => {
  dispatch({ type: types.PERMISSIONS_REQUEST });
  const url = urls.POST_PERMISSIONS + globals.clientId;
  get({
    url,
    success: types.PERMISSIONS_SUCCESS,
    failure: types.PERMISSIONS_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};


export const setPermissionObject = data => dispatch => {
  dispatch({ type: types.SET_PERMISSION_OBJECT, data });
};
