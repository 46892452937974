import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import AppLoader from '../../common/AppLoader';

const ViewReport = () => {
  const [data, setData] = useState('');
  const fileurl = localStorage.getItem('fileurl');
  const authToken = localStorage.getItem('authToken');
  const customHeaders = {
    'Content-Type': 'application/json',
    'x-cpm-auth-token': authToken,
  };

  axios({ url: fileurl, method: 'GET', headers: customHeaders })
    .then(response => {
      setData(response.data);
    });

  if (data !== '') {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: data }} />
      </div>);
  }
  return (
    <div>
      <AppLoader />
    </div>);
};

ViewReport.propTypes = {};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(ViewReport);
