import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { sessionExpiryRedirection } from './utils/Redirect';

class SessionTimer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  _onIdle() {
    const isLoggedIn = localStorage.getItem('clientID');
    if (isLoggedIn) {
      localStorage.setItem('sessionExpired', true);
      sessionExpiryRedirection();
    }
  }

  render() {
    const sessionTimeInMinute = 10;
    return (
      <IdleTimer
        ref={ref => { this.idleTimer = ref; }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={1000 * 60 * sessionTimeInMinute}
      />
    );
  }
}


export default SessionTimer;
