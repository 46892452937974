import { actionTypes as types, urls } from '../constants';
import {
  get, put, del, post,
}
from '../helpers';


export const getSystemMetaDataRequest = () => dispatch => {
  dispatch({ type: types.GET_SYSTEM_METADATA_REQUEST });
  get({
    url: urls.GET_SYSTEM_METADATA,
    success: types.GET_SYSTEM_METADATA_SUCCESS,
    failure: types.GET_SYSTEM_METADATA_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getClientConfigurationRequest = () => dispatch => {
  dispatch({ type: types.GET_CLIENT_CONFIGURATION_REQUEST });
  get({
    url: urls.GET_CLIENT_CONFIGURATION,
    success: types.GET_CLIENT_CONFIGURATION_SUCCESS,
    failure: types.GET_CLIENT_CONFIGURATION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const postClientConfigurationRequest = data => dispatch => {
  dispatch({ type: types.POST_CLIENT_CONFIGURATION_REQUEST });
  post({
    url: urls.POST_CLIENT_CONFIGURATION,
    success: types.POST_CLIENT_CONFIGURATION_SUCCESS,
    failure: types.POST_CLIENT_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getPaymentMetaDataRequest = () => dispatch => {
  dispatch({ type: types.GET_PAYMENT_METADATA_REQUEST });
  get({
    url: urls.GET_PAYMENT_METADATA,
    success: types.GET_PAYMENT_METADATA_SUCCESS,
    failure: types.GET_PAYMENT_METADATA_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const deleteClientConfigurationRequest = data => dispatch => {
  dispatch({ type: types.DELETE_CLIENT_CONFIGURATION_REQUEST });
  let url = '';
  if ((data.p_id) === -1 && !(data.client_id === undefined)) {
    url = `${urls.DELETE_CLIENT_CONFIGURATION}client_id=${data.client_id}&p_id=${data.p_id}`;
  } else if ((data.url) === -1 && !(data.client_id === undefined)) {
    url = `${urls.DELETE_CLIENT_CONFIGURATION}client_id=${data.client_id}&url=${data.url}`;
  }
  get({
    url,
    success: types.DELETE_CLIENT_CONFIGURATION_SUCCESS,
    failure: types.DELETE_CLIENT_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const updateClientConfigurationRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_CLIENT_CONFIGURATION_REQUEST });
  post({
    url: urls.POST_CLIENT_CONFIGURATION,
    success: types.UPDATE_CLIENT_CONFIGURATION_SUCCESS,
    failure: types.UPDATE_CLIENT_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const updateRoutePSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_ROUTE_PSP_CONFIGURATION_REQUEST });
  put({
    url: urls.UPDATE_ROUTE_PSP_CONFIGURATION,
    success: types.UPDATE_ROUTE_PSP_CONFIGURATION_SUCCESS,
    failure: types.UPDATE_ROUTE_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getAddonConfigurationRequest = () => dispatch => {
  dispatch({ type: types.GET_ADDON_CONFIGURATION_REQUEST });
  get({
    url: urls.GET_ADDON_CONFIGURATION,
    success: types.GET_ADDON_CONFIGURATION_SUCCESS,
    failure: types.GET_ADDON_CONFIGURATION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const deleteAddonConfigurationRequest = data => dispatch => {
  dispatch({ type: types.DELETE_ADDON_CONFIGURATION_REQUEST });
  let url = '';
  if (data.dcc === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&dcc=${data.dcc}`;
  }
  if (data.pcc === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&pcc=${data.pcc}`;
  }
  if (data.mcp === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&mcp=${data.mcp}`;
  }
  if (data.preAuth === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&pre_auth=${data.preAuth}`;
  }
  if (data.postAuth === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&post_auth=${data.postAuth}`;
  }
  if (data.mpi === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&mpi=${data.mpi}`;
  }
  if (data.conventional === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&conventional=${data.conventional}`;
  }
  if (data.cashless === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&cashless=${data.cashless}`;
  }
  if (data.hybrid === -1) {
    url = `${urls.DELETE_ADDON_CONFIGURATION}client_id=${data.client_id}&hybrid=${data.hybrid}`;
  }
  get({
    url,
    success: types.DELETE_ADDON_CONFIGURATION_SUCCESS,
    failure: types.DELETE_ADDON_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getRouteConfigurationRequest = data => dispatch => {
  dispatch({ type: types.GET_ROUTE_CONFIGURATION_REQUEST });
  let url = '';
  if (data) {
    if (data.route_id) {
      url = `${urls.GET_ROUTE_CONFIGURATION}id=${data.route_id}&isMetadataRequired=${data.is_meta_data_required}`;
    }
  }
  get({
    url,
    success: types.GET_ROUTE_CONFIGURATION_SUCCESS,
    failure: types.GET_ROUTE_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getRoutePSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.GET_ROUTE_PSP_CONFIGURATION_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.GET_ROUTE_PSP_CONFIGURATION}pspid=${data.psp_id}&isMetadataRequired=${data.is_meta_data_required}`;
  }
  get({
    url,
    success: types.GET_ROUTE_PSP_CONFIGURATION_SUCCESS,
    failure: types.GET_ROUTE_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getAllConfigurationForFxRequest = () => dispatch => {
  dispatch({ type: types.GET_ALL_CONFIGURATION_FOR_FX_REQUEST });
  get({
    url: urls.GET_ALL_CONFIGURATION_FOR_FX,
    success: types.GET_ALL_CONFIGURATION_FOR_FX_SUCCESS,
    failure: types.GET_ALL_CONFIGURATION_FOR_FX_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const postAddonConfigurationRequest = data => dispatch => {
  dispatch({ type: types.POST_ADDON_CONFIGURATION_REQUEST });
  post({
    url: urls.POST_ADDON_CONFIGURATION,
    success: types.POST_ADDON_CONFIGURATION_SUCCESS,
    failure: types.POST_ADDON_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getProviderConfigurationRequest = () => dispatch => {
  dispatch({ type: types.GET_PROVIDER_CONFIGURATION_REQUEST });
  get({
    url: urls.GET_PROVIDER_CONFIGURATION,
    success: types.GET_PROVIDER_CONFIGURATION_SUCCESS,
    failure: types.GET_PROVIDER_CONFIGURATION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const postProviderConfigurationRequest = data => dispatch => {
  dispatch({ type: types.POST_ADDON_CONFIGURATION_REQUEST });
  post({
    url: urls.POST_PROVIDER_CONFIGURATION,
    success: types.POST_PROVIDER_CONFIGURATION_SUCCESS,
    failure: types.POST_PROVIDER_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const deleteProviderConfigurationRequest = data => dispatch => {
  dispatch({ type: types.DELETE_PROVIDER_CONFIGURATION_REQUEST });
  let url = '';
  if (data.provider_type) {
    url = `${urls.DELETE_PROVIDER_CONFIGURATION}client_id=${data.client_id}&provider_type=${data.provider_type}`;
  }
  get({
    url,
    success: types.DELETE_PROVIDER_CONFIGURATION_SUCCESS,
    failure: types.DELETE_PROVIDER_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const createClientConfigurationRequest = data => dispatch => {
  dispatch({ type: types.CREATE_CLIENT_CONFIGURATION_REQUEST });
  post({
    url: urls.CREATE_CLIENT_CONFIGURATION,
    success: types.CREATE_CLIENT_CONFIGURATION_SUCCESS,
    failure: types.CREATE_CLIENT_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getPSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.GET_PSP_CONFIGURATION_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.GET_PSP_CONFIGURATION}isMetadataRequired=${true}`;
  }
  get({
    url,
    success: types.GET_PSP_CONFIGURATION_SUCCESS,
    failure: types.GET_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const updatePSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_PSP_CONFIGURATION_REQUEST });
  put({
    url: urls.GET_PSP_CONFIGURATION,
    success: types.UPDATE_PSP_CONFIGURATION_SUCCESS,
    failure: types.UPDATE_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const deletePSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.DELETE_PSP_CONFIGURATION_REQUEST });
  let url = '';
  if (data.id !== -1 && data.propertyId) {
    url = `${urls.GET_PSP_CONFIGURATION}id=${data.id}&propertyId=${data.propertyId}`;
  } else if (data.id === -1) {
    url = `${urls.GET_PSP_CONFIGURATION}id=${data.id}`;
  }
  del({
    url,
    success: types.DELETE_PSP_CONFIGURATION_SUCCESS,
    failure: types.DELETE_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getPSPConfigurationByIdRequest = data => dispatch => {
  dispatch({ type: types.GET_PSP_CONFIGURATION_BY_ID_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.GET_PSP_CONFIGURATION}id=${data.id}&isMetadataRequired=${data.is_meta_data_required}`;
  }
  get({
    url,
    success: types.GET_PSP_CONFIGURATION_BY_ID_SUCCESS,
    failure: types.GET_PSP_CONFIGURATION_BY_ID_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const deleteRoutePSPConfigurationRequest = data => dispatch => {
  dispatch({ type: types.DELETE_ROUTE_PSP_CONFIGURATION_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.DELETE_ROUTE_PSP_CONFIGURATION}?pspId=${data.provider_id}&id=${data.id}`;
  }
  del({
    url,
    success: types.DELETE_ROUTE_PSP_CONFIGURATION_SUCCESS,
    failure: types.DELETE_ROUTE_PSP_CONFIGURATION_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getRoutePSPConfigurationByIdRequest = data => dispatch => {
  dispatch({ type: types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.GET_ROUTE_PSP_CONFIGURATION_BY_ID}id=${data.id}`;
  }
  get({
    url,
    success: types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_SUCCESS,
    failure: types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const updateRoutePSPConfigurationByRouteIdRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST });
  put({
    url: urls.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID,
    success: types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS,
    failure: types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const deleteRoutePSPConfigurationByRouteIdRequest = data => dispatch => {
  dispatch({ type: types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST });
  let url = '';
  if (data) {
    url = `${urls.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID}?pspId=${data.pspId}&id=${data.id}`;
  }
  del({
    url,
    success: types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS,
    failure: types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
