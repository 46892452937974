import $ from 'jquery';
// This file will be used for the common functionalities.

// Preventing  user from inserting spaces in value input
export const handleSpace = e => {
  if (e.which === 32 && e.target.selectionStart === 0) {
    e.preventDefault();
  }
};
// Styiling used for every table heading  element
export function tableHeadingColor() {
  const style = 'tableHeading';
  return style;
}

// Tooltip in case of long length values
export const _onHoverBootstapTableTooltip = fieldValue => fieldValue;

// highlighting row for delete functionality
// Need all four params in order to highlight delete functionality and its change color
export const _onClickDeleteRowStyleDisable = (fieldValue, row) => {
  const style = (row !== undefined && row.deleteKey) ? 'delete-row-css' : '';
  return style;
};

export const _onClickDeleteRowStyleDisableForValueCol = (fieldValue, row) => {
  const style = (row !== undefined && row.deleteKey) ? 'delete-row-css' : 'merchantSetupValueCol';
  return style;
};
export function alertMessageSetTimeOut() {
  $('.alert').slideDown(500, 0);
  setTimeout(() => {
    $('.alert').slideUp(500, 0);
  }, 8000);
}
