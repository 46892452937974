
// This file will be used for the constants.

// Authentication Mode List on the Merchant Configuration
export const authModeListArray = [
  {
    name: 'STRICT',
    id: 1,
  },
  {
    name: 'LOOSE',
    id: 2,
  },
];

// Bootstap Table constants
const ReactBsTable = require('react-bootstrap-table');

export const BootstrapTable = ReactBsTable.BootstrapTable;
export const TableHeaderColumn = ReactBsTable.TableHeaderColumn;

// Constant value for the Please Select text for the first value of the drop down wherever require
export const validationRequiredInputMessage = 'Please Select All Required Values.';

// Active key Value constant for all the Accrdions
export const activeKeyMerchantConfiguration = 1;
export const activeKeyMerchantProperty = 2;
export const activeKeyMerchantUrl = 3;
export const activeKeyValueAddService = 5;

export const activeKeyEnablePaymentGateway = 1;
export const activeKeyAddPaymentGateway = 2;
export const activeKeyConfigurePayementGateway = 3;
export const activeKeyConfigureRouteProperties = 4;

export const activeKeyFx = 1;
export const activeKeyFraudManagement = 2;
export const activeKeySplitPayment = 3;

export const pspListArrayItems = [1, 2, 4, 7, 11, 13];


// On an off status for toggle
export const on = 1;
export const off = 5;

// split payment constant names
export const toggleOnValue = 1;
export const toggleRollback = 'toggleRollback';
export const toggleReoffer = 'toggleReoffer';

// MT Report Title
export const reportTitle = 'Report';
