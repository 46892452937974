import React, { useState, useEffect } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PopSetupEnablePaymentGateway from './EnablePaymentGateway';
import PopSetupAddPaymentGateway from './AddPaymentGateway';
import PopSetupConfigurePaymentGateway from './ConfigurePaymentGateway';
import PopSetupConfigurePaymentRouteProperties from './ConfigurePaymentRouteProperties';
import ClientName from './ClientName';
import PageLoader from '../../../../shared/Loaders/TransparentLoader';
import {
  getClientConfigurationRequest, getPaymentMetaDataRequest,
} from '../../../../../redux/actions/MerchantOnboarding';

function PopSetupComponent(props) {
  const [activeKey, setActiveKey] = useState('0');
  const [popSetup, setpopSetup] = useState('0');
  const [showLoader] = useState(false);

  useEffect(() => {
    props.getPaymentMetaDataRequest();
  }, []);

  useEffect(() => {
    props.getClientConfigurationRequest();
  }, []);

  function onBtnClick(location) {
    props.history.push(`/${location}`);
  }

  const permissions = props.getPermissionObject.data;
  const MerchantOnboardingCreate = permissions && permissions.actions.merchantOnBoarding.view;

  return (
    <main role="main" className="col-md-12 mt3r pt-3 ml-auto">
      {showLoader && <PageLoader />}
      <div className="navlink-button-group">
        {!MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantOnboarding') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantOnboarding')}>
          Merchant Onboarding
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantSetup')}>
          Merchant Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('PopSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('PopSetup')}>
          Pop Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('VasSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('VasSetup')}>
          VAS Setup
        </Button>}
      </div>
      <Accordion activeKey={activeKey}>
        <ClientName />
        <PopSetupEnablePaymentGateway activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setpopSetup(1)} popSetup={popSetup} />
        <PopSetupAddPaymentGateway activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setpopSetup(2)} popSetup={popSetup} />
        <PopSetupConfigurePaymentGateway activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setpopSetup(3)} popSetup={popSetup} />
        <PopSetupConfigurePaymentRouteProperties activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setpopSetup(4)} popSetup={popSetup} />
      </Accordion>
    </main>
  );
}

PopSetupComponent.propTypes = {
  history: PropTypes.object,
  getPermissionObject: PropTypes.object,
  getPaymentMetaDataRequest: PropTypes.object,
  getClientConfigurationRequest: PropTypes.object,
};
const mapStateToProps = state => ({
  getPermissionObject: state.getPermissionObject,
});
export default connect(
  mapStateToProps,
  { getClientConfigurationRequest, getPaymentMetaDataRequest }
)(PopSetupComponent);
