import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: '',
};

export const broadcastResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BROADCAST_REQUEST:
    return { ...state, fetching: true, error: false };
  case types.BROADCAST_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BROADCAST_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const broadcastCreateResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BROADCAST_CREATE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.BROADCAST_CREATE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BROADCAST_CREATE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const editBroadcastResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BROADCAST_EDIT_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.BROADCAST_EDIT_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BROADCAST_EDIT_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const deleteBroadcastResult = (state = initialState, action) => {
  switch (action.type) {
  case types.BROADCAST_DELETE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.BROADCAST_DELETE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.BROADCAST_DELETE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
