import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: '',
};

export const previewPublishResult = (state = initialState, action) => {
  switch (action.type) {
  case types.PREVIEW_PUBLISH_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.PREVIEW_PUBLISH_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.PREVIEW_PUBLISH_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
