/* eslint-disable */
import { toast } from 'react-toastify';

export const toastMessages = {

  promotion: {
    success:
    {
      added: 'Promotion added successfully !',
      updated: 'Promotion updated successfully !',
      deleted: 'Deleted Successfully !',
      captureScheduled: 'Capture operation is scheduled !',
    },
    warn:
    {
      duplicateCode: 'Duplicate promocode',
      pushContentNotConfigured: 'Promotion cannot be scheduled for Push as no content has been configured',
    },
    error:
    {
      failedUpdate: 'Failed to update promotion.Please try again!',
      failedCreate: 'Failed to create promotion .Please try again !',
    },
  },


  event: {
    success:
    {
      added: 'Event added successfully !',
      updated: 'Event updated successfully !',
    },
    warn:
    {
      duplicateCode: 'Duplicate promocode',
      pushContentNotConfigured: 'Event cannot be scheduled for Push as no content has been configured',
    },
    error:
    {
      failedUpdate: 'Failed to update event. Please try again!',
      failedCreate: 'Failed to create event. Please try again !',
    },
  },

  dynamicBanner: {
    success:
    {
      added: 'Banner added successfully !',
      updated: 'Banner updated successfully !',
      deleted: 'Banner deleted Successfully !',
    },
    error:
    {
      mandetory: 'Banner name and one of banner image is mandatory',
      apiFailed: 'Something went wrong',
    },
  },


  broadcast: {
    success:
    {
      added: 'Topic added successfully !',
      updated: 'Topic updated successfully !',
      deleted: 'Topic deleted successfully !',
    },
    warn:
    {

      duplicateCode: 'Duplicate topic name',

    },
    error:
    {
      failedUpdate: 'Failed to update topic.Please try again !',
      failedCreate: 'Failed to create topic .Please try again !',
      failedDelete: 'Failed to delete topic.Please try again !',
    },
  },


  autoTrigger: {

    success:
    {
      added: 'AutoTrigger configuration added successfully',
      updated: 'AutoTrigger configuration updated successfully',
      deleted: 'AutoTrigger configuration deleted successfully',
    },
    warn:
    {

      configurationAlready: 'Configurations already present',

    },
    error:
    {
      failedUpdate: 'Failed to update autoTrigger.Please try again!',
      failedCreate: 'Failed to create autoTrigger .Please try again !',
      failedDelete: 'Failed to delete autoTrigger.Please try again !',
    },

  },

  FraudManagementRemove: {
    warn:
    {
      deleted: 'Please remove the associated rules before performing this action.',
    },
  },


  manualTrigger: {

    success:
    {
      added: 'ManualTrigger  created successfully',
      updated: 'ManualTrigger  updated successfully',
      deleted: 'ManualTrigger  deleted successfully',
    },
    warn:
    {

      configurationAlready: 'Configurations already present',

    },
    error:
    {
      failedUpdate: 'Failed to update manualTrigger. Please try again!',
      failedCreate: 'Failed to create manualTrigger. Please try again!',
      failedDelete: 'Failed to delete manualTrigger. Please try again!',
    },

  },

  subscription: {
    success: {
      added: 'Subscription added successfully.',
      updated: 'Subscription updated successfully.',
      unSubscribed: 'Successfully unsubscribed.',
    },
    warn: {
      alreadyExist: 'Subscription already exist with given name.',
    },
    error: {
      failedSubscribe: 'Failed to subscribe. Please try again later.',
      failedUnsubscribe: 'Failed to unsubscribe.',
      failedAdd: 'Failed to add subscription. Please try again later.',
      failedUpdate: 'Failed to update subscription. Please try again later.',
      someFailedUnsubscribe: 'Failed to unsubscribe some of the subscriptions.',
    },
  },

  filter: {
    success: {
      added: 'Filter added successfully.',
      updated: 'Filter updated successfully.',
      deleted: 'Filter deleted successfully.',
    },
    warn: {

    },
    error: {
      failedAdd: 'Failed to add filter.',
      failedUpdate: 'Failed to update filter.',
      failedLoad: 'Failed to load filer.',
      failedDelete: 'Failed to delete.',
    },
  },
  report: {
    error: {
      failedLoad: 'Failed to load',
    },
  },
  geoFence: {
    success: {
      created: 'Geofence created successfully',
      deleted: 'Geofence deleted successfully',
    },
    error: {
      failedCreate: 'Failed to create Geofence',
      duplicateName: 'Duplicate geofence name',
      failedDelete: 'Failed to delete Geofence',
    },
    warn: {
      delete: 'Can not delete geofence as it is active or scheduled with promotion',
    },
  },
  transactionManagement: {
    success: {
      captured: 'Successfully Captured',
      refunded: 'Successfully Refunded',
      refundRequested: 'Payment Refund Requested',
      canceled: 'Successfully Cancelled',
      captureScheduled: 'Capture operation is scheduled !',
    },
    error: {
      errorOccurred: 'An error occurred while performing operation',
      declinedByPSP: 'Operation declined by PSP',
      resultNotFound: 'Result not found',
    },
    warn: {
      amountHigh: 'Amount too high',
      amountLow: 'Amount too low',
    },
  },

  downloadTransaction: {
    success: {
      startProgress: 'Manage Transaction Report download is in progress',
    },
    error: {
      failed: 'Failed to download MT Report',
      requestFailed: 'Please try after some time',
    },
  },


  notificationEngine: {
    success: {
      created: 'Notification created successfully',
      updated: 'Notification updated successfully',
    },
    error: {
      failed: 'Failed to create Notification',
      failedCreate: 'Notification Content already configured for this product',
      failedUpdate: 'Failed to update notification',
      failedRetrieve: 'Failed to get Notification Details',
    },
  },

  screenConfigurations: {
    success: {
      deleted: 'Screen configuration deleted successfully',
      added: 'Screen configuration added sucessfully',
    },
    error: {
      failedDelete: 'Failed to delete screen configuration',
      FailedAdd: 'Failed to add screen configuration',
    },
    warn: {
      alreadyExist: 'Screen configuration already exist',
    },
  },
  routingConfigurations: {
    success: {
      added: 'Rule created sucessfully',
      deleted: 'Rule deleted  sucessfully',
      updated: 'Rule updated  sucessfully',
    },
    error: {
      DuplicateName: 'Error occured while creating rule due to duplicate name',
      DuplicatePriority: 'Error occured while creating rule due to duplicate priority',
      failedDeleted: 'Failed to delete rule',
      failedUpdated: 'Failed to update rule',
      invalidRule: 'Invalid Rule Configuration',
      fail: 'Something went wrong',
    },
  },
  merchantServices: {
    success: {
      added: 'Configurations added sucessfully !',
      deleted: 'Configurations deleted successfully !',
      updated: 'Configurations updated  sucessfully !',
      reset: 'Configurations reset successfully !',
    },
    error: {
      added: 'Failed to add Configurations.Please try again !',
      deleted: 'Failed to delete configurations. Please try again !',
      updated: 'Failed to update configurations, Please try again !',
      backendError: 'No response returned from external system',
    },
  },
  FXConfigurations: {
    success: {
      added: 'FX Configuration created sucessfully',
      updated: 'FX Configuration updated sucessfully',
      view: 'FX Configuration fetched sucessfully',
    },
    error: {
      FailedAdd: 'Failed to create FX Configuration',
      alreadyExist: 'Configurations for this service type already exist for ',
      FailedView: 'Failed to get FX Configuration',
      FailedUpdate: 'Failed to update FX Configuration',
    },
  },
  EventnotificationConfigurations: {
    success: {
      added: 'Filter Configuration created sucessfully',
      updated: 'Filter Configuration updated sucessfully',
      deleted: 'Filter Configuration deleted sucessfully',
    },
    error: {
      FailedAdd: 'Failed to create Filter Configuration',
      FailedUpdate: 'Failed to update Filter Configuration',
      invalidConfig: 'Invalid Filter Configuration',
      failedDeleted: 'Failed to delete filter-configuration',
    },
  },
};


export const notify = (type, message, closeTime) => {
  const options = {
    success: {},
    warn: {autoClose: 4000},
    error: { autoClose: 4000 },
  };

  toast[type](message, options[type]);
};

export const dismissAllToast = () => {
  toast.dismiss();
};
