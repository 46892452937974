import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux/store';

// styles
import './static/style/main.scss';
import SessionTimer from './app/shared/IdleTimer';
// custom components
import App from './app';

const Main = (
  <div>
    <SessionTimer />
    <App />
  </div>
);

export const Root = () => (
  <Provider store={store}>
    <BrowserRouter>
      {Main}
    </BrowserRouter>
  </Provider>
);

if (!module.hot) render(<Root />, document.querySelector('react'));
