export function fraudConfigurationList(listType, addonConfigurationList) {
  const fraud = [];
  if (listType === 'preAuth') {
    addonConfigurationList.forEach(preAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        country: preAddonConfig.country_name,
        currency: preAddonConfig.currency_code,
        paymentMethod: preAddonConfig.pm_name,
        country_id: 0,
        currency_id: 0,
        pm_id: 0,
        provider_id: preAddonConfig.provider_id,
        providerName: preAddonConfig.provider_name,
        action: '',
        deleteKey: false,
        existFlag: true,
        preAuth: true,
      });
    });
  } else if (listType === 'postAuth') {
    addonConfigurationList.forEach(preAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        country: preAddonConfig.country_name,
        currency: preAddonConfig.currency_code,
        paymentMethod: preAddonConfig.pm_name,
        country_id: 0,
        currency_id: 0,
        pm_id: 0,
        provider_id: preAddonConfig.provider_id,
        providerName: preAddonConfig.provider_name,
        action: '',
        deleteKey: false,
        existFlag: true,
        postAuth: true,
      });
    });
  } else if (listType === '3DS') {
    addonConfigurationList.forEach(preAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        paymentMethod: preAddonConfig.pm_name,
        pm_id: preAddonConfig.pm_id,
        provider_id: preAddonConfig.provider_id,
        providerName: preAddonConfig.provider_name,
        version_id: preAddonConfig.version_id,
        versionName: preAddonConfig.version_name,
        country_id: preAddonConfig.country_id,
        country: preAddonConfig.country_name,
        currency_id: preAddonConfig.currency_id,
        currency: preAddonConfig.currency_code,
        account_id: preAddonConfig.account_id,
        storeFronts: preAddonConfig.account_name,
        action: '',
        deleteKey: false,
        existFlag: true,
        threeDS: true,
      });
    });
  }
  return fraud;
}
