import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import { Accordion, Button } from 'react-bootstrap';
import globals from '../../../../../redux/constants/globals';
import {
  handleSpace, tableHeadingColor, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut,
  _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisableForValueCol,
} from '../Helper';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import {
  getClientConfigurationRequest, updateClientConfigurationRequest, deleteClientConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import arrowdown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyMerchantProperty, validationRequiredInputMessage } from '../Constants';

function MerchantProperty(props) {
  const reset = {
    propertyNameId: '',
    propertyCategory: '',
    propertyName: '',
    propertyValue: '',
  };
  const [merchantInputField, setMerchantInputField] = useState(reset);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [propertyName, setPropertyName] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [propertyCategoryListArray, setpropertyCategoryListArray] = useState([]);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [validationError, setValidationError] = useState({
    propertyCategoryDropdownValue: false,
    propertyNameDropdownValue: false,
    propertyValueTextValue: false,
  });


  function clearState() {
    const existingMerchantPropertyData = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.property_details.property_detail;
    const initialTableData = [];
    let count = 1;
    if (existingMerchantPropertyData) {
      // Show the existing data
      existingMerchantPropertyData.forEach(keyExistingArray => {
        keyExistingArray.properties.property.forEach(keyProperty => {
          if (keyProperty.value) {
            initialTableData.push({
              key: count,
              propertyCategory: keyExistingArray.property_sub_category,
              propertyNameId: keyProperty.id,
              propertyName: keyProperty.name,
              propertyValue: keyProperty.value,
              action: '',
              deleteKey: false,
              existFlag: false,
              hybrid: true,
            });
            count += 1;
          }
        });
      });
    }

    const data = { ...merchantInputField };
    data.propertyNameId = '';
    data.propertyCategory = '';
    data.propertyName = '';
    data.propertyValue = '';
    setMerchantInputField(data);
    setTableData(initialTableData);

    // Removing the existing validation message in case of cancel button click
    const errorValue = { ...validationError };
    errorValue.propertyCategoryDropdownValue = false;
    errorValue.propertyNameDropdownValue = false;
    errorValue.propertyValueTextValue = false;
    setValidationError(errorValue);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // toast message
    notify('success', toastMessages.merchantServices.success.reset);
  }

  // Wait for Sucess result of updateClientConfigurationRequest then call getClientConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyMerchantProperty && props.updateClientConfigurationResult.fetched && !props.updateClientConfigurationResult.fetching && props.updateClientConfigurationResult.requestStatus) {
      if (props.updateClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.updated);
      } else {
        notify('success', toastMessages.merchantServices.error.updated);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.updateClientConfigurationResult]);

  useEffect(() => {
    if (props.activeKey === activeKeyMerchantProperty && props.deleteClientConfigurationResult.fetched && !props.deleteClientConfigurationResult.fetching && props.deleteClientConfigurationResult.requestStatus) {
      if (props.deleteClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.deleted);
      } else {
        notify('error', toastMessages.merchantServices.error.deleted);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.deleteClientConfigurationResult]);

  // Show Loader still get getClientConfigurationResult
  useEffect(() => {
    if (props.getClientConfigurationResult.fetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  });

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'propertyValue') {
      value = event.target.value;
      setMerchantInputField({ ...merchantInputField, [field]: value });
    } else if (field === 'propertyCategory') {
      // fetch the data from the system metadata for the dropdown listing
      const existingMerchantPropertyData = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.property_details.property_detail;

      const requestData = [];
      // Show the existing data
      existingMerchantPropertyData.forEach(keyExistingArray => {
        keyExistingArray.properties.property.forEach(keyProperty => {
          requestData.push({
            property_category: keyExistingArray.property_sub_category,
            property_name_id: keyProperty.id,
            property_name: keyProperty.name,
          });
        });
      });

      const serviceRequestObject = getPropertyCategoryListData(requestData);
      const filteredPropertyNameArray = serviceRequestObject.filter(service => service.propertyCategory === event.target.value)[0].propertyNames;
      let filteredTableData = [];
      if (tableData.length > 0) {
        filteredTableData = tableData.filter(category => category.propertyCategory === event.target.value);
      } else {
        filteredTableData = requestData.filter(category => category.propertyCategory === event.target.value);
      }

      const filteredTableDataResult = filteredTableData.map(a => a.propertyNameId);
      const filteredExistingMerchantPropertyData = filteredPropertyNameArray.filter(category => filteredTableDataResult.includes(category.propertyNameId) === false);

      setPropertyName(filteredExistingMerchantPropertyData);
      value = event.target.value;
      setMerchantInputField({ ...merchantInputField, [field]: value });
    } else if (field === 'propertyName') {
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      const propertyNameIdKey = 'propertyNameId';
      setMerchantInputField({ ...merchantInputField, [field]: value, [propertyNameIdKey]: parseInt(event.target.value, 10) });
    }
  };

  const initTableData = (propertyNameIdParam, propertyCategoryParam, propertyNameParam, propertyValueParam) => {
    const count = tableData.length + 1;
    tableData.push({
      key: count,
      propertyNameId: propertyNameIdParam,
      propertyCategory: propertyCategoryParam,
      propertyName: propertyNameParam,
      propertyValue: propertyValueParam,
      action: '',
      deleteKey: false,
      existFlag: false,
      hybrid: true,
    });
  };

  const getPropertyCategoryListData = requestData => {
    // Code to show the property types which are associated with selected property category
    const serviceRequestObject = [];

    requestData.forEach(key => {
      const propertyNamesObject = [];

      const found = serviceRequestObject.some(el => el.propertyCategory === key.property_category);
      if (!found) {
        propertyNamesObject.push({
          propertyNameId: key.property_name_id,
          propertyName: key.property_name,
        });

        serviceRequestObject.push({
          propertyCategory: key.property_category,
          propertyNames: propertyNamesObject,
        });
      } else {
        const serviceRequestObjectLength = serviceRequestObject.length - 1;
        serviceRequestObject[serviceRequestObjectLength].propertyNames.push({
          propertyNameId: key.property_name_id,
          propertyName: key.property_name,
        });
      }
    });
    return serviceRequestObject;
  };

  const getInitialStateData = () => {
    // Set the initial table data which is stored in the client configuration
    const existingMerchantPropertyData = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.property_details.property_detail;

    const requestData = [];
    if (existingMerchantPropertyData && !apiCall) {
      setApiCall(true);
      // Show the existing data
      existingMerchantPropertyData.forEach(keyExistingArray => {
        keyExistingArray.properties.property.forEach(keyProperty => {
          if (!keyProperty.value) {
            requestData.push({
              property_category: keyExistingArray.property_sub_category,
              property_name_id: keyProperty.id,
              property_name: keyProperty.name,
            });
          } else {
            initTableData(keyProperty.id, keyExistingArray.property_sub_category, keyProperty.name, keyProperty.value);
          }
        });
        setLoader(false);
      });

      const serviceRequestObject = getPropertyCategoryListData(requestData);
      setpropertyCategoryListArray(serviceRequestObject);
    }
  };

  // common function for initial data
  getInitialStateData();

  const onClickAddButton = () => {
    if (merchantPropertyValidation()) {
      const count = tableData.length + 1;
      tableData.push({
        key: count,
        propertyNameId: merchantInputField.propertyNameId,
        propertyCategory: merchantInputField.propertyCategory,
        propertyName: merchantInputField.propertyName,
        propertyValue: merchantInputField.propertyValue.trim(),
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });
      setTableData(tableData);
      const data = { ...merchantInputField };
      data.propertyNameId = '';
      data.propertyCategory = '';
      data.propertyName = '';
      data.propertyValue = '';
      setPropertyName([]);
      setMerchantInputField(data);

      // Make save button enabled
      setSaveButtonDisabledFlag(0);
    }
  };

  function merchantPropertyValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (merchantInputField.propertyCategory === '' || merchantInputField.propertyCategory === 'Please Select') {
      errorValue.propertyCategoryDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (merchantInputField.propertyName === '' || merchantInputField.propertyName === 'Please Select') {
      errorValue.propertyCategoryDropdownValue = false;
      errorValue.propertyNameDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (merchantInputField.propertyValue === '') {
      errorValue.propertyCategoryDropdownValue = false;
      errorValue.propertyNameDropdownValue = false;
      errorValue.propertyValueTextValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else {
      errorValue.propertyCategoryDropdownValue = false;
      errorValue.propertyNameDropdownValue = false;
      errorValue.propertyValueTextValue = false;
    }
    setValidationError(errorValue);
    return validate;
  }

  const deleteRow = row => {
    let filter = '';
    if (row.propertyNameId) {
      filter = getUnique(tableData, 'key');
      if (filter.length !== tableData.length) {
        setTableData(filter);
      } else {
        filter = tableData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setTableData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };
  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const saveMerchantPropertyResults = () => {
    const categorySelectedListArray = [];
    const updatedTableData = [];
    let count = 1;
    if (tableData.length !== 0) {
      const tableDataDeleteKeyArray = tableData.filter(deleteKeyIndex => deleteKeyIndex.deleteKey === false);
      if (tableDataDeleteKeyArray.length === 0) {
        const selectConfigData = {
          p_id: -1,
          client_id: globals.clientId,
        };
        props.deleteClientConfigurationRequest(selectConfigData);
        setTableData([]);
      } else {
        tableData.forEach(key => {
          if (key.deleteKey === false) {
            const selectPropertyCategoryData = {
              id: key.propertyNameId,
              value: key.propertyValue,
              enabled: true,
            };
            categorySelectedListArray.push(selectPropertyCategoryData);

            updatedTableData.push({
              key: count,
              propertyNameId: key.propertyNameId,
              propertyCategory: key.propertyCategory,
              propertyName: key.propertyName,
              propertyValue: key.propertyValue,
              enabled: true,
              action: '',
              deleteKey: false,
              existFlag: false,
              hybrid: true,
            });
            count += 1;
          }
        });

        const requestBody = {
          id: globals.clientId,
          properties: categorySelectedListArray,
        };
        props.updateClientConfigurationRequest(requestBody);
        setTableData(updatedTableData);
      }

      // Make save button disabled
      setSaveButtonDisabledFlag(1);
    }
  };

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const action = (cell, row) => (
    <div>
      { (row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );

  // Need all four params in order to highlight delete functionality and its change color
  const viewMode = props.getFormMode.result === 'View';
  return (
    <div className="card">
      {props.activeKey === activeKeyMerchantProperty && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = tableData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setTableData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveMerchantPropertyResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingTwo">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyMerchantProperty ? '' : activeKeyMerchantProperty)} eventKey={activeKeyMerchantProperty}>
          <h6>
            Merchant Properties
            <img src={arrowdown} className="float-right" alt="" />
          </h6>

        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyMerchantProperty}>
        <div className="card-body">
          <div className="mt-4">
            <Form className="row">
              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Category</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'propertyCategory')}
                  value={merchantInputField.propertyCategory}
                  disabled={viewMode}
                >
                  <option disabled selected value="">Please Select</option>
                  {propertyCategoryListArray && propertyCategoryListArray.map(key => <option value={key.propertyCategory}>{key.propertyCategory}</option>)}
                </Form.Control>
                {(validationError.propertyCategoryDropdownValue || validationError.propertyNameDropdownValue || validationError.propertyValueTextValue) && <div className="alert alert-danger">
                  {validationRequiredInputMessage}
                </div>}
              </Col>

              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Property Name</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'propertyName')}
                  value={merchantInputField.propertyNameId}
                  disabled={viewMode}
                >
                  <option disabled selected value="">Please Select</option>
                  {propertyName.map(key => <option value={key.propertyNameId}>{key.propertyName}</option>)}
                </Form.Control>
              </Col>

              <Col className="form-group" md={4}>
                <Form.Label className="mandatory">Value</Form.Label>
                {/* <Form.Control
                  type="text"
                  onKeyDown={handleSpace}
                  // placeholder="Value"
                  name="propertyValue"
                  onChange={e => inputOnChange(e, 'propertyValue')}
                  value={merchantInputField.propertyValue}
                  disabled={viewMode}

                /> */}
                <Form.Control
                  as="textarea"
                  rows={5}
                  onKeyDown={handleSpace}
                  name="propertyValue"
                  onChange={e => inputOnChange(e, 'propertyValue')}
                  value={merchantInputField.propertyValue}
                  disabled={viewMode}

                />
              </Col>
              <div className="col">
                <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                <button
                  type="button"
                  className="btn btn-sm float-left btn-outline-primary mt-1"
                  onClick={() => onClickAddButton()}
                >
                  <i className="fas fa-plus" />
                  &nbsp;&nbsp;Add
                </button>
              </div>
              <div className="row">
                {(!showLoader) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  <BootstrapTable
                    data={tableData}
                  >
                    <TableHeaderColumn dataField="key" hidden isKey>No.</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyCategory" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Category</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyName" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Property Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyValue" dataAlign="left" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisableForValueCol}>Value</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <Col className="pt-2 pb-2 mt-3" md={12}>
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveMerchantPropertyResults()}
                  >
                    Save
                  </button>}

                  <button
                    type="button"
                    className="btn btn-outline-primary float-right btn-sm  mr-3"
                    disabled={viewMode}
                    onClick={() => clearState()}
                  >
                    Cancel
                  </button>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}

MerchantProperty.propTypes = {
  getClientConfigurationRequest: PropTypes.object,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
  getClientConfigurationResult: PropTypes.object,
  updateClientConfigurationRequest: PropTypes.func,
  updateClientConfigurationResult: PropTypes.object,
  getFormMode: PropTypes.any,
  deleteClientConfigurationRequest: PropTypes.func,
  deleteClientConfigurationResult: PropTypes.object,
};
const mapStateToProps = state => ({
  getClientConfigurationResult: state.getClientConfigurationResult,
  updateClientConfigurationResult: state.updateClientConfigurationResult,
  getFormMode: state.getFormMode,
  deleteClientConfigurationResult: state.deleteClientConfigurationResult,
});

export default connect(
  mapStateToProps,
  { getClientConfigurationRequest, updateClientConfigurationRequest, deleteClientConfigurationRequest }
)(MerchantProperty);
