/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import {
  Route, Switch, Redirect,
} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { permissionsObj } from './shared/utils/Permissions';
import { setPermissionObject } from '../redux/actions/Permissions';
import Notify from './shared/ToastNotification';
import { arrLength } from '../redux/constants/urls';
import AppLoader from './components/common/AppLoader';
import { clearBanner } from './components/pages/DynamicBanner/Redux/actions';
import HomePage from './components/pages/Home';
import ViewReport from './components/pages/Reports/ViewReport';
import { pageNavigationURL } from '../redux/constants/urls';
import SessionExpiredPopup from './shared/utils/SessionExpiredPopup';
import PopSetupComponent from './components/pages/MerchantOnboarding/PopSetup';
import VasSetupComponent from './components/pages/MerchantOnboarding/VasSetup';
import MerchantSetupComponent from './components/pages/MerchantOnboarding/MerchantSetup/MerchantSetup';

const NotFoundPage = Loadable({ loader: () => import(/* webpackChunkName: "header" */ './components/pages/NotFound'), loading: AppLoader });
const Header = Loadable({ loader: () => import(/* webpackChunkName: "header" */ './components/header/newHeader'), loading: AppLoader });
const LoginPage = Loadable({ loader: () => import(/* webpackChunkName: "login" */ './components/pages/login'), loading: AppLoader });
const ResetPassword = Loadable({ loader: () => import(/* webpackChunkName: "resetPassword" */ './components/pages/resetPassword'), loading: AppLoader });
const MerchantList = Loadable({ loader: () => import(/* webpackChunkName: "merchantList" */ './components/merchantList/merchant-list'), loading: AppLoader });
const TransactionDashboard = Loadable({ loader: () => import(/* webpackChunkName: "transactionDashboard" */ './components/pages/Dashboards/transactionDashboard'), loading: AppLoader });
const RevenueDashboard = Loadable({ loader: () => import(/* webpackChunkName: "revenueDashboard" */ './components/pages/Dashboards/revenueDashboard'), loading: AppLoader });
const FraudCheckDashboard = Loadable({ loader: () => import(/* webpackChunkName: "fraudCheckDashboard" */ './components/pages/Dashboards/fraudCheckDashboard'), loading: AppLoader });
const ProfileDashboard = Loadable({ loader: () => import(/* webpackChunkName: "profileDashboard" */ './components/pages/profileDashboard'), loading: AppLoader });
const PromotionsDashboard = Loadable({ loader: () => import(/* webpackChunkName: "promotionsDashboard" */ './components/pages/promotionsDashboard'), loading: AppLoader });
const NotificationDashboard = Loadable({ loader: () => import(/* webpackChunkName: "NotificationDashboard" */ './components/pages/Dashboards/NotificationDashboard'), loading: AppLoader });
const KPIDashboard = Loadable({ loader: () => import(/* webpackChunkName: "kpiDashboard" */ './components/pages/kpiDashboard'), loading: AppLoader });
const Reports = Loadable({ loader: () => import(/* webpackChunkName: "Reports" */ './components/pages/Reports'), loading: AppLoader });
const Promotions = Loadable({ loader: () => import(/* webpackChunkName: "Promotions" */ './components/pages/Promotions'), loading: AppLoader });
const CreatePromotions = Loadable({ loader: () => import(/* webpackChunkName: "createPromotion" */ './components/pages/Promotions/createPromotion'), loading: AppLoader });
const CreateEvents = Loadable({ loader: () => import(/* webpackChunkName: "createEvents" */ './components/pages/Events/createEvents'), loading: AppLoader });
const viewEvents = Loadable({ loader: () => import(/* webpackChunkName: "viewEvents" */ './components/pages/Events/viewEvents'), loading: AppLoader });
const PaymentLink = Loadable({ loader: () => import(/* webpackChunkName: "paymentLink" */ './components/pages/PBL/PaymentLinkLanding'), loading: AppLoader });
const PayByLink = Loadable({ loader: () => import(/* webpackChunkName: "payByLink" */ './components/pages/pay-by-link-landing'), loading: AppLoader });
const ManualTrigger = Loadable({ loader: () => import(/* webpackChunkName: "manualTrigger" */ './components/pages/manualTrigger'), loading: AppLoader });
const AutoTrigger = Loadable({ loader: () => import(/* webpackChunkName: "autoTrigger" */ './components/pages/autoTrigger'), loading: AppLoader });
const MerchantOnboardingLocale = Loadable({ loader: () => import(/* webpackChunkName: "mobLocale" */ './components/pages/merchantOnboardingLocale'), loading: AppLoader });
const MerchantOnboardingCountry = Loadable({ loader: () => import(/* webpackChunkName: "mobCountry" */ './components/pages/merchantOnboardingCountry'), loading: AppLoader });
const MerchantOnboardingProducts = Loadable({ loader: () => import(/* webpackChunkName: "mobProducts" */ './components/pages/merchantOnboardingProducts'), loading: AppLoader });
const DynamicRouting = Loadable({ loader: () => import(/* webpackChunkName: "dynamicRouting" */ './components/pages/DynamicRouting/dynamicRoutingLanding'), loading: AppLoader });
const MerchantOnboarding = Loadable({ loader: () => import(/* webpackChunkName: "dynamicRouting" */ './components/pages/DynamicRouting/MerchantOnboarding'), loading: AppLoader });
const EventNotification = Loadable({ loader: () => import(/* webpackChunkName: "EventNotification" */ './components/pages/EventNotification/eventNotification'), loading: AppLoader });
const CreateRule = Loadable({ loader: () => import(/* webpackChunkName: "createRules" */ './components/pages/DynamicRouting/ConfigureRule'), loading: AppLoader });
const CreatePreAuthFraudRule = Loadable({ loader: () => import(/* webpackChunkName: "CreatePreAuthFraudRule" */ './components/pages/DynamicRouting/CreatePreAuthFraudRule'), loading: AppLoader });
const CreatePostAuthFraudRule = Loadable({ loader: () => import(/* webpackChunkName: "CreatePostAuthFraudRule" */ './components/pages/DynamicRouting/CreatePostAuthFraudRule'), loading: AppLoader });


const CreateFOP = Loadable({ loader: () => import(/* webpackChunkName: "createFOP" */ './components/pages/DynamicRouting/FOP'), loading: AppLoader });
const CreatePreAuth = Loadable({ loader: () => import(/* webpackChunkName: "CreatePreAuth" */ './components/pages/DynamicRouting/PreAuth'), loading: AppLoader });
const CreatePostAuth = Loadable({ loader: () => import(/* webpackChunkName: "CreatePostAuth" */ './components/pages/DynamicRouting/PostAuth'), loading: AppLoader });

const CreateEventNotification = Loadable({ loader: () => import(/* webpackChunkName: "createFOP" */ './components/pages/EventNotification/CreateEventNotification'), loading: AppLoader });
const CreateInstallments = Loadable({ loader: () => import(/* webpackChunkName: "createFOP" */ './components/pages/DynamicRouting/Installments'), loading: AppLoader });
const StaticRoute = Loadable({ loader: () => import(/* webpackChunkName: "Route" */ './components/pages/DynamicRouting/Route'), loading: AppLoader });
const FX = Loadable({ loader: () => import(/* webpackChunkName: "fx" */ './components/pages/FX'), loading: AppLoader });
const ConfigureServices = Loadable({ loader: () => import(/* webpackChunkName: "configureServices" */ './components/pages/configure-services'), loading: AppLoader });
const HostedPaymentPage = Loadable({ loader: () => import(/* webpackChunkName: "hppConfiguration" */ './components/pages/hppConfiguration'), loading: AppLoader });
const Broadcast = Loadable({ loader: () => import(/* webpackChunkName: "Broadcast" */ './components/pages/Broadcast'), loading: AppLoader });
const DynamicBanner = Loadable({ loader: () => import(/* webpackChunkName: "Broadcast" */ './components/pages/DynamicBanner'), loading: AppLoader });
const NotificationConfigure = Loadable({ loader: () => import(/* webpackChunkName: "Notification Configure" */ './components/pages/NotificationEngine/Configure'), loading: AppLoader });
const NotificationEngine = Loadable({ loader: () => import(/* webpackChunkName: "Notification Engine" */ './components/pages/NotificationEngine'), loading: AppLoader });
const TravelBank = Loadable({ loader: () => import(/* webpackChunkName: "Travel Bank" */ './components/pages/travelBankConfiguration'), loading: AppLoader });
const ScreenConfiguration = Loadable({ loader: () => import(/* webpackChunkName: "Screen Configuration" */ './components/pages/ScreenConfigurations'), loading: AppLoader });
const MySubscriptions = Loadable({ loader: () => import(/* webpackChunkName: "Screen Configuration" */ './components/pages/Reports/MySubscriptions'), loading: AppLoader });
const SalesDashboard = Loadable({ loader: () => import(/* webpackChunkName: "ProductSales Dashboard" */ './components/pages/productSalesDashboard'), loading: AppLoader });
const ManageTransaction = Loadable({ loader: () => import(/* webpackChunkName: " Manage Transaction" */ './components/pages/ManageTransaction'), loading: AppLoader });
const GeoFence = Loadable({ loader: () => import(/* webpackChunkName: "ProductSales Dashboard" */ './components/pages/GeoFence'), loading: AppLoader });
const CreateGeoFence = Loadable({ loader: () => import(/* webpackChunkName: "ProductSales Dashboard" */ './components/pages/GeoFence/CreateGeoFence'), loading: AppLoader });
const NotificationChannelType = Loadable({ loader: () => import(/* webpackChunkName: "Screen Configuration" */ './components/pages/notificationChannelType'), loading: AppLoader });
const SupportPage = Loadable({ loader: () => import(/* webpackChunkName: "ProductSales Dashboard" */ './components/pages/Home/suport'), loading: AppLoader });
// const MerchantSetup = Loadable({ loader: () => import(/* webpackChunkName: "dynamicRouting" */ './components/pages/MerchantOnboarding/MerchantSetup/MerchantSetup'), loading: AppLoader });
const Profile = Loadable({ loader: () => import(/* webpackChunkName: "dynamicRouting" */ './components/pages/Home/profile'), loading: AppLoader });
const LogOutApp = Loadable({ loader: () => import(/* webpackChunkName: "dynamicRouting" */ './components/pages/sso/LogOut'), loading: AppLoader });


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loggedIn: true,
      permissionSet: this.props.getPermissionObject.data,
    };
    // Set the permissions
    this.props.setPermissionObject(permissionsObj());

    // Set client confing data
    const clientConfigResponse = this.props.clientConfigResult.result;

    if (clientConfigResponse && clientConfigResponse.configurations) {
      localStorage.setItem('clientConfiguration', JSON.stringify(clientConfigResponse.configurations));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clientConfigResult.result !== this.props.clientConfigResult.result) {
      const response = nextProps.clientConfigResult.result;

      if (response && response.configurations) {
        localStorage.setItem('clientConfiguration', JSON.stringify(response.configurations));
      }
    }
    if (nextProps.SystemMetaDataResult.result !== this.props.SystemMetaDataResult.result) {
      const response = nextProps.SystemMetaDataResult.result;
       if (response) {
        localStorage.setItem('systemMetaDataResult', JSON.stringify(response));
      }
    }
    if (nextProps.getPermissionObject.data !== this.props.getPermissionObject.data) {
      this.setState({ permissionSet: nextProps.getPermissionObject.data });
    }
  }

  clearBanner() {
    this.props.clearBanner();
  }

  render() {
    const permissions = this.state.permissionSet.menu;
    const actionPermissions = this.state.permissionSet.actions;
    const routes = [

      {
        path: pageNavigationURL.login,
        exact: true,
        component: LoginPage,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.resetPassword,
        component: ResetPassword,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.profile,
        component: Profile,
        hasPermission: true,
      },{
        path: pageNavigationURL.loguot,
        component: LogOutApp,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.support,
        component: SupportPage,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.createRule,
        component: CreateRule,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.transactionsDashboard,
        component: TransactionDashboard,
        hasPermission: permissions && permissions.dashboards.transactions,
      },
      {
        path: pageNavigationURL.revenueDashboard,
        component: RevenueDashboard,
        hasPermission: permissions && permissions.dashboards.revenue,
      },
      {
        path: pageNavigationURL.fraudCheckDashboard,
        component: FraudCheckDashboard,
        hasPermission: permissions && permissions.dashboards.fraudcheck,
      },
      {
        path: pageNavigationURL.promotionsDashboard,
        component: PromotionsDashboard,
        hasPermission: permissions && permissions.dashboards.promotion,
      },
      {
        path: pageNavigationURL.notificationDashboard,
        component: NotificationDashboard,
        hasPermission: permissions && permissions.dashboards.notification,
      },
      // {
      //  path: pageNavigationURL.productDashboard,
      //   component: ProductDashboard,
      //   hasPermission: permissions && permissions.dashboards.product.menu,
      // },
      {
        path: pageNavigationURL.profileDashboard,
        component: ProfileDashboard,
        hasPermission: permissions && permissions.dashboards.profile,
      },
      {
        path: pageNavigationURL.KPIsDashboard,
        component: KPIDashboard,
        hasPermission: permissions && permissions.dashboards.KPIDashboard,
      },
      {
        path: pageNavigationURL.reports,
        component: Reports,
        hasPermission: permissions && permissions.reports.viewReports,
      },
      {
        path: pageNavigationURL.promotionsAndEvents,
        component: Promotions,
        hasPermission: permissions && permissions.vantage.promotionsAndEvents,
      },
      {
        path: pageNavigationURL.createPromotions,
        component: CreatePromotions,
        hasPermission: actionPermissions && actionPermissions.promotions.create,
      },
      {
        path: pageNavigationURL.viewPromotions,
        component: CreatePromotions,
        hasPermission: actionPermissions && actionPermissions.promotions.view,
      },
      {
        path: pageNavigationURL.createEvents,
        component: CreateEvents,
        hasPermission: actionPermissions && actionPermissions.events.create,
      },
      {
        path: pageNavigationURL.viewEvents,
        component: viewEvents,
        hasPermission: actionPermissions && actionPermissions.events.view,
      },
      {
        path: pageNavigationURL.editPromotions,
        component: CreatePromotions,
        hasPermission: actionPermissions && actionPermissions.promotions.edit,
      },
      {
        path: pageNavigationURL.createDynamicBanner,
        component: DynamicBanner,
        hasPermission: actionPermissions && actionPermissions.dynamicBanners.create,
      },
      {
        path: pageNavigationURL.viewDynamicBanner,
        component: DynamicBanner,
        hasPermission: actionPermissions && actionPermissions.dynamicBanners.view,
      },
      {
        path: pageNavigationURL.editDynamicBanner,
        component: DynamicBanner,
        hasPermission: actionPermissions && actionPermissions.dynamicBanners.edit,
      },
      {
        path: pageNavigationURL.geofence,
        component: GeoFence,
        hasPermission: permissions && permissions.vantage.geofence,
      },
      {
        path: pageNavigationURL.viewGeoFence,
        component: CreateGeoFence,
        hasPermission: actionPermissions && actionPermissions.geoFence.view,
      },
      {
        path: pageNavigationURL.createGeoFence,
        component: CreateGeoFence,
        hasPermission: actionPermissions && actionPermissions.geoFence.create,
      },
      {
        path: pageNavigationURL.payByLink,
        component: PayByLink,
        hasPermission: permissions && permissions.velocity.payByLink,
      },
      {
        path: pageNavigationURL.paymentLink,
        component: PaymentLink,
        hasPermission: permissions && permissions.velocity.paymentLink,
      },
      {
        path: pageNavigationURL.manualTrigger,
        component: ManualTrigger,
        hasPermission: permissions && permissions.velocity.paymentLink,
      },
      {
        path: pageNavigationURL.viewPaymentLink,
        component: ManualTrigger,
        hasPermission: permissions && permissions.velocity.paymentLink,
      },
      {
        path: pageNavigationURL.autoTrigger,
        component: AutoTrigger,
        hasPermission: permissions && permissions.velocity.autoTrigger,
      },
      // {
      //   path: '/BulkUpload',
      //   component: BulkUpload,
      //   hasPermission: permissions && permissions.bulkUpload.menu,
      // },

      // {
      //   path: '/Locations',
      //   component: Locations,
      //   hasPermission: permissions && permissions.locations.menu,
      // },
      {
        path: pageNavigationURL.hostedPaymentPage,
        component: HostedPaymentPage,
        hasPermission: permissions && permissions.velocity.hostedPaymentPage,
      },
      // {
      //   path: '/Ibeconfigurations',
      //   component: Ibeconfigurations,
      //   hasPermission: permissions && permissions.ibeConfigurations.menu,
      // },
      {
        path: pageNavigationURL.topics,
        component: Broadcast,
        hasPermission: permissions && permissions.vantage.topics,
      },

      {
        path: pageNavigationURL.merchantOnboardingLocale,
        component: MerchantOnboardingProducts,
        hasPermission: permissions && permissions.velocity.merchantOnboardingLocale,
      },
      {
        path: pageNavigationURL.configureServices,
        component: ConfigureServices,
        hasPermission: permissions && permissions.velocity.configureServices,
      },
      {
        path: pageNavigationURL.dynamicRouting,
        component: DynamicRouting,
        hasPermission: permissions && permissions.velocity.dynamicRouting,
      },
      {
        path: pageNavigationURL.FOP,
        component: DynamicRouting,
        hasPermission: permissions && permissions.velocity.fop,
      },
      {
        path: pageNavigationURL.PreAuth,
        component: DynamicRouting,
        hasPermission: true,
      },  
      
      {
        path: pageNavigationURL.EditPreAuth,
        component: CreatePreAuth,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.edit,
      },
      
      {
        path: pageNavigationURL.ViewPreAuth,
        component: CreatePreAuth,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.view,
      },      
      {
        path: pageNavigationURL.PostAuth,
        component: DynamicRouting,
        hasPermission: true,
      }, 
      {
        path: pageNavigationURL.EditPostAuth,
        component: CreatePostAuth,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.edit,
      },
      {
        path: pageNavigationURL.ViewPostAuth,
        component: CreatePostAuth,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.view,
      },
      {
        path: pageNavigationURL.CreatePreAuthFraudRule,
        component: CreatePreAuthFraudRule,
        hasPermission: true,
      },
      {
      path: pageNavigationURL.CreatePostAuthFraudRule,
      component: CreatePostAuthFraudRule,
      hasPermission: true,
      },    
      
      {
        path: pageNavigationURL.FMS,
        component: DynamicRouting,
        hasPermission: permissions && permissions.velocity.fop,
      },
      {
        path: pageNavigationURL.fx,
        component: FX,
        hasPermission: permissions && permissions.velocity.fx,
      },
      {
        path: pageNavigationURL.installment,
        component: DynamicRouting,
        hasPermission: permissions && permissions.velocity.installments,
      },
      {
        path: pageNavigationURL.EditInstallment,
        component: CreateInstallments,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.edit,
      },
      {
        path: pageNavigationURL.ViewInstallment,
        component: CreateInstallments,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.view,
      },
      
      {
        path: pageNavigationURL.merchantOnboardingCountry,
        component: MerchantOnboardingCountry,
        hasPermission: permissions && permissions.velocity.merchantOnboardingLocale,
      },
      {
        path: pageNavigationURL.createRule,
        component: CreateRule,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingPaymentRule.create,
      },
      {
        path: pageNavigationURL.EditRule,
        component: CreateRule,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingPaymentRule.edit,
      },
      {
        path: pageNavigationURL.ViewRule,
        component: CreateRule,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingPaymentRule.view,
      },
      {
        path: pageNavigationURL.createFOP,
        component: CreateFOP,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.create,
      },
      {
        path: pageNavigationURL.createInstallments,
        component: CreateInstallments,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.create,
      },
      {
        path: pageNavigationURL.EditFop,
        component: CreateFOP,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.edit,
      },
      {
        path: pageNavigationURL.ViewFop,
        component: CreateFOP,
        hasPermission: actionPermissions && actionPermissions.dynamicRoutingFopRule.view,
      },
     
      {
        path: pageNavigationURL.merchantOnboardingLocale,
        component: MerchantOnboardingLocale,
        hasPermission: false,
      },
      {
        path: pageNavigationURL.notificationConfigure,
        component: NotificationConfigure,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.notificationEngine,
        component: NotificationEngine,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.travelbank,
        component: TravelBank,
        hasPermission: permissions && permissions.velocity.travelBank,
      },
      {
        path: pageNavigationURL.screenConfigurations,
        component: ScreenConfiguration,
        hasPermission: permissions && permissions.notification.screenConfigurations,
      },
      {
        path: '/notificationChannelType',
        component: NotificationChannelType,
        hasPermission: true,
      },
      {
        path: pageNavigationURL.mySubscriptions,
        component: MySubscriptions,
        hasPermission: permissions && permissions.reports.mySubscriptions,
      },
      {
        path: '/ProductSalesDashboard',
        component: SalesDashboard,
        hasPermission: permissions && permissions.dashboards.product,
      },
      {
        path: pageNavigationURL.manageTransaction,
        component: ManageTransaction,
        hasPermission: permissions && permissions.reports.manageTransaction,
      },
      {
        path: pageNavigationURL.static,
        component: DynamicRouting,
        hasPermission: permissions && permissions.velocity.dynamicRouting,
      },
      {
        path: pageNavigationURL.createRoute,
        component: StaticRoute,
        hasPermission: permissions && permissions.velocity.dynamicRouting,
      },
      {
        path: pageNavigationURL.EditRoute,
        component: StaticRoute,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
      {
        path: pageNavigationURL.ViewRoute,
        component: StaticRoute,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
      {
        path: pageNavigationURL.merchantOnboarding,
        component: MerchantOnboarding,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
      {
        path: pageNavigationURL.eventNotification,
        component: EventNotification,
        hasPermission: permissions && permissions.console.eventNotification,
      },
      {
        path: pageNavigationURL.createEventNotification,
        component: CreateEventNotification,
        hasPermission: permissions && permissions.console.eventNotification,
      },
      {
        path: pageNavigationURL.editEventNotification,
        component: CreateEventNotification,
        hasPermission: permissions && permissions.console.eventNotification,
      },
      {
        path: pageNavigationURL.viewEventNotification,
        component: CreateEventNotification,
        hasPermission: permissions && permissions.console.eventNotification,
      },
      {
        path: pageNavigationURL.popSetup,
        component: PopSetupComponent,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
      {
        path: pageNavigationURL.vasSetup,
        component: VasSetupComponent,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
      {
        path: pageNavigationURL.merchantSetup,
        component: MerchantSetupComponent,
        hasPermission: permissions && permissions.console.merchantOnBoarding,
      },
    ];

    const isLoggedIn = localStorage.getItem('login');
    const windowPathName = window.location.pathname;
    const validRoutes = routes.filter(key => key.hasPermission);
    const inValidPath = validRoutes.filter(key => key.path.toLowerCase() === windowPathName.toLowerCase()).length === 0;

    const isShowReportURL = window.location.pathname === pageNavigationURL.showReport;
    const hideSideMenuHeader = inValidPath || isShowReportURL || windowPathName === '/';

    return (

      (isLoggedIn)
        ? <div className={windowPathName === '/' ? '' : 'container-fluid py-5'}>
          { !hideSideMenuHeader && <Header />}
          <Notify />
          <SessionExpiredPopup />
          <div className={!hideSideMenuHeader ? 'row justify-content-center pt-3 mt-4' : ' '}>

            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/viewReport" exact component={ViewReport} />
              {routes.map(key => key.hasPermission && <Route path={key.path} exact={key.exact} component={key.component} />)}
              <Route component={NotFoundPage} />
            </Switch>

            { !hideSideMenuHeader && arrLength > 1 && <MerchantList />}

          </div>
        </div>

        : <Switch>
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/" exact component={LoginPage} />
          <Redirect to="/" />
        </Switch>


    );
  }
}
App.propTypes = {
  getPermissionObject: PropTypes.object,
  setPermissionObject: PropTypes.func,
  clearBanner: PropTypes.func,
  clientConfigResult: PropTypes.object,
  SystemMetaDataResult: PropTypes.object,
};
const mapStateToProps = state => ({
  getPermissionObject: state.getPermissionObject,
  clientConfigResult: state.clientConfigResult,
  SystemMetaDataResult: state.SystemMetaDataResult,
});
export default withRouter(connect(mapStateToProps, { setPermissionObject, clearBanner })(App));
