import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Accordion, Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useState, useEffect } from 'react';
import globals from '../../../../../redux/constants/globals';
import {
  tableHeadingColor, _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut,
} from '../Helper';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import AlertPopup from '../../../../shared/Popups/AlertPopup';
import {
  getPSPConfigurationRequest, getRoutePSPConfigurationRequest, getRoutePSPConfigurationByIdRequest, updateRoutePSPConfigurationByRouteIdRequest, deleteRoutePSPConfigurationByRouteIdRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyConfigureRouteProperties, validationRequiredInputMessage } from '../Constants';

function PopSetupConfigurePaymentRouteProperties(props) {
  const reset = {
    addPaymentGateway: {
      nameProp: '',
      idProp: '',
    },
    propertyName: {
      nameProp: '',
      idProp: '',
    },
    routeName: {
      nameProp: '',
      idProp: '',
    },
    propertyValue: '',
  };
  const [inputField, setInpuField] = useState(reset);
  const [newId, setNewId] = useState('');
  const [newvalue, setNewValue] = useState('');
  const [idForAcquirer, setIdForAcquirer] = useState('');
  const [valueForAcquirer, setValueForAcquirer] = useState('');
  const [cfgData, setCfgData] = useState([]);
  const [prevPSPConfigurationResult, setPrevPSPConfigurationResult] = useState(props.getPSPConfigurationResult.result);
  const [propertyNameList, setPropertyNameList] = useState([]);
  const [routeNameList, setRouteNameList] = useState([]);
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [msg, setMsg] = useState('');
  const [disFlag, setDisFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [propertyMsg, setPropertyMsg] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [alertMessage, setAlertMessage] = useState(false);
  const [alertMessageForAcquirer, setAlertMessageForAcquirer] = useState(false);

  useEffect(() => {
    setPrevPSPConfigurationResult(props.getPSPConfigurationResult.result);
  }, [props.getPSPConfigurationResult.result]);

  const paymentGatewayAcquirerList = [];
  let clientPspConfigurationsArrayList = prevPSPConfigurationResult && prevPSPConfigurationResult.client_psp_configurations;
  if (clientPspConfigurationsArrayList) {
    if (!Array.isArray(clientPspConfigurationsArrayList)) {
      clientPspConfigurationsArrayList = [{
        ...clientPspConfigurationsArrayList,
      }];
    }

    let psps = prevPSPConfigurationResult && prevPSPConfigurationResult.psps;
    if (psps) {
      if (!Array.isArray(psps)) {
        psps = [{
          ...psps,
        }];
      }

      clientPspConfigurationsArrayList.forEach(element => {
        const pspName = psps.find(pspKey => (pspKey.id === element.id));
        if (pspName) {
          paymentGatewayAcquirerList.push({
            id: pspName.id,
            name: pspName.name,
          });
        }
      });
    }
  }

  const inputOnChange = (event, field) => {
    let value;
    const id = event.target.value;
    if (field === 'propertyName') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else if (field === 'addPaymentGateway') {
      setPropertyMsg(false);
      const fieldValue = event.target.value;
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      if (inputField.addPaymentGateway.idProp === '' || inputField.addPaymentGateway.nameProp === 'Please Select') {
        setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        const requestBodyForGetRoutePSPApi = {
          psp_id: fieldValue,
          is_meta_data_required: false,
        };
        props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
      } else {
        const findUpdatedData = cfgData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setIdForAcquirer(id);
          setValueForAcquirer(value);
          setAlertMessageForAcquirer(true);
        } else {
          setCfgData([]);
          setRouteNameList([]);
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
          const requestBodyForGetRoutePSPApi = {
            psp_id: fieldValue,
            is_meta_data_required: false,
          };
          props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
          setAlertMessage(false);
        }
      }
    } else if (field === 'routeName') {
      const fieldValue = event.target.value;
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      if (inputField.routeName.idProp === '' || inputField.routeName.nameProp === 'Please Select') {
        setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        if (fieldValue !== 'Please Select') {
          const requestBodyForGetRoutePSPApi = {
            id: fieldValue,
          };
          props.getRoutePSPConfigurationByIdRequest(requestBodyForGetRoutePSPApi);
        }
      } else {
        const findUpdatedData = cfgData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setNewId(id);
          setNewValue(value);
          setAlertMessage(true);
        } else {
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
          const requestBodyForGetRoutePSPApi = {
            id: fieldValue,
          };
          props.getRoutePSPConfigurationByIdRequest(requestBodyForGetRoutePSPApi);
          setAlertMessage(false);
        }
      }
    } else if (field === 'propertyValue') {
      setInpuField({ ...inputField, [field]: id });
    }
  };
  useEffect(() => {
    let routePspConfigurationResult = [];
    if (!props.getRoutePSPConfigurationResult.fetching && props.getRoutePSPConfigurationResult.fetched && props.getRoutePSPConfigurationResult.requestStatus) {
      routePspConfigurationResult = props.getRoutePSPConfigurationResult.result.route_configurations;
    }
    if (routePspConfigurationResult) {
      setRouteNameList(routeNameData(routePspConfigurationResult));
    } else {
      setRouteNameList([]);
    }
  }, [props.getRoutePSPConfigurationResult]);

  useEffect(() => {
    let routePspConfigurationResult = [];
    if (!props.getRoutePSPConfigurationByIdResult.fetching && props.getRoutePSPConfigurationByIdResult.fetched && props.getRoutePSPConfigurationByIdResult.requestStatus) {
      routePspConfigurationResult = props.getRoutePSPConfigurationByIdResult.result.route_configurations;
    }
    if (routePspConfigurationResult) {
      setPropertyNameList(propertyNameData(routePspConfigurationResult));
      setCfgData(cfgDataList(routePspConfigurationResult));
    } else {
      setPropertyNameList([]);
    }
  }, [props.getRoutePSPConfigurationByIdResult]);

  const propertyNameData = routePspConfigurationResult => {
    const propertyName = [];
    if (routePspConfigurationResult) {
      routePspConfigurationResult.forEach(clientRoutePspConfigurationsKey => {
        if (clientRoutePspConfigurationsKey.property_details) {
          setPropertyMsg(false);
          clientRoutePspConfigurationsKey.property_details.forEach(propertyDetailKey => {
            if (propertyDetailKey.properties) {
              propertyDetailKey.properties.forEach(propertyKey => {
                if (!propertyKey.value) {
                  propertyName.push({
                    id: Number(propertyKey.id),
                    name: propertyKey.name,
                  });
                }
              });
            }
          });
        } else if (clientRoutePspConfigurationsKey.property_details === null) {
          setPropertyMsg(true);
        }
      });
    }
    return propertyName;
  };
  const cfgDataList = routePspConfigurationResult => {
    const configPaymentData = [];
    if (routePspConfigurationResult) {
      routePspConfigurationResult.forEach(clientRoutePspConfigurationsKey => {
        if (clientRoutePspConfigurationsKey.property_details) {
          clientRoutePspConfigurationsKey.property_details.forEach(propertyDetailKey => {
            if (propertyDetailKey.properties) {
              propertyDetailKey.properties.forEach(propertyKey => {
                if (propertyKey.value) {
                  configPaymentData.push({
                    key: configPaymentData.length + 1,
                    id: Number(inputField.addPaymentGateway.idProp),
                    providerName: inputField.addPaymentGateway.nameProp,
                    routeName: inputField.routeName.nameProp,
                    routeId: Number(inputField.routeName.idProp),
                    propertyName: propertyKey.name,
                    propertyId: Number(propertyKey.id),
                    propertyValue: propertyKey.value,
                    action: '',
                    deleteKey: false,
                    existFlag: true,
                    cfg: true,
                  });
                }
              });
            }
          });
        }
      });
    }
    return configPaymentData;
  };

  const routeNameData = routePspConfigurationResult => {
    const routeName = [];
    if (routePspConfigurationResult) {
      routePspConfigurationResult.forEach(routePspConfigurationObject => {
        routeName.push({
          id: routePspConfigurationObject.id,
          name: routePspConfigurationObject.name,
        });
      });
      return routeName;
    }
  };

  const deleteRow = row => {
    let filter = '';
    if (row.cfg) {
      filter = getUnique(cfgData, 'key');
      if (filter.length !== cfgData.length) {
        setCfgData(filter);
      } else {
        filter = cfgData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setCfgData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const action = (cell, row) => (
    <div>
      {(row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );


  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const _onClickAddButton = () => {
    let flag = false;
    const count = cfgData.length + 1;
    setMsg('');
    setDisFlag(false);
    if (inputField.propertyName.nameProp === undefined || inputField.propertyValue === undefined || inputField.propertyName.nameProp === '' || inputField.propertyValue === ''
      || inputField.propertyName.nameProp === 'Please Select' || inputField.propertyValue === 'Please Select') {
      setErrorMsg(true);
      const errmsg = validationRequiredInputMessage;
      setMsg(errmsg);
      alertMessageSetTimeOut();
      return false;
    }
    cfgData.forEach(data => {
      if (parseInt(data.propertyId, 10) === parseInt(inputField.propertyName.idProp, 10)) {
        flag = true;
        if (flag) {
          setErrorMsg(true);
          const errmsg = 'Property Already Configured';
          setMsg(errmsg);
          alertMessageSetTimeOut();
        }
      }
    });
    if (!flag) {
      cfgData.push({
        key: count,
        providerName: inputField.addPaymentGateway.nameProp,
        id: Number(inputField.addPaymentGateway.idProp),
        routeName: inputField.routeName.nameProp,
        routeId: Number(inputField.routeName.idProp),
        propertyName: inputField.propertyName.nameProp,
        propertyId: Number(inputField.propertyName.idProp),
        propertyValue: inputField.propertyValue,
        action: '',
        deleteKey: false,
        existFlag: false,
        cfg: true,
      });
    }
    setCfgData(cfgData);
    const data = { ...inputField };
    data.key = 0;
    data.propertyValue = '';
    data.propertyName.nameProp = '';
    data.propertyName.idProp = '';
    setInpuField(data);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  const savePspConfigResults = () => {
    setMsg('');
    setDisFlag(false);
    if (cfgData.length > 0) {
      const findAPG = cfgData.some(element => (element.deleteKey === false));
      if (cfgData.length > 0 && !findAPG) {
        const requestBody = {
          pspId: Number(inputField.addPaymentGateway.idProp),
          id: Number(inputField.routeName.idProp),
        };
        props.deleteRoutePSPConfigurationByRouteIdRequest(requestBody);
        setCfgData([]);
        setInpuField(reset);
      } else {
        const propertyConfigClientPspConfiguration = [];
        const propertyConfig = [];
        cfgData.forEach(keyId => {
          if (!keyId.deleteKey) {
            propertyConfig.push({
              id: keyId.propertyId,
              value: keyId.propertyValue,
            });
          }
        });
        if (propertyConfig.length > 0) {
          propertyConfigClientPspConfiguration.push({
            id: cfgData[0] && cfgData[0].routeId,
            properties: propertyConfig,
          });
        }
        const requestBody = {
          client_id: globals.clientId,
          route_configurations: propertyConfigClientPspConfiguration,
        };
        props.updateRoutePSPConfigurationByRouteIdRequest(requestBody);
      }

      // Make save button disabled
      setSaveButtonDisabledFlag(1);
    }
  };

  // Wait for Sucess result of updateRoutePSPConfigurationRequest then call getRoutePSPConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyConfigureRouteProperties && props.updateRoutePSPConfigurationByRouteIdResult.fetched && props.updateRoutePSPConfigurationByRouteIdResult.requestStatus && !props.updateRoutePSPConfigurationByRouteIdResult.fetching) {
      notify('success', toastMessages.merchantServices.success.updated);
      const requestBodyForGetRoutePSPApi = {
        id: inputField.routeName.idProp,
        is_meta_data_required: false,
      };
      props.getRoutePSPConfigurationByIdRequest(requestBodyForGetRoutePSPApi);
    } else if (props.activeKey === activeKeyConfigureRouteProperties && !props.updateRoutePSPConfigurationByRouteIdResult.fetched && !props.updateRoutePSPConfigurationByRouteIdResult.requestStatus && !props.updateRoutePSPConfigurationByRouteIdResult.fetching) {
      notify('error', toastMessages.merchantServices.error.updated);
    }
  }, [props.updateRoutePSPConfigurationByRouteIdResult]);

  useEffect(() => {
    if (props.activeKey === activeKeyConfigureRouteProperties && props.deleteRoutePSPConfigurationByRouteIdResult.fetched && !props.deleteRoutePSPConfigurationByRouteIdResult.fetching && props.deleteRoutePSPConfigurationByRouteIdResult.requestStatus) {
      const requestBodyForGetRoutePSPApi = {
        id: inputField.routeName.idProp,
        is_meta_data_required: false,
      };
      props.getRoutePSPConfigurationByIdRequest(requestBodyForGetRoutePSPApi);
    }
  }, [props.deleteRoutePSPConfigurationByRouteIdResult]);

  useEffect(() => {
    if (props.getRoutePSPConfigurationByIdResult.fetching || props.deleteRoutePSPConfigurationByRouteIdResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });

  // set default data for table
  const clearState = () => {
    setDisFlag(false);
    setMsg('');
    setCfgData([]);
    setPropertyNameList([]);
    const data = { ...inputField };
    data.key = 0;
    data.routeName.nameProp = 'Please Select';
    data.routeName.idProp = '';
    data.propertyValue = '';
    data.propertyName.nameProp = '';
    data.propertyName.idProp = '';
    setInpuField(data);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    notify('success', toastMessages.merchantServices.success.reset);
  };
  const confirmDiscardData = () => {
    const field = 'routeName';
    setInpuField({ ...inputField, [field]: { nameProp: newvalue, idProp: newId } });
    const requestBodyForGetRoutePSPApi = {
      id: Number(newId),
    };
    props.getRoutePSPConfigurationByIdRequest(requestBodyForGetRoutePSPApi);
    setAlertMessage(false);
  };
  const confirmCancelData = () => {
    setAlertMessage(false);
  };
  const confirmYesData = () => {
    const field = 'addPaymentGateway';
    setCfgData([]);
    setRouteNameList([]);
    setInpuField({ ...inputField, [field]: { nameProp: valueForAcquirer, idProp: idForAcquirer } });
    const requestBodyForGetRoutePSPApi = {
      psp_id: idForAcquirer,
      is_meta_data_required: false,
    };
    props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
    setAlertMessageForAcquirer(false);
  };
  const confirmNoData = () => {
    setAlertMessageForAcquirer(false);
  };
  return (
    <div className="card">
      {(alertMessage) && <AlertPopup
        onClickYes={() => confirmDiscardData()}
        onClickNo={() => confirmCancelData()}
      />}
      {(alertMessageForAcquirer) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      {props.activeKey === activeKeyConfigureRouteProperties && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = cfgData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setCfgData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          savePspConfigResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyConfigureRouteProperties ? '' : activeKeyConfigureRouteProperties)} eventKey={activeKeyConfigureRouteProperties}>
          <h6>
            Configure Payment Route Properties
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyConfigureRouteProperties}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Payment Gateway / Acquirer
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'addPaymentGateway')}
                      value={inputField.addPaymentGateway.idProp}
                      data-toggle="modal"
                      data-target="#alertModal"
                    >
                      <option disabled selected value="">Please Select</option>
                      {paymentGatewayAcquirerList && paymentGatewayAcquirerList.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
                {(inputField.addPaymentGateway.nameProp) && <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Route Name
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'routeName')}
                      value={inputField.routeName.idProp}
                      data-toggle="modal"
                      data-target="#alertModal"
                    >
                      <option selected>Please Select</option>
                      {routeNameList && routeNameList.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>}
              </div>
              {(inputField.addPaymentGateway.nameProp) && <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Property Name
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'propertyName')}
                      value={inputField.propertyName.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      {propertyNameList && propertyNameList.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Value
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Value"
                      onChange={e => inputOnChange(e, 'propertyValue')}
                      value={inputField.propertyValue}
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </div>
              </div>}
              {errorMsg && <div className="alert alert-danger">
                {errorMsg ? msg : ''}
              </div>}
              {disFlag && <div className="alert alert-danger">
                {disFlag ? validationRequiredInputMessage : ''}
              </div>}
              {propertyMsg && <div className="alert alert-danger">
                {propertyMsg ? 'The selected route does not have any configured properties.' : ''}
              </div>}
              {(!isLoaderOn) ? <div className="row">
                {(inputField.addPaymentGateway.nameProp) && <div className="col-md-12  pt-2 pb-2 mt-3">
                  <BootstrapTable data={cfgData}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    <TableHeaderColumn dataField="providerName" className={tableHeadingColor} dataAlign="center" dataSort columnClassName={_onClickDeleteRowStyleDisable}>Payment Gateway / Acquirer</TableHeaderColumn>
                    <TableHeaderColumn dataField="routeName" className={tableHeadingColor} dataAlign="left" dataSort columnClassName={_onClickDeleteRowStyleDisable}>Route Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyName" className={tableHeadingColor} dataAlign="left" dataSort columnClassName={_onClickDeleteRowStyleDisable}>Property Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyValue" className={tableHeadingColor} dataAlign="left" dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Value</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataAlign="left" dataFormat={action} columnClassName={_onClickDeleteRowStyleDisable}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>}
                <div className="col-md-12  pt-2 pb-2 mt-3">
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => savePspConfigResults()}
                  >
                    Save
                  </button>}
                  <button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mr-3"
                    onClick={() => clearState()}
                  >
                    Cancel
                  </button>
                </div>
              </div> : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}
PopSetupConfigurePaymentRouteProperties.propTypes = {
  getPSPConfigurationResult: PropTypes.object,
  getRoutePSPConfigurationRequest: PropTypes.object,
  getRoutePSPConfigurationByIdRequest: PropTypes.object,
  getRoutePSPConfigurationResult: PropTypes.object,
  getRoutePSPConfigurationByIdResult: PropTypes.object,
  updateRoutePSPConfigurationByRouteIdRequest: PropTypes.func,
  updateRoutePSPConfigurationByRouteIdResult: PropTypes.object,
  deleteRoutePSPConfigurationByRouteIdRequest: PropTypes.func,
  deleteRoutePSPConfigurationByRouteIdResult: PropTypes.object,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getPSPConfigurationResult: state.getPSPConfigurationResult,
  getRoutePSPConfigurationResult: state.getRoutePSPConfigurationResult,
  getRoutePSPConfigurationByIdResult: state.getRoutePSPConfigurationByIdResult,
  updateRoutePSPConfigurationByRouteIdResult: state.updateRoutePSPConfigurationByRouteIdResult,
  deleteRoutePSPConfigurationByRouteIdResult: state.deleteRoutePSPConfigurationByRouteIdResult,
});

export default connect(
  mapStateToProps, {
    getPSPConfigurationRequest, getRoutePSPConfigurationRequest, getRoutePSPConfigurationByIdRequest, updateRoutePSPConfigurationByRouteIdRequest, deleteRoutePSPConfigurationByRouteIdRequest,
  }
)(PopSetupConfigurePaymentRouteProperties);
