import * as types from '../constants/actionTypes';

const initialState = {
  isExpired: false,
};


export const getSessionExpired = (state = initialState, action) => {
  switch (action.type) {
  case types.SET_SESSION_EXPIRED:
    return { ...state, isExpired: true };
  case types.CLEAR_SESSION_EXPIRED:
    return { ...state, isExpired: false };
  default:
    return state;
  }
};
