import PropTypes from 'prop-types';
import Multiselect from '@khanacademy/react-multi-select';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import Form from 'react-bootstrap/Form';
import { Accordion, Button } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import globals from '../../../../../redux/constants/globals';
import {
  tableHeadingColor, _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut,
} from '../Helper';
import CloseModal from '../../../../../static/icons/x.svg';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import EditConfigurePaymentGateway from './EditConfigurePaymentGateway';
import ArrowDown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import AlertPopup from '../../../../shared/Popups/AlertPopup';
import {
  getPSPConfigurationRequest, getRouteConfigurationRequest, getRoutePSPConfigurationRequest, updateRoutePSPConfigurationRequest, deleteRoutePSPConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { BootstrapTable, off, on } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyConfigurePayementGateway, validationRequiredInputMessage } from '../Constants';

function PopSetupConfigurePaymentGateway(props) {
  const [popSetupInputField, setPopSetupInputField] = useState({
    paymentGatewayAcquirer: '',
    paymentGatewayAcquirerId: '',
    routeName: '',
    routeId: '',
    mid: '',
    username: '',
    password: '',
    country: '',
    currency: '',
    paymentMethod: '',
    paymentMethodId: '',
    captureType: '',
    captureTypeId: '',
    refundRouteFeature: on,
    cancelRouteFeature: on,
    partialCaptureRouteFeature: on,
    partialRefundRouteFeature: on,
    partialCancelRouteFeature: on,
    installmentRouteFeature: on,
    typeOf3ds: '',
  });
  const [cfgData, setCfgData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [prevPSPConfigurationResult, setPrevPSPConfigurationResult] = useState(props.getPSPConfigurationResult.result);
  const [paymentGateway, setpaymentGateway] = useState(
    {
      paymentGatewayAcquirer: '',
      paymentGatewayAcquirerId: '',
    }
  );
  const [selectedRouteId, setSelectedRouteId] = useState('');
  const [selectedPaymentGatewayAcquirerId, setSelectedPaymentGatewayAcquirerId] = useState('');
  const [editKey, setEditKey] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [prevPaymentMetaDataResult, setPrevPaymentMetaDataResult] = useState(props.getPaymentMetaDataResult.result);
  const [alertMessageForAcquirer, setAlertMessageForAcquirer] = useState(false);
  const [idForAcquirer, setIdForAcquirer] = useState('');
  const [valueForAcquirer, setValueForAcquirer] = useState('');

  const isMetadataRequired = true;

  // Wait for Sucess result of updateRoutePSPConfigurationRequest then call getRoutePSPConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyConfigurePayementGateway && props.updateRoutePSPConfigurationResult.fetched && props.updateRoutePSPConfigurationResult.requestStatus && !props.updateRoutePSPConfigurationResult.fetching) {
      notify('success', toastMessages.merchantServices.success.updated);
      const requestBodyForGetRoutePSPApi = {
        psp_id: cfgData[0] && cfgData[0].paymentGatewayAcquirerId,
        is_meta_data_required: false,
      };
      props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
    } else if (props.activeKey === activeKeyConfigurePayementGateway && !props.updateRoutePSPConfigurationResult.fetched && !props.updateRoutePSPConfigurationResult.requestStatus && !props.updateRoutePSPConfigurationResult.fetching) {
      notify('error', toastMessages.merchantServices.error.updated);
    }
  }, [props.updateRoutePSPConfigurationResult]);

  useEffect(() => {
    if (props.activeKey === activeKeyConfigurePayementGateway && props.deleteRoutePSPConfigurationResult.fetched && !props.deleteRoutePSPConfigurationResult.fetching && props.deleteRoutePSPConfigurationResult.requestStatus) {
      const requestBodyForGetRoutePSPApi = {
        psp_id: cfgData[0] && cfgData[0].paymentGatewayAcquirerId,
        is_meta_data_required: false,
      };
      props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
    }
  }, [props.deleteRoutePSPConfigurationResult]);

  useEffect(() => {
    props.getPSPConfigurationRequest(isMetadataRequired);
  }, []);
  useEffect(() => {
    if (props.getPSPConfigurationResult.fetching) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  });

  useEffect(() => {
    if (props.getRoutePSPConfigurationResult.fetching) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  });

  useEffect(() => {
    if (prevPaymentMetaDataResult !== props.getPaymentMetaDataResult.result) {
      setPrevPaymentMetaDataResult(props.getPaymentMetaDataResult);
    }
  }, [props, prevPaymentMetaDataResult]);

  const [validationError, setValidationError] = useState({
    paymentGatewayAcquirerValueValidation: false,
    routeNameValueValidation: false,
    paymentMethodValueValidation: false,
    captureTypeValueValidation: false,
    midValueValidation: false,
    usernameValueValidation: false,
    passwordValueValidation: false,
  });

  useEffect(() => {
    setPrevPSPConfigurationResult(props.getPSPConfigurationResult.result);
  }, [props.getPSPConfigurationResult.result]);

  useEffect(() => {
    let routePspConfigurationResult = [];
    if (props.activeKey === activeKeyConfigurePayementGateway && props.getRoutePSPConfigurationResult.fetched && props.getRoutePSPConfigurationResult.requestStatus && !props.getRoutePSPConfigurationResult.fetching) {
      routePspConfigurationResult = props.getRoutePSPConfigurationResult.result.route_configurations;
    }
    if (routePspConfigurationResult) {
      setCfgData(createTableDate(routePspConfigurationResult));
    } else {
      setCfgData([]);
    }
  }, [props.getRoutePSPConfigurationResult]);

  const viewMode = props.getFormMode.result === 'View';

  // Populating Payment Gateway Aquirer
  const paymentGatewayAcquirerArray = [];

  const paymentGatewayAcquirerDetails = prevPSPConfigurationResult.client_psp_configurations;
  if (paymentGatewayAcquirerDetails && prevPSPConfigurationResult && prevPSPConfigurationResult.psps) {
    paymentGatewayAcquirerDetails.forEach(key => {
      const requiredPSPConfiguration = prevPSPConfigurationResult.psps.filter(keyIndex => keyIndex.id === key.id);
      if (requiredPSPConfiguration && requiredPSPConfiguration[0]) {
        const selectOption = {
          acquirer_id: key.id,
          acquirer_name: requiredPSPConfiguration[0].name,
        };
        paymentGatewayAcquirerArray.push(selectOption);
      }
    });
  }

  // Populating Country List
  const countryListArray = [];
  const countryDetails = prevPSPConfigurationResult.country_details;
  if (countryDetails) {
    countryDetails.sort((a, b) => a.name.localeCompare(b.name));
    countryDetails.forEach(key => {
      const selectOption = {
        country_id: key.id,
        name: key.name,
      };
      countryListArray.push(selectOption);
    });
  }

  // Populating Currency List
  const currencyListArray = [];
  const currencyDetails = prevPSPConfigurationResult.currency_details;
  if (currencyDetails) {
    currencyDetails.sort((a, b) => a.code.localeCompare(b.code));
    currencyDetails.forEach(key => {
      const selectOption = {
        currency_id: key.id,
        name: key.name,
        code: key.code,
      };
      currencyListArray.push(selectOption);
    });
  }

  // Populating Payment Method
  let payConfigList = [];
  const pmConfigurationsAPICall = paymentList => {
    let pmConfigurations = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.pm_configurations && props.getClientConfigurationResult.result.client_configuration.pm_configurations.pm_configuration;
    if (pmConfigurations) {
      if (!Array.isArray(pmConfigurations)) {
        pmConfigurations = [{
          ...pmConfigurations,
        }];
      }
      payConfigList = pmConfigurations.filter(obj => obj.enabled === true).map(obj => obj.pm_id);
    }
    paymentList.forEach(key => {
      const multiSelectOption = {
        value: key.id,
        label: key.name,
      };
      paymentMethodListArray.push(multiSelectOption);
      if (payConfigList.includes(key.id)) paymentMethodSelectedListArray.push(multiSelectOption);
    });
  };
  const paymentMethodListArray = [];
  const paymentMethodSelectedListArray = [];
  let paymentMethod = props.getPaymentMetaDataResult.result && props.getPaymentMetaDataResult.result.payment_metadata && props.getPaymentMetaDataResult.result.payment_metadata.pms && props.getPaymentMetaDataResult.result.payment_metadata.pms.pm;
  if (paymentMethod) {
    if (!Array.isArray(paymentMethod)) {
      paymentMethod = [{
        ...paymentMethod,
      }];
    }
    pmConfigurationsAPICall(paymentMethod);
  }

  // Populating Capture Types
  const captureTypeListArray = [];
  const captureTypeDetails = prevPSPConfigurationResult.capture_types;
  if (captureTypeDetails) {
    captureTypeDetails.forEach(key => {
      const selectOption = {
        capture_type_id: key.id,
        name: key.name,
      };
      captureTypeListArray.push(selectOption);
    });
  }

  // Populating Route Features
  const routeFeaturesListArray = [];
  let partialCaptureDetails = [];
  let refundDetails = [];
  let partialRefundDetails = [];
  let installmentDetails = [];
  let cancelDetails = [];
  let partialCancelDetails = [];
  let typeOf3dsDetails = [];

  const routeFeaturesDetails = prevPSPConfigurationResult.route_features;
  if (routeFeaturesDetails) {
    routeFeaturesDetails.forEach(key => {
      const selectOption = {
        id: key.id,
        name: key.name,
      };
      routeFeaturesListArray.push(selectOption);
    });

    partialCaptureDetails = routeFeaturesListArray.filter(x => (x.id === 4));
    refundDetails = routeFeaturesListArray.filter(x => (x.id === 5));
    partialRefundDetails = routeFeaturesListArray.filter(x => (x.id === 6));
    installmentDetails = routeFeaturesListArray.filter(x => (x.id === 10));
    cancelDetails = routeFeaturesListArray.filter(x => (x.id === 18));
    partialCancelDetails = routeFeaturesListArray.filter(x => (x.id === 19));
    typeOf3dsDetails = routeFeaturesListArray.filter(x => ([9, 20].includes(x.id)));
  }

  const createTableDate = routePspConfigurationResult => {
    const initialCfgData = [];
    let count = 1;
    routePspConfigurationResult.forEach(routePspConfigurationObject => {
      // Get the data from the Payment Methods
      const requiredPaymentMethodIds = [];
      const requiredPaymentMethodNames = [];
      if (routePspConfigurationObject.pm_configurations) {
        routePspConfigurationObject.pm_configurations.forEach(keyPm => {
          requiredPaymentMethodIds.push(keyPm.pm_id);
        });
        const paymentMethodFilteredArrayForTableData = paymentMethodListArray.filter(x => requiredPaymentMethodIds.includes(x.value));
        paymentMethodFilteredArrayForTableData.forEach(keyPmName => {
          requiredPaymentMethodNames.push(keyPmName.label);
        });
      }

      // Get the data from the Capture Types
      let requiredCaptureTypeName = '';
      if (routePspConfigurationObject) {
        const requiredCaptureTypeNameDetails = captureTypeListArray.filter(x => (x.capture_type_id === routePspConfigurationObject.capture_type));
        requiredCaptureTypeName = requiredCaptureTypeNameDetails[0].name;
      }

      let intPartialCaptureRouteFeature = '';
      let intRefundRouteFeature = '';
      let intPartialRefundRouteFeature = '';
      let intInstallmentRouteFeature = '';
      let intCancelRouteFeature = '';
      let intPartialCancelRouteFeature = '';
      let intTypeOf3ds = '';
      if (routePspConfigurationObject.route_features) {
        routePspConfigurationObject.route_features.forEach(keyRouteFeatures => {
          if (keyRouteFeatures.id === 4) {
            intPartialCaptureRouteFeature = 4;
          }
          if (keyRouteFeatures.id === 5) {
            intRefundRouteFeature = 5;
          }
          if (keyRouteFeatures.id === 6) {
            intPartialRefundRouteFeature = 6;
          }
          if (keyRouteFeatures.id === 10) {
            intInstallmentRouteFeature = 10;
          }
          if (keyRouteFeatures.id === 18) {
            intCancelRouteFeature = 18;
          }
          if (keyRouteFeatures.id === 19) {
            intPartialCancelRouteFeature = 19;
          }
          if (keyRouteFeatures.id === 9) {
            intTypeOf3ds = 9;
          } else if (keyRouteFeatures.id === 20) {
            intTypeOf3ds = 20;
          }
        });
      }

      let intCountry = '';
      if (routePspConfigurationObject.country_details) {
        intCountry = routePspConfigurationObject.country_details[0].id;
      }

      let intCurrency = '';
      if (routePspConfigurationObject.currency_details) {
        intCurrency = routePspConfigurationObject.currency_details[0].id;
      }

      initialCfgData.push({
        key: count,
        paymentGatewayAcquirer: paymentGateway.paymentGatewayAcquirer,
        paymentGatewayAcquirerId: paymentGateway.paymentGatewayAcquirerId,
        routeName: routePspConfigurationObject.name,
        routeId: routePspConfigurationObject.id,
        mid: routePspConfigurationObject.mid,
        username: routePspConfigurationObject.username,
        password: routePspConfigurationObject.password,
        country: intCountry,
        currency: intCurrency,
        paymentMethod: requiredPaymentMethodNames,
        paymentMethodId: requiredPaymentMethodIds,
        captureType: requiredCaptureTypeName,
        captureTypeId: routePspConfigurationObject.capture_type,
        refundRouteFeature: intRefundRouteFeature,
        cancelRouteFeature: intCancelRouteFeature,
        partialCaptureRouteFeature: intPartialCaptureRouteFeature,
        partialRefundRouteFeature: intPartialRefundRouteFeature,
        partialCancelRouteFeature: intPartialCancelRouteFeature,
        installmentRouteFeature: intInstallmentRouteFeature,
        typeOf3ds: intTypeOf3ds,
        action: '',
        deleteKey: false,
        existFlag: true,
        hybrid: true,
      });
      count += 1;
    });
    return initialCfgData;
  };

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'paymentMethod') {
      value = event;
    } else if (field === 'paymentGatewayAcquirer') {
      const fieldValue = event.target.value;
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      if (popSetupInputField.paymentGatewayAcquirer === '' || popSetupInputField.paymentGatewayAcquirer === 'Please Select') {
        const requestBodyForGetRoutePSPApi = {
          psp_id: fieldValue,
          is_meta_data_required: false,
        };
        props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
        setpaymentGateway({
          paymentGatewayAcquirer: value,
          paymentGatewayAcquirerId: fieldValue,
        });
        popSetupInputField.paymentGatewayAcquirerId = fieldValue;
      } else {
        const findUpdatedData = cfgData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setIdForAcquirer(fieldValue);
          setValueForAcquirer(value);
          setAlertMessageForAcquirer(true);
        } else {
          const requestBodyForGetRoutePSPApi = {
            psp_id: fieldValue,
            is_meta_data_required: false,
          };
          props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
          setpaymentGateway({
            paymentGatewayAcquirer: value,
            paymentGatewayAcquirerId: fieldValue,
          });
          popSetupInputField.paymentGatewayAcquirerId = fieldValue;
          popSetupInputField.paymentGatewayAcquirer = value;
        }
      }
    } else if (field === 'country' || field === 'currency' || field === 'captureType' || field === 'captureType' || field === 'typeOf3ds') {
      value = event.target.value;
    } else {
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
    }
    setPopSetupInputField({ ...popSetupInputField, [field]: value });
  };
  const _inputOnChange = (event, field) => {
    const value = event.target.value;
    if (field === 'refundRouteFeature') {
      if (popSetupInputField.refundRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.refundRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else if (field === 'cancelRouteFeature') {
      if (popSetupInputField.cancelRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.cancelRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else if (field === 'partialCaptureRouteFeature') {
      if (popSetupInputField.partialCaptureRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.partialCaptureRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else if (field === 'partialRefundRouteFeature') {
      if (popSetupInputField.partialRefundRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.partialRefundRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else if (field === 'partialCancelRouteFeature') {
      if (popSetupInputField.partialCancelRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.partialCancelRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else if (field === 'installmentRouteFeature') {
      if (popSetupInputField.installmentRouteFeature === on) {
        setPopSetupInputField({ ...popSetupInputField, [field]: off });
      } else if (popSetupInputField.installmentRouteFeature === off) {
        setPopSetupInputField({ ...popSetupInputField, [field]: on });
      }
    } else {
      setPopSetupInputField({ ...popSetupInputField, [field]: value });
    }
  };

  const _onClickEdit = (e, row) => {
    setSelectedRouteId(row.routeId);
    setSelectedPaymentGatewayAcquirerId(row.paymentGatewayAcquirerId);
    setEditKey(row.key);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const deleteRow = row => {
    let filter = '';
    if (row.routeName) {
      filter = getUnique(cfgData, 'key');
      if (filter.length !== cfgData.length) {
        setCfgData(filter);
      } else {
        filter = cfgData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));

        setCfgData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };
  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  function configurePaymentGatewayValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (popSetupInputField.paymentGatewayAcquirer === '' || popSetupInputField.paymentGatewayAcquirer === 'Please Select') {
      errorValue.paymentGatewayAcquirerValueValidation = true;
      validate = false;
    } else if (popSetupInputField.routeName === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = true;
      validate = false;
    } else if (popSetupInputField.mid === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = true;
      validate = false;
    } else if (popSetupInputField.paymentMethod === '' || popSetupInputField.paymentMethod === 'Please Select') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = true;
      validate = false;
    } else if (popSetupInputField.captureType === '' || popSetupInputField.captureType === 'Please Select') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = true;
      validate = false;
    } else if (popSetupInputField.username === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.usernameValueValidation = true;
      validate = false;
    } else if (popSetupInputField.password === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.usernameValueValidation = false;
      errorValue.passwordValueValidation = true;
      validate = false;
    } else {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.usernameValueValidation = false;
      errorValue.passwordValueValidation = false;
    }
    setValidationError(errorValue);
    alertMessageSetTimeOut();
    return validate;
  }

  const _onClickAddButton = () => {
    if (configurePaymentGatewayValidation()) {
      let count = 1;
      if (cfgData) {
        count = cfgData.length + 1;
      }

      // Validation check for Fx form
      if (popSetupInputField.paymentMethod === undefined
          || popSetupInputField.routeName === '' || popSetupInputField.paymentMethod === '' || popSetupInputField.mid === '') {
        return false;
      }

      // This code is for showing the payment methods label instead of value
      const paymentMethodFilteredArray = [];
      if (paymentMethodListArray) {
        const paymentMethodFilteredArrayForTableData = paymentMethodListArray.filter(x => popSetupInputField.paymentMethod.includes(x.value));
        paymentMethodFilteredArrayForTableData.forEach(key => {
          paymentMethodFilteredArray.push(key.label);
        });
      }

      // Get the data from the payment Gateway Acquirer
      let requiredPaymentGatewayAcquirerId = '';
      if (popSetupInputField.paymentGatewayAcquirer) {
        const requiredPaymentGatewayAcquirerIdDetails = paymentGatewayAcquirerArray.filter(x => (x.acquirer_name === popSetupInputField.paymentGatewayAcquirer));
        requiredPaymentGatewayAcquirerId = requiredPaymentGatewayAcquirerIdDetails[0].acquirer_id;
      }

      // Get the data from the capture type
      let requiredCaptureTypeName = '';
      if (popSetupInputField.captureType) {
        const requiredCaptureTypeIdDetails = captureTypeListArray.filter(x => (x.capture_type_id === Number(popSetupInputField.captureType)));
        requiredCaptureTypeName = requiredCaptureTypeIdDetails[0].name;
      }

      // Get the data from the Route Features
      let inputRefundRouteFeature = '';
      if (popSetupInputField.refundRouteFeature === off) {
        inputRefundRouteFeature = refundDetails[0].id;
      }
      let inputCancelRouteFeature = '';
      if (popSetupInputField.cancelRouteFeature === off) {
        inputCancelRouteFeature = cancelDetails[0].id;
      }
      let inputPartialCaptureRouteFeature = '';
      if (popSetupInputField.partialCaptureRouteFeature === off) {
        inputPartialCaptureRouteFeature = partialCaptureDetails[0].id;
      }
      let inputPartialRefundRouteFeature = '';
      if (popSetupInputField.partialRefundRouteFeature === off) {
        inputPartialRefundRouteFeature = partialRefundDetails[0].id;
      }
      let inputPartialCancelRouteFeature = '';
      if (popSetupInputField.partialCancelRouteFeature === off) {
        inputPartialCancelRouteFeature = partialCancelDetails[0].id;
      }
      let inputInstallmentRouteFeature = '';
      if (popSetupInputField.installmentRouteFeature === off) {
        inputInstallmentRouteFeature = installmentDetails[0].id;
      }

      cfgData.push({
        key: count,
        paymentGatewayAcquirer: popSetupInputField.paymentGatewayAcquirer,
        paymentGatewayAcquirerId: requiredPaymentGatewayAcquirerId,
        routeName: popSetupInputField.routeName,
        mid: popSetupInputField.mid,
        username: popSetupInputField.username,
        password: popSetupInputField.password,
        country: popSetupInputField.country,
        currency: popSetupInputField.currency,
        paymentMethod: paymentMethodFilteredArray,
        paymentMethodId: popSetupInputField.paymentMethod,
        captureType: requiredCaptureTypeName,
        captureTypeId: popSetupInputField.captureType,
        refundRouteFeature: inputRefundRouteFeature,
        cancelRouteFeature: inputCancelRouteFeature,
        partialCaptureRouteFeature: inputPartialCaptureRouteFeature,
        partialRefundRouteFeature: inputPartialRefundRouteFeature,
        partialCancelRouteFeature: inputPartialCancelRouteFeature,
        installmentRouteFeature: inputInstallmentRouteFeature,
        typeOf3ds: popSetupInputField.typeOf3ds,
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });

      setCfgData(cfgData);
      setPopSetupInputField(clearInputData());

      // Make save button enabled
      setSaveButtonDisabledFlag(0);
    }
  };

  const saveConfigurePaymentGatewayResults = () => {
    // check calling the delete key API for all records deleted
    const cfgDataDeleteKeyArray = cfgData.filter(deleteKeyIndex => deleteKeyIndex.deleteKey === false);
    if (cfgDataDeleteKeyArray.length === 0) {
      const selectRouteConfigData = {
        provider_id: cfgData[0].paymentGatewayAcquirerId,
        id: -1,
      };
      props.deleteRoutePSPConfigurationRequest(selectRouteConfigData);
    } else {
      const routeConfigurationSelectedListArray = [];
      if (cfgData.length !== 0) {
        cfgData.forEach(key => {
          if (key.deleteKey === false) {
            const pmSelectedListArray = [];
            const countrySelectedListArray = [];
            const currencySelectedListArray = [];
            const routeFeatureArrayList = [];
            key.paymentMethodId.forEach(keyOne => {
              const selectPaymentMethod = {
                pm_id: keyOne,
              };
              pmSelectedListArray.push(selectPaymentMethod);
            });

            if (key.country) {
              countrySelectedListArray.push({ id: Number(key.country) });
            }

            if (key.currency) {
              currencySelectedListArray.push({ id: Number(key.currency) });
            }

            if (key.refundRouteFeature) {
              const selectRouteFeature = {
                id: key.refundRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.cancelRouteFeature) {
              const selectRouteFeature = {
                id: key.cancelRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.partialCaptureRouteFeature) {
              const selectRouteFeature = {
                id: key.partialCaptureRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.partialRefundRouteFeature) {
              const selectRouteFeature = {
                id: key.partialRefundRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.partialCancelRouteFeature) {
              const selectRouteFeature = {
                id: key.partialCancelRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.installmentRouteFeature) {
              const selectRouteFeature = {
                id: key.installmentRouteFeature,
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }
            if (key.typeOf3ds) {
              const selectRouteFeature = {
                id: Number(key.typeOf3ds),
              };
              routeFeatureArrayList.push(selectRouteFeature);
            }

            const selectRouteConfigData = {
              name: key.routeName,
              mid: key.mid,
              username: key.username,
              password: key.password,
              capture_type: key.captureTypeId,
              country_details: countrySelectedListArray,
              currency_details: currencySelectedListArray,
              pm_configurations: pmSelectedListArray,
              route_features: routeFeatureArrayList,
            };

            if (key.routeId) {
              selectRouteConfigData.id = key.routeId;
            } else {
              selectRouteConfigData.provider_id = key.paymentGatewayAcquirerId;
            }
            routeConfigurationSelectedListArray.push(selectRouteConfigData);
          }
        });

        const requestBody = {
          client_id: globals.clientId,
          route_configurations: routeConfigurationSelectedListArray,
        };
        props.updateRoutePSPConfigurationRequest(requestBody);
      }
    }

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
  };

  const updatePopupData = e => {
    setShowLoader(true);
    setCfgData(e);
    closeModal();
    setShowLoader(false);
    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };

  const clearInputData = () => {
    const data = { ...popSetupInputField };
    data.routeName = '';
    data.mid = '';
    data.username = '';
    data.password = '';
    data.country = '';
    data.currency = '';
    data.paymentMethod = '';
    data.paymentMethodId = '';
    data.captureType = '';
    data.captureTypeId = '';
    data.refundRouteFeature = 1;
    data.cancelRouteFeature = 1;
    data.partialCaptureRouteFeature = 1;
    data.partialRefundRouteFeature = 1;
    data.partialCancelRouteFeature = 1;
    data.installmentRouteFeature = 1;
    data.typeOf3ds = '';

    return data;
  };

  const clearState = () => {
    let routePspConfigurationResult = [];
    if (props.getRoutePSPConfigurationResult.fetched && props.getRoutePSPConfigurationResult.requestStatus) {
      routePspConfigurationResult = props.getRoutePSPConfigurationResult.result.route_configurations;
    }
    if (routePspConfigurationResult) {
      setCfgData(createTableDate(routePspConfigurationResult));
    } else {
      setCfgData([]);
    }
    setPopSetupInputField(clearInputData());

    // Removing the existing validation message in case of cancel button click
    const errorValue = { ...validationError };
    errorValue.paymentGatewayAcquirerValueValidation = false;
    errorValue.routeNameValueValidation = false;
    errorValue.paymentMethodValueValidation = false;
    errorValue.captureTypeValueValidation = false;
    errorValue.midValueValidation = false;
    errorValue.usernameValueValidation = false;
    errorValue.passwordValueValidation = false;
    setValidationError(errorValue);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    notify('success', toastMessages.merchantServices.success.reset);
  };

  const action = (cell, row) => (
    <div>
      { (row.routeId)
        ? <Button variant="light" onClick={e => _onClickEdit(e, row)} title="Edit" data-toggle="modal" data-target="#editModal"><i className="far fa-edit" /></Button>
        : <div />
      }
      { (row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );

  // Need all four params in order to highlight delete functionality and its change color


  const confirmYesData = () => {
    const requestBodyForGetRoutePSPApi = {
      psp_id: idForAcquirer,
      is_meta_data_required: false,
    };
    props.getRoutePSPConfigurationRequest(requestBodyForGetRoutePSPApi);
    setpaymentGateway({
      paymentGatewayAcquirer: valueForAcquirer,
      paymentGatewayAcquirerId: idForAcquirer,
    });
    setAlertMessageForAcquirer(false);
    popSetupInputField.paymentGatewayAcquirer = valueForAcquirer;
    popSetupInputField.paymentGatewayAcquirerId = idForAcquirer;
  };
  const confirmNoData = () => {
    setPopSetupInputField({ ...popSetupInputField, paymentGatewayAcquirer: paymentGateway.paymentGatewayAcquirer });
    setPopSetupInputField({ ...popSetupInputField, paymentGatewayAcquirerId: paymentGateway.paymentGatewayAcquirerId });
    setAlertMessageForAcquirer(false);
  };
  return (
    <div className="card">
      {props.activeKey === activeKeyConfigurePayementGateway && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = cfgData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setCfgData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          saveConfigurePaymentGatewayResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      {(alertMessageForAcquirer) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyConfigurePayementGateway ? '' : activeKeyConfigurePayementGateway)} eventKey={activeKeyConfigurePayementGateway}>
          <h6>
            Configure Payment Gateway / Acquirer - (ROUTE)
            <img src={ArrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyConfigurePayementGateway}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Payment Gateway / Acquirer
                      </Form.Label>

                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'paymentGatewayAcquirer')}
                        value={popSetupInputField.paymentGatewayAcquirerId}
                        data-toggle="modal"
                        data-target="#alertModal"
                      >
                        <option disabled selected value="">Please Select</option>
                        {paymentGatewayAcquirerArray.map(key => <option value={key.acquirer_id}>{key.acquirer_name}</option>)}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Route Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={e => _inputOnChange(e, 'routeName')}
                        value={popSetupInputField.routeName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        MID
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={e => _inputOnChange(e, 'mid')}
                        value={popSetupInputField.mid}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Username
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={e => _inputOnChange(e, 'username')}
                        value={popSetupInputField.username}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Password
                      </Form.Label>
                      <Form.Control
                        type="text"
                        onChange={e => _inputOnChange(e, 'password')}
                        value={popSetupInputField.password}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label>
                        Country
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'country')}
                        value={popSetupInputField.country}
                      >
                        <option disabled selected value="">Please Select</option>
                        <option value={0}>-- All --</option>
                        {countryListArray.map(key => <option value={key.country_id}>{key.name}</option>)}
                      </Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label>
                        Currency
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'currency')}
                        value={popSetupInputField.currency}
                      >
                        <option disabled selected value="">Please Select</option>
                        <option value={0}>-- All --</option>
                        {currencyListArray.map(key => <option value={key.currency_id}>{key.code}</option>)}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Payment Method
                      </Form.Label>
                      <Multiselect
                        options={paymentMethodSelectedListArray}
                        selected={popSetupInputField.paymentMethod}
                        value={popSetupInputField.paymentMethod}
                        disabled={viewMode}
                        onSelectedChanged={e => inputOnChange(e, 'paymentMethod')}
                        overrideStrings={{
                          selectSomeItems: 'Select Payment Method',
                          allItemsAreSelected: 'All Payment Methods are selected',
                          selectAll: 'Select All Payment Methods',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label className="mandatory">
                        Capture Type
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'captureType')}
                        value={popSetupInputField.captureType}
                      >
                        <option disabled selected value="">Please Select</option>
                        {captureTypeListArray.map(key => <option value={key.capture_type_id}>{key.name}</option>)}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards_heading mr-1 ml-1 mt-3">Route Features</div>
              <div className="row">
                {refundDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{refundDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.refundRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'refundRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
                {cancelDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{cancelDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.cancelRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'cancelRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
                {partialCaptureDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{partialCaptureDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.partialCaptureRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialCaptureRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
              </div>
              <div className="row">
                {partialRefundDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{partialRefundDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.partialRefundRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialRefundRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
                {partialCancelDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{partialCancelDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.partialCancelRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialCancelRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
                {installmentDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{installmentDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={popSetupInputField.installmentRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'installmentRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
                }
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <div>
                      <Form.Label>
                        Type of 3DS
                      </Form.Label>
                      <Form.Control
                        as="select"
                        onChange={e => inputOnChange(e, 'typeOf3ds')}
                        value={popSetupInputField.typeOf3ds}
                      >
                        <option disabled selected value="">Please Select</option>
                        {typeOf3dsDetails.map(key => <option value={key.id}>{key.name}</option>)}
                      </Form.Control>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cards_heading mr-1 ml-1 mt-3">&nbsp;</div>
              <div className="row">
                <div className="col-md-11">
                  {(validationError.captureTypeValueValidation || validationError.paymentMethodValueValidation || validationError.usernameValueValidation || validationError.passwordValueValidation || validationError.midValueValidation || validationError.routeNameValueValidation || validationError.paymentGatewayAcquirerValueValidation) && <div className="alert alert-danger">
                    {validationRequiredInputMessage}
                  </div>}
                </div>
                <div className="col-md-1">
                  <button
                    type="button"
                    className="btn btn-sm float-center btn-outline-primary mt-2"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </div>
              </div>
              {(!showLoader) ? <div className="row">
                <div className="col-md-12  pt-2 pb-2 mt-3">
                  <BootstrapTable data={cfgData}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    <TableHeaderColumn dataField="paymentGatewayAcquirer" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Payment Gateway / Acquirer</TableHeaderColumn>
                    <TableHeaderColumn dataField="paymentGatewayAcquirerId" className={tableHeadingColor} dataAlign="center" hidden>Payment Gateway / Acquirer ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="routeName" dataAlign="center" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Route Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="routeId" hidden>Route Id</TableHeaderColumn>
                    <TableHeaderColumn dataField="paymentMethod" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Payment Method</TableHeaderColumn>
                    <TableHeaderColumn dataField="paymentMethodId" hidden>Payment Method ID</TableHeaderColumn>
                    <TableHeaderColumn dataField="captureType" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Capture Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="captureTypeId" hidden>Capture Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="mid" className={tableHeadingColor} dataSort columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>MID</TableHeaderColumn>
                    <TableHeaderColumn dataField="username" hidden>User Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="password" hidden>Password</TableHeaderColumn>
                    <TableHeaderColumn dataField="country" hidden>Country</TableHeaderColumn>
                    <TableHeaderColumn dataField="currency" hidden>Currency</TableHeaderColumn>
                    <TableHeaderColumn dataField="refundRouteFeature" hidden>Refund RouteFeature</TableHeaderColumn>
                    <TableHeaderColumn dataField="cancelRouteFeature" hidden>Cancel RouteFeature</TableHeaderColumn>
                    <TableHeaderColumn dataField="partialCaptureRouteFeature" hidden>Partial Capture RouteFeature</TableHeaderColumn>
                    <TableHeaderColumn dataField="partialRefundRouteFeature" hidden>Partial Refund RouteFeature</TableHeaderColumn>
                    <TableHeaderColumn dataField="partialCancelRouteFeature" hidden>Partial Cancel RouteFeature</TableHeaderColumn>
                    <TableHeaderColumn dataField="installmentRouteFeature" hidden>Installment</TableHeaderColumn>
                    <TableHeaderColumn dataField="typeOf3ds" hidden>Type Of 3ds</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>
              </div> : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
              <div className="row">
                <div className="col-md-12  pt-2 pb-2 mt-3">
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => saveConfigurePaymentGatewayResults()}
                  >
                    Save
                  </button>}
                  <button
                    type="button"
                    className="btn btn-outline-primary float-right btn-sm  mr-3"
                    disabled={viewMode}
                    onClick={() => clearState()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Accordion.Collapse>

      <ReactModal
        onRequestClose={closeModal}
        isOpen={isOpen}
        ariaHideApp={false}
        className="modal_content TransactionModal"
        overlayClassName="modal_overlay"
      >
        <Button className="closeModal" id="mt_view_btn_close" onClick={closeModal}><img src={CloseModal} alt="close" /></Button>

        <EditConfigurePaymentGateway
          selectedRouteId={selectedRouteId}
          selectedPaymentGatewayAcquirerId={selectedPaymentGatewayAcquirerId}
          editKey={editKey}
          cfgData={cfgData}
          paymentGatewayAcquirerArray={paymentGatewayAcquirerArray}
          countryListArray={countryListArray}
          currencyListArray={currencyListArray}
          paymentMethodSelectedListArray={paymentMethodSelectedListArray}
          captureTypeListArray={captureTypeListArray}
          routeFeaturesListArray={routeFeaturesListArray}
          refundDetails={refundDetails}
          cancelDetails={cancelDetails}
          partialCaptureDetails={partialCaptureDetails}
          partialRefundDetails={partialRefundDetails}
          partialCancelDetails={partialCancelDetails}
          installmentDetails={installmentDetails}
          typeOf3dsDetails={typeOf3dsDetails}
          updatePopupData={e => updatePopupData(e)}
          onClose={closeModal}
          activeKey={props.activeKey}
        />
      </ReactModal>
    </div>
  );
}
PopSetupConfigurePaymentGateway.propTypes = {
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
  getPSPConfigurationRequest: PropTypes.object,
  getPSPConfigurationResult: PropTypes.object,
  getRoutePSPConfigurationRequest: PropTypes.object,
  getRoutePSPConfigurationResult: PropTypes.object,
  updateRoutePSPConfigurationRequest: PropTypes.func,
  updateRoutePSPConfigurationResult: PropTypes.object,
  deleteRoutePSPConfigurationRequest: PropTypes.func,
  deleteRoutePSPConfigurationResult: PropTypes.object,
  getClientConfigurationResult: PropTypes.object,
  getPaymentMetaDataResult: PropTypes.object,
  getFormMode: PropTypes.any,
};
const mapStateToProps = state => ({
  getPSPConfigurationResult: state.getPSPConfigurationResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  getRoutePSPConfigurationResult: state.getRoutePSPConfigurationResult,
  updateRoutePSPConfigurationResult: state.updateRoutePSPConfigurationResult,
  deleteRoutePSPConfigurationResult: state.deleteRoutePSPConfigurationResult,
  getPaymentMetaDataResult: state.getPaymentMetaDataResult,
  getFormMode: state.getFormMode,
});
export default connect(
  mapStateToProps,
  {
    getPSPConfigurationRequest, getRouteConfigurationRequest, getRoutePSPConfigurationRequest, updateRoutePSPConfigurationRequest, deleteRoutePSPConfigurationRequest,
  }
)(PopSetupConfigurePaymentGateway);
