import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
};

export const searchReportsResult = (state = initialState, action) => {
  switch (action.type) {
  case types.SEARCHREPORTS_REQUEST:
    return { ...state, fetching: true, fetched: false };
  case types.SEARCHREPORTS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.SEARCHREPORTS_FAILURE:
    return {
      ...state, fetching: false, fetched: true, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};

export const viewReportResult = (state = initialState, action) => {
  switch (action.type) {
  case types.VIEWREPORTS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.VIEWREPORTS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.VIEWREPORTS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const downloadReportResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DOWNLOAD_REPORT_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DOWNLOAD_REPORT_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DOWNLOAD_REPORT_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const emailReportResult = (state = initialState, action) => {
  switch (action.type) {
  case types.EMAIL_REPORT_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.EMAIL_REPORT_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.EMAIL_REPORT_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getMySubscriptionsResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_MYSUBSCRIPTIONS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_MYSUBSCRIPTIONS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_MYSUBSCRIPTIONS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const getSubscribeResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_SUBSCRIBE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_SUBSCRIBE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_SUBSCRIBE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const subscribeReportResult = (state = initialState, action) => {
  switch (action.type) {
  case types.SUBSCRIBE_REPORT_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.SUBSCRIBE_REPORT_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.SUBSCRIBE_REPORT_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const updateSubscriptionResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_SUBSCRIPTION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_SUBSCRIPTION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_SUBSCRIPTION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const unSubscribeResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UNSUBSCRIBE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UNSUBSCRIBE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UNSUBSCRIBE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const saveReportFilterResult = (state = initialState, action) => {
  switch (action.type) {
  case types.SAVE_FILTER_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.SAVE_FILTER_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.SAVE_FILTER_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const getReportFilterResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_FILTER_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_FILTER_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_FILTER_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const updateReportFilterResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_FILTER_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_FILTER_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_FILTER_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteReportFilterResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_FILTER_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_FILTER_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_FILTER_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
