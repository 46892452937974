import * as moment from 'moment';
import { actionTypes as types } from '../../constants';


const initialState = {
  mode: 'create',
  eventViewEditData: false,
  eventConfigFormInputs: {
    eventId: '',
    name: '',
    description: '',
    expiryDate: moment().add(1, 'day'),
  },
  fetching: false,
  fetched: false,
  result: false,
  requestStatus: false,
  error: '',
};

export const eventResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CHANGE_EVENT_NAME: {
    return Object.assign({}, state, {
      ...state,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        name: action.name,
      },
    });
  }
  case types.CHANGE_EVENT_DESCRIPTION: {
    return Object.assign({}, state, {
      ...state,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        description: action.description,
      },
    });
  }
  case types.CHANGE_EVENT_EXPIRYDATE: {
    return Object.assign({}, state, {
      ...state,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        expiryDate: action.expiryDate,
      },
    });
  }
  case types.RESET_EVENT_CONFIGURE_FORM: {
    return Object.assign({}, state, {
      ...state,
      eventConfigFormInputs: {
        name: '',
        description: '',
        expiryDate: moment().add(1, 'day'),
      },
    });
  }

  case types.EVENTCREATE_REQUEST:
    return {
      ...state,
      fetching: true,
    };

  case types.EVENTCREATE_SUCCESS:
    return Object.assign({}, state, {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        eventId: action.data.eventId,
      },
    });

  case types.EVENTCREATE_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };

  case types.EVENTUPDATE_REQUEST:
    return {
      ...state,
      fetching: true,
    };

  case types.EVENTUPDATE_SUCCESS:
    return {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
    };

  case types.EVENTUPDATE_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };

  case types.EVENTVIEW_REQUEST:
    return {
      ...state,
      fetching: true,
    };

  case types.EVENTVIEW_SUCCESS:

    return Object.assign({}, state, {
      ...state,
      mode: 'view',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        eventId: action.data.events.event.id,
        name: action.data.events.event.name,
        description: action.data.events.event.description,
        expiryDate: moment(action.data.events.event['end-date']),
      },
    });

  case types.EVENTVIEW_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };
  case types.EVENTEDIT_REQUEST:
    return {
      ...state,
      fetching: true,
    };

  case types.EVENTEDIT_SUCCESS:
    return Object.assign({}, state, {
      ...state,
      mode: 'edit',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        eventId: action.data.events.event.id,
        name: action.data.events.event.name,
        description: action.data.events.event.description,
        expiryDate: moment(action.data.events.event['end-date']),
      },
    });

  case types.EVENTEDIT_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };

  case types.RESET_EVENT_CONFIGURE_EDIT_FORM: {
    const prevState = state.eventViewEditData.data.events.event;
    return Object.assign({}, state, {
      ...state,
      eventConfigFormInputs: {
        ...state.eventConfigFormInputs,
        name: prevState.name,
        description: prevState.description,
        expiryDate: prevState['end-date'],
      },
    });
  }

  case types.SET_VIEW_EVENT:
    return Object.assign({}, state, {
      ...state,
      eventViewEditData: action.data,
    });
  case types.CLEAR_VIEW_EVENT:
    return Object.assign({}, state, {
      ...state,
      mode: 'create',
      eventViewEditData: false,
      result: false,
      eventConfigFormInputs: {
        eventId: '',
        name: '',
        description: '',
        expiryDate: moment().add(1, 'day'),
      },
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: '',
    });

  default:
    return state;
  }
};
