import { pageNavigationURL } from '../../../redux/constants/urls';
import DesktopIcon from '../../../static/icons/white/desktopIcon.svg';
import Dollar from '../../../static/icons/white/dollar.svg';
import DollarRound from '../../../static/icons/white/dollar-round.svg';
import PayByLink from '../../../static/icons/white/link.svg';
import Megaphone from '../../../static/icons/white/megaphone.svg';
import Newspaper from '../../../static/icons/white/newspaper.svg';
import Notification from '../../../static/icons/white/notification.svg';
import Route from '../../../static/icons/white/route.svg';
import Target from '../../../static/icons/white/target.svg';
import Geofence from '../../../static/icons/white/geofence.svg';
import Topics from '../../../static/icons/white/topic.svg';
import ScreenConfigurations from '../../../static/icons/white/screenconfige.svg';
import HostedPaymentPage from '../../../static/icons/white/hostedpayment.svg';
import MerchantOnboardingLocale from '../../../static/icons/white/marchanonboarding.svg';
import ConfigureServices from '../../../static/icons/white/Configeservices.svg';
import TravelBank from '../../../static/icons/white/travelbank.svg';
import Configureicon from '../../../static/icons/settings.svg';


// filters the features/menus based on permission
const filterPermission = list => list.filter(key => key.permission);


// take first url of submenus based on permission
const getSubMenuURLS = list => {
  const filteredList = filterPermission(list);
  return filteredList.length > 0 ? filteredList[0].menuURL : '/';
};

// checks if all submenus has permission
const getSubMenuPermission = list => {
  const filteredList = filterPermission(list);
  return filteredList.length > 0;
};


// List of all features
export function getAllFeatures(permissions) {
  const dashboard = [
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: pageNavigationURL.transactionsDashboard,
      menuItem: 'Transactions',
      permission: permissions.dashboards.transactions,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/RevenueDashboard',
      menuItem: 'Revenue',
      permission: permissions.dashboards.revenue,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/FraudCheckDashboard',
      menuItem: 'Fraud Screening',
      permission: permissions.dashboards.fraudcheck,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/ProfileDashboard',
      menuItem: 'Profile',
      permission: permissions.dashboards.profile,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/PromotionsDashboard',
      menuItem: 'Promotion',
      permission: permissions.dashboards.promotion,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/KPIsDashboard',
      menuItem: 'KPIs',
      permission: permissions.dashboards.KPIDashboard,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: '/ProductSalesDashboard',
      menuItem: 'Sales',
      permission: permissions.dashboards.product,
    },
    {
      id: 'analytics',
      subMenuId: 'dashboard',
      menuURL: pageNavigationURL.notificationDashboard,
      menuItem: 'Notification',
      permission: permissions.dashboards.notification,
    }];


  const reports = [
    {
      id: 'analytics',
      subMenuId: 'reports',
      menuURL: '/Reports',
      menuItem: 'View Reports',
      permission: permissions.reports.viewReports,
    },
    {
      id: 'analytics',
      subMenuId: 'reports',
      menuURL: pageNavigationURL.mySubscriptions,
      menuItem: 'My Subscriptions',
      permission: permissions.reports.mySubscriptions,
    },

  ];
  const analytics = [
    {
      id: 'analytics',
      menuURL: getSubMenuURLS(dashboard),
      menuItem: 'Dashboard',
      permission: getSubMenuPermission(dashboard),
      subMenus: filterPermission(dashboard),
      icon: DesktopIcon,
    },
    {
      id: 'analytics',
      menuURL: getSubMenuURLS(reports),
      menuItem: 'Reports',
      permission: getSubMenuPermission(reports),
      subMenus: filterPermission(reports),
      icon: Newspaper,
    },
    {
      id: 'analytics',
      menuURL: pageNavigationURL.manageTransaction,
      menuItem: 'Manage Transaction',
      permission: permissions.reports.manageTransaction,
      icon: Dollar,
    },
  ];

  const voyage = [
    {
      id: 'voyage',
      menuURL: '/Promotions',
      menuItem: 'Promotions and Events',
      permission: permissions.vantage.promotionsAndEvents,
      icon: Megaphone,
    },
    {
      id: 'voyage',
      menuURL: '/Geofence',
      menuItem: 'Geofence',
      permission: permissions.vantage.geofence,
      icon: Geofence,
    },
    {
      id: 'voyage',
      menuURL: '/Topics',
      menuItem: 'Topics',
      permission: permissions.vantage.topics,
      icon: Topics,
    },
    {

      id: 'voyage',
      menuURL: '/ScreenConfigurations',
      menuItem: 'Screens Configurations',
      permission: permissions.notification.screenConfigurations,
      icon: ScreenConfigurations,
    },
    {

      id: 'voyage',
      menuURL: '/NotificationEngine',
      menuItem: 'Notification Engine',
      permission: permissions.notification.notificationEngine,
      icon: Notification,
    },
  ];


  const velocity = [
    // {
    //   id: 'velocity',
    //   menuURL: '/PayByLink',
    //   menuItem: 'Pay By Link',
    //   permission: permissions.velocity.payByLink,
    //   icon: PayByLink,
    // },
    {
      id: 'velocity',
      menuURL: pageNavigationURL.paymentLink,
      menuItem: 'PaymentLink Subscription',
      permission: permissions.velocity.paymentLink,
      icon: PayByLink,
    },
    {
      id: 'velocity',
      menuURL: pageNavigationURL.autoTrigger,
      menuItem: 'Re-targeting',
      permission: permissions.velocity.autoTrigger,
      icon: Target,
    },
    {
      id: 'velocity',
      menuURL: pageNavigationURL.dynamicRouting,
      menuItem: 'Business Rule Engine',
      permission: permissions.velocity.dynamicRouting,
      icon: Route,
    },
    {
      id: 'velocity',
      menuURL: '/FX',
      menuItem: 'Currency / FX',
      permission: permissions.velocity.fx,
      icon: DollarRound,
    },
    {
      id: 'velocity',
      menuURL: '/HostedPaymentPage',
      menuItem: 'Hosted Payment Page',
      permission: permissions.velocity.hostedPaymentPage,
      icon: HostedPaymentPage,
    },
    {
      id: 'velocity',
      menuURL: '/MerchantOnboardingLocale',
      menuItem: 'Merchant Onboarding',
      permission: permissions.velocity.merchantOnboardingLocale,
      icon: MerchantOnboardingLocale,
    },
    {
      id: 'velocity',
      menuURL: '/ConfigureServices',
      menuItem: 'Configure Services',
      permission: permissions.velocity.configureServices,
      icon: ConfigureServices,
    },
    {
      id: 'velocity',
      menuURL: '/TravelBank',
      menuItem: 'Travel Bank',
      permission: permissions.velocity.travelBank,
      icon: TravelBank,
    },
  ];

  const console = [
    {
      id: 'console',
      menuURL: '/MerchantSetup',
      menuItem: 'Merchant Onboarding',
      permission: permissions.console.merchantOnBoarding,
      icon: Configureicon,
    },
    {
      id: 'console',
      menuURL: '/EventNotification',
      menuItem: 'Event Notification',
      permission: permissions.console.eventNotification,
      icon: Configureicon,
    },
  ];

  const allmenusList = [
    ...dashboard, ...reports, ...analytics, ...voyage, ...velocity, ...console,
  ];
  return {
    analytics, voyage, velocity, console, allmenusList,
  };
}


export function features(permissions) {
  const allFeatureList = getAllFeatures(permissions);

  // Group the filtered(permission) features
  const finalList = [];
  finalList.push({
    menuGroup: 'Analytics',
    menus: filterPermission(allFeatureList.analytics),
  },
  {
    menuGroup: 'Velocity',
    menus: filterPermission(allFeatureList.velocity),
  },
  {
    menuGroup: 'Voyage',
    menus: filterPermission(allFeatureList.voyage),
  },
  {
    menuGroup: 'Console',
    menus: filterPermission(allFeatureList.console),
  });

  return finalList;
}
