import Form from 'react-bootstrap/Form';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React from 'react';

function VasSetupClientName() {
  return (
    <div className="p-4">
      <Form>
        <div className="row">
          <Form className="col-md-3">
            <div className="form-group">
              <Form.Label>
                Client Name
              </Form.Label>
              <Form.Control
                type="text"
                value={window.localStorage.clientName}
                disabled={1}
              />
            </div>
          </Form>
        </div>
      </Form>
    </div>
  );
}
export default VasSetupClientName;
