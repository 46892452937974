import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
};

export const getFilterConfigurationsResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALL_FILTER_CONFIGURATIONS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALL_FILTER_CONFIGURATIONS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALL_FILTER_CONFIGURATIONS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getAllKeysResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALL_KEYS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALL_KEYS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALL_KEYS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getAllQueuesResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALL_QUEUES_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALL_QUEUES_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALL_QUEUES_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getAllFiltersResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALL_FILTERS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALL_FILTERS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALL_FILTERS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const createFilterConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CREATE_FILTER_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CREATE_FILTER_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CREATE_FILTER_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteFilterConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_FILTER_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_FILTER_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_FILTER_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getFilterConfigResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_FILTER_CONFIG_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_FILTER_CONFIG_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_FILTER_CONFIG_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const updateFilterConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_FILTER_CONFIG_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_FILTER_CONFIG_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_FILTER_CONFIG_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
