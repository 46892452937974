import PropTypes from 'prop-types';
import Multiselect from '@khanacademy/react-multi-select';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import React, { useState, useEffect } from 'react';
import {
  getRouteConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import { activeKeyConfigurePayementGateway, off, on } from '../Constants';

function EditConfigurePaymentGateway(props) {
  const [editPopSetupInputField, setEditPopSetupInputField] = useState({
    paymentGatewayAcquirer: '',
    paymentGatewayAcquirerId: '',
    routeName: '',
    routeId: '',
    mid: '',
    username: '',
    password: '',
    country: '',
    currency: '',
    paymentMethod: '',
    paymentMethodId: '',
    captureType: '',
    captureTypeId: '',
    refundRouteFeature: on,
    cancelRouteFeature: on,
    partialCaptureRouteFeature: on,
    partialRefundRouteFeature: on,
    partialCancelRouteFeature: on,
    installmentRouteFeature: on,
    typeOf3ds: '',
  });
  const [assignDataFlag, setAssignDataFlag] = useState(true);
  const viewMode = props.getFormMode.result === 'View';
  const [showLoader, setShowLoader] = useState(false);

  const [validationError, setValidationError] = useState({
    paymentGatewayAcquirerValueValidation: false,
    routeNameValueValidation: false,
    paymentMethodValueValidation: false,
    captureTypeValueValidation: false,
    midValueValidation: false,
    usernameValueValidation: false,
    passwordValueValidation: false,
  });

  useEffect(() => {
    if (assignDataFlag) {
      const requestBodyForGetRouteApi = {
        route_id: props.selectedRouteId,
        is_meta_data_required: false,
      };
      props.getRouteConfigurationRequest(requestBodyForGetRouteApi);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => loadPspConfigurationResult(), 500);
    return () => clearTimeout(timer);
  }, [props.getRouteConfigurationResult]);

  const loadPspConfigurationResult = () => {
    let routePspConfigurationResult = [];
    if (props.activeKey === activeKeyConfigurePayementGateway && props.getRouteConfigurationResult.fetched && props.getRouteConfigurationResult.requestStatus && !props.getRouteConfigurationResult.fetching) {
      routePspConfigurationResult = props.getRouteConfigurationResult.result.route_configurations;
      if (routePspConfigurationResult) {
        _setData(routePspConfigurationResult[0]);
      } else {
        _setData([]);
      }
    }
  };

  useEffect(() => {
    if (props.getRouteConfigurationResult.fetching) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  });

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'paymentMethod') {
      value = event;
    } else if (field === 'country' || field === 'currency' || field === 'captureType' || field === 'captureType' || field === 'typeOf3ds') {
      value = event.target.value;
    } else {
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
    }
    setEditPopSetupInputField({ ...editPopSetupInputField, [field]: value });
  };

  const _inputOnChange = (event, field) => {
    const value = event.target.value;
    if (field === 'refundRouteFeature') {
      if (editPopSetupInputField.refundRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.refundRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else if (field === 'cancelRouteFeature') {
      if (editPopSetupInputField.cancelRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.cancelRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else if (field === 'partialCaptureRouteFeature') {
      if (editPopSetupInputField.partialCaptureRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.partialCaptureRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else if (field === 'partialRefundRouteFeature') {
      if (editPopSetupInputField.partialRefundRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.partialRefundRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else if (field === 'partialCancelRouteFeature') {
      if (editPopSetupInputField.partialCancelRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.partialCancelRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else if (field === 'installmentRouteFeature') {
      if (editPopSetupInputField.installmentRouteFeature === on) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: off });
      } else if (editPopSetupInputField.installmentRouteFeature === off) {
        setEditPopSetupInputField({ ...editPopSetupInputField, [field]: on });
      }
    } else {
      setEditPopSetupInputField({ ...editPopSetupInputField, [field]: value });
    }
  };

  function _setData(response) {
    if (response) {
      const requiredPaymentMethodIds = [];
      if (response.pm_configurations) {
        response.pm_configurations.forEach(keyPm => {
          requiredPaymentMethodIds.push(keyPm.pm_id);
        });
      }

      const requiredCountryIds = [];
      let intCountryId = '';
      if (response.country_details) {
        response.country_details.forEach(keyCountry => {
          requiredCountryIds.push(keyCountry.id);
        });
      }
      if (requiredCountryIds) {
        intCountryId = (requiredCountryIds[0] === null || requiredCountryIds[0] === 0) ? 0 : requiredCountryIds[0];
      }
      const requiredCurrencyIds = [];
      let intCurrencyId = '';
      if (response.currency_details) {
        response.currency_details.forEach(keyCurrency => {
          requiredCurrencyIds.push(keyCurrency.id);
        });
      }
      if (requiredCurrencyIds) {
        intCurrencyId = (requiredCurrencyIds[0] === null || requiredCurrencyIds[0] === 0) ? 0 : requiredCurrencyIds[0];
      }

      editPopSetupInputField.editKey = props.editKey;
      editPopSetupInputField.routeId = props.selectedRouteId;
      editPopSetupInputField.paymentGatewayAcquirerId = props.selectedPaymentGatewayAcquirerId;
      editPopSetupInputField.routeName = response.name;
      editPopSetupInputField.mid = response.mid;
      editPopSetupInputField.username = response.username;
      editPopSetupInputField.password = response.password;
      editPopSetupInputField.country = intCountryId;
      editPopSetupInputField.currency = intCurrencyId;
      editPopSetupInputField.paymentMethod = requiredPaymentMethodIds;
      editPopSetupInputField.captureType = response.capture_type;

      const routeFeaturesListArray = [];
      let partialCaptureDetails = [];
      let refundDetails = [];
      let partialRefundDetails = [];
      let installmentDetails = [];
      let cancelDetails = [];
      let partialCancelDetails = [];
      let typeOf3dsDetails = [];

      const routeFeaturesDetails = response.route_features;
      if (routeFeaturesDetails) {
        routeFeaturesDetails.forEach(key => {
          const selectOption = {
            id: key.id,
            name: key.name,
          };
          routeFeaturesListArray.push(selectOption);
        });

        partialCaptureDetails = routeFeaturesListArray.filter(x => (x.id === 4));
        if (partialCaptureDetails.length === 1 && typeof partialCaptureDetails[0].id === 'number') {
          editPopSetupInputField.partialCaptureRouteFeature = off;
        }

        refundDetails = routeFeaturesListArray.filter(x => (x.id === 5));
        if (refundDetails.length === 1 && typeof refundDetails[0].id === 'number') {
          editPopSetupInputField.refundRouteFeature = off;
        }
        partialRefundDetails = routeFeaturesListArray.filter(x => (x.id === 6));
        if (partialRefundDetails.length === 1 && typeof partialRefundDetails[0].id === 'number') {
          editPopSetupInputField.partialRefundRouteFeature = off;
        }

        installmentDetails = routeFeaturesListArray.filter(x => (x.id === 10));
        if (installmentDetails.length === 1 && typeof installmentDetails[0].id === 'number') {
          editPopSetupInputField.installmentRouteFeature = off;
        }

        cancelDetails = routeFeaturesListArray.filter(x => (x.id === 18));
        if (cancelDetails.length === 1 && typeof cancelDetails[0].id === 'number') {
          editPopSetupInputField.cancelRouteFeature = off;
        }

        partialCancelDetails = routeFeaturesListArray.filter(x => (x.id === 19));
        if (partialCancelDetails.length === 1 && typeof partialCancelDetails[0].id === 'number') {
          editPopSetupInputField.partialCancelRouteFeature = off;
        }

        typeOf3dsDetails = routeFeaturesListArray.filter(x => ([9, 20].includes(x.id)));
        if (typeOf3dsDetails.length === 1 && typeof typeOf3dsDetails[0].id === 'number') {
          editPopSetupInputField.typeOf3ds = typeOf3dsDetails[0].id;
        }
      }

      setAssignDataFlag(false);
    }
  }

  function configurePaymentGatewayValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (editPopSetupInputField.routeName === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = true;
      validate = false;
    } else if (editPopSetupInputField.mid === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = true;
      validate = false;
    } else if (editPopSetupInputField.paymentMethod === '' || editPopSetupInputField.paymentMethod.length === 0) {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = true;
      validate = false;
    } else if (editPopSetupInputField.captureType === '' || editPopSetupInputField.captureType === 'Please Select') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = true;
      validate = false;
    } else if (editPopSetupInputField.username === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.usernameValueValidation = true;
      validate = false;
    } else if (editPopSetupInputField.password === '') {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.usernameValueValidation = false;
      errorValue.passwordValueValidation = true;
      validate = false;
    } else {
      errorValue.paymentGatewayAcquirerValueValidation = false;
      errorValue.routeNameValueValidation = false;
      errorValue.midValueValidation = false;
      errorValue.paymentMethodValueValidation = false;
      errorValue.captureTypeValueValidation = false;
      errorValue.usernameValueValidation = false;
      errorValue.passwordValueValidation = false;
    }
    setValidationError(errorValue);
    return validate;
  }

  const updateState = () => {
    if (configurePaymentGatewayValidation()) {
      const passCfgDataToParent = [];
      props.cfgData.forEach(key => {
        if (key.routeId === editPopSetupInputField.routeId) {
          const paymentMethodFilteredArray = [];
          if (props.paymentMethodSelectedListArray) {
            const paymentMethodFilteredArrayForTableData = props.paymentMethodSelectedListArray.filter(x => editPopSetupInputField.paymentMethod.includes(x.value));
            paymentMethodFilteredArrayForTableData.forEach(key => {
              paymentMethodFilteredArray.push(key.label);
            });
          }

          // Get the data from the payment Gateway Acquirer
          let requiredPaymentGatewayAcquirer = '';
          if (editPopSetupInputField.paymentGatewayAcquirerId) {
            const requiredPaymentGatewayAcquirerIdDetails = props.paymentGatewayAcquirerArray.filter(x => (x.acquirer_id === Number(editPopSetupInputField.paymentGatewayAcquirerId)));
            requiredPaymentGatewayAcquirer = requiredPaymentGatewayAcquirerIdDetails[0].acquirer_name;
          }

          // Get the data from the capture type
          let requiredCaptureTypeName = '';
          if (editPopSetupInputField.captureType) {
            const requiredCaptureTypeIdDetails = props.captureTypeListArray.filter(x => (x.capture_type_id === Number(editPopSetupInputField.captureType)));
            requiredCaptureTypeName = requiredCaptureTypeIdDetails[0].name;
          }

          // Get the data from the Route Features
          let inputRefundRouteFeature = '';
          if (editPopSetupInputField.refundRouteFeature === off) {
            inputRefundRouteFeature = props.refundDetails[0].id;
          }
          let inputCancelRouteFeature = '';
          if (editPopSetupInputField.cancelRouteFeature === off) {
            inputCancelRouteFeature = props.cancelDetails[0].id;
          }
          let inputPartialCaptureRouteFeature = '';
          if (editPopSetupInputField.partialCaptureRouteFeature === off) {
            inputPartialCaptureRouteFeature = props.partialCaptureDetails[0].id;
          }
          let inputPartialRefundRouteFeature = '';
          if (editPopSetupInputField.partialRefundRouteFeature === off) {
            inputPartialRefundRouteFeature = props.partialRefundDetails[0].id;
          }
          let inputPartialCancelRouteFeature = '';
          if (editPopSetupInputField.partialCancelRouteFeature === off) {
            inputPartialCancelRouteFeature = props.partialCancelDetails[0].id;
          }
          let inputInstallmentRouteFeature = '';
          if (editPopSetupInputField.installmentRouteFeature === off) {
            inputInstallmentRouteFeature = props.installmentDetails[0].id;
          }

          passCfgDataToParent.push({
            key: editPopSetupInputField.editKey,
            paymentGatewayAcquirer: requiredPaymentGatewayAcquirer,
            paymentGatewayAcquirerId: editPopSetupInputField.paymentGatewayAcquirerId,
            routeId: editPopSetupInputField.routeId,
            routeName: editPopSetupInputField.routeName,
            mid: editPopSetupInputField.mid,
            username: editPopSetupInputField.username,
            password: editPopSetupInputField.password,
            country: editPopSetupInputField.country,
            currency: editPopSetupInputField.currency,
            paymentMethod: paymentMethodFilteredArray,
            paymentMethodId: editPopSetupInputField.paymentMethod,
            captureType: requiredCaptureTypeName,
            captureTypeId: editPopSetupInputField.captureType,
            refundRouteFeature: inputRefundRouteFeature,
            cancelRouteFeature: inputCancelRouteFeature,
            partialCaptureRouteFeature: inputPartialCaptureRouteFeature,
            partialRefundRouteFeature: inputPartialRefundRouteFeature,
            partialCancelRouteFeature: inputPartialCancelRouteFeature,
            installmentRouteFeature: inputInstallmentRouteFeature,
            typeOf3ds: editPopSetupInputField.typeOf3ds,
            action: '',
            deleteKey: false,
            existFlag: false,
            hybrid: true,
          });

          // passCfgDataToParent.push(editPopSetupInputField);
        } else {
          passCfgDataToParent.push(key);
        }
      });

      props.updatePopupData(passCfgDataToParent);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        {(!showLoader) ? <div className="p-4">
          <Form>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Payment Gateway / Acquirer
                    </Form.Label>

                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'paymentGatewayAcquirer')}
                      value={editPopSetupInputField.paymentGatewayAcquirerId}
                      disabled
                    >
                      <option disabled selected value="">Please Select</option>
                      {props.paymentGatewayAcquirerArray.map(key => <option value={key.acquirer_id}>{key.acquirer_name}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Route Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => _inputOnChange(e, 'routeName')}
                      value={editPopSetupInputField.routeName}
                    />
                    {validationError.routeNameValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter Route Name.
                    </Form.Text>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      MID
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => _inputOnChange(e, 'mid')}
                      value={editPopSetupInputField.mid}
                    />
                    {validationError.midValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter MID.
                    </Form.Text>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Username
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => _inputOnChange(e, 'username')}
                      value={editPopSetupInputField.username}
                    />
                    {validationError.usernameValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter Username.
                    </Form.Text>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={e => _inputOnChange(e, 'password')}
                      value={editPopSetupInputField.password}
                    />
                    {validationError.passwordValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter Password.
                    </Form.Text>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label>
                      Country
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'country')}
                      value={editPopSetupInputField.country}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {props.countryListArray.map(key => <option value={key.country_id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label>
                      Currency
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'currency')}
                      value={editPopSetupInputField.currency}
                    >
                      <option disabled selected value="">Please Select</option>
                      <option value={0}>-- All --</option>
                      {props.currencyListArray.map(key => <option value={key.currency_id}>{key.code}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Payment Method
                    </Form.Label>
                    <Multiselect
                      options={props.paymentMethodSelectedListArray}
                      selected={editPopSetupInputField.paymentMethod}
                      value={editPopSetupInputField.paymentMethod}
                      disabled={viewMode}
                      onSelectedChanged={e => inputOnChange(e, 'paymentMethod')}
                      overrideStrings={{
                        selectSomeItems: 'Select Payment Method',
                        allItemsAreSelected: 'All Payment Methods are selected',
                        selectAll: 'Select All Payment Methods',
                      }}
                    />
                    {validationError.paymentMethodValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter Payment Method.
                    </Form.Text>}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label className="mandatory">
                      Capture Type
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'captureType')}
                      value={editPopSetupInputField.captureType}
                    >
                      <option disabled selected value="">Please Select</option>
                      {props.captureTypeListArray.map(key => <option value={key.capture_type_id}>{key.name}</option>)}
                    </Form.Control>
                    {validationError.captureTypeValueValidation && <Form.Text className="text-danger" delay={5000}>
                      Please Enter Capture Type.
                    </Form.Text>}
                  </div>
                </div>
              </div>
            </div>
            <div className="cards_heading mr-1 ml-1 mt-3">Route Features</div>
            <div className="row">
              {props.refundDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.refundDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.refundRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'refundRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
              {props.cancelDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.cancelDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.cancelRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'cancelRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
              {props.partialCaptureDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.partialCaptureDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.partialCaptureRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialCaptureRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="row">
              {props.partialRefundDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.partialRefundDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.partialRefundRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialRefundRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
              {props.partialCancelDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.partialCancelDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.partialCancelRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'partialCancelRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
              {props.installmentDetails[0]
                && <div className="form-group col-md-4 pt-2 pb-2 mt-3">
                  <Form.Label>{props.installmentDetails[0].name}</Form.Label>
                  <div className="pretty p-switch p-fill d-block">
                    <input
                      type="checkbox"
                      checked={editPopSetupInputField.installmentRouteFeature === off}
                      // disabled
                      onClick={e => _inputOnChange(e, 'installmentRouteFeature')} />
                    <div className="state p-success">
                      <label htmlFor="Toggle" />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <div>
                    <Form.Label>
                      Type of 3DS
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'typeOf3ds')}
                      value={editPopSetupInputField.typeOf3ds}
                    >
                      <option disabled selected value="">Please Select</option>
                      {props.typeOf3dsDetails.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
            </div>
            <div className="cards_heading mr-1 ml-1 mt-3">&nbsp;</div>
            <div className="row">
              <div className="col-md-12  pt-2 pb-2 mt-3">
                <button
                  type="button"
                  className="btn btn-outline-primary float-right btn-sm  mr-3"
                  disabled={viewMode}
                  onClick={() => updateState()}
                >
                  Update
                </button>
              </div>
            </div>
          </Form>
        </div> : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
      </div>
    </div>
  );
}
EditConfigurePaymentGateway.propTypes = {
  getFormMode: PropTypes.any,
  getRouteConfigurationRequest: PropTypes.func,
  getRouteConfigurationResult: PropTypes.object,
  editKey: PropTypes.any,
  selectedRouteId: PropTypes.any,
  selectedPaymentGatewayAcquirerId: PropTypes.any,
  cfgData: PropTypes.array,
  paymentGatewayAcquirerArray: PropTypes.array,
  countryListArray: PropTypes.array,
  currencyListArray: PropTypes.array,
  paymentMethodSelectedListArray: PropTypes.array,
  captureTypeListArray: PropTypes.array,
  refundDetails: PropTypes.any,
  cancelDetails: PropTypes.any,
  partialCaptureDetails: PropTypes.any,
  partialRefundDetails: PropTypes.any,
  partialCancelDetails: PropTypes.any,
  installmentDetails: PropTypes.any,
  typeOf3dsDetails: PropTypes.array,
  updatePopupData: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getRouteConfigurationResult: state.getRouteConfigurationResult,
  getFormMode: state.getFormMode,
});
export default connect(
  mapStateToProps,
  {
    getRouteConfigurationRequest,
  }
)(EditConfigurePaymentGateway);
