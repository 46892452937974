
import { store } from '../../../redux/store';
import * as types from '../../../redux/constants/actionTypes';
import { logoutRequest } from '../../../redux/actions/Login';

export const logoutRequestHandler = () => {
  store.dispatch(logoutRequest());
};

export const sessionRedirection = () => {
  store.dispatch(logoutRequest());
  setTimeout(() => {
    const url = JSON.parse(JSON.stringify(localStorage.getItem('callbackUrl')));
    localStorage.clear();
    const homeURL = '/';
    window.location.href = url === null ? homeURL : url;
  }, 2000);
};

export const sessionExpiryRedirection = () => {
  store.dispatch({ type: types.SET_SESSION_EXPIRED });
};
