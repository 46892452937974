import * as moment from 'moment';
import {
  CHANGE_DYNAMICBANNER_NAME,
  CHANGE_DYNAMICBANNER_FILE,
  ON_DYNAMICBANNER_FILE_DELETE,
  CHANGE_DYNAMICBANNER_STARTDATE,
  CHANGE_DYNAMICBANNER_ENDDATE,
  ADD_DYNAMICBANNER_ENDDATE,
  REMOVE_DYNAMICBANNER_ENDDATE,
  ADD_DYNAMICBANNER_NEWBANNER,
  DYNAMICBANNER_CREATE_REQUEST,
  DYNAMICBANNER_CREATE_SUCCESS,
  DYNAMICBANNER_CREATE_FAILURE,
  DYNAMICBANNER_UPDATE_REQUEST,
  DYNAMICBANNER_UPDATE_SUCCESS,
  DYNAMICBANNER_UPDATE_FAILURE,
  DYNAMICBANNER_VIEW_REQUEST,
  DYNAMICBANNER_VIEW_SUCCESS,
  DYNAMICBANNER_VIEW_FAILURE,
  DYNAMICBANNER_EDIT_REQUEST,
  DYNAMICBANNER_EDIT_FAILURE,
  DYNAMICBANNER_EDIT_SUCCESS,
  GET_DYNAMICBANNER_AWSOBJECT_REQUEST,
  GET_DYNAMICBANNER_AWSOBJECT_SUCCESS,
  GET_DYNAMICBANNER_AWSOBJECT_FAILURE,
  DYNAMICBANNER_DELETE_REQUEST,
  DYNAMICBANNER_DELETE_SUCCESS,
  DYNAMICBANNER_DELETE_FAILURE,
  SET_VIEW_DYNAMICBANNER,
  CLEAR_VIEW_DYNAMICBANNER,
} from './constants';


const initialState = {
  formMode: false,
  bannerNmbr: false,
  imageArray: [],
  bannerFormInputs: {
    name: '',
    files: {
      file: [
        {
          name: '',
          'start-date': moment(),
          'image-data': '',
        },
        {
          name: '',
          'start-date': moment(),
          'image-data': '',
        },
        {
          name: '',
          'start-date': moment(),
          'image-data': '',
        },
      ],
    },
  },
  dynamicBannerViewEditData: false,
  fetching: false,
  fetched: false,
  result: false,
  requestStatus: false,
  error: false,
};

export const dynamicBannerResult = (state = initialState, action) => {
  switch (action.type) {
  case CHANGE_DYNAMICBANNER_NAME: {
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        name: action.name,
      },
    });
  }


  case CHANGE_DYNAMICBANNER_FILE: {
    const newState = state.bannerFormInputs.files.file;
    newState[action.bannerNumber].name = action.fileName;
    newState[action.bannerNumber]['image-data'] = action.filedata;
    if (state.formMode === 'edit') newState[action.bannerNumber]['start-date'] = moment().add(1, 'minutes');

    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }


  case ON_DYNAMICBANNER_FILE_DELETE: {
    const newState = state.bannerFormInputs.files.file;
    newState[action.bannerNumber]['image-data'] = '';
    if (state.formMode === 'edit') newState[action.bannerNumber]['image-url'] = '';
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }


  case CHANGE_DYNAMICBANNER_STARTDATE: {
    const newState = state.bannerFormInputs.files.file;
    newState[action.bannerNumber]['start-date'] = action.date;
    if (state.bannerFormInputs.files.file[action.bannerNumber]['end-date']
    && state.bannerFormInputs.files.file[action.bannerNumber]['end-date'] !== '') {
      newState[action.bannerNumber]['end-date'] = moment(action.date).add(2, 'days');
    }
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }
  case CHANGE_DYNAMICBANNER_ENDDATE: {
    const newState = state.bannerFormInputs.files.file;
    newState[action.bannerNumber]['end-date'] = action.date;
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }
  case ADD_DYNAMICBANNER_ENDDATE: {
    const newState = state.bannerFormInputs.files.file;
    newState[action.bannerNumber]['end-date'] = moment(newState[action.bannerNumber]['start-date']).add(2, 'days');

    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }
  case REMOVE_DYNAMICBANNER_ENDDATE: {
    const newState = state.bannerFormInputs.files.file;
    delete newState[action.bannerNumber]['end-date'];
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          file: newState,
        },
      },
    });
  }


  case ADD_DYNAMICBANNER_NEWBANNER: {
    const newState = state.bannerFormInputs.files;
    const newFileArray = [];

    const newBanner = {
      name: '',
      'start-date': moment(),
      'image-data': '',
    };

    if (newState.file === undefined) {
      newFileArray.push(newBanner);
      newState.file = newFileArray;
    } else {
      newState.file.push(newBanner);
    }

    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: newState,
      },
    });
  }


  // Dynamic banner Create
  case DYNAMICBANNER_CREATE_REQUEST:
    return Object.assign({}, state, { ...state, fetching: true, error: '' });

  case DYNAMICBANNER_CREATE_SUCCESS:
    return Object.assign({}, state, {
      ...state,
      formMode: 'create',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
    });

  case DYNAMICBANNER_CREATE_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };

  // Dynamic banner Create
  case DYNAMICBANNER_UPDATE_REQUEST:
    return Object.assign({}, state, {
      ...state, fetching: true, fetched: false, error: '',
    });

  case DYNAMICBANNER_UPDATE_SUCCESS:
    return Object.assign({}, state, {
      ...state,
      formMode: 'create',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
    });

  case DYNAMICBANNER_UPDATE_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      error: 'something went wrong',
    };


  // Dynamic Banner VIew
  case DYNAMICBANNER_VIEW_REQUEST:
    return Object.assign({}, state, {
      ...state,
      bannerFormInputs: {
        name: '',
        files: {
          file: [
            {
              name: '',
              'start-date': '',
              'image-data': '',
            },
            {
              name: '',
              'start-date': '',
              'image-data': '',
            },
            {
              name: '',
              'start-date': '',
              'image-data': '',
            },
          ],
        },
      },
      fetching: true,
    });

  case DYNAMICBANNER_VIEW_SUCCESS: {
    const checkTypeOf = action.data.banners.banner.bannerURLs.url instanceof Array;
    const DynmcBnrFilesArray = [];
    if (!checkTypeOf) {
      DynmcBnrFilesArray.push(action.data.banners.banner.bannerURLs.url);
    } else {
      action.data.banners.banner.bannerURLs.url.map(file => DynmcBnrFilesArray.push(file));
    }


    return Object.assign({}, state, {
      ...state,
      formMode: 'view',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        name: action.data.banners.banner.name,
        id: action.data.banners.banner.id,
        files: {
          ...state.bannerFormInputs.files,
          file: DynmcBnrFilesArray,
        },
      },
    });
  }

  case DYNAMICBANNER_VIEW_FAILURE:
    return Object.assign({}, state, {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    });


  /*
  ** Dynamic Banner View AWS Object
  */
  case GET_DYNAMICBANNER_AWSOBJECT_REQUEST:
    return Object.assign({}, state, {
      ...state,
      fetching: true,
      bannerNmbr: action.bannerNumber,
    });

  case GET_DYNAMICBANNER_AWSOBJECT_SUCCESS: {
    const newState = state.imageArray;
    newState[action.bannerNumber] = action.data;

    const newBannerFormInputsState = state.bannerFormInputs.files.file;

    const checkTypeOf = newBannerFormInputsState instanceof Array;
    const DynmcBnrFilesArray = [];
    if (!checkTypeOf) DynmcBnrFilesArray.push(newBannerFormInputsState);
    else newBannerFormInputsState.map(file => DynmcBnrFilesArray.push(file));

    DynmcBnrFilesArray[action.bannerNumber]['image-data'] = action.data['image-url-response'].value;

    return Object.assign({}, state, {
      ...state,
      fetching: false,
      imageArray: newState,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        files: {
          ...state.bannerFormInputs.files,
          file: DynmcBnrFilesArray,
        },
      },
    });
  }
  case GET_DYNAMICBANNER_AWSOBJECT_FAILURE:
    return Object.assign({}, state, {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    });


  // Dynamic Banner Edit
  case DYNAMICBANNER_EDIT_REQUEST:
    return Object.assign({}, state, {
      ...state,
      fetching: true,
    });

  case DYNAMICBANNER_EDIT_SUCCESS: {
    const checkTypeOf = action.data.banners.banner.bannerURLs.url instanceof Array;
    const DynmcBnrFilesArray = [];
    if (!checkTypeOf) {
      DynmcBnrFilesArray.push(action.data.banners.banner.bannerURLs.url);
    } else {
      action.data.banners.banner.bannerURLs.url.map(file => DynmcBnrFilesArray.push(file));
    }

    return Object.assign({}, state, {
      ...state,
      formMode: 'edit',
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        name: action.data.banners.banner.name,
        id: action.data.banners.banner.id,
        files: {
          ...state.bannerFormInputs.files,
          file: DynmcBnrFilesArray,
        },
      },
    });
  }

  case DYNAMICBANNER_EDIT_FAILURE:
    return Object.assign({}, state, {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    });


  // Dynamic Banner Delete
  case DYNAMICBANNER_DELETE_REQUEST:
    return Object.assign({}, state, { ...state, fetching: true });
  case DYNAMICBANNER_DELETE_SUCCESS:
    return Object.assign({}, state, {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    });
  case DYNAMICBANNER_DELETE_FAILURE:
    return Object.assign({}, state, {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    });


  case SET_VIEW_DYNAMICBANNER:
    return Object.assign({}, state, {
      ...state,
      formMode: action.data.mode,
      bannerFormInputs: {
        ...state.bannerFormInputs,
        name: action.data.data.banners.banner.name,
        files: {
          file: action.data.data.banners.banner.bannerURLs.url,
        },
      },
    });

  // Clear banner form
  case CLEAR_VIEW_DYNAMICBANNER:
    return Object.assign({}, state, {
      formMode: 'create',
      bannerNmbr: false,
      imageArray: [],
      bannerFormInputs: {
        name: '',
        files: {
          file: [
            {
              name: '',
              'start-date': moment(),
              'image-data': '',
            },
            {
              name: '',
              'start-date': moment(),
              'image-data': '',
            },
            {
              name: '',
              'start-date': moment(),
              'image-data': '',
            },
          ],
        },
      },
      dynamicBannerViewEditData: false,
      fetching: false,
      fetched: false,
      result: false,
      requestStatus: false,
      error: false,
    });

  default:
    return state;
  }
};
