export function splitPaymentConfigList(listType, splitPaymentConfig, paymentTypeDetail) {
  const split = [];
  if (listType === 'conventional') {
    splitPaymentConfig.forEach(subTypes => {
      const conventionalDetails = [];
      let paymentType1 = '';
      let paymentType2 = '';
      let paymentType3 = '';
      let paymentType4 = '';
      let paymentTypeName1 = '';
      let paymentTypeName2 = '';
      let paymentTypeName3 = '';
      let paymentTypeName4 = '';
      const count = split.length + 1;
      if (subTypes.addon_configurations && subTypes.addon_configurations) {
        subTypes.addon_configurations.addon_configuration.forEach(addOnConfig => {
          conventionalDetails.push({
            paymentTypeId1: addOnConfig.sequence_no === 1 ? addOnConfig.payment_type_id : '',
            paymentTypeId2: addOnConfig.sequence_no === 2 ? addOnConfig.payment_type_id : '',
            paymentTypeId3: addOnConfig.sequence_no === 3 ? addOnConfig.payment_type_id : '',
            paymentTypeId4: addOnConfig.sequence_no === 4 ? addOnConfig.payment_type_id : '',
          });
        });
      }
      conventionalDetails.forEach(list => {
        paymentTypeDetail.forEach(pd => {
          if (list.paymentTypeId1 === pd.id) {
            paymentType1 = list.paymentTypeId1;
            paymentTypeName1 = pd.name;
          }
          if (list.paymentTypeId2 === pd.id) {
            paymentType2 = list.paymentTypeId2;
            paymentTypeName2 = pd.name;
          }
          if (list.paymentTypeId3 === pd.id) {
            paymentType3 = list.paymentTypeId3;
            paymentTypeName3 = pd.name;
          }
          if (list.paymentTypeId4 === pd.id) {
            paymentType4 = list.paymentTypeId4;
            paymentTypeName4 = pd.name;
          }
        });
      });
      if (subTypes.addon_configurations && subTypes.addon_configurations) {
        split.push({
          key: count,
          serviceTypeName: subTypes.sub_type,
          combinationName: subTypes.name,
          paymentType1Id: paymentType1,
          paymentType2Id: paymentType2,
          paymentType3Id: paymentType3,
          paymentType4Id: paymentType4,
          paymentType1Name: paymentTypeName1,
          paymentType2Name: paymentTypeName2,
          paymentType3Name: paymentTypeName3,
          paymentType4Name: paymentTypeName4,
          action: '',
          deleteKey: false,
          existFlag: true,
          conventional: true,
        });
      }
    });
  } else if (listType === 'cashless') {
    splitPaymentConfig.forEach(subTypes => {
      const cashlessDetails = [];
      let paymentType1 = '';
      let paymentType2 = '';
      let paymentType3 = '';
      let paymentType4 = '';
      let paymentTypeName1 = '';
      let paymentTypeName2 = '';
      let paymentTypeName3 = '';
      let paymentTypeName4 = '';
      const count = split.length + 1;
      if (subTypes && subTypes.addon_configurations) {
        subTypes.addon_configurations.addon_configuration.forEach(addOnConfig => {
          cashlessDetails.push({
            paymentTypeId1: addOnConfig.sequence_no === 1 ? addOnConfig.payment_type_id : '',
            paymentTypeId2: addOnConfig.sequence_no === 2 ? addOnConfig.payment_type_id : '',
            paymentTypeId3: addOnConfig.sequence_no === 3 ? addOnConfig.payment_type_id : '',
            paymentTypeId4: addOnConfig.sequence_no === 4 ? addOnConfig.payment_type_id : '',
          });
        });
      }
      cashlessDetails.forEach(list => {
        paymentTypeDetail.forEach(pd => {
          if (list.paymentTypeId1 === pd.id) {
            paymentType1 = list.paymentTypeId1;
            paymentTypeName1 = pd.name;
          }
          if (list.paymentTypeId2 === pd.id) {
            paymentType2 = list.paymentTypeId2;
            paymentTypeName2 = pd.name;
          }
          if (list.paymentTypeId3 === pd.id) {
            paymentType3 = list.paymentTypeId3;
            paymentTypeName3 = pd.name;
          }
          if (list.paymentTypeId4 === pd.id) {
            paymentType4 = list.paymentTypeId4;
            paymentTypeName4 = pd.name;
          }
        });
      });
      if (subTypes.addon_configurations && subTypes.addon_configurations) {
        split.push({
          key: count,
          serviceTypeName: subTypes.sub_type,
          combinationName: subTypes.name,
          paymentType1Id: paymentType1,
          paymentType2Id: paymentType2,
          paymentType3Id: paymentType3,
          paymentType4Id: paymentType4,
          paymentType1Name: paymentTypeName1,
          paymentType2Name: paymentTypeName2,
          paymentType3Name: paymentTypeName3,
          paymentType4Name: paymentTypeName4,
          action: '',
          deleteKey: false,
          existFlag: true,
          cashless: true,
        });
      }
    });
  } else if (listType === 'hybrid') {
    splitPaymentConfig.forEach(subTypes => {
      const hybridDetails = [];
      let paymentType1 = '';
      let paymentType2 = '';
      let paymentType3 = '';
      let paymentType4 = '';
      let paymentTypeName1 = '';
      let paymentTypeName2 = '';
      let paymentTypeName3 = '';
      let paymentTypeName4 = '';
      const count = split.length + 1;
      if (subTypes && subTypes.addon_configurations) {
        subTypes.addon_configurations.addon_configuration.forEach(addOnConfig => {
          hybridDetails.push({
            paymentTypeId1: addOnConfig.sequence_no === 1 ? addOnConfig.payment_type_id : '',
            paymentTypeId2: addOnConfig.sequence_no === 2 ? addOnConfig.payment_type_id : '',
            paymentTypeId3: addOnConfig.sequence_no === 3 ? addOnConfig.payment_type_id : '',
            paymentTypeId4: addOnConfig.sequence_no === 4 ? addOnConfig.payment_type_id : '',
          });
        });
      }
      hybridDetails.forEach(list => {
        paymentTypeDetail.forEach(pd => {
          if (list.paymentTypeId1 === pd.id) {
            paymentType1 = list.paymentTypeId1;
            paymentTypeName1 = pd.name;
          }
          if (list.paymentTypeId2 === pd.id) {
            paymentType2 = list.paymentTypeId2;
            paymentTypeName2 = pd.name;
          }
          if (list.paymentTypeId3 === pd.id) {
            paymentType3 = list.paymentTypeId3;
            paymentTypeName3 = pd.name;
          }
          if (list.paymentTypeId4 === pd.id) {
            paymentType4 = list.paymentTypeId4;
            paymentTypeName4 = pd.name;
          }
        });
      });
      if (subTypes.addon_configurations && subTypes.addon_configurations) {
        split.push({
          key: count,
          serviceTypeName: subTypes.sub_type,
          combinationName: subTypes.name,
          paymentType1Id: paymentType1,
          paymentType2Id: paymentType2,
          paymentType3Id: paymentType3,
          paymentType4Id: paymentType4,
          paymentType1Name: paymentTypeName1,
          paymentType2Name: paymentTypeName2,
          paymentType3Name: paymentTypeName3,
          paymentType4Name: paymentTypeName4,
          action: '',
          deleteKey: false,
          existFlag: true,
          hybrid: true,
        });
      }
    });
  }
  return split;
}
