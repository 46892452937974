export function fraudConfigList(listType, addonConfigurationList) {
  const fraud = [];
  if (listType === 'preAuth') {
    addonConfigurationList.forEach(prePostAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        providerId: prePostAddonConfig.provider_id,
        providerName: prePostAddonConfig.provider_name,
        mid: prePostAddonConfig.mid,
        username: prePostAddonConfig.username,
        password: prePostAddonConfig.password,
        deleteKey: false,
        existFlag: true,
        preA: true,
      });
    });
  } else if (listType === 'postAuth') {
    addonConfigurationList.forEach(prePostAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        providerId: prePostAddonConfig.provider_id,
        providerName: prePostAddonConfig.provider_name,
        mid: prePostAddonConfig.mid,
        username: prePostAddonConfig.username,
        password: prePostAddonConfig.password,
        deleteKey: false,
        existFlag: true,
        postA: true,
      });
    });
  } else if (listType === '3DS') {
    addonConfigurationList.forEach(prePostAddonConfig => {
      const count = fraud.length + 1;
      fraud.push({
        key: count,
        providerId: prePostAddonConfig.provider_id,
        providerName: prePostAddonConfig.provider_name,
        mid: prePostAddonConfig.mid,
        username: prePostAddonConfig.username,
        password: prePostAddonConfig.password,
        deleteKey: false,
        existFlag: true,
        tDS: true,
      });
    });
  }
  return fraud;
}
