import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Accordion, Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useState, useEffect } from 'react';
import {
  tableHeadingColor, _onHoverBootstapTableTooltip, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut,
} from '../Helper';
import globals from '../../../../../redux/constants/globals';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import AlertPopup from '../../../../shared/Popups/AlertPopup';
import {
  getPSPConfigurationRequest, updatePSPConfigurationRequest, deletePSPConfigurationRequest, getPSPConfigurationByIdRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyAddPaymentGateway, validationRequiredInputMessage } from '../Constants';

function PopSetupAddPaymentGateway(props) {
  const reset = {
    addPaymentGateway: {
      nameProp: '',
      idProp: '',
    },
    propertyName: {
      nameProp: '',
      idProp: '',
    },
    propertyValue: '',
  };
  const [inputField, setInpuField] = useState(reset);
  const [addPGData, setAddPGData] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [prevPSPConfigurationResult, setPrevPSPConfigurationResult] = useState(props.getPSPConfigurationResult.result);
  const [prevPSPConfigurationByIdResult, setPrevPSPConfigurationByIdResult] = useState(props.getPSPConfigurationByIdResult.result);
  const [propertyNames, setPropertyNames] = useState([]);
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [msg, setMsg] = useState('');
  const [disFlag, setDisFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [alertMessageForAcquirer, setAlertMessageForAcquirer] = useState(false);
  const [idForAcquirer, setIdForAcquirer] = useState('');
  const [valueForAcquirer, setValueForAcquirer] = useState('');

  useEffect(() => {
    setPrevPSPConfigurationResult(props.getPSPConfigurationResult.result);
  }, [props.getPSPConfigurationResult.result]);

  useEffect(() => {
    if (prevPSPConfigurationByIdResult !== props.getPSPConfigurationByIdResult.result) {
      setPrevPSPConfigurationByIdResult(props.getPSPConfigurationByIdResult.result);
    }
  }, [props.getPSPConfigurationByIdResult.result]);

  const paymentGatewayAcquirerList = [];
  let clientPspConfigurationsArrayList = prevPSPConfigurationResult && prevPSPConfigurationResult.client_psp_configurations;
  if (clientPspConfigurationsArrayList) {
    if (!Array.isArray(clientPspConfigurationsArrayList)) {
      clientPspConfigurationsArrayList = [{
        ...clientPspConfigurationsArrayList,
      }];
    }

    let psps = prevPSPConfigurationResult && prevPSPConfigurationResult.psps;
    if (psps) {
      if (!Array.isArray(psps)) {
        psps = [{
          ...psps,
        }];
      }

      clientPspConfigurationsArrayList.forEach(element => {
        const pspName = psps.find(pspKey => (pspKey.id === element.id));
        if (pspName) {
          paymentGatewayAcquirerList.push({
            id: pspName.id,
            name: pspName.name,
          });
        }
      });
    }
  }

  const inputOnChange = (event, field) => {
    let value;
    const id = event.target.value;
    if (field === 'propertyName') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else if (field === 'addPaymentGateway') {
      const fieldValue = event.target.value;
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      inputField.propertyName.nameProp = '';
      inputField.propertyName.idProp = '';
      setPropertyNames([]);
      if (inputField.addPaymentGateway.idProp === '' || inputField.addPaymentGateway.nameProp === 'Please Select') {
        setAddPGData([]);
        setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
        const requestBodyForGetPSPConfigPropertiesById = {
          id: fieldValue,
          is_meta_data_required: false,
        };
        props.getPSPConfigurationByIdRequest(requestBodyForGetPSPConfigPropertiesById);
        if (props.getPSPConfigurationByIdResult.requestStatus === false && props.getPSPConfigurationByIdResult.fetched) {
          notify('error', toastMessages.merchantServices.error.backendError);
        }
        setApiCall(false);
      } else {
        const findUpdatedData = addPGData.some(element => (element.deleteKey === true || element.existFlag === false));
        if (findUpdatedData) {
          setIdForAcquirer(id);
          setValueForAcquirer(value);
          setAlertMessageForAcquirer(true);
        } else {
          setAddPGData([]);
          setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
          const requestBodyForGetPSPConfigPropertiesById = {
            id: fieldValue,
            is_meta_data_required: false,
          };
          props.getPSPConfigurationByIdRequest(requestBodyForGetPSPConfigPropertiesById);
          setApiCall(false);
          setAlertMessageForAcquirer(false);
        }
      }
    } else if (field === 'propertyValue') {
      setInpuField({ ...inputField, [field]: id });
    }
  };

  useEffect(() => {
    if (props.activeKey === activeKeyAddPaymentGateway && props.getPSPConfigurationByIdResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });

  useEffect(() => {
    if (!props.getPSPConfigurationByIdResult.error && props.getPSPConfigurationByIdResult.fetched && props.getPSPConfigurationByIdResult.requestStatus && !props.getPSPConfigurationResult.fetching) {
      setPrevPSPConfigurationByIdResult(props.getPSPConfigurationByIdResult.result);
    }
  }, [props.getPSPConfigurationByIdResult]);

  useEffect(() => {
    let routePspConfigurationResult = [];
    if (!props.getPSPConfigurationByIdResult.error && props.getPSPConfigurationByIdResult.fetched && props.getPSPConfigurationByIdResult.requestStatus && !props.getPSPConfigurationResult.fetching) {
      routePspConfigurationResult = props.getPSPConfigurationByIdResult.result.client_psp_configurations;
      if (routePspConfigurationResult) {
        routePspConfigurationResult.forEach(key => {
          if (key.property_details) {
            setAddPGData(createTableDate(routePspConfigurationResult));
          } else {
            setAddPGData([]);
            setPropertyNames([]);
          }
        });
      }
    }
  }, [props.getPSPConfigurationByIdResult]);

  const createTableDate = psp => {
    if (psp && !apiCall) {
      setAddPGData([]);
      setApiCall(true);
      psp.forEach(clientPspConfigurationsKey => {
        if (clientPspConfigurationsKey.property_details) {
          clientPspConfigurationsKey.property_details.forEach(propertyDetailsKey => {
            propertyDetailsKey.properties.forEach(propertiesKey => {
              if (!propertiesKey.value) {
                propertyNames.push({
                  id: Number(propertiesKey.id),
                  name: propertiesKey.name,
                });
              } else {
                addPGData.push({
                  key: addPGData.length + 1,
                  id: Number(clientPspConfigurationsKey.id),
                  propertyName: propertiesKey.name,
                  propertyId: Number(propertiesKey.id),
                  propertyValue: propertiesKey.value,
                  action: '',
                  deleteKey: false,
                  existFlag: true,
                  apg: true,
                });
              }
            });
          });
        }
      });
    }
    return addPGData;
  };


  const deleteRow = row => {
    let filter = '';
    if (row.apg) {
      filter = getUnique(addPGData, 'key');
      if (filter.length !== addPGData.length) {
        setAddPGData(filter);
      } else {
        filter = addPGData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setAddPGData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const action = (cell, row) => (
    <div>
      {(row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );


  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const _onClickAddButton = () => {
    let flag = false;
    const count = addPGData.length + 1;
    setMsg('');
    setDisFlag(false);
    if (inputField.propertyName.nameProp === undefined || inputField.propertyValue === undefined || inputField.propertyName.nameProp === '' || inputField.propertyValue === ''
      || inputField.propertyName.nameProp === 'Please Select' || inputField.propertyValue === 'Please Select') {
      setErrorMsg(true);
      const errmsg = validationRequiredInputMessage;
      setMsg(errmsg);
      alertMessageSetTimeOut();
      return false;
    }
    addPGData.forEach(data => {
      if (parseInt(data.propertyId, 10) === parseInt(inputField.propertyName.idProp, 10)) {
        flag = true;
        if (flag) {
          setErrorMsg(true);
          const errmsg = 'Property Already Configured';
          setMsg(errmsg);
          alertMessageSetTimeOut();
        }
      }
    });
    if (!flag) {
      addPGData.push({
        key: count,
        id: inputField.addPaymentGateway.idProp,
        propertyName: inputField.propertyName.nameProp,
        propertyId: inputField.propertyName.idProp,
        propertyValue: inputField.propertyValue,
        action: '',
        deleteKey: false,
        existFlag: false,
        apg: true,
      });
    }
    setAddPGData(addPGData);
    const data = { ...inputField };
    data.key = 0;
    data.propertyValue = '';
    data.propertyName.nameProp = '';
    data.propertyName.idProp = '';
    setInpuField(data);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  // set default data for table
  const clearState = () => {
    setDisFlag(false);
    setMsg('');
    const preConfigAddAPGdata = addPGData.filter(apg => apg.existFlag === true);
    const filter = preConfigAddAPGdata.filter((
      item => {
        if (item.deleteKey) {
          item.deleteKey = false; // eslint-disable-line no-param-reassign
        }
        return item;
      }
    ));
    setAddPGData(filter);
    const data = { ...inputField };
    data.key = 0;
    data.propertyValue = '';
    data.propertyName.nameProp = '';
    data.propertyName.idProp = '';
    setInpuField(data);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    notify('success', toastMessages.merchantServices.success.reset);
  };

  const propertyConfigurations = [];
  const savePspConfigResults = () => {
    if (addPGData.length > 0) {
      const findAPG = addPGData.some(element => (element.deleteKey === false));
      let keyId = '';
      addPGData.forEach(key => {
        keyId = key.id;
      });
      if (addPGData.length > 0 && !findAPG) {
        const requestBody = {
          id: keyId,
          propertyId: -1,
        };
        props.deletePSPConfigurationRequest(requestBody);
        setAddPGData([]);

        const propertyData = [];
        const routePspConfigurationResult = props.getPSPConfigurationByIdResult.result && props.getPSPConfigurationByIdResult.result.client_psp_configurations;
        if (routePspConfigurationResult) {
          routePspConfigurationResult.forEach(clientPspConfigurationsKey => {
            if (clientPspConfigurationsKey.property_details) {
              clientPspConfigurationsKey.property_details.forEach(propertyDetailsKey => {
                if (propertyDetailsKey.properties) {
                  propertyDetailsKey.properties.forEach(propertiesKey => {
                    propertyData.push({
                      id: Number(propertiesKey.id),
                      name: propertiesKey.name,
                    });
                  });
                }
              });
            }
          });
          setPropertyNames(propertyData);
        }
      } else {
        addPGData.forEach(key => {
          propertyConfigurations.push({
            id: key.id,
          });
        });
        const propertyConfigclientPspConfiguration = [];
        const propertyConfig = [];
        addPGData.forEach(keyId => {
          if (!keyId.deleteKey) {
            propertyConfig.push({
              id: keyId.propertyId,
              value: keyId.propertyValue,
            });
          }
        });
        if (propertyConfig.length > 0) {
          propertyConfigclientPspConfiguration.push({
            id: inputField.addPaymentGateway.idProp,
            properties: propertyConfig,
          });
        }
        const requestBody = {
          client_id: globals.clientId,
          client_psp_configurations: propertyConfigclientPspConfiguration,
        };
        props.updatePSPConfigurationRequest(requestBody);
      }

      // Make save button disabled
      setSaveButtonDisabledFlag(1);
    }
  };

  // Wait for Sucess result of updatePSPConfigurationResult then call getPSPConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyAddPaymentGateway && props.updatePSPConfigurationResult.fetched && !props.updatePSPConfigurationResult.fetching && !props.updatePSPConfigurationResult.error && props.updatePSPConfigurationResult.requestStatus) {
      notify('success', toastMessages.merchantServices.success.updated);
      const requestBodyForGetPSPConfigPropertiesById = {
        id: inputField.addPaymentGateway.idProp,
        is_meta_data_required: false,
      };
      props.getPSPConfigurationByIdRequest(requestBodyForGetPSPConfigPropertiesById);
    } else if (props.activeKey === activeKeyAddPaymentGateway && !props.updatePSPConfigurationResult.fetched && !props.updatePSPConfigurationResult.fetching && props.updatePSPConfigurationResult.error && !props.updatePSPConfigurationResult.requestStatus) {
      notify('error', toastMessages.merchantServices.error.updated);
    }
  }, [props.updatePSPConfigurationResult]);

  // Show Loader still get getPSPConfigurationResult or deletePSPConfigurationResult
  useEffect(() => {
    if (props.getPSPConfigurationByIdResult.fetching || props.deletePSPConfigurationResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });
  useEffect(() => {
    let routePspConfigurationResult = [];
    let routePspConfigurationResults = [];
    if (!props.updatePSPConfigurationResult.error && props.updatePSPConfigurationResult.fetched && props.updatePSPConfigurationResult.requestStatus && !props.updatePSPConfigurationResult.fetching) {
      routePspConfigurationResult = props.getPSPConfigurationByIdResult.result.client_psp_configurations;
      routePspConfigurationResults = prevPSPConfigurationByIdResult.client_psp_configurations;
      if (prevPSPConfigurationByIdResult !== routePspConfigurationResults) {
        if (routePspConfigurationResult) {
          routePspConfigurationResult.forEach(key => {
            if (key.property_details) {
              setAddPGData(updatedAddPGData(routePspConfigurationResult));
              setPropertyNames(updatedProperty(routePspConfigurationResult));
            } else {
              setAddPGData([]);
              setPropertyNames([]);
            }
          });
        }
      }
      setPrevPSPConfigurationByIdResult(props.getPSPConfigurationByIdResult.result);
    }
  }, [props.getPSPConfigurationByIdResult]);

  const updatedAddPGData = psp => {
    const apgData = [];
    if (psp) {
      psp.forEach(clientPspConfigurationsKey => {
        if (clientPspConfigurationsKey.property_details) {
          clientPspConfigurationsKey.property_details.forEach(propertyDetailsKey => {
            propertyDetailsKey.properties.forEach(propertiesKey => {
              if (!propertiesKey.value) {
                propertyNames.push({
                  id: Number(propertiesKey.id),
                  name: propertiesKey.name,
                });
              } else {
                apgData.push({
                  key: apgData.length + 1,
                  id: Number(clientPspConfigurationsKey.id),
                  propertyName: propertiesKey.name,
                  propertyId: Number(propertiesKey.id),
                  propertyValue: propertiesKey.value,
                  action: '',
                  deleteKey: false,
                  existFlag: true,
                  apg: true,
                });
              }
            });
          });
        }
      });
    }
    return apgData;
  };
  const updatedProperty = psp => {
    const propertyData = [];
    if (psp) {
      psp.forEach(clientPspConfigurationsKey => {
        if (clientPspConfigurationsKey.property_details) {
          clientPspConfigurationsKey.property_details.forEach(propertyDetailsKey => {
            propertyDetailsKey.properties.forEach(propertiesKey => {
              if (!propertiesKey.value) {
                propertyData.push({
                  id: Number(propertiesKey.id),
                  name: propertiesKey.name,
                });
              }
            });
          });
        }
      });
    }
    return propertyData;
  };
  const confirmYesData = () => {
    const field = 'addPaymentGateway';
    setAddPGData([]);
    setInpuField({ ...inputField, [field]: { nameProp: valueForAcquirer, idProp: idForAcquirer } });
    const requestBodyForGetPSPConfigPropertiesById = {
      id: idForAcquirer,
      is_meta_data_required: false,
    };
    props.getPSPConfigurationByIdRequest(requestBodyForGetPSPConfigPropertiesById);
    setApiCall(false);
    setAlertMessageForAcquirer(false);
  };
  const confirmNoData = () => {
    setAlertMessageForAcquirer(false);
  };
  return (
    <div className="card">
      {(alertMessageForAcquirer) && <AlertPopup
        onClickYes={() => confirmYesData()}
        onClickNo={() => confirmNoData()}
      />}
      {props.activeKey === activeKeyAddPaymentGateway && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = addPGData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setAddPGData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          savePspConfigResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyAddPaymentGateway ? '' : activeKeyAddPaymentGateway)} eventKey={activeKeyAddPaymentGateway}>
          <h6>
            Add Payment Gateway / Acquirer Properties
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyAddPaymentGateway}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Payment Gateway / Acquirer
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'addPaymentGateway')}
                      value={inputField.addPaymentGateway.idProp}
                      data-toggle="modal"
                      data-target="#alertModal"
                    >
                      <option disabled selected value="">Please Select</option>
                      {paymentGatewayAcquirerList && paymentGatewayAcquirerList.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
              </div>
              {(inputField.addPaymentGateway.nameProp) && <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Property Name
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'propertyName')}
                      value={inputField.propertyName.idProp}
                    >
                      <option selected value="">Please Select</option>
                      {propertyNames.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Value
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Value"
                      onChange={e => inputOnChange(e, 'propertyValue')}
                      value={inputField.propertyValue}
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-sm float-left btn-outline-primary mt-1"
                    onClick={() => _onClickAddButton()}>
                    <i className="fas fa-plus" />
                    {' '}
                    Add
                  </button>
                </div>
              </div>}
              {errorMsg && <div className="alert alert-danger">
                {errorMsg ? msg : ''}
              </div>}
              {disFlag && <div className="alert alert-danger">
                {disFlag ? validationRequiredInputMessage : ''}
              </div>}
              {(!isLoaderOn) ? <div className="row">
                {(inputField.addPaymentGateway.nameProp) && <div className="col-md-12  pt-2 pb-2 mt-3">
                  <BootstrapTable data={addPGData}>
                    <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyName" className={tableHeadingColor} dataSort dataAlign="left" columnClassName={_onClickDeleteRowStyleDisable}>Property Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="propertyValue" className={tableHeadingColor} dataSort dataAlign="left" columnTitle={_onHoverBootstapTableTooltip} columnClassName={_onClickDeleteRowStyleDisable}>Value</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataAlign="left" dataFormat={action} columnClassName={_onClickDeleteRowStyleDisable}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </div>}
                {(inputField.addPaymentGateway.nameProp) && <div className="col-md-12  pt-2 pb-2 mt-3">
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => savePspConfigResults()}
                  >
                    Save
                  </button>}

                  <Button
                    type="button"
                    className="btn btn-sm float-right btn-outline-primary mr-3"
                    onClick={() => clearState()}
                  >
                    Cancel
                  </Button>
                </div>}
              </div> : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}
PopSetupAddPaymentGateway.propTypes = {
  getPSPConfigurationResult: PropTypes.object,
  getPSPConfigurationByIdRequest: PropTypes.func,
  getPSPConfigurationByIdResult: PropTypes.object,

  updatePSPConfigurationRequest: PropTypes.func,
  updatePSPConfigurationResult: PropTypes.object,
  deletePSPConfigurationResult: PropTypes.object,
  deletePSPConfigurationRequest: PropTypes.func,

  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getPSPConfigurationResult: state.getPSPConfigurationResult,
  updatePSPConfigurationResult: state.updatePSPConfigurationResult,
  deletePSPConfigurationResult: state.deletePSPConfigurationResult,
  getPSPConfigurationByIdResult: state.getPSPConfigurationByIdResult,
});

export default connect(
  mapStateToProps, {
    getPSPConfigurationRequest, updatePSPConfigurationRequest, deletePSPConfigurationRequest, getPSPConfigurationByIdRequest,
  }
)(PopSetupAddPaymentGateway);
