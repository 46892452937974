import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MultiSelect from '@khanacademy/react-multi-select';
import Form from 'react-bootstrap/Form';
import { Accordion, Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import React, { useState, useEffect } from 'react';
import globals from '../../../../../redux/constants/globals';
import { tableHeadingColor, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut } from '../Helper';
import arrowDown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import {
  getPSPConfigurationRequest, updatePSPConfigurationRequest, deletePSPConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyEnablePaymentGateway, validationRequiredInputMessage, pspListArrayItems } from '../Constants';

function PopSetupEnablePaymentGateway(props) {
  const reset = {
    provider: {
      nameProp: '',
      idProp: '',
    },
    paymentMethod: [],
  };
  const [isLoaderOn, setIsLoaderOn] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [disFlag, setDisFlag] = useState(false);
  const [allDeleteFlag, setAllDeleteFlag] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [prevPSPConfigurationResult, setPrevPSPConfigurationResult] = useState(props.getPSPConfigurationResult.result);
  const isMetadataRequired = true;

  useEffect(() => {
    if (!props.getPSPConfigurationResult.result) {
      props.getPSPConfigurationRequest(isMetadataRequired);
    }
    if (!props.getPSPConfigurationResult.requestStatus && props.getPSPConfigurationResult.fetched) {
      notify('error', toastMessages.merchantServices.error.backendError);
    }
  }, []);
  useEffect(() => {
    if (props.activeKey === activeKeyEnablePaymentGateway && props.getPSPConfigurationResult.fetching) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });
  const pspsArrayList = [];
  let psps = prevPSPConfigurationResult && prevPSPConfigurationResult.psps;
  if (psps) {
    if (!Array.isArray(psps)) {
      psps = [{
        ...psps,
      }];
    }
    psps.forEach(key => {
      if (pspListArrayItems.includes(key.type_id)) {
        pspsArrayList.push({
          id: key.id,
          name: key.name,
          type_id: key.type_id,
        });
      }
    });
  }
  const [inputField, setInpuField] = useState(reset);
  const [enablePGdata, setenablePGdata] = useState([]);

  let payConfigList = [];
  const pmConfigurationsAPICall = paymentList => {
    let pmConfigurations = prevPSPConfigurationResult && prevPSPConfigurationResult.client_pm_configurations;
    if (pmConfigurations) {
      if (!Array.isArray(pmConfigurations)) {
        pmConfigurations = [{
          ...pmConfigurations,
        }];
      }
      payConfigList = pmConfigurations.filter(obj => obj.enabled === true).map(obj => obj.pm_id);
    }
    paymentList.forEach(key => {
      const multiSelectOption = {
        value: key.id,
        label: key.name,
      };
      paymentMethodListArray.push(multiSelectOption);
      if (payConfigList.includes(key.id)) paymentMethodSelectedListArray.push(multiSelectOption);
    });
  };
  const paymentMethodListArray = [];
  const paymentMethodSelectedListArray = [];
  let paymentMethod = prevPSPConfigurationResult && prevPSPConfigurationResult.pms;
  if (paymentMethod) {
    if (!Array.isArray(paymentMethod)) {
      paymentMethod = [{
        ...paymentMethod,
      }];
    }
    pmConfigurationsAPICall(paymentMethod);
  }
  let clientPspConfigurationsArrayList = prevPSPConfigurationResult && prevPSPConfigurationResult.client_psp_configurations;
  if (clientPspConfigurationsArrayList && !apiCall) {
    setApiCall(true);
    if (!Array.isArray(clientPspConfigurationsArrayList)) {
      clientPspConfigurationsArrayList = [{
        ...clientPspConfigurationsArrayList,
      }];
    }
    clientPspConfigurationsArrayList.forEach(clientPspConfigurationsKey => {
      let pspName = '';
      let pmName = '';
      if (pspsArrayList !== undefined) {
        pspName = pspsArrayList.find(pspKey => (pspKey.id === clientPspConfigurationsKey.id));
      }
      if (clientPspConfigurationsKey && clientPspConfigurationsKey.pm_configurations) {
        clientPspConfigurationsKey.pm_configurations.forEach(key => {
          if (paymentMethodListArray.length > 0) {
            pmName = paymentMethodListArray.find(paymentMethodKey => (key.pm_id === paymentMethodKey.value));
            enablePGdata.push({
              key: enablePGdata.length + 1,
              paymentMethod: pmName && pmName.label,
              pm_id: pmName && pmName.value,
              providerName: pspName && pspName.name,
              provider_id: pspName && pspName.id,
              action: '',
              deleteKey: false,
              existFlag: true,
              epg: true,
            });
          }
        });
      }
    });
  }
  const inputOnChange = (event, field) => {
    const id = event.target.value;
    if (field === 'provider' || field === 'paymentMethod') {
      const index = event.nativeEvent.target.selectedIndex;
      const value = event.nativeEvent.target[index].text;
      setInpuField({ ...inputField, [field]: { nameProp: value, idProp: id } });
    } else {
      setInpuField({ ...inputField, [field]: id });
    }
  };
  const inputOnChangePm = (event, field) => {
    setInpuField({ ...inputField, [field]: event });
  };
  const _onClickDelete = row => {
    deleteRow(row);
  };

  const deleteRow = row => {
    let filter = '';
    if (row.epg) {
      filter = getUnique(enablePGdata, 'key');
      if (filter.length !== enablePGdata.length) {
        setenablePGdata(filter);
      } else {
        filter = enablePGdata.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setenablePGdata(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };
  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const onClickAddButton = () => {
    let flag = false;
    setMsg('');
    setDisFlag(false);
    setAllDeleteFlag(false);
    if (inputField.provider.nameProp === undefined || inputField.paymentMethod === undefined || inputField.provider.nameProp === ''
      || inputField.provider.nameProp === 'Please Select' || inputField.paymentMethod === 'Select Payment Method' || inputField.paymentMethod.length === 0 || inputField.provider.nameProp === '') {
      setErrorMsg(true);
      const errmsg = validationRequiredInputMessage;
      setMsg(errmsg);
      alertMessageSetTimeOut();
      return false;
    }
    if (inputField.paymentMethod) {
      inputField.paymentMethod.forEach(key => {
        flag = false;
        enablePGdata.forEach(data => {
          if (data.provider_id === parseInt(inputField.provider.idProp, 10) && data.pm_id === key) {
            flag = true;
            if (flag) {
              setErrorMsg(true);
              const errmsg = 'Duplicate Entry Not Allow';
              setMsg(errmsg);
              alertMessageSetTimeOut();
            }
          }
        });
      });
      inputField.paymentMethod.forEach(key => {
        flag = false;
        enablePGdata.forEach(data => {
          if (data.provider_id === parseInt(inputField.provider.idProp, 10) && data.pm_id === key) {
            flag = true;
          }
        });
        if (!flag) {
          const selectedPaymentMethod = paymentMethodListArray.filter(listPm => listPm.value === key);
          const count = enablePGdata.length + 1;
          enablePGdata.push({
            key: count,
            paymentMethod: selectedPaymentMethod[0].label,
            pm_id: key,
            providerName: inputField.provider.nameProp,
            provider_id: parseInt(inputField.provider.idProp, 10),
            action: '',
            deleteKey: false,
            existFlag: false,
            epg: true,
          });
        }
      });
    }
    const formValues = { ...inputField };
    formValues.provider.idProp = '';
    formValues.provider.nameProp = 'Please Select';
    formValues.paymentMethod = '';
    setInpuField(formValues);

    // Make save button enabled
    setSaveButtonDisabledFlag(0);
  };
  const action = (cell, row) => (
    <div>
      {(row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={() => _onClickDelete(row)} title="Delete" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={() => _onClickDelete(row)} title="Add" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );
  // set default data for table
  const clearState = () => {
    setAllDeleteFlag(false);
    setDisFlag(false);
    setMsg('');
    const preConfigenablePGdata = enablePGdata.filter(epg => epg.existFlag === true);
    const filter = preConfigenablePGdata.filter((
      item => {
        if (item.deleteKey) {
          item.deleteKey = false; // eslint-disable-line no-param-reassign
        }
        return item;
      }
    ));
    setenablePGdata(filter);
    setInpuField(reset);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    notify('success', toastMessages.merchantServices.success.reset);
  };
  const pmConfigurations = [];
  const savePspConfigResults = () => {
    const findEPG = enablePGdata.some(element => (element.deleteKey === false));
    if (enablePGdata.length > 0 && !findEPG) {
      setAllDeleteFlag(true);
      // const requestBody = {
      //   id: -1,
      // };
      // props.deletePSPConfigurationRequest(requestBody);
      // setenablePGdata([]);
    } else {
      setAllDeleteFlag(false);
      enablePGdata.forEach(key => {
        pmConfigurations.push({
          id: key.provider_id,
        });
      });
      const filter = getUnique(pmConfigurations, 'id');
      const pmConfigclientPspConfiguration = [];
      filter.forEach(key => {
        const pmConfig = [];
        enablePGdata.forEach(keyId => {
          if (!keyId.deleteKey) {
            if (key.id === keyId.provider_id) {
              pmConfig.push({
                pm_id: keyId.pm_id,
              });
            }
          }
        });
        if (pmConfig.length > 0) {
          pmConfigclientPspConfiguration.push({
            id: key.id,
            pm_configurations: pmConfig,
          });
        }
      });
      const requestBody = {
        client_id: globals.clientId,
        client_psp_configurations: pmConfigclientPspConfiguration,
      };
      props.updatePSPConfigurationRequest(requestBody);
    }

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
  };
  // Wait for Sucess result of updatePSPConfigurationResult then call getPSPConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyEnablePaymentGateway && props.updatePSPConfigurationResult.fetched && !props.updatePSPConfigurationResult.fetching && !props.updatePSPConfigurationResult.error && props.updatePSPConfigurationResult.requestStatus) {
      notify('success', toastMessages.merchantServices.success.updated);
      props.getPSPConfigurationRequest(isMetadataRequired);
    } else if (props.activeKey === activeKeyEnablePaymentGateway && !props.updatePSPConfigurationResult.fetched && !props.updatePSPConfigurationResult.fetching && props.updatePSPConfigurationResult.error && !props.updatePSPConfigurationResult.requestStatus) {
      notify('error', toastMessages.merchantServices.error.updated);
    }
  }, [props.updatePSPConfigurationResult]);

  // Show Loader still get getPSPConfigurationResult or deletePSPConfigurationResult
  useEffect(() => {
    if (props.activeKey === activeKeyEnablePaymentGateway && (props.getPSPConfigurationResult.fetching || props.deletePSPConfigurationResult.fetching)) {
      setIsLoaderOn(true);
    } else {
      setIsLoaderOn(false);
    }
  });

  useEffect(() => {
    if (!props.getPSPConfigurationResult.error && props.getPSPConfigurationResult.fetched && props.getPSPConfigurationResult.requestStatus && !props.getPSPConfigurationResult.fetching) {
      setenablePGdata(updatedenablePGdata());
      setPrevPSPConfigurationResult(props.getPSPConfigurationResult.result);
    }
  }, [props.getPSPConfigurationResult]);

  const updatedenablePGdata = () => {
    const enablePGdata = [];
    let clientPspConfigurationsArrayList = props.getPSPConfigurationResult.result && props.getPSPConfigurationResult.result.client_psp_configurations;
    if (clientPspConfigurationsArrayList) {
      if (!Array.isArray(clientPspConfigurationsArrayList)) {
        clientPspConfigurationsArrayList = [{
          ...clientPspConfigurationsArrayList,
        }];
      }
      clientPspConfigurationsArrayList.forEach(clientPspConfigurationsKey => {
        let pspName = '';
        let pmName = '';
        if (pspsArrayList !== undefined) {
          pspName = pspsArrayList.find(pspKey => (pspKey.id === clientPspConfigurationsKey.id));
        }
        if (clientPspConfigurationsKey && clientPspConfigurationsKey.pm_configurations) {
          clientPspConfigurationsKey.pm_configurations.forEach(key => {
            if (paymentMethodListArray !== undefined && pspsArrayList !== undefined) {
              pmName = paymentMethodListArray.find(paymentMethodKey => (key.pm_id === paymentMethodKey.value));
              if (pmName !== undefined) {
                enablePGdata.push({
                  key: enablePGdata.length + 1,
                  paymentMethod: pmName.label,
                  pm_id: pmName.value,
                  providerName: pspName.name,
                  provider_id: pspName.id,
                  action: '',
                  deleteKey: false,
                  existFlag: true,
                  epg: true,
                });
              }
            }
          });
        }
      });
    }
    return enablePGdata;
  };

  return (
    <div className="card">
      {props.activeKey === activeKeyEnablePaymentGateway && hasDeleteRecordExist === 1 && <DeletePopup
        params="enable_payment_gateway"
        declineDeleteClick={() => {
          const filterTableData = enablePGdata.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setenablePGdata(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          savePspConfigResults();
          setHasDeleteRecordExist(0);
        }}
      />}
      <div className="card-header" id="headingOne">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyEnablePaymentGateway ? '' : activeKeyEnablePaymentGateway)} eventKey={activeKeyEnablePaymentGateway}>
          <h6>
            Enable Payment Gateway / Acquirer
            <img src={arrowDown} alt="arrow" className="float-right" />
          </h6>
        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyEnablePaymentGateway}>
        <div className="card-body">
          <div className="p-4">
            <Form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Payment Gateway / Acquirer
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={e => inputOnChange(e, 'provider')}
                      value={inputField.provider.idProp}
                    >
                      <option disabled selected value="">Please Select</option>
                      {pspsArrayList && pspsArrayList.map(key => <option value={key.id}>{key.name}</option>)}
                    </Form.Control>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <Form.Label className="mandatory">
                      Payment Method
                    </Form.Label>
                    <MultiSelect
                      options={paymentMethodSelectedListArray}
                      selected={inputField.paymentMethod}
                      onSelectedChanged={e => inputOnChangePm(e, 'paymentMethod')}
                      overrideStrings={{
                        selectSomeItems: 'Select Payment Method',
                        allItemsAreSelected: 'All Payment Method are selected...',
                        selectAll: 'Select All Payment Method',
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                    <button
                      type="button"
                      className="btn btn-sm float-left btn-outline-primary mt-1"
                      onClick={() => onClickAddButton()}>
                      <i className="fas fa-plus" />
                      {' '}
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Form>
            {errorMsg && <div className="alert alert-danger">
              {errorMsg ? msg : ''}
            </div>}
            {disFlag && <div className="alert alert-danger">
              {disFlag ? 'Please enter all required input fields.' : ''}
            </div>}
            {(!isLoaderOn) ? <div className="row">
              <div className="col-md-12  pt-2 pb-2 mt-3">
                <BootstrapTable data={enablePGdata}>
                  <TableHeaderColumn dataField="key" hidden isKey>Key</TableHeaderColumn>
                  <TableHeaderColumn dataField="providerName" className={tableHeadingColor} dataSort dataAlign="center" columnClassName={_onClickDeleteRowStyleDisable}>Payment Gateway / Acquirer</TableHeaderColumn>
                  <TableHeaderColumn dataField="paymentMethod" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Payment Method</TableHeaderColumn>
                  <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div> : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
            <div className="row">
              <div className="col-md-12  pt-2 pb-2 mt-3">
                {allDeleteFlag && <div className="alert alert-danger">
                  {allDeleteFlag ? 'Not allowed to delete all the configuration at once.' : ''}
                </div>}
                {hasDeleteRecordExist ? <button
                  type="button"
                  disabled={saveButtonDisabledFlag}
                  className="btn btn-primary float-right btn-sm  mr-3"
                  data-target="#deleteModal"
                  data-toggle="modal"
                >
                  Save
                </button> : <button
                  type="button"
                  disabled={saveButtonDisabledFlag}
                  className="btn btn-primary float-right btn-sm  mr-3"
                  data-target="#deleteModal"
                  data-toggle="modal"
                  onClick={() => savePspConfigResults()}
                >
                  Save
                </button>}

                <Button
                  type="button"
                  className="btn btn-sm float-right btn-outline-primary mr-3"
                  onClick={() => clearState()}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}
PopSetupEnablePaymentGateway.propTypes = {
  getPSPConfigurationRequest: PropTypes.func,
  updatePSPConfigurationRequest: PropTypes.func,
  getPSPConfigurationResult: PropTypes.object,
  updatePSPConfigurationResult: PropTypes.object,
  deletePSPConfigurationResult: PropTypes.object,
  // deletePSPConfigurationRequest: PropTypes.func,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,
};
const mapStateToProps = state => ({
  getPSPConfigurationResult: state.getPSPConfigurationResult,
  updatePSPConfigurationResult: state.updatePSPConfigurationResult,
  deletePSPConfigurationResult: state.deletePSPConfigurationResult,
});
export default connect(
  mapStateToProps, { getPSPConfigurationRequest, updatePSPConfigurationRequest, deletePSPConfigurationRequest }
)(PopSetupEnablePaymentGateway);
