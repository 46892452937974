import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sessionRedirection } from './Redirect';
import gifLoader from '../../../static/images/Loaders/transparentLoader.gif';


class SessionExpiredPopup extends Component {
  constructor() {
    super();
    this.state = {
      sessionExpired: !true,
    };
  }

  componentWillReceiveProps(nextProp) {
    if (nextProp.getSessionExpired.isExpired !== this.props.getSessionExpired.isExpired) {
      this.setState({ sessionExpired: nextProp.getSessionExpired.isExpired }, () => {
        if (this.state.sessionExpired) {
          setTimeout(() => {
            sessionRedirection();
          }, 3000);
        }
      });
    }
  }

  render() {
    if (this.state.sessionExpired) {
      return (
        <div className="modal fade show sessionExpireModal" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="exampleModalCenterTitle">Session Expired</h4>
              </div>
              <div className="modal-body">
                Your session is expired. You will now get redirected to vision.
                <img src={gifLoader} className="loader" alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    } return <div />;
  }
}
SessionExpiredPopup.propTypes = {
  getSessionExpired: PropTypes.object,
};
const mapStateToProps = state => ({
  getSessionExpired: state.getSessionExpired,
});
export default connect(mapStateToProps, { })(SessionExpiredPopup);
