import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
  sequence: '',
};

export const dashboardResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DASHBOARD_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {}, sequence: '',
    };
  case types.DASHBOARD_SUCCESS:
    return {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      error: false,
      sequence: action.bannerNumber,
    };
  case types.DASHBOARD_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      result: false,
      error: true,
      sequence: action.bannerNumber,

    };
  default:
    return state;
  }
};


export const KPIDashboardResult = (state = initialState, action) => {
  switch (action.type) {
  case types.KPI_DASHBOARD_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.KPI_DASHBOARD_SUCCESS:
    return {
      ...state,
      fetching: false,
      fetched: true,
      requestStatus: true,
      result: action.data,
      error: false,
    };
  case types.KPI_DASHBOARD_FAILURE:
    return {
      ...state,
      fetching: false,
      fetched: false,
      requestStatus: false,
      result: false,
      error: true,
    };
  default:
    return state;
  }
};


export const FilterResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DASHBOARD_FILTER:
    return {
      ...state, result: action.filter,
    };
  default:
    return state;
  }
};
