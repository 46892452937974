import React from 'react';
import TransparentLoader from '../../shared/Loaders/TransparentLoader';

const AppLoader = () => {
  let content = '';

  // if (isLoading) {
  // } else if (error) {
  //   content = <div className="AppLoader">Sorry, there was a problem loading the page.</div>;
  // }
  content = (
    <TransparentLoader />
  );
  return (
    <div className="col-sm-12 col-md-12 pt-3 ml-auto">
      <div className="AppLoader">
        {content}
      </div>
    </div>
  );
};

export default AppLoader;
