import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
  mode: null,
};
export const getSystemMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_SYSTEM_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_SYSTEM_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_SYSTEM_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getClientConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_CLIENT_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_CLIENT_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_CLIENT_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const postClientConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_CLIENT_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.POST_CLIENT_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.POST_CLIENT_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getPaymentMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PAYMENT_METADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PAYMENT_METADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PAYMENT_METADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const deleteClientConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_CLIENT_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_CLIENT_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_CLIENT_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const updateClientConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_CLIENT_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_CLIENT_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_CLIENT_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getAddonConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ADDON_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ADDON_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ADDON_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteAddonConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_ADDON_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_ADDON_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_ADDON_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getRouteConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getRoutePSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getAllConfigurationForFxResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ALL_CONFIGURATION_FOR_FX_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ALL_CONFIGURATION_FOR_FX_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ALL_CONFIGURATION_FOR_FX_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const postAddonConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_ADDON_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.POST_ADDON_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.POST_ADDON_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const getProviderConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PROVIDER_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PROVIDER_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PROVIDER_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const postProviderConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.POST_PROVIDER_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.POST_PROVIDER_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.POST_PROVIDER_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const deleteProviderConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_PROVIDER_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_PROVIDER_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_PROVIDER_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
export const updatePSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const updateRoutePSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deletePSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPSPConfigurationByIdResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PSP_CONFIGURATION_BY_ID_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PSP_CONFIGURATION_BY_ID_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PSP_CONFIGURATION_BY_ID_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteRoutePSPConfigurationResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_ROUTE_PSP_CONFIGURATION_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_ROUTE_PSP_CONFIGURATION_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_ROUTE_PSP_CONFIGURATION_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getRoutePSPConfigurationByIdResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_ROUTE_PSP_CONFIGURATION_BY_ID_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const updateRoutePSPConfigurationByRouteIdResult = (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.UPDATE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteRoutePSPConfigurationByRouteIdResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_ROUTE_PSP_CONFIGURATION_BY_ROUTE_ID_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
