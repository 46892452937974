import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
};

export const getPaymentLinkMetaDataResult = (state = initialState, action) => {
  switch (action.type) {
  case types.PAYMENTLINK_GETMETADATA_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.PAYMENTLINK_GETMETADATA_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.PAYMENTLINK_GETMETADATA_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const createPaymentLinkResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CREATE_PAYMENTLINK_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CREATE_PAYMENTLINK_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CREATE_PAYMENTLINK_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPaymentLinksResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PAYMENTLINKS_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PAYMENTLINKS_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PAYMENTLINKS_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deletePaymentLinkResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_PAYMENTLINK_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_PAYMENTLINK_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_PAYMENTLINK_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPaymentLinkFileResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PAYMENTLINKFILE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PAYMENTLINKFILE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PAYMENTLINKFILE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const getPaymentLinkResult = (state = initialState, action) => {
  switch (action.type) {
  case types.GET_PAYMENTLINK_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.GET_PAYMENTLINK_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.GET_PAYMENTLINK_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};
