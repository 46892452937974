import { actionTypes as types, urls } from '../constants';
import { get, post } from '../helpers';


export const getRulesRequest = () => dispatch => {
  dispatch({ type: types.GET_RULES_REQUEST });
  get({
    url: urls.GET_RULES,
    success: types.GET_RULES_SUCCESS,
    failure: types.GET_RULES_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const getFraudAuthRulesRequest = data => dispatch => {
  dispatch({ type: types.GET_FRAUD_AUTH_RULES_REQUEST });
  const url = `${urls.GET_FRAUD_ROUTING_RULES}?event_type_id=${data.id}`;
  get({
    url,
    success: types.GET_FRAUD_AUTH_RULES_SUCCESS,
    failure: types.GET_FRAUD_AUTH_RULES_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const getPreAuthRulesRequest = data => dispatch => {
  dispatch({ type: types.GET_PRI_AUTH_RULES_REQUEST });
  const url = `${urls.GET_FRAUD_ROUTING_RULES}?event_type_id=${data.id}`;
  get({
    url,
    success: types.GET_PRI_AUTH_RULES_SUCCESS,
    failure: types.GET_PRI_AUTH_RULES_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const getPostAuthRulesRequest = data => dispatch => {
  dispatch({ type: types.GET_POST_AUTH_RULES_REQUEST });
  const url = `${urls.GET_FRAUD_ROUTING_RULES}?event_type_id=${data.id}`;
  get({
    url,
    success: types.GET_POST_AUTH_RULES_SUCCESS,
    failure: types.GET_POST_AUTH_RULES_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const getfraudMetaDataRequest = data => dispatch => {
  dispatch({ type: types.GET_FRAUD_ROUTING_METADATA_REQUEST });
  post({
    url: urls.GET_FRAUD_ROUTING_METADATA,
    success: types.GET_FRAUD_ROUTING_METADATA_SUCCESS,
    failure: types.GET_FRAUD_ROUTING_METADATA_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getRulesMetaDataRequest = data => dispatch => {
  dispatch({ type: types.GET_RULES_METADATA_REQUEST });
  post({
    url: urls.GET_RULES_META_DATA,
    success: types.GET_RULES_METADATA_SUCCESS,
    failure: types.GET_RULES_METADATA_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getDRRulesMetaDataRequest = data => dispatch => {
  dispatch({ type: types.GET_DR_RULES_METADATA_REQUEST });
  post({
    url: urls.GET_DR_RULES_META_DATA,
    success: types.GET_DR_RULES_METADATA_SUCCESS,
    failure: types.GET_DR_RULES_METADATA_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getInstallmentRulesMetaDataRequest = data => dispatch => {
  dispatch({ type: types.GET_INSTALLMENT_RULES_METADATA_REQUEST });
  post({
    url: urls.GET_INSTALLMENT_RULES_META_DATA,
    success: types.GET_INSTALLMENT_RULES_METADATA_SUCCESS,
    failure: types.GET_INSTALLMENT_RULES_METADATA_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const createRuleRequest = (data, fraudRouting) => dispatch => {
  dispatch({ type: types.CREATE_RULE_REQUEST });
  post({
    url: fraudRouting ? urls.CREATE_FRAUD_RULE : urls.CREATE_RULE,
    success: types.CREATE_RULE_SUCCESS,
    failure: types.CREATE_RULE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getRuleRequest = (data, fraudRouting) => dispatch => {
  dispatch({ type: types.CREATE_RULE_REQUEST });
  post({
    url: fraudRouting ? urls.GET_FRAUD_RULE : urls.GET_RULE,
    success: types.GET_RULE_SUCCESS,
    failure: types.GET_RULE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const updateRuleRequest = (data, fraudRouting) => dispatch => {
  dispatch({ type: types.UPDATE_RULE_REQUEST });
  post({
    url: fraudRouting ? urls.UPDATE_FRAUD_RULE : urls.UPDATE_RULE,
    success: types.UPDATE_RULE_SUCCESS,
    failure: types.UPDATE_RULE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const setFormMode = data => dispatch => {
  dispatch({ type: types.SET_FORM_MODE, data });
};
export const deleteRuleRequest = (data, fraudRouting) => dispatch => {
  dispatch({ type: types.DELETE_RULE_REQUEST });
  const deleteUrl = fraudRouting ? urls.DELETE_FRAUD_RULE : urls.DELETE_RULE;
  const url = `${deleteUrl}id=${data.ruleId}`;
  get({
    url,
    success: types.DELETE_RULE_SUCCESS,
    failure: types.DELETE_RULE_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getClientConfigDataRequest = () => dispatch => {
  dispatch({ type: types.CLIENT_CONFIG_DATA_REQUEST });
  get({
    url: urls.CLIENT_CONFIG_DATA,
    success: types.CLIENT_CONFIG_DATA_SUCCESS,
    failure: types.CLIENT_CONFIG_DATA_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getCustomerTypeRequest = () => dispatch => {
  dispatch({ type: types.CUSTOMER_TYPE_REQUEST });
  get({
    url: urls.GET_CUSTOMER_TYPE,
    success: types.CUSTOMER_TYPE_SUCCESS,
    failure: types.CUSTOMER_TYPE_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getSubscriptionRequest = () => dispatch => {
  dispatch({ type: types.GET_SUBSCRIPTION_REQUEST });
  get({
    url: urls.GET_SUBSCRIPTION,
    success: types.GET_SUBSCRIPTION_SUCCESS,
    failure: types.GET_SUBSCRIPTION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};

export const getBinRangeMetaDataRequest = () => dispatch => {
  dispatch({ type: types.BINRANGE_METADATA_REQUEST });
  get({
    url: urls.GET_BIN_RANGE_METADATA,
    success: types.BINRANGE_METADATA_SUCCESS,
    failure: types.BINRANGE_METADATA_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const updateMerchantSubscriptionRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_MERCHANT_SUBSCRIPTION_REQUEST });
  const url = `${urls.UPDATE_MERCHANT_SUBSCRIPTION}routingSubscriptionType=${data}`;
  get({
    url,
    success: types.UPDATE_MERCHANT_SUBSCRIPTION_SUCCESS,
    failure: types.UPDATE_MERCHANT_SUBSCRIPTION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const configureRouteRequest = data => dispatch => {
  dispatch({ type: types.CONFIGURE_ROUTE_REQUEST });
  post({
    url: urls.CONFIGURE_ROUTE,
    success: types.CONFIGURE_ROUTE_SUCCESS,
    failure: types.CONFIGURE_ROUTE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getRouteRequest = () => dispatch => {
  dispatch({ type: types.GET_ROUTE_REQUEST });
  get({
    url: urls.GET_ROUTES,
    success: types.GET_ROUTE_SUCCESS,
    failure: types.GET_ROUTE_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getRouteDetailsRequest = data => dispatch => {
  dispatch({ type: types.GET_ROUTE_DETAILS_REQUEST });
  post({
    url: urls.GET_ROUTE,
    success: types.GET_ROUTE_DETAILS_SUCCESS,
    failure: types.GET_ROUTE_DETAILS_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getBinRangeSearchRequest = data => dispatch => {
  dispatch({ type: types.BINRANGE_SEARCH_REQUEST });
  post({
    url: urls.GET_BIN_RANGE_SEARCH,
    success: types.BINRANGE_SEARCH_SUCCESS,
    failure: types.BINRANGE_SEARCH_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getRouteMetaDataRequest = () => dispatch => {
  dispatch({ type: types.GET_ROUTE_METADATA_REQUEST });
  get({
    url: urls.GET_ROUTE_METADATA,
    success: types.GET_ROUTE_METADATA_SUCCESS,
    failure: types.GET_SUBSCRIPTION_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const getIataCodeRequest = () => dispatch => {
  dispatch({ type: types.IATA_CODE_REQUEST });
  get({
    url: urls.GET_IATA_CODES,
    success: types.IATA_CODE_SUCCESS,
    failure: types.IATA_CODE_FAILURE,
    dispatch,
    setAuthToken: true,
  });
};
export const updateRouteRequest = data => dispatch => {
  dispatch({ type: types.UPDATE_ROUTE_REQUEST });
  post({
    url: urls.UPDATE_ROUTE,
    success: types.UPDATE_ROUTE_SUCCESS,
    failure: types.UPDATE_ROUTE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const deleteRouteRequest = data => dispatch => {
  dispatch({ type: types.DELETE_ROUTE_REQUEST });
  post({
    url: urls.DELETE_ROUTE,
    success: types.DELETE_ROUTE_SUCCESS,
    failure: types.DELETE_ROUTE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const getActiveRuleRequest = data => dispatch => {
  dispatch({ type: types.GET_ACTIVE_RULE_REQUEST });
  const url = `${urls.GET_ACTIVE_RULE}routeConfigid=${data.routeConfigid}`;
  get({
    url,
    success: types.GET_ACTIVE_RULE_SUCCESS,
    failure: types.GET_ACTIVE_RULE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const deleteActiveRuleRequest = data => dispatch => {
  dispatch({ type: types.DELETE_ACTIVE_RULE_REQUEST });
  const url = `${urls.DELETE_ACTIVE_RULE}routeConfigid=${data.routeConfigid}`;
  get({
    url,
    success: types.DELETE_ACTIVE_RULE_SUCCESS,
    failure: types.DELETE_ACTIVE_RULE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};
export const setStaticRouteRedirect = data => ({
  type: types.STATIC_ROUTE_REDIRECT,
  data,
});
