import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: '',
};

export const triggerEditResult = (state = initialState, action) => {
  switch (action.type) {
  case types.TRIGGER_EDIT_REQUEST:
    return { ...state, fetching: true };
  case types.TRIGGER_EDIT_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data,
    };
  case types.TRIGGER_EDIT_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, error: 'something went wrong',
    };
  default:
    return state;
  }
};
