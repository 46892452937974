import {
  CHANGE_DYNAMICBANNER_NAME,
  CHANGE_DYNAMICBANNER_FILE,
  ON_DYNAMICBANNER_FILE_DELETE,
  CHANGE_DYNAMICBANNER_STARTDATE,
  CHANGE_DYNAMICBANNER_ENDDATE,
  ADD_DYNAMICBANNER_ENDDATE,
  REMOVE_DYNAMICBANNER_ENDDATE,
  ADD_DYNAMICBANNER_NEWBANNER,
  DYNAMICBANNER_CREATE_REQUEST,
  DYNAMICBANNER_CREATE_SUCCESS,
  DYNAMICBANNER_CREATE_FAILURE,
  DYNAMICBANNER_UPDATE_REQUEST,
  DYNAMICBANNER_UPDATE_SUCCESS,
  DYNAMICBANNER_UPDATE_FAILURE,
  DYNAMICBANNER_VIEW_REQUEST,
  DYNAMICBANNER_VIEW_SUCCESS,
  DYNAMICBANNER_VIEW_FAILURE,
  DYNAMICBANNER_EDIT_REQUEST,
  DYNAMICBANNER_EDIT_FAILURE,
  DYNAMICBANNER_EDIT_SUCCESS,
  GET_DYNAMICBANNER_AWSOBJECT_REQUEST,
  GET_DYNAMICBANNER_AWSOBJECT_SUCCESS,
  GET_DYNAMICBANNER_AWSOBJECT_FAILURE,
  DYNAMICBANNER_DELETE_REQUEST,
  DYNAMICBANNER_DELETE_SUCCESS,
  DYNAMICBANNER_DELETE_FAILURE,
  SET_VIEW_DYNAMICBANNER,
  CLEAR_VIEW_DYNAMICBANNER,
} from './constants';

import { urls } from '../../../../../redux/constants';
import { post, postSync } from '../../../../../redux/helpers';

export function changeDynamicBannerName(name) {
  return {
    type: CHANGE_DYNAMICBANNER_NAME,
    name,
  };
}

export function changeDynamicBannerFile(bannerNumber, fileName, filedata) {
  return {
    type: CHANGE_DYNAMICBANNER_FILE,
    bannerNumber,
    fileName,
    filedata,
  };
}

export function onDynamicBannerFileDelete(bannerNumber) {
  return {
    type: ON_DYNAMICBANNER_FILE_DELETE,
    bannerNumber,
  };
}

export function onChangeDynamicBannerStartDate(bannerNumber, date) {
  return {
    type: CHANGE_DYNAMICBANNER_STARTDATE,
    bannerNumber,
    date,
  };
}

export function onDynamicBannerEndDateAdded(bannerNumber) {
  return {
    type: ADD_DYNAMICBANNER_ENDDATE,
    bannerNumber,
  };
}

export function onDynamicBannerEndDateCancelled(bannerNumber) {
  return {
    type: REMOVE_DYNAMICBANNER_ENDDATE,
    bannerNumber,
  };
}

export function onAddNewBanner() {
  return {
    type: ADD_DYNAMICBANNER_NEWBANNER,
  };
}

export function onChangeDynamicBannerEndDate(bannerNumber, date) {
  return {
    type: CHANGE_DYNAMICBANNER_ENDDATE,
    bannerNumber,
    date,
  };
}

export const dynamicBannerCreateRequest = data => dispatch => {
  dispatch({ type: DYNAMICBANNER_CREATE_REQUEST });
  post({
    url: urls.POST_CREATE_DYNAMICBANNER,
    success: DYNAMICBANNER_CREATE_SUCCESS,
    failure: DYNAMICBANNER_CREATE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const dynamicBannerUpdateRequest = data => dispatch => {
  dispatch({ type: DYNAMICBANNER_UPDATE_REQUEST });
  post({
    url: urls.POST_UPDATE_DYNAMICBANNER,
    success: DYNAMICBANNER_UPDATE_SUCCESS,
    failure: DYNAMICBANNER_UPDATE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const dynamicBannerViewRequest = data => dispatch => {
  dispatch({ type: DYNAMICBANNER_VIEW_REQUEST });
  post({
    url: urls.POST_VIEWPROMOTION,
    success: DYNAMICBANNER_VIEW_SUCCESS,
    failure: DYNAMICBANNER_VIEW_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const dynamicBannerEditRequest = data => dispatch => {
  dispatch({ type: DYNAMICBANNER_EDIT_REQUEST });
  post({
    url: urls.POST_VIEWPROMOTION,
    success: DYNAMICBANNER_EDIT_SUCCESS,
    failure: DYNAMICBANNER_EDIT_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export const getDynamicBannerAwsObjectRequest = (bannerNumber, data) => dispatch => {
  dispatch({ type: GET_DYNAMICBANNER_AWSOBJECT_REQUEST, bannerNumber });
  postSync({
    url: urls.GET_AWSOBJECT,
    bannerNumber,
    success: GET_DYNAMICBANNER_AWSOBJECT_SUCCESS,
    failure: GET_DYNAMICBANNER_AWSOBJECT_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};


export const dynamicBannerDeleteRequest = data => dispatch => {
  dispatch({ type: DYNAMICBANNER_DELETE_REQUEST });
  post({
    url: urls.POST_DELETE_DYNAMICBANNER,
    success: DYNAMICBANNER_DELETE_SUCCESS,
    failure: DYNAMICBANNER_DELETE_FAILURE,
    dispatch,
    body: data,
    setAuthToken: true,
  });
};

export function clearBanner() {
  return {
    type: CLEAR_VIEW_DYNAMICBANNER,
  };
}

export const setViewDynamicBannerData = data => dispatch => {
  if (data.action === 'clear') dispatch({ type: CLEAR_VIEW_DYNAMICBANNER });
  else if (data.action === 'set') dispatch({ type: SET_VIEW_DYNAMICBANNER, data });
  else dispatch({ type: CLEAR_VIEW_DYNAMICBANNER });
};
