import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import PageLoader from '../../../../shared/Loaders/TransparentLoader';
import {
  getAddonConfigurationRequest, getSystemMetaDataRequest, getClientConfigurationRequest, getPaymentMetaDataRequest, getAllConfigurationForFxRequest, getProviderConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import VasSetupFx from './Fx';
import VasSetupFraudManagement from './FraudManagement';
import VasSetupSplitPayment from './SplitPayment';
import VasSetupClientName from './ClientName';


function VasSetupComponent(props) {
  const [activeKey, setActiveKey] = useState('0');
  const [vasSetup, setvasSetup] = useState('0');
  const [showLoader, setLoader] = useState(true);
  const [prevAddonConfigurationResult, setPrevAddonConfigurationResult] = useState(props.getAddonConfigurationResult.result);

  useEffect(() => {
    props.getAddonConfigurationRequest();
    if (!props.getAddonConfigurationResult.requestStatus && props.getAddonConfigurationResult.fetched) {
      notify('error', toastMessages.merchantServices.error.backendError);
    }
  }, []);
  useEffect(() => {
    props.getSystemMetaDataRequest();
  }, []);
  useEffect(() => {
    props.getClientConfigurationRequest();
  }, []);
  useEffect(() => {
    props.getPaymentMetaDataRequest();
  }, []);
  useEffect(() => {
    props.getProviderConfigurationRequest();
  }, []);
  useEffect(() => {
    props.getAllConfigurationForFxRequest();
    if (!props.getAllConfigurationForFxResult.requestStatus && props.getAllConfigurationForFxResult.fetched) {
      notify('error', toastMessages.merchantServices.error.backendError);
    }
  }, []);
  useEffect(() => {
    if (props.getAllConfigurationForFxResult.fetched
      && !props.getAllConfigurationForFxResult.fetching
      && !props.getAllConfigurationForFxResult.error) {
      setLoader(false);
    }
  });

  useEffect(() => {
    if (prevAddonConfigurationResult !== props.getAddonConfigurationResult.result) {
      setPrevAddonConfigurationResult(props.getAddonConfigurationResult);
    }
  }, [props, prevAddonConfigurationResult]);
  function onBtnClick(location) {
    props.history.push(`/${location}`);
  }
  const permissions = props.getPermissionObject.data;
  const MerchantOnboardingCreate = permissions && permissions.actions.merchantOnBoarding.view;

  return (
    <main role="main" className="col-md-12 mt3r pt-3 ml-auto">
      {showLoader && <PageLoader />}
      <div className="navlink-button-group">
        {!MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantOnboarding') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantOnboarding')}>
          Merchant Onboarding
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantSetup')}>
          Merchant Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('PopSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('PopSetup')}>
          Pop Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('VasSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('VasSetup')}>
          VAS Setup
        </Button>}
      </div>
      <Accordion activeKey={activeKey}>
        <VasSetupClientName />
        <VasSetupFx activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setvasSetup(1)} vasSetup={vasSetup} />
        <VasSetupFraudManagement activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setvasSetup(2)} vasSetup={vasSetup} />
        <VasSetupSplitPayment activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setvasSetup(3)} vasSetup={vasSetup} />
      </Accordion>
    </main>
  );
}
VasSetupComponent.propTypes = {
  getAddonConfigurationRequest: PropTypes.object,
  getSystemMetaDataRequest: PropTypes.object,
  getClientConfigurationRequest: PropTypes.object,
  getPaymentMetaDataRequest: PropTypes.object,
  getAllConfigurationForFxRequest: PropTypes.object,
  getAddonConfigurationResult: PropTypes.object,
  getAllConfigurationForFxResult: PropTypes.object,
  getProviderConfigurationRequest: PropTypes.object,
  getPermissionObject: PropTypes.object,
  history: PropTypes.object,
};
const mapStateToProps = state => ({
  getAddonConfigurationResult: state.getAddonConfigurationResult,
  getAllConfigurationForFxResult: state.getAllConfigurationForFxResult,
  getPermissionObject: state.getPermissionObject,
});
export default connect(
  mapStateToProps,
  {
    getAddonConfigurationRequest, getSystemMetaDataRequest, getClientConfigurationRequest, getPaymentMetaDataRequest, getAllConfigurationForFxRequest, getProviderConfigurationRequest,
  }
)(VasSetupComponent);
