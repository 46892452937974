export const objectToArray = obj => {
  let data = obj;
  if (!Array.isArray(obj)) {
    data = [{
      ...obj,
    }];
  }
  return data;
};

export const getUniqueFromArray = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])

  // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
};
