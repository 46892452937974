import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import { Accordion, Button } from 'react-bootstrap';
import globals from '../../../../../redux/constants/globals';
import { tableHeadingColor, _onClickDeleteRowStyleDisable, alertMessageSetTimeOut } from '../Helper';
import { toastMessages, notify } from '../../../../shared/ToastNotification/Messages';
import { getClientConfigurationRequest, updateClientConfigurationRequest } from '../../../../../redux/actions/MerchantOnboarding';
import arrowdown from '../../../../../static/icons/chevron-down.svg';
import gifLoader from '../../../../../static/images/Loaders/loader.gif';
import DeletePopup from '../../../../shared/Popups/DeleteMerchantOnboarding';
import { BootstrapTable } from '../Constants';
import { TableHeaderColumn } from '../Constants';
import { activeKeyValueAddService, validationRequiredInputMessage } from '../Constants';

function ValueAddService(props) {
  const reset = {
    serviceName: '',
    serviceType: '',
    serviceTypeName: '',
  };
  const [merchantInputField, setMerchantInputField] = useState(reset);
  const [tableData, setTableData] = useState([]);
  const [showLoader, setLoader] = useState(false);
  const [serviceType, setServiceType] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [hasDeleteRecordExist, setHasDeleteRecordExist] = useState(0);
  const [serviceNameListArray, setServiceNameListArray] = useState([]);
  const [saveButtonDisabledFlag, setSaveButtonDisabledFlag] = useState(1);
  const [validationError, setValidationError] = useState({
    properties: false,
    duplicate: false,
  });

  function clearState() {
    const initialTableData = [];
    let count = 1;
    // dropdown values
    let serviesList = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.services;

    let addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types
          && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;

    if (addonTypes) {
      setApiCall(true);
      if (!Array.isArray(addonTypes)) {
        addonTypes = [{
          ...addonTypes,
        }];
      }

      if (!Array.isArray(serviesList)) {
        serviesList = [{
          ...serviesList,
        }];
      }

      const addOnTypesUpdated = getFilteredAddOnServiceType(addonTypes);
      addOnTypesUpdated.forEach(key => {
        key.addon_subtypes.addon_subtype.forEach(object => {
          let randomText = object.name.toString().toLowerCase();
          if (randomText === 'pre auth, post auth') {
            randomText = 'fraud';
          } else if (randomText === '3ds') {
            randomText = 'mpi';
          } else if (randomText === 'cashless, conventional, hybrid') {
            randomText = 'split_payment';
          }
          if (serviesList && serviesList[0][randomText]) {
            initialTableData.push({
              key: count,
              serviceName: key.name,
              serviceTypeName: object.name,
              serviceType: 1,
              action: '',
              deleteKey: false,
              existFlag: false,
              hybrid: true,
            });
            count += 1;
          }
        });
      });
    }

    const data = { ...merchantInputField };
    data.serviceName = '';
    data.serviceType = '';
    setMerchantInputField(data);
    setTableData(initialTableData);

    // Removing the existing validation message in case of cancel button click
    const errorValue = { ...validationError };
    errorValue.serviceNameDropdownValue = false;
    errorValue.serviceTypeDropdownValue = false;
    setValidationError(errorValue);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
    // Toast message
    notify('success', toastMessages.merchantServices.success.reset);
  }

  function merchantValueAddServiceValidation() {
    let validate = true;
    const errorValue = { ...validationError };
    if (merchantInputField.serviceName === '' || merchantInputField.serviceName === 'Please Select') {
      errorValue.serviceNameDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else if (merchantInputField.serviceType === '' || merchantInputField.serviceType === 'Please Select') {
      errorValue.serviceNameDropdownValue = false;
      errorValue.serviceTypeDropdownValue = true;
      alertMessageSetTimeOut();
      validate = false;
    } else {
      errorValue.serviceNameDropdownValue = false;
      errorValue.serviceTypeDropdownValue = false;
    }
    setValidationError(errorValue);
    return validate;
  }

  const inputOnChange = (event, field) => {
    let value;
    if (field === 'serviceName') {
      merchantInputField.serviceTypeName = '';
      merchantInputField.serviceType = '';
      // dropdown values
      const addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types
      && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;

      const addOnTypesUpdated = getFilteredAddOnServiceType(addonTypes);
      const filteredAddOnTypes = addOnTypesUpdated.filter(service => service.name === event.target.value)[0].addon_subtypes.addon_subtype;
      const filteredTableDataResult = tableData.map(a => a.serviceTypeName);
      const filteredExistingAddOnServiceData = filteredAddOnTypes.filter(category => filteredTableDataResult.includes(category.name) === false);

      setServiceType(filteredExistingAddOnServiceData);
      value = event.target.value;
      setMerchantInputField({ ...merchantInputField, [field]: value });
    } else if (field === 'serviceType') {
      const index = event.nativeEvent.target.selectedIndex;
      value = event.nativeEvent.target[index].text;
      setMerchantInputField({ ...merchantInputField, serviceTypeName: value, [field]: event.target.value });
    }
  };

  const initTableData = (serviceNameParam, serviceTypeParam) => {
    const count = tableData.length + 1;
    tableData.push({
      key: count,
      serviceName: serviceNameParam,
      serviceTypeName: serviceTypeParam,
      serviceType: 1, // This need to be handle
      action: '',
      deleteKey: false,
      existFlag: false,
      hybrid: true,
    });
  };

  const getFilteredAddOnServiceType = addonTypes => {
    const addOnTypesUpdated = [];
    addonTypes.forEach(keyObject => {
      if (keyObject.name === 'FX') {
        addOnTypesUpdated.push({
          id: keyObject.id,
          name: keyObject.name,
          addon_subtypes: {
            addon_subtype: keyObject.addon_subtypes.addon_subtype,
          },
        });
      } else if (keyObject.name === 'Fraud Management') {
        const filteredKeyObject = keyObject.addon_subtypes.addon_subtype.filter(e => e.id !== 6);
        const test = filteredKeyObject.map(u => u.name).join(', ');
        const arrVar = [];
        arrVar.push({
          id: keyObject.addon_subtypes.addon_subtype[0].id,
          name: test,
        });
        arrVar.push({
          id: keyObject.addon_subtypes.addon_subtype[2].id,
          name: keyObject.addon_subtypes.addon_subtype[2].name,
        });
        addOnTypesUpdated.push({
          id: keyObject.id,
          name: keyObject.name,
          addon_subtypes: {
            addon_subtype: arrVar,
          },
        });
      } else if (keyObject.name === 'Split Payment') {
        const test = keyObject.addon_subtypes.addon_subtype.map(u => u.name).join(', ');
        const arrVar = {
          id: keyObject.addon_subtypes.addon_subtype[0].id,
          name: test,
        };
        addOnTypesUpdated.push({
          id: keyObject.id,
          name: keyObject.name,
          addon_subtypes: {
            addon_subtype: [arrVar],
          },
        });
      }
    });
    return addOnTypesUpdated;
  };

  // dropdown values
  let serviesList = props.getClientConfigurationResult.result && props.getClientConfigurationResult.result.client_configuration && props.getClientConfigurationResult.result.client_configuration.services;

  let addonTypes = props.getSystemMetaDataResult.result && props.getSystemMetaDataResult.result.system_metadata && props.getSystemMetaDataResult.result.system_metadata.addon_types
    && props.getSystemMetaDataResult.result.system_metadata.addon_types.addon_type;

  if (addonTypes && !apiCall) {
    setApiCall(true);
    const serviceRequestObject = [];
    if (!Array.isArray(addonTypes)) {
      addonTypes = [{
        ...addonTypes,
      }];
    }

    if (!Array.isArray(serviesList)) {
      serviesList = [{
        ...serviesList,
      }];
    }

    const addOnTypesUpdated = getFilteredAddOnServiceType(addonTypes);
    addOnTypesUpdated.forEach(key => {
      const serviceTypesObject = [];
      key.addon_subtypes.addon_subtype.forEach(object => {
        let randomText = object.name.toString().toLowerCase();
        if (randomText === 'pre auth, post auth') {
          randomText = 'fraud';
        } else if (randomText === '3ds') {
          randomText = 'mpi';
        } else if (randomText === 'cashless, conventional, hybrid') {
          randomText = 'split_payment';
        }
        if (!serviesList[0][randomText]) {
          serviceTypesObject.push(object);
        } else if (serviesList && serviesList[0][randomText]) {
          initTableData(key.name, object.name);
        }
      });
      serviceRequestObject.push({
        serviceName: key.name,
        serviceTypes: serviceTypesObject,
      });
      setLoader(false);
    });
    setServiceNameListArray(serviceRequestObject);
  }

  const onClickAddButton = () => {
    if (merchantValueAddServiceValidation()) {
      const count = tableData.length + 1;
      tableData.push({
        key: count,
        serviceName: merchantInputField.serviceName,
        serviceTypeName: merchantInputField.serviceTypeName,
        serviceType: merchantInputField.serviceType,
        action: '',
        deleteKey: false,
        existFlag: false,
        hybrid: true,
      });

      setTableData(tableData);
      const data = { ...merchantInputField };
      data.serviceName = '';
      data.serviceType = '';
      setServiceType([]);
      setMerchantInputField(data);

      // Make save button enabled
      setSaveButtonDisabledFlag(0);
    }
  };

  const deleteRow = row => {
    let filter = '';
    if (row.key) {
      filter = getUnique(tableData, 'key');
      if (filter.length !== tableData.length) {
        setTableData(filter);
      } else {
        filter = tableData.filter((
          item => {
            if (item.key === row.key) {
              item.deleteKey = !item.deleteKey; // eslint-disable-line no-param-reassign
            }
            return item;
          }
        ));
        setTableData(filter);

        if (filter.filter(item => item.deleteKey === true).length > 0) {
          // Make save button enabled
          setSaveButtonDisabledFlag(0);
          setHasDeleteRecordExist(1);
        } else {
          // Make save button disabled
          setSaveButtonDisabledFlag(1);
          setHasDeleteRecordExist(0);
        }
      }
    }
  };
  function getUnique(arr, index) {
    const unique = arr
      .map(e => e[index].toString())

    // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
      .filter(e => arr[e]).map(e => arr[e]);

    return unique;
  }

  const onClickSave = () => {
    let dccFlag = false;
    let pccFlag = false;
    let mcpFlag = false;
    let splitPaymentFlag = false;
    let fraudFlag = false;
    let mpiFlag = false;
    const updatedTableData = [];
    let count = 1;
    tableData.forEach(key => {
      if (!key.deleteKey) {
        if (key.serviceName === 'FX') {
          if (key.serviceTypeName === 'DCC') {
            dccFlag = true;
          } else if (key.serviceTypeName === 'PCC') {
            pccFlag = true;
          } else if (key.serviceTypeName === 'MCP') {
            mcpFlag = true;
          }
        } else if (key.serviceName === 'Split Payment') {
          splitPaymentFlag = true;
        } else if (key.serviceName === 'Fraud Management') {
          if (key.serviceTypeName === 'Pre Auth, Post Auth') {
            fraudFlag = true;
          } else if (key.serviceTypeName === '3DS') {
            mpiFlag = true;
          }
        }

        updatedTableData.push({
          key: count,
          serviceName: key.serviceName,
          serviceTypeName: key.serviceTypeName,
          serviceType: key.serviceType, // This need to be handle
          action: '',
          deleteKey: false,
          existFlag: false,
          hybrid: true,
        });
        count += 1;
      }
    });
    const requestBody = {
      id: globals.clientId,
      services: {
        dcc: dccFlag,
        pcc: pccFlag,
        mcp: mcpFlag,
        fraud: fraudFlag,
        split_payment: splitPaymentFlag,
        mpi: mpiFlag,
      },
    };
    props.updateClientConfigurationRequest(requestBody);
    setTableData(updatedTableData);

    // Make save button disabled
    setSaveButtonDisabledFlag(1);
  };

  // Wait for Sucess result of updateClientConfigurationRequest then call getClientConfigurationRequest
  useEffect(() => {
    if (props.activeKey === activeKeyValueAddService && props.updateClientConfigurationResult.fetched && !props.updateClientConfigurationResult.fetching && props.updateClientConfigurationResult.requestStatus) {
      if (props.updateClientConfigurationResult.requestStatus === true) {
        notify('success', toastMessages.merchantServices.success.updated);
      } else {
        notify('error', toastMessages.merchantServices.error.updated);
      }
      props.getClientConfigurationRequest();
    }
  }, [props.updateClientConfigurationResult]);

  // Show Loader still get getClientConfigurationResult
  useEffect(() => {
    if (props.getClientConfigurationResult.fetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  });

  const _onClickDelete = (e, row) => {
    deleteRow(row);
  };

  const action = (cell, row) => (
    <div>
      { (row.deleteKey !== undefined && row.deleteKey === false)
        ? <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Disable" data-toggle="modal" className="text-danger"><i className="fa fa-times" /></Button>
        : <Button variant="light" onClick={e => _onClickDelete(e, row)} title="Enable" data-toggle="modal" className="text-danger"><i className="fas fa-check" /></Button>
      }
    </div>
  );


  const viewMode = props.getFormMode.result === 'View';
  return (
    <div className="card">
      {props.activeKey === activeKeyValueAddService && hasDeleteRecordExist === 1 && <DeletePopup
        declineDeleteClick={() => {
          const filterTableData = tableData.filter((
            item => {
              if (item.deleteKey) {
                item.deleteKey = false; // eslint-disable-line no-param-reassign
              }
              return item;
            }
          ));
          setTableData(filterTableData);
          setHasDeleteRecordExist(0);

          // Make save button disabled
          setSaveButtonDisabledFlag(1);
        }}
        acceptDeleteClick={() => {
          onClickSave();
          setHasDeleteRecordExist(0);
        }}
      />}
      {/* {showLoader && <PageLoader />} */}
      <div className="card-header" id="headingTwo">
        <Accordion.Toggle as={Button} className="accordion-btn" variant="link" onClick={() => props.activeCollapse(props.activeKey === activeKeyValueAddService ? '' : activeKeyValueAddService)} eventKey={activeKeyValueAddService}>
          <h6>
            Value Add Service
            <img src={arrowdown} alt="down arrow" className="float-right" />
          </h6>

        </Accordion.Toggle>
      </div>
      <Accordion.Collapse eventKey={activeKeyValueAddService}>
        <div className="card-body">
          <div className="mt-4">
            <Form className="row">
              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Service Name</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'serviceName')}
                  value={merchantInputField.serviceName}
                  disabled={viewMode}
                >
                  <option disabled value="">Please Select</option>
                  {serviceNameListArray && serviceNameListArray.map(key => <option value={key.serviceName}>{key.serviceName}</option>)}
                </Form.Control>
                {(validationError.serviceNameDropdownValue || validationError.serviceTypeDropdownValue) && <div className="alert alert-danger">
                  {validationRequiredInputMessage}
                </div>}
              </Col>

              <Col className="form-group" md={3}>
                <Form.Label className="mandatory">Service Type</Form.Label>
                <Form.Control
                  as="select"
                  onChange={e => inputOnChange(e, 'serviceType')}
                  value={merchantInputField.serviceType}
                >
                  <option value="">Please Select</option>
                  {serviceType.map(key => <option value={key.id}>{key.name}</option>)}
                </Form.Control>
              </Col>
              <div className="col">
                <label htmlFor="exampleFormControlInput1" className="d-block">&nbsp;</label>
                <button
                  type="button"
                  className="btn btn-sm float-left btn-outline-primary mt-1"
                  onClick={() => onClickAddButton()}>
                  <i className="fas fa-plus" />
                  {' '}
                  Add
                </button>
              </div>
              <div className="col-12">
                <p className="error redMandatory" />
              </div>
              <div className="row">
                {(!showLoader) ? <Col className="pt-2 pb-2 mt-3" md={12}>
                  <BootstrapTable
                    data={tableData}
                  >
                    <TableHeaderColumn dataField="key" hidden isKey>No.</TableHeaderColumn>
                    <TableHeaderColumn dataField="serviceName" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Service Name</TableHeaderColumn>
                    <TableHeaderColumn dataField="serviceTypeName" dataAlign="left" className={tableHeadingColor} dataSort columnClassName={_onClickDeleteRowStyleDisable}>Service Type</TableHeaderColumn>
                    <TableHeaderColumn dataField="action" className={tableHeadingColor} dataFormat={action}>Actions</TableHeaderColumn>
                  </BootstrapTable>
                </Col>
                  : <img src={gifLoader} className="reports--btn--loader" alt="loader" />}
                <Col className="pt-2 pb-2 mt-3" md={12}>
                  {hasDeleteRecordExist ? <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                  >
                    Save
                  </button> : <button
                    type="button"
                    disabled={saveButtonDisabledFlag}
                    className="btn btn-primary float-right btn-sm  mr-3"
                    data-target="#deleteModal"
                    data-toggle="modal"
                    onClick={() => onClickSave()}
                  >
                    Save
                  </button>}
                  <button
                    type="button"
                    className="btn btn-outline-primary float-right btn-sm  mr-3"
                    disabled={viewMode}
                    onClick={() => clearState()}
                  >
                    Cancel
                  </button>
                </Col>
              </div>
            </Form>
          </div>
        </div>
      </Accordion.Collapse>
    </div>
  );
}

ValueAddService.propTypes = {
  getClientConfigurationRequest: PropTypes.object,
  updateClientConfigurationRequest: PropTypes.func,
  updateClientConfigurationResult: PropTypes.object,
  getClientConfigurationResult: PropTypes.object,
  getSystemMetaDataResult: PropTypes.object,
  getFormMode: PropTypes.any,
  activeCollapse: PropTypes.func,
  activeKey: PropTypes.any,

};
const mapStateToProps = state => ({
  getSystemMetaDataResult: state.getSystemMetaDataResult,
  getClientConfigurationResult: state.getClientConfigurationResult,
  updateClientConfigurationResult: state.updateClientConfigurationResult,
  getFormMode: state.getFormMode,
});

export default connect(
  mapStateToProps,
  { getClientConfigurationRequest, updateClientConfigurationRequest }
)(ValueAddService);
