import { actionTypes as types } from '../constants';

const initialState = {
  fetching: false,
  fetched: false,
  result: {},
  requestStatus: false,
  error: false,
};

export const createGeoFenceResult = (state = initialState, action) => {
  switch (action.type) {
  case types.CREATE_GEOFENCE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.CREATE_GEOFENCE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.CREATE_GEOFENCE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

export const deleteGeoFenceResult = (state = initialState, action) => {
  switch (action.type) {
  case types.DELETE_GEOFENCE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.DELETE_GEOFENCE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.DELETE_GEOFENCE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const viewGeoFenceResult = (state = initialState, action) => {
  switch (action.type) {
  case types.VIEW_GEOFENCE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.VIEW_GEOFENCE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.VIEW_GEOFENCE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};


export const searchGeoFenceResult = (state = initialState, action) => {
  switch (action.type) {
  case types.SEARCH_GEOFENCE_REQUEST:
    return {
      ...state, fetching: true, error: false, result: {},
    };
  case types.SEARCH_GEOFENCE_SUCCESS:
    return {
      ...state, fetching: false, fetched: true, requestStatus: true, result: action.data, error: false,
    };
  case types.SEARCH_GEOFENCE_FAILURE:
    return {
      ...state, fetching: false, fetched: false, requestStatus: false, result: false, error: true,
    };
  default:
    return state;
  }
};

const getGeoGenceState = {
  type: '',
  data: false,
};

export const getGeoFenceData = (state = getGeoGenceState, action) => {
  switch (action.type) {
  case types.SET_GEOFENCE:
    return { ...state, type: types.SET_GEOFENCE, data: action.data };
  case types.CLEAR_GEOFENCE:
    return { ...state, type: types.CLEAR_GEOFENCE, data: false };
  default:
    return state;
  }
};
