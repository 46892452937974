import timezones from 'timezones-list';

const GetUserListForAccount = () => {
  let userList = false;
  const userListForAccount = JSON.parse(localStorage.getItem('userListForAccount'));
  if (userListForAccount) {
    const getTimeZon = timezones.find(item => item.tzCode === userListForAccount.userTimezone);
    userList = JSON.stringify({
      userTypeId: userListForAccount.userTypeId,
      shared: userListForAccount.shared,
      firstName: userListForAccount.firstName,
      lastName: userListForAccount.lastName,
      mobile: userListForAccount.mobile,
      defaultClientId: userListForAccount.defaultClientId,
      userName: userListForAccount.userName,
      userId: userListForAccount.userId,
      userTimezone: userListForAccount.userTimezone,
      userUTCTimezone: getTimeZon && getTimeZon.utc,
      countryId: userListForAccount.countryId,
      email: userListForAccount.email,
      lagnuageId: userListForAccount.lagnuageId,
    });
  }
  return userList;
};

export default {
  baseCurrency: '',
  transactionState: '',
  datafull: true,
  clientId: localStorage.getItem('clientID') || '',
  // userListForAccount: localStorage.getItem('userListForAccount') || [],
  // additionalFilters: localStorage.getItem('additionalFilters') || [],
  userListForAccount: GetUserListForAccount(),
  clientIdNameList: localStorage.getItem('clientIDNameList') || '',
  counter: 0,
  kpi: [60],
  username: localStorage.getItem('username') || '',
  loginError: false,
  loginErrorMessage: '',
  permissionSet: localStorage.getItem('permissionSet') || '',
  logincounter: 0,
  promotionProductType: localStorage.getItem('promotionProductType') || [],
  promotionType: localStorage.getItem('promotionType') || '',
  promoType: localStorage.getItem('promoType') || '',
  promoLocation: localStorage.getItem('promoLocation') || '',
  initialized: '1001',
  authorized: '2000',
  captured: '2001',
  initializedPSP: '1009',
  cancelled: '2002',
  refunded: '2003',
  rejected: '2010',
  declined: '2011',
  voidFailed: '2021',
  threeDVerification: '2005',
  threeDVerificationfailed: '2016',
  authorizationnotattempted: '2017',
  awaitingPayment: '1041',
  paymentRequestExpired: '2015',
  paymentRequestCancelled: '2014',
  paymentUsingStoredCard: '2008',
  cancelFailed: '2012',
  refundFailed: '2013',
  captureInitialized: '20012',
  cancelInitialized: '20022',
  RefundInitialized: '20032',
  partialCaptured: '2201',
  partialRefunded: '2203',
  partialCancelled: '2202',
  postAuthScreenSkipBusinessRules: '3117',
  preAuthInitiated: '3010',
  preAuthSuccess: '3011',
  preAuthUnavbl: '3012',
  preAuthUnknown: '3013',
  preAuthReview: '3014',
  preAuthFail: '3015',
  preAuthConxFail: '3016',
  postAuthInitiated: '3110',
  postAuthSuccess: '3111',
  postAuthUnavbl: '3112',
  postAuthUnknown: '3113',
  postAuthReview: '3114',
  postAuthFail: '3115',
  postAuthConxFail: '3116',
  preAuthRevSuccess: '30141',
  preAuthRevFail: '30142',
  postAuthRevSuccess: '31141',
  postAuthRevFail: '31142',
  preAuthTechError: '3018',
  postAuthTechError: '3118',
  postAuthFraudCheckRequired: '3100',
  LINK_SENT_SUCCESSFULLY: 200,
  PAY_BY_LINK_STATUS_COMPLETED: 3,
  PAY_BY_LINK_STATUS_PARTIALLY_COMPLETED: 101,
  PAY_BY_LINK_STATUS_SCHEDULED: 2,
  PAY_BY_LINK_STATUS_CREATED: 1,
  PAY_BY_LINK_STATUS_FAILED: 400,
  MANUAL_TRIGGER: 1,
  BULK_UPLOAD: 2,
  Discount_percent: '%',
  Discount_flat: 'Flat',
  Discount_no: 'No discount',
  fileURL: localStorage.getItem('fileURL') || '',
  pblID: '',
  fileBannerURL: localStorage.getItem('fileBannerURL') || '',
  smsToggleValue: 0,
  emailToggleValue: 0,
  inappToggleValue: 0,
  custFullName: '',
  smsMsg: '',
  emailMsg: '',
  expiryDate: '',
  schedulePublishDate: new Date(),
  schedulePushDate: new Date(),
  scheduleTypeId: '',
  selectedcurrencyPbl: '',
  accountID: 100062,
  userID: localStorage.getItem('userID') || '',
  autopblID: '',
  clientName: localStorage.getItem('clientName') || '',
  promoID: '',
  iosToggleValue: 0,
  androidToggleValue: 0,
  webToggleValue: 0,
  recurrenceTypeId: '1',
  enabledRecurrence: true,
  recurrenceEndDate: new Date(),
  promotionView: false,
  promotionEdit: false,
  EventMode: 'create',
  newState: {},
  ExpiryDate: new Date(),
  eventID: '',
  filepromoURL: localStorage.getItem('filepromoURL') || '',
  invoiceFileName: '',
  headerFileURL: localStorage.getItem('headerFileURL') || '',
  headerFileName: '',
  filepromoname: '',
  bookToDate: `${(new Date()).toISOString().split('.')[0]}Z`,
  bookFromDate: `${(new Date()).toISOString().split('.')[0]}Z`,
  promoCode: '',
  promoDesc: '',
  merchantTimezone: localStorage.getItem('merchantTimezone') || '',
  selectedcurrencydashboard: '',
  merchantDate: '',
  provider: '',
};
