import React from 'react';
import PropTypes from 'prop-types';

const AlertPopup = props => (
  <div className="modal fade show active" id="alertModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Alert!</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          Are you sure you want to discard any unsaved changes?
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={props.onClickNo}>
            No
          </button>
          <button type="button" className="btn btn-outline-primary" data-dismiss="modal" onClick={props.onClickYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
);
AlertPopup.propTypes = {
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
};

export default AlertPopup;
