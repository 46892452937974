import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import '../../../../../static/style/vendor/react-bootstrap-table-all.min.css';
import { Accordion } from 'react-bootstrap';
import {
  getClientConfigurationRequest,
} from '../../../../../redux/actions/MerchantOnboarding';
import PageLoader from '../../../../shared/Loaders/TransparentLoader';

import MerchantConfiguration from './MerchantConfiguration';
import MerchantProperty from './MerchantProperty';
import MerchantURL from './MerchantURL';
import ValueAddService from './ValueAddService';

function MerchantSetup(props) {
  const [activeKey, setActiveKey] = useState('0');
  const [merchantSetup, setMerchantSetup] = useState('0');
  const [showLoader] = useState(false);

  useEffect(() => {
    props.getClientConfigurationRequest();
  }, []);

  function onBtnClick(location) {
    props.history.push(`/${location}`);
  }
  const permissions = props.getPermissionObject.data;
  const MerchantOnboardingCreate = permissions && permissions.actions.merchantOnBoarding.view;
  if (!MerchantOnboardingCreate) {
    props.history.push('/MerchantOnboarding');
  }
  return (
    <main role="main" className="col-md-12 mt3r pt-3 ml-auto">
      {showLoader && <PageLoader />}
      <div className="navlink-button-group">
        {!MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantOnboarding') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantOnboarding')}>
          Merchant Onboarding
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('MerchantSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('MerchantSetup')}>
          Merchant Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('PopSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('PopSetup')}>
          Pop Setup
        </Button>}
        {MerchantOnboardingCreate && <Button className={`navlink-button ${window.location.pathname.includes('VasSetup') ? 'btn-primary' : 'btn-light'}`} onClick={() => onBtnClick('VasSetup')}>
          VAS Setup
        </Button>}
      </div>
      <div>
        <Form className="p-4">
          <div className="row">
            <Form className="col-md-3">
              <div className="form-group">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type="text"
                  value={window.localStorage.clientName}
                  disabled={1}
                />
              </div>
            </Form>
          </div>
        </Form>
      </div>
      <Accordion>
        <MerchantConfiguration activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setMerchantSetup(1)} merchantSetup={merchantSetup} />
        <MerchantProperty activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setMerchantSetup(2)} merchantSetup={merchantSetup} />
        <MerchantURL activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setMerchantSetup(3)} merchantSetup={merchantSetup} />
        <ValueAddService activeKey={activeKey} activeCollapse={key => { setActiveKey(key); }} history={props.history} onClick={() => setMerchantSetup(5)} merchantSetup={merchantSetup} />
      </Accordion>
    </main>

  );
}

MerchantSetup.propTypes = {
  getPermissionObject: PropTypes.object,
  getClientConfigurationRequest: PropTypes.object,
  history: PropTypes.func,
};
const mapStateToProps = state => ({
  getPermissionObject: state.getPermissionObject,
  getClientConfigurationResult: state.getClientConfigurationResult,
  prevClientConfigurationResult: state.getClientConfigurationResult,
});
export default connect(
  mapStateToProps,
  {
    getClientConfigurationRequest,
  }
)(MerchantSetup);
